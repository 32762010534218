import { all, takeEvery, put, fork } from "redux-saga/effects";

//import axios from "axios";
import axios from "./initialize";

import { Modal } from "antd";
import {
  ExclamationCircleOutlined,
  CheckCircleFilled,
  WarningFilled
} from "@ant-design/icons";
import {
  SAVE_APP,
  DELETE_APP,
  GET_APP_LIST,
  PUBLISH_APP,
  EDIT_APP,
  GET_PUBLISHED_APP_LIST,
  SUBSCRIBE_APP,
  GET_SUBSCRIBED_APP_LIST,
  GET_DEVELOPER_APP,
  GET_MFA_SETUP_HOME,
  APP_LOGIN_AUTHENTICATION,
  USING_2FA,
  GET_USING_2FA_VALUE,
  ADD_APP_PERMISSION,
  ADD_APP_ROLE,
  GET_APP_PERMISSIONS,
  GET_APP_ROLES,
  DELETE_APP_PERMISSION,
  UPDATE_APP_PERMISSION,
  DELETE_APP_ROLE,
  UPDATE_APP_ROLE,
  GET_APP_ROLE_PERMISSIONS,
  ADD_APP_SETTINGS_JSON,
  GET_APP_SETTINGS_DETAILS,
  GET_APP_INSTANCE_DATA,
  UI_PROPERTIES_JSON,
  APP_UI_PROPERTIES,
  ADD_APP_UI_PROPERTIES,
  EDIT_APP_UI_PROPERTIES,
  DELETE_APP_UI_PROPERTIES,
  GET_ADMIN_APP_UI_PROPERTIES,
  SUBSCRIBE_APP_DETAIL_PAGE,
  SUBSCRIBE_APP_SETTINGS_PAGE,
  GET_ACCOUNT_USER_APP_INSTANCE_ROLES
} from "redux/constants/app";

import {
  apps,
  app,
  showAppMessage,
  registerSuccess,
  publishedAppList,
  subscribedAppList,
  developerApp,
  twoFactorPageSuccess,
  toggle2fa,
  appPermissions,
  appRoles,
  appRolePermissions,
  setAddEdit,
  appSettingsJson,
  appData,
  uiPropertiesJson,
  accountUserAppInstanceRoles
} from "redux/actions/app";
import { API_BASE_DEVELOPER_APP, API_BASE_URL, APP_PREFIX_PATH, AUTH_PREFIX_PATH, MANAGE_PREPIX_PATH, USERS_PREPIX_PATH,API,VERSION } from "configs/AppConfig";
import { AUTH_TOKEN } from "../constants/Auth";
import { t } from "i18next";
import { PATH_VARIABLE } from "constants/ApiConstant";
import { appInstaneData } from "redux/actions/appInstance";

export function* getApps() {
  yield takeEvery(GET_APP_LIST, function* ({ requestData }) {
    // const {email, password} = payload;
    // const {query, sorter,pagination} = requestData;
    const userId = localStorage.getItem("id");
    const accountId = localStorage.getItem("accountId");
    const config = {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
        accountId: localStorage.getItem("accountId"),
        appInstanceId: localStorage.getItem("appInstanceId"),
        accountUserId: localStorage.getItem("accountUserId")
      },
    };
    try {
      const appResponse = yield axios.get(
        `${API_BASE_DEVELOPER_APP}/developerApp/apps?accountId=${accountId}&query=${
          requestData?.query
        }&sortfield=${requestData?.sorter?.field}&sortorder=${
          requestData?.sorter?.order
        }&page=${requestData?.pagination?.current - 1}&size=${
          requestData?.pagination?.pageSize
        }&type=${
          requestData?.type?requestData?.type:"app"
        }`,
        config
      );
      if (appResponse.status === 200) {
        yield put(apps(appResponse.data));
      }
    } catch (err) {
      console.log(err);
      yield put(showAppMessage(err?.response));
    }
  });
}

export function* publishApp() {
  yield takeEvery(PUBLISH_APP, function* ({ payload }) {
    const config = {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
        accountId: localStorage.getItem("accountId"),
        appInstanceId: localStorage.getItem("appInstanceId"),
        accountUserId: localStorage.getItem("accountUserId")
      },
    };
    try {
      const res = yield axios.put(
        `${API_BASE_DEVELOPER_APP}/developerApp/app/${payload.id}`,
        payload,
        config
      );
      if (res.status === 200) {
        var title = t("impaqx.adminApp.appUpdateMessage")?.replace("updated",payload?.published?payload?.published?.toString()
        .toLowerCase():"updated").replace("App",payload?.type?.toString()
        .toLowerCase());
        Modal.success({
          title: title?.charAt(0).toUpperCase() + title?.slice(1),
          icon: <CheckCircleFilled />,
          okText:t("impaqx.adminApp.close"),
          //cancelText: 'Cancel',
          onOk() {
            //deleteApp(id);
          },
        });
        yield put(showAppMessage(res));
      }
    } catch (error) {
      console.log(error);
      yield put(showAppMessage(error?.response));
    }
  });
}

export function* getPublishedApps() {
  yield takeEvery(GET_PUBLISHED_APP_LIST, function* ({ payload }) {
    try {
      const accountId = localStorage.getItem("accountId");
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
          Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
          accountId: localStorage.getItem("accountId"),
          appInstanceId: localStorage.getItem("appInstanceId"),
          accountUserId: localStorage.getItem("accountUserId")
        },
      };
      const res = yield axios.get(
        `${API_BASE_DEVELOPER_APP}/developerApp/publishedApps?accountId=${accountId}&published=${payload.published}&type=${payload.type}`,
        config
      );
      if (res.status === 200) {
        yield put(publishedAppList(res.data));
      }
    } catch (error) {
      console.log(error);
      yield put(showAppMessage(error?.response));
    }
  });
}

export function* getSubscribedApps() {
  yield takeEvery(GET_SUBSCRIBED_APP_LIST, function* ({ requestData }) {
    try {
      const accountId = localStorage.getItem("accountId");
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
          Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
          accountId: localStorage.getItem("accountId"),
          appInstanceId: localStorage.getItem("appInstanceId"),
          accountUserId: localStorage.getItem("accountUserId")
        },
      };
      const res = yield axios.get(
        `${API_BASE_DEVELOPER_APP}/developerApp/subscribedApps?accountId=${accountId}&query=${requestData?.query}&type=${requestData?.type}`,
        config
      );
      if (res.status === 200) {
        yield put(subscribedAppList(res.data));
      }
    } catch (error) {
      console.log(error);
      yield put(showAppMessage(error?.response));
    }
  });
}

export function* getDeveloperApp() {
  yield takeEvery(GET_DEVELOPER_APP, function* ({ payload }) {
    try {
      const accountId = localStorage.getItem("accountId");
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
          Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
          accountId: localStorage.getItem("accountId"),
          appName: `DeveloperApp_${accountId}`,
        },
      };
      const developerAppResponse = yield axios.get(
        `${API_BASE_DEVELOPER_APP}/developerApp/developerApp?appName=DeveloperApp_${accountId}&accountId=${accountId}`,
        config
      );
      if (developerAppResponse.status === 200) {
        var app = {};
        if (localStorage.getItem(AUTH_TOKEN) == undefined) {
          app["username"] = localStorage.getItem("username");
          app["userId"] = localStorage.getItem("userId");
          app["accountId"] = localStorage.getItem("accountId");
          app["accountUserId"] = localStorage.getItem("accountUserId");
          app["businessName"] = localStorage.getItem("businessName");
          app["accountCode"] = localStorage.getItem("accountCode");
          app["subscriptionId"] = developerAppResponse?.data?.id;
          app["appInstanceId"] = developerAppResponse?.data?.id;
          app["appId"] = developerAppResponse.data.appId;
          var code = Date.now();
          const tokenResponse = yield axios.post(
            `${API_BASE_URL}${MANAGE_PREPIX_PATH}${API}${VERSION}${USERS_PREPIX_PATH}/appLoginAuthentication?code=${code}`,
            app,
            config
          );
          if (tokenResponse.status === 200) {
           // localStorage.setItem(AUTH_TOKEN, tokenResponse.data.accessToken);
            localStorage.setItem("accountId", app.accountId);
            localStorage.setItem("appId", app.appId);
            localStorage.setItem("appInstanceId", app.id);
            localStorage.setItem("subscriptionId", app.id);
            yield put(developerApp(tokenResponse.data));
          }
        }
      }
    } catch (error) {
      console.log(error);
      yield put(showAppMessage(error?.response));
    }
  });
}

export function* subscribeAppSettingsPage() {
  yield takeEvery(SUBSCRIBE_APP_SETTINGS_PAGE, function* ({ payload }) {
    try {
      payload["accountId"] = localStorage.getItem("accountId");
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
          Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
          accountId: localStorage.getItem("accountId"),
          appInstanceId: localStorage.getItem("appInstanceId"),
          accountUserId: localStorage.getItem("accountUserId")
        },
      };

      const appInstanceResonse = yield axios.post(
        `${API_BASE_DEVELOPER_APP}/developerApp/accountAppInstance?userType=${localStorage.getItem("userType")}`,
        payload,
        config
      );
      if (appInstanceResonse.status === 200) {
        if (appInstanceResonse.data.data.status == "ACTIVE") {
          Modal.success({
            title: `${t("impaqx.adminApp.subscribedSettingsMessage")}  ${payload.name}.`,
            content: `${t("impaqx.adminApp.subscriptionTerm")} : ${appInstanceResonse.data.data.subscriptionTerm}`,
            icon: <CheckCircleFilled />,
            okText: "Close",
            //cancelText: 'Cancel',
            onOk() {
              //deleteApp(id);
            },
          });
          yield put(appInstaneData(appInstanceResonse?.data?.data))
        } else {
          Modal.info({
            title: `${t("impaqx.adminApp.unSubscribedMessage")} ${payload.name}.`,
            content: `${t("impaqx.adminApp.subscriptionTerm")} : ${appInstanceResonse.data.data.subscriptionTerm}`,
            icon: <CheckCircleFilled />,
            okText: "Close",
            //cancelText: 'Cancel',
            onOk() {
              //deleteApp(id);
              window.location.replace(`${PATH_VARIABLE}/app/${appInstanceResonse?.data?.data?.accountId}/${appInstanceResonse?.data?.data?.appId}/detialPage`);
            },
          });
        }
       }
      } catch (err) {
        console.log(err);
        yield put(showAppMessage(err?.response));
    }
  })
}

export function* subscribeAppDetailPage() {
  yield takeEvery(SUBSCRIBE_APP_DETAIL_PAGE, function* ({ payload }) {
    try {
      payload["accountId"] = localStorage.getItem("accountId");
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
          Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
          accountId: localStorage.getItem("accountId"),
          appInstanceId: localStorage.getItem("appInstanceId"),
          accountUserId: localStorage.getItem("accountUserId")
        },
      };

      const appInstanceResonse = yield axios.post(
        `${API_BASE_DEVELOPER_APP}/developerApp/accountAppInstance?userType=${localStorage.getItem("userType")}`,
        payload,
        config
      );
      if (appInstanceResonse.status === 200) {
        if (appInstanceResonse.data.data.status == "ACTIVE") {
          Modal.success({
            title: `${t("impaqx.adminApp.subscribedMessage")}  ${payload.name}.`,
            content: `${t("impaqx.adminApp.subscriptionTerm")} : ${appInstanceResonse.data.data.subscriptionTerm}`,
            icon: <CheckCircleFilled />,
            okText: "Close",
            //cancelText: 'Cancel',
            onOk() {
              window.location.replace(`${PATH_VARIABLE}/app/${appInstanceResonse?.data?.data?.accountId}/${appInstanceResonse?.data?.data?.appId}/${appInstanceResonse?.data?.data?.id}/detialPage`);
            },
          });
        } else {
          Modal.info({
            title: `${t("impaqx.adminApp.unSubscribedMessage")} ${payload.name}.`,
            content: `${t("impaqx.adminApp.subscriptionTerm")} : ${appInstanceResonse.data.data.subscriptionTerm}`,
            icon: <CheckCircleFilled />,
            okText: "Close",
            //cancelText: 'Cancel',
            onOk() {
              window.location.replace(`${PATH_VARIABLE}/app/${appInstanceResonse?.data?.data?.accountId}/${appInstanceResonse?.data?.data?.appId}/detialPage`);
            },
          });
        }
       }
      } catch (err) {
        console.log(err);
        yield put(showAppMessage(err?.response));
    }
  })
}

export function* subscribeApp() {
  yield takeEvery(SUBSCRIBE_APP, function* ({ payload }) {
    try {
      payload["accountId"] = localStorage.getItem("accountId");
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
          Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
          accountId: localStorage.getItem("accountId"),
          appInstanceId: localStorage.getItem("appInstanceId"),
          accountUserId: localStorage.getItem("accountUserId")
        },
      };

      const appInstanceResonse = yield axios.post(
        `${API_BASE_DEVELOPER_APP}/developerApp/accountAppInstance?userType=${localStorage.getItem("userType")}`,
        payload,
        config
      );
      if (appInstanceResonse.status === 200) {
        if (appInstanceResonse.data.data.status == "ACTIVE") {
          Modal.success({
            title: `${t("impaqx.adminApp.subscribedMessage")}  ${payload.name}.`,
            content: `${t("impaqx.adminApp.subscriptionTerm")} : ${appInstanceResonse.data.data.subscriptionTerm}`,
            icon: <CheckCircleFilled />,
            okText: "Close",
            //cancelText: 'Cancel',
            onOk() {
              //deleteApp(id);
            },
          });
        } else {
          Modal.info({
            title: `${t("impaqx.adminApp.unSubscribedMessage")} ${payload.name}.`,
            content: `${t("impaqx.adminApp.subscriptionTerm")} : ${appInstanceResonse.data.data.subscriptionTerm}`,
            icon: <CheckCircleFilled />,
            okText: "Close",
            //cancelText: 'Cancel',
            onOk() {
              //deleteApp(id);
            },
          });
        }
        const accountId = localStorage.getItem("accountId");
        const config = {
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
            Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
            accountId: localStorage.getItem("accountId"),
            appInstanceId: localStorage.getItem("appInstanceId"),
            accountUserId: localStorage.getItem("accountUserId")
          },
        };
        const res = yield axios.get(
          `${API_BASE_DEVELOPER_APP}/developerApp/accountUser-appInstanceRole?accountUserId=${localStorage.getItem("accountUserId")}`,
          config
        );
        if (res.status === 200) {
          yield put(accountUserAppInstanceRoles(res.data));
        }
        const subscribedAppResponse = yield axios.get(
          `${API_BASE_DEVELOPER_APP}/developerApp/subscribedApps?accountId=${accountId}&query=`,
          config
        );
        if (subscribedAppResponse.status === 200) {
          yield put(subscribedAppList(subscribedAppResponse.data));
        } else {
          yield put(showAppMessage(subscribedAppResponse));
        }

        yield put(registerSuccess(appInstanceResonse));
      } else {
        yield put(showAppMessage(appInstanceResonse));
      }
    } catch (err) {
      console.log(err);
      yield put(showAppMessage(err?.response));
    }
  });
}

export function* editApp() {
  yield takeEvery(EDIT_APP, function* ({ payload }) {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
          Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
          accountId: localStorage.getItem("accountId"),
          appInstanceId: localStorage.getItem("appInstanceId"),
          accountUserId: localStorage.getItem("accountUserId")
        },
      };
      const res = yield axios.get(
        `${API_BASE_DEVELOPER_APP}/developerApp/app/${payload}`,
        config
      );
      if (res.status === 200) {
        yield put(app(res.data));
      }
    } catch (error) {
      console.log(error);
      yield put(showAppMessage(error?.response));
    }
  });
}

export function* appRegister() {
  yield takeEvery(SAVE_APP, function* ({ payload }) {
    try {
      const accountId = localStorage.getItem("accountId");
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
          Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
          accountId: localStorage.getItem("accountId"),
          appInstanceId: localStorage.getItem("appInstanceId"),
          accountUserId: localStorage.getItem("accountUserId")
        },
      };
      const appRegResponse = yield axios.post(
        `${API_BASE_DEVELOPER_APP}/developerApp/app`,
        payload,
        config
      );
      if (appRegResponse.status === 200) {
        if (payload.name.startsWith("DeveloperApp")) {
          let subscribeData = {};
          subscribeData["accountId"] = localStorage.getItem("accountId");
          subscribeData["appId"] = appRegResponse.data.id;
          subscribeData["status"] = "ACTIVE";
          subscribeData["userCreated"] = localStorage.getItem("userId");
          subscribeData["userUpdated"] = localStorage.getItem("userId");
          subscribeData["subscriptionTerm"] = "ALLTIME";
          subscribeData["subscriptionRenewalTerm"] = "ALLTIME";
          const developerAppInstanceResponse = yield axios.post(
            `${API_BASE_DEVELOPER_APP}/developerApp/accountAppInstance?userType=${localStorage.getItem("userType")}`,
            subscribeData,
            config
          );
          if (developerAppInstanceResponse.status === 200) {
            const appsResponse = yield axios.get(
              `${API_BASE_DEVELOPER_APP}/developerApp/apps?accountId=${accountId}&query=${
                payload?.query
              }&sortfield=${payload?.sorter?.field}&sortorder=${
                payload?.sorter?.order
              }&page=${payload?.pagination?.current - 1}&size=${
                payload?.pagination?.pageSize
              }&type=${
                appRegResponse?.type
              }`,
              config
            );
            if (appsResponse.status === 200) {
              Modal.success({
                title: t("impaqx.adminApp.developerPortalSuccessMessage"),
                icon: <CheckCircleFilled />,
                okText: t("impaqx.adminApp.close"),
                //cancelText: 'Cancel',
                onOk() {
                  //deleteApp(id);
                },
              });
              yield put(apps(appsResponse.data));
            }
          }
        } else {
          Modal.success({
            title: t("impaqx.adminApp.appSuccessMessage").replace("App",payload?.type),
            icon: <CheckCircleFilled />,
            okText:t("impaqx.adminApp.close"),
            //cancelText: 'Cancel',
            onOk() {
              //deleteApp(id);
            },
          });
          yield put(registerSuccess(appRegResponse));
        }
      } else {
        yield put(showAppMessage(appRegResponse));
      }
    } catch (err) {
      console.log(err);
      yield put(showAppMessage(err?.response));
    }
  });
}

export function* deleteApp() {
  yield takeEvery(DELETE_APP, function* ({ payload }) {
    try {
      const accountId = localStorage.getItem("accountId");
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
          Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
          accountId: localStorage.getItem("accountId"),
          appInstanceId: localStorage.getItem("appInstanceId"),
          accountUserId: localStorage.getItem("accountUserId")
        },
      };
      const res = yield axios.delete(
        `${API_BASE_DEVELOPER_APP}/developerApp/app/${payload.id}`,
        config
      );
      if (res.status === 200) {
        //yield put(showAccountMessage(res));
        const response = yield axios.get(
          `${API_BASE_DEVELOPER_APP}/developerApp/apps?accountId=${accountId}&query=${
            payload?.query
          }&sortfield=${payload?.sorter?.field}&sortorder=${
            payload?.sorter?.order
          }&page=${payload?.pagination?.current - 1}&size=${
            payload?.pagination?.pageSize
          }&type=${
            payload?.type
          }`,
          config
        );
        if (response.status === 200) {
          Modal.success({
            title: `${payload.name}  ${t("impaqx.adminApp.appDeleteMessage")}`,
            icon: <CheckCircleFilled />,
            okText: "Close",
            //cancelText: 'Cancel',
            onOk() {
              //deleteApp(id);
            },
          });
          yield put(apps(response.data));
        }
      }
    } catch (error) {
      console.log(error);
      yield put(showAppMessage(error?.response));
    }
  });
}

export function* getMfaSetupHome() {
  yield takeEvery(GET_MFA_SETUP_HOME, function* ({ username }) {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
          Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
          accountId: localStorage.getItem("accountId"),
          appInstanceId: localStorage.getItem("appInstanceId"),
          accountUserId: localStorage.getItem("accountUserId")
        },
      };
      const res = yield axios.get(
        `${API_BASE_URL}${MANAGE_PREPIX_PATH}${API}${VERSION}${USERS_PREPIX_PATH}/mfasetup?username=${username}`,
        config
      );
      if (res.status === 200) {
        yield put(twoFactorPageSuccess(res.data));
      }
    } catch (error) {
      console.log(error.response);
      yield put(showAppMessage(error?.response));
    }
  });
}
export function* appLoginAuthentication() {
  yield takeEvery(APP_LOGIN_AUTHENTICATION, function* ({ app }) {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
          Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
          accountId: localStorage.getItem("accountId"),
          appInstanceId: localStorage.getItem("appInstanceId"),
          accountUserId: localStorage.getItem("accountUserId")
        },
      };
      var appData = {};
      appData["username"] = localStorage.getItem("username");
      appData["userId"] = localStorage.getItem("userId");
      appData["accountId"] = localStorage.getItem("accountId");
      appData["accountUserId"] = localStorage.getItem("accountUserId");
      appData["businessName"] = localStorage.getItem("businessName");
      appData["accountCode"] = localStorage.getItem("accountCode");
      appData["appInstanceId"] = app.id;
      appData["subscriptionId"] = app.id;
      appData["appId"] = app.appId;
      var code = Date.now();
      const res = yield axios.post(
        `${API_BASE_URL}${MANAGE_PREPIX_PATH}${API}${VERSION}${USERS_PREPIX_PATH}/appLoginAuthentication?code=${code}`,
        appData,
        config
      );
      if (res.status === 200) {
       // localStorage.setItem(AUTH_TOKEN, res.data.accessToken);
        localStorage.setItem("accountId", res.data.accountId);
        localStorage.setItem("appId", res.data.appId);
        localStorage.setItem("appInstanceId", res.data.subscriptionId);
        localStorage.setItem("subscriptionId", res.data.subscriptionId);
        window.open(app.accessUrl + "?code=" + code);
        //newWindow.postMessage({ message: "requestResult" }, "*");

      	yield put(showAppMessage(res));
      }
    } catch (error) {
      console.log(error.response);
      yield put(showAppMessage(error?.response));
    }
  });
}

export function* onUsing2fa() {
  yield takeEvery(USING_2FA, function* ({ using2fa }) {
    const username = localStorage.getItem("username");
    const config = {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
        accountId: localStorage.getItem("accountId"),
        appInstanceId: localStorage.getItem("appInstanceId"),
        accountUserId: localStorage.getItem("accountUserId")
      },
    };
    try {
      const res = yield axios.get(
        `${API_BASE_URL}${MANAGE_PREPIX_PATH}${API}${VERSION}${USERS_PREPIX_PATH}/using2fa?using2fa=${using2fa}&username=${username}`,
        config
      );
      if (res.status === 200) {
        yield put(toggle2fa(using2fa));
      }
    } catch (error) {
      console.log(error.response);
      yield put(showAppMessage(error?.response));
    }
  });
}

export function* getUsing2faValue() {
  yield takeEvery(GET_USING_2FA_VALUE, function* ({ using2fa }) {
    const username = localStorage.getItem("username");
    const config = {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
        accountId: localStorage.getItem("accountId"),
        appInstanceId: localStorage.getItem("appInstanceId"),
        accountUserId: localStorage.getItem("accountUserId")
      },
    };
    try {
      const res = yield axios.get(
        `${API_BASE_URL}${MANAGE_PREPIX_PATH}${API}${VERSION}${USERS_PREPIX_PATH}/getUsing2fa?username=${username}`,
        config
      );
      if (res.status === 200) {
        yield put(toggle2fa(res.data));
      }
    } catch (error) {
      console.log(error.response);
      yield put(showAppMessage(error?.response));
    }
  });
}

export function* addAppPermission() {
  yield takeEvery(ADD_APP_PERMISSION, function* ({ payload }) {
    try {
      const accountId = localStorage.getItem("accountId");
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
          Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
          accountId: localStorage.getItem("accountId"),
          appInstanceId: localStorage.getItem("appInstanceId"),
          accountUserId: localStorage.getItem("accountUserId")
        },
      };
      const res = yield axios.post(
        `${API_BASE_DEVELOPER_APP}/developerApp/appPermission`,
        payload,
        config
      );
      if (res.status === 200) {
        const response = yield axios.get(
          `${API_BASE_DEVELOPER_APP}/developerApp/appPermissions?appId=${
            res?.data?.permission?.appId
          }&query=${payload?.query}&sortfield=${
            payload?.sorter?.field
          }&sortorder=${payload?.sorter?.order}&page=${
            payload?.pagination?.current - 1
          }&size=${payload?.pagination?.pageSize}`,
          config
        );
        if (response.status === 200) {
          Modal.success({
            title: t("impaqx.adminApp.permissionCreateSuccessMessage"),
            icon: <CheckCircleFilled />,
            okText: t("impaqx.adminApp.close"),
            //cancelText: 'Cancel',
            onOk() {
              //deleteApp(id);
            },
          });
        }
        yield put(appPermissions(response.data));
        yield put(setAddEdit(false));
      }
    } catch (err) {
      console.log(err);
      yield put(showAppMessage(err?.response));
    }
  });
}

export function* addAppRole() {
  yield takeEvery(ADD_APP_ROLE, function* ({ payload }) {
    try {
      const { values, checkedPermission } = payload;
      //const accountId = localStorage.getItem("accountId");
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
          Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
          accountId: localStorage.getItem("accountId"),
          appInstanceId: localStorage.getItem("appInstanceId"),
          accountUserId: localStorage.getItem("accountUserId")
        },
      };
      const res = yield axios.post(
        `${API_BASE_DEVELOPER_APP}/developerApp/appDefaultUserRole`,
        values,
        config
      );
      if (res.status === 200) {
        var appDefaultUserRolePermissionData = {};
        if (res.data.appDefaultUserRole) {
          Modal.success({
            title: t("impaqx.adminApp.roleCreatedSuccessMessage"),
            icon: <CheckCircleFilled />,
            okText: t("impaqx.adminApp.close"),
            //cancelText: 'Cancel',
            onOk() {
              //deleteApp(id);
            },
          });
          const appRoleResponse = yield axios.get(
            `${API_BASE_DEVELOPER_APP}/developerApp/appDefaultUserRoles?appId=${
              res?.data?.appDefaultUserRole?.appId
            }&userType=${payload?.userType}&query=${payload?.query}&sortfield=${
              payload?.sorter?.field
            }&sortorder=${payload?.sorter?.order}&page=${
              payload?.pagination?.current - 1
            }&size=${payload?.pagination?.pageSize}`,
            config
          );
          if (appRoleResponse.status === 200) {
            yield put(appRoles(appRoleResponse.data));
            yield put(setAddEdit(false));
          }
          appDefaultUserRolePermissionData["appDefaultUserRolesId"] =
            res.data.appDefaultUserRole.id;
          // appDefaultUserRolePermissionData["status"]="ACTIVE";
          appDefaultUserRolePermissionData["userCreated"] =
            res.data.appDefaultUserRole.userCreated;
          appDefaultUserRolePermissionData["userUpdated"] =
            res.data.appDefaultUserRole.userCreated;
          // Object.keys(checkedPermission).forEach(function(appPermissionId) {
          // 	appDefaultUserRolePermissionData["appPermissionsId"]=appPermissionId;
          appDefaultUserRolePermissionData["checkedPermission"] =
            checkedPermission;
          const response = yield axios.post(
            `${API_BASE_DEVELOPER_APP}/developerApp/appDefaultUserRolePermission`,
            appDefaultUserRolePermissionData,
            config
          );
          if (response.status === 200) {
            //	yield put(apps(response.data));
          }
          //});
        }
      }
    } catch (err) {
      console.log(err);
      yield put(showAppMessage(err?.response));
    }
  });
}

export function* getAppPermissions() {
  yield takeEvery(GET_APP_PERMISSIONS, function* ({ payload }) {
    // const userId = localStorage.getItem("id")
    //const accountId = localStorage.getItem("accountId")
    const config = {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
        accountId: localStorage.getItem("accountId"),
        appInstanceId: localStorage.getItem("appInstanceId"),
        accountUserId: localStorage.getItem("accountUserId")
      },
    };
    try {
      const res = yield axios.get(
        `${API_BASE_DEVELOPER_APP}/developerApp/appPermissions?appId=${
          payload?.appId
        }&query=${payload?.query}&sortfield=${
          payload?.sorter?.field
        }&sortorder=${payload?.sorter?.order}&page=${
          payload?.pagination?.current - 1
        }&size=${payload?.pagination?.pageSize}`,
        config
      );
      if (res.status === 200) {
        yield put(appPermissions(res.data));
      }
    } catch (err) {
      console.log(err);
      yield put(showAppMessage(err?.response));
    }
  });
}

export function* getAppRoles() {
  yield takeEvery(GET_APP_ROLES, function* ({ payload }) {
    const userId = localStorage.getItem("id");
    const accountId = localStorage.getItem("accountId");
    const config = {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
        accountId: localStorage.getItem("accountId"),
        appInstanceId: localStorage.getItem("appInstanceId"),
        accountUserId: localStorage.getItem("accountUserId")
      },
    };
    try {
      const res = yield axios.get(
        `${API_BASE_DEVELOPER_APP}/developerApp/appDefaultUserRoles?appId=${
          payload?.appId
        }&appName=${
          payload?.appName
        }&userType=${payload?.userType}&query=${payload?.query}&sortfield=${
          payload?.sorter?.field
        }&sortorder=${payload?.sorter?.order}&page=${
          payload?.pagination?.current - 1
        }&size=${payload?.pagination?.pageSize}`,
        config
      );
      if (res.status === 200) {
        yield put(appRoles(res.data));
      }
    } catch (err) {
      console.log(err);
      yield put(showAppMessage(err?.response));
    }
  });
}

export function* deleteAppPermission() {
  yield takeEvery(DELETE_APP_PERMISSION, function* ({ payload }) {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
          Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
          accountId: localStorage.getItem("accountId"),
          appInstanceId: localStorage.getItem("appInstanceId"),
          accountUserId: localStorage.getItem("accountUserId")
        },
      };
      const res = yield axios.delete(
        `${API_BASE_DEVELOPER_APP}/developerApp/appPermission/${payload.id}`,
        config
      );
      if (res.status === 200) {
        //yield put(showAccountMessage(res));
        const response = yield axios.get(
          `${API_BASE_DEVELOPER_APP}/developerApp/appPermissions?appId=${
            payload?.appId
          }&query=${payload?.query}&sortfield=${
            payload?.sorter?.field
          }&sortorder=${payload?.sorter?.order}&page=${
            payload?.pagination?.current - 1
          }&size=${payload?.pagination?.pageSize}`,
          config
        );
        if (response.status === 200) {
          Modal.success({
            title: `${payload.permission}  ${t("impaqx.adminApp.deletePermissionMessage")}`,
            icon: <CheckCircleFilled />,
            okText: "Close",
            //cancelText: 'Cancel',
            onOk() {
              //deleteApp(id);
            },
          });
          yield put(appPermissions(response.data));
        }
      }
    } catch (error) {
      console.log(error);
      yield put(showAppMessage(error?.response));
    }
  });
}

export function* updateAppPermission() {
  yield takeEvery(UPDATE_APP_PERMISSION, function* ({ payload }) {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
          Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
          accountId: localStorage.getItem("accountId"),
          appInstanceId: localStorage.getItem("appInstanceId"),
          accountUserId: localStorage.getItem("accountUserId")
        },
      };
      const res = yield axios.put(
        `${API_BASE_DEVELOPER_APP}/developerApp/appPermission/${payload.id}`,
        payload,
        config
      );
      if (res.status === 200) {
        const response = yield axios.get(
          `${API_BASE_DEVELOPER_APP}/developerApp/appPermissions?appId=${
            res?.data?.appId
          }&query=${payload?.query}&sortfield=${
            payload?.sorter?.field
          }&sortorder=${payload?.sorter?.order}&page=${
            payload?.pagination?.current - 1
          }&size=${payload?.pagination?.pageSize}`,
          config
        );
        if (response.status === 200) {
          Modal.success({
            title: `${payload.permission}  ${t("impaqx.adminApp.updatePermissionMessage")}`,
            icon: <CheckCircleFilled />,
            okText: "Close",
            //cancelText: 'Cancel',
            onOk() {
              //deleteApp(id);
            },
          });
          yield put(appPermissions(response.data));
          yield put(setAddEdit(false));
        }
      }
    } catch (error) {
      console.log(error);
      yield put(showAppMessage(error?.response));
    }
  });
}

export function* deleteAppRole() {
  yield takeEvery(DELETE_APP_ROLE, function* ({ payload }) {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
          Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
          accountId: localStorage.getItem("accountId"),
          appInstanceId: localStorage.getItem("appInstanceId"),
          accountUserId: localStorage.getItem("accountUserId")
        },
      };
      const res = yield axios.delete(
        `${API_BASE_DEVELOPER_APP}/developerApp/appDefaultUserRole/${payload.id}`,
        config
      );
      if (res.status === 200) {
        //yield put(showAccountMessage(res));
        const response = yield axios.get(
          `${API_BASE_DEVELOPER_APP}/developerApp/appDefaultUserRoles?appId=${
            payload?.appId
          }&userType=${payload?.userType}&query=${payload?.query}&sortfield=${
            payload?.sorter?.field
          }&sortorder=${payload?.sorter?.order}&page=${
            payload?.pagination?.current - 1
          }&size=${payload?.pagination?.pageSize}`,
          config
        );
        if (response.status === 200) {
          Modal.success({
            title: `${payload.roleName}  ${t("impaqx.adminApp.roleDeleteMessage")}`,
            icon: <CheckCircleFilled />,
            okText: "Close",
            //cancelText: 'Cancel',
            onOk() {
              //deleteApp(id);
            },
          });
          yield put(appRoles(response.data));
        }
      }
    } catch (error) {
      console.log(error);
      if(error?.response?.status==403)
      Modal.error({
        title: t("impaqx.adminApp.roleDeletedErrorMessage"),
        icon: <WarningFilled />,
        okText: t("impaqx.adminApp.close"),
        //cancelText: 'Cancel',
        onOk() {
          //deleteApp(id);
        },
      });
      yield put(showAppMessage(error?.response));
    }
  });
}

export function* updateAppRole() {
  yield takeEvery(UPDATE_APP_ROLE, function* ({ payload }) {
    try {
      const { values, checkedPermission } = payload;
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
          Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
          accountId: localStorage.getItem("accountId"),
          appInstanceId: localStorage.getItem("appInstanceId"),
          accountUserId: localStorage.getItem("accountUserId")
        },
      };
      const res = yield axios.put(
        `${API_BASE_DEVELOPER_APP}/developerApp/appDefaultUserRole/${values.id}`,
        values,
        config
      );
      if (res.status === 200) {
        //const response = yield  axios.get(`${API_BASE_DEVELOPER_APP}/developerApp/appDefaultUserRoles?appId=${res?.data?.appId}&query=${payload?.query}&sortfield=${payload?.sorter?.field}&sortorder=${payload?.sorter?.order}&page=${payload?.pagination?.current-1}&size=${payload?.pagination?.pageSize}`);
        //if (response.status===200) {
        var appDefaultUserRolePermissionData = {};
        appDefaultUserRolePermissionData["appDefaultUserRolesId"] =
          res.data.appDefaultUserRole.id;
        // appDefaultUserRolePermissionData["status"]="ACTIVE";
        appDefaultUserRolePermissionData["userCreated"] =
          res.data.appDefaultUserRole.userCreated;
        appDefaultUserRolePermissionData["userUpdated"] =
          res.data.appDefaultUserRole.userCreated;
        // Object.keys(checkedPermission).forEach(function(appPermissionId) {
        // 	appDefaultUserRolePermissionData["appPermissionsId"]=appPermissionId;
        appDefaultUserRolePermissionData["checkedPermission"] =
          checkedPermission;
        const response = yield axios.post(
          `${API_BASE_DEVELOPER_APP}/developerApp/appDefaultUserRolePermission`,
          appDefaultUserRolePermissionData,
          config
        );
        if (response.status === 200) {
          //	yield put(apps(response.data));
          Modal.success({
            title: `${values.roleName}  ${t("impaqx.adminApp.permissionUpdateMessage")}`,
            icon: <CheckCircleFilled />,
            okText: "Close",
            //cancelText: 'Cancel',
            onOk() {
              //deleteApp(id);
            },
          });
        }
        const appRoleResponse = yield axios.get(
          `${API_BASE_DEVELOPER_APP}/developerApp/appDefaultUserRoles?appId=${
            res?.data?.appDefaultUserRole?.appId
          }&userType=${payload?.userType}&query=${payload?.query}&sortfield=${
            payload?.sorter?.field
          }&sortorder=${payload?.sorter?.order}&page=${
            payload?.pagination?.current - 1
          }&size=${payload?.pagination?.pageSize}`,
          config
        );
        if (appRoleResponse.status === 200) {
          yield put(setAddEdit(false));
          yield put(appRoles(appRoleResponse.data));
        }
        //});
        //yield put(appRoles(response.data));

        //	}
      }
    } catch (error) {
      console.log(error);
      yield put(showAppMessage(error?.response));
    }
  });
}

export function* getAppRolePermissions() {
  yield takeEvery(GET_APP_ROLE_PERMISSIONS, function* ({ payload }) {
    const userId = localStorage.getItem("id");
    const accountId = localStorage.getItem("accountId");
    const config = {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
        accountId: localStorage.getItem("accountId"),
        appInstanceId: localStorage.getItem("appInstanceId"),
        accountUserId: localStorage.getItem("accountUserId")
      },
    };
    try {
      const res = yield axios.get(
        `${API_BASE_DEVELOPER_APP}/developerApp/appDefaultUserRolePermissons?appRoleId=${
          payload?.appRoleId
        }&query=${payload?.query}&sortfield=${
          payload?.sorter?.field
        }&sortorder=${payload?.sorter?.order}&page=${
          payload?.pagination?.current - 1
        }&size=${payload?.pagination?.pageSize}`,
        config
      );
      if (res.status === 200) {
        yield put(appRolePermissions(res.data));
      }
    } catch (err) {
      console.log(err);
      yield put(showAppMessage(err?.response));
    }
  });
}

export function* addAppSettingsReactJson() {
  yield takeEvery(ADD_APP_SETTINGS_JSON, function* ({ payload }) {
    const { id, settingsSchema, settingsDefaultValues } = payload;
    const config = {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
        accountId: localStorage.getItem("accountId"),
        appInstanceId: localStorage.getItem("appInstanceId"),
        accountUserId: localStorage.getItem("accountUserId")
      },
    };
    try {
      const res = yield axios.put(
        `${API_BASE_DEVELOPER_APP}/developerApp/app/settings/${id}`,
        {
          [`settingsSchema`]: settingsSchema,

          [`settingsDefaultValues`]: settingsDefaultValues,
        },
        config
      );

      if (res.status === 200) {
        yield put(appSettingsJson(res.data));

        Modal.success({
          title: "Form configuration updated successfully",

          icon: <CheckCircleFilled />,

          okText: "Close",
        });
      }
    } catch (err) {
      console.log(err);

      yield put(showAppMessage(err?.response));
    }
  });
}

export function* getApplicationSettingsDetails() {
  yield takeEvery(GET_APP_SETTINGS_DETAILS, function* ({ payload }) {
    const config = {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
        accountId: localStorage.getItem("accountId"),
        appInstanceId: localStorage.getItem("appInstanceId"),
        accountUserId: localStorage.getItem("accountUserId")
      },
    };
    try {
      const res = yield axios.get(
        `${API_BASE_DEVELOPER_APP}/developerApp/app/${payload}`,
        config
      );

      if (res.status === 200) {
        yield put(appSettingsJson(res.data));
      }
    } catch (error) {
      console.log(error);

      yield put(showAppMessage(error?.response));
    }
  });
}

export function* getAppInstance() {
  yield takeEvery(GET_APP_INSTANCE_DATA, function* ({ payload }) {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
          Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
          accountId: localStorage.getItem("accountId"),
          appInstanceId: localStorage.getItem("appInstanceId"),
          accountUserId: localStorage.getItem("accountUserId")
        },
      };
      const res = yield axios.get(
        `${API_BASE_DEVELOPER_APP}/developerApp/app/appInstance/${payload}`,
        config
      );
      if (res.status === 200) {
        yield put(appData(res.data));
      }
    } catch (error) {
      console.log(error);
      yield put(showAppMessage(error?.response));
    }
  });
}

export function* getAdminAppUiPropertiesDetails() {
  yield takeEvery(GET_ADMIN_APP_UI_PROPERTIES, function* ({ payload }) {
    const config = {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
        accountId: localStorage.getItem("accountId"),
        appInstanceId: localStorage.getItem("appInstanceId"),
        accountUserId: localStorage.getItem("accountUserId")
      },
    };
    try {
      const response = yield axios.get(
        `${API_BASE_DEVELOPER_APP}/developerApp/parent-app?appName=AdminApp`, {headers: {
          'Authorization': `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
          'Content-Type': 'application/json',
          'accountId':localStorage.getItem("accountId"),
          'appInstanceId':localStorage.getItem("appInstanceId"),
          'accountUserId':localStorage.getItem("accountUserId")
        }});
      if (response.status === 200) {
          const res = yield axios.get(
            `${API_BASE_DEVELOPER_APP}/developerApp/properties/admin-ui-properties?appId=${response?.data?.id}&query=&sortfield=undefined&sortorder=undefined`,
            {headers: {
              'Authorization': `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
              'Content-Type': 'application/json'
            }}
          );
          //`${API_BASE_DEVELOPER_APP}/developerApp/properties/ui-proerties?appId=${appId}&query=${queryString}&sortfield=${sortField}&sortorder=${sortorder}`,
          if (res.status === 200) {
            const enData = {};
            const frData = {};
            const spData = {};
            const jpData = {};
            res.data &&
            res.data.map((uiDetails, index) => {
              if (uiDetails.locale) {
                enData[`${uiDetails.propertyName}`] = uiDetails?.locale?.en;
                frData[`${uiDetails.propertyName}`] = uiDetails?.locale?.fr;
                spData[`${uiDetails.propertyName}`] = uiDetails?.locale?.sp;
                jpData[`${uiDetails.propertyName}`] = uiDetails?.locale?.jp;
              }
            });
            localStorage.setItem("ENGLISH-DATA", JSON.stringify(enData));
            localStorage.setItem("FRENCH-DATA", JSON.stringify(frData));
            localStorage.setItem("SPANISH-DATA", JSON.stringify(spData));
            localStorage.setItem("JAPNESE-DATA", JSON.stringify(jpData));
            //yield put(uiPropertiesJson([...res.data]));
          }
        }
    } catch (error) {
      console.log(error);
      yield put(showAppMessage(error?.response));
    }
  });
}

export function* getUiPropertiesDetails() {
  yield takeEvery(APP_UI_PROPERTIES, function* ({ payload }) {
    const { appId, query, sorter,pagination } = payload;
    const config = {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
        accountId: localStorage.getItem("accountId"),
        appInstanceId: localStorage.getItem("appInstanceId"),
        accountUserId: localStorage.getItem("accountUserId")
      },
    };
    try {
      const res = yield axios.get(
        `${API_BASE_DEVELOPER_APP}/developerApp/properties/ui-properties?appId=${appId}&query=${query}&sortfield=${sorter?.field}&sortorder=${sorter?.order}&page=${pagination?.current - 1}&size=${pagination?.pageSize}`,
        config
      );
      //`${API_BASE_DEVELOPER_APP}/developerApp/properties/ui-proerties?appId=${appId}&query=${queryString}&sortfield=${sortField}&sortorder=${sortorder}`,
      if (res.status === 200) {
        yield put(uiPropertiesJson(res.data));
      }
    } catch (error) {
      console.log(error);
      yield put(showAppMessage(error?.response));
    }
  });
}
export function* addAUiPropertiesData() {
  yield takeEvery(ADD_APP_UI_PROPERTIES, function* ({ payload }) {
    const config = {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
        accountId: localStorage.getItem("accountId"),
        appInstanceId: localStorage.getItem("appInstanceId"),
        accountUserId: localStorage.getItem("accountUserId")
      },
    };
    try {
      const res = yield axios.post(
        `${API_BASE_DEVELOPER_APP}/developerApp/properties/ui-property`,
        payload,
        config
      );
      if (res.status === 200) {
        if(payload?.appId){
        const response = yield axios.get(
          `${API_BASE_DEVELOPER_APP}/developerApp/properties/ui-properties?appId=${payload?.appId}&query=&sortfield=undefined&sortorder=undefined`,
          config
        );
        //`${API_BASE_DEVELOPER_APP}/developerApp/properties/ui-proerties?appId=${appId}&query=${queryString}&sortfield=${sortField}&sortorder=${sortorder}`,
        if (response.status === 200) {
          yield put(uiPropertiesJson(response.data));
        }
      }
        Modal.success({
          title: t("impaqx.adminApp.propertyAddMessage"),
          icon: <ExclamationCircleOutlined />,
          okText: "Close",
        });
      }
    } catch (err) {
      console.log(err);
      yield put(showAppMessage(err?.response));
    }
  });
}
export function* editUiPropertiesData() {
  yield takeEvery(EDIT_APP_UI_PROPERTIES, function* ({ payload }) {
    const { propId, updatedData } = payload;
    const config = {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
        accountId: localStorage.getItem("accountId"),
        appInstanceId: localStorage.getItem("appInstanceId"),
        accountUserId: localStorage.getItem("accountUserId")
      },
    };
    try {
      const res = yield axios.put(
        `${API_BASE_DEVELOPER_APP}/developerApp/properties/ui-properties/${propId}`,
        updatedData,
        config
      );
      if (res.status === 200) {
        const response = yield axios.get(
          `${API_BASE_DEVELOPER_APP}/developerApp/properties/ui-properties?appId=${updatedData?.appId}&query=&sortfield=undefined&sortorder=undefined`,
          config
        );
        //`${API_BASE_DEVELOPER_APP}/developerApp/properties/ui-proerties?appId=${appId}&query=${queryString}&sortfield=${sortField}&sortorder=${sortorder}`,
        if (response.status === 200) {
          yield put(uiPropertiesJson(response.data));
        }
        Modal.success({
          title: t("impaqx.adminApp.propertySaveMessage"),
          icon: <ExclamationCircleOutlined />,
          okText: "Close",
        });
      }
    } catch (err) {
      console.log(err);
      yield put(showAppMessage(err?.response));
    }
  });
}
export function* deleteUiPropertiesData() {
  yield takeEvery(DELETE_APP_UI_PROPERTIES, function* ({ payload }) {
    const { id, appId } = payload;
    const config = {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
        accountId: localStorage.getItem("accountId"),
        appInstanceId: localStorage.getItem("appInstanceId"),
        accountUserId: localStorage.getItem("accountUserId")
      },
    };
    try {
      const res = yield axios.delete(
        `${API_BASE_DEVELOPER_APP}/developerApp/properties/ui-properties/${id}`,
        config
      );
      if (res.status === 200) {
        const response = yield axios.get(
          `${API_BASE_DEVELOPER_APP}/developerApp/properties/ui-properties?appId=${appId}&query=&sortfield=undefined&sortorder=undefined`,
          config
        );
        //`${API_BASE_DEVELOPER_APP}/developerApp/properties/ui-proerties?appId=${appId}&query=${queryString}&sortfield=${sortField}&sortorder=${sortorder}`,
        if (response.status === 200) {
          yield put(uiPropertiesJson(response.data));
        }
        Modal.success({
          title: t("impaqx.adminApp.uiPropertyDeleteMessage"),
          icon: <ExclamationCircleOutlined />,
          okText: "Close",
        });
      }
    } catch (err) {
      console.log(err);
      yield put(showAppMessage(err?.response));
    }
  });
}

export function* getAccountUserAppInstanceRoles() {
  yield takeEvery(GET_ACCOUNT_USER_APP_INSTANCE_ROLES, function* ({ requestData }) {
    try {
      const accountId = localStorage.getItem("accountId");
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
          Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
          accountId: localStorage.getItem("accountId"),
          appInstanceId: localStorage.getItem("appInstanceId"),
          accountUserId: localStorage.getItem("accountUserId")
        },
      };
      const res = yield axios.get(
        `${API_BASE_DEVELOPER_APP}/developerApp/accountUser-appInstanceRole?accountUserId=${localStorage.getItem("accountUserId")}`,
        config
      );
      if (res.status === 200) {
        yield put(accountUserAppInstanceRoles(res.data));
      }
    } catch (error) {
      console.log(error);
      yield put(showAppMessage(error?.response));
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(getApps),
    fork(appRegister),
    fork(deleteApp),
    fork(publishApp),
    fork(editApp),
    fork(getPublishedApps),
    fork(subscribeApp),
    fork(getSubscribedApps),
    fork(getDeveloperApp),
    fork(getMfaSetupHome),
    fork(appLoginAuthentication),
    fork(onUsing2fa),
    fork(getUsing2faValue),
    fork(addAppPermission),
    fork(addAppRole),
    fork(getAppPermissions),
    fork(getAppRoles),
    fork(deleteAppPermission),
    fork(updateAppPermission),
    fork(deleteAppRole),
    fork(updateAppRole),
    fork(getAppRolePermissions),
    fork(addAppSettingsReactJson),
    fork(getApplicationSettingsDetails),
    fork(getAppInstance),
    fork(getUiPropertiesDetails),
    fork(addAUiPropertiesData),
    fork(editUiPropertiesData),
    fork(deleteUiPropertiesData),
    fork(getAdminAppUiPropertiesDetails),
    fork(subscribeAppDetailPage),
    fork(subscribeAppSettingsPage),
    fork(getAccountUserAppInstanceRoles)
  ]);
}
