import React, { useState, useEffect } from "react";
import { Card } from "antd";
import './Skeleton.css';

const SkeletonLoader = props => {

  const { loading,columns } = props
  const enUs = 'en-US: '
  const [markdown, setMarkdown] = useState('')

  useEffect(() => {
    
  });

  return (<>{loading?<>
  	<Card bodyStyle={{'padding': '0px'}}>
				<div className="table-responsive">
  <div class="tableWrapper">
  <div className="table-responsive">
<div className="ant-table-wrapper">
  <div className="ant-spin-nested-loading">
  <div className="ant-spin-container">
 <div className="ant-table">
  <div className="ant-table-container"> 
     <div className="ant-table-content custom-table">
    <table class="table" style={{tableLayout:"auto"}}>
      <thead className="ant-table-thead">         <tr>
        {columns?.map(column=>
 
          <th className="ant-table-cell ant-table-column-has-sorters">{column.title}</th>
      
    )}  </tr>
      
      </thead>
      <tbody className="ant-table-tbody">

        {Array.from(Array(10), (e, i) => {
             return     <tr className="ant-table-row ant-table-row-level-0">
                     {columns?.map(column=>
          <td class="loading ant-table-cell">
            <div class="bar"></div>
          </td>
        )}
          </tr>

        })}
      </tbody>
    </table>
  </div></div></div></div></div></div></div></div></div></Card></>:null}</>
  )
}

export default SkeletonLoader;
