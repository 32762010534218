
import {BASE_URL,MICROSOFT_CLIENT_ID,AUTHORITY_URL} from 'constants/ApiConstant'

const FirebaseConfig = {
  // apiKey: 'AIzaSyAOWoRQGoegkmwnCca7B2GG5GUG_ZP1lcM',
  // authDomain: 'emilus.firebaseapp.com',
  // databaseURL: 'https://emilus.firebaseio.com',
  // projectId: 'emilus',
  // storageBucket: 'emilus.appspot.com',
  // messagingSenderId: '807555350717',
  // appId: '1:807555350717:web:145ba7c21af47203a2f7d4',
  // measurementId: 'G-8KE7HJB252'
  apiKey: "AIzaSyDP3rJ7lJpUCrGttrp4GEJ_euQC39e8bL0",
  authDomain: "extremeb2b-d4601.firebaseapp.com",
  projectId: "extremeb2b-d4601",
  storageBucket: "extremeb2b-d4601.appspot.com",
  messagingSenderId: "468325759541",
  appId: "1:468325759541:web:06eb20612d12cf8a734f7c",
  measurementId: "G-BSZRZNQBFK"
};

export default FirebaseConfig

