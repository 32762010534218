import {ACCOUNT_USER_LIST,ACCOUNT_USER_MESSAGE,SHOW_LOADING,NOTIFICATION_LIST, INVITE_ACCOUNT_USER_LIST, HIDE_LOADING} from "redux/constants/user";

const initState = {
    loading : true,
    accountUserList : [],
    inviteAccountUserList:[],
    notificationDataList:[],
    message: '',
    showMessage: false,
    redirect : "",
    allowRedirect:false,
    status:null,
    token:null
}

const user = (state=initState,action)=>{
    switch(action?.type){
        case ACCOUNT_USER_LIST: 
        return {
            ...state,
            accountUserList : action.accountUserList,
            allowRedirect :false,
            showMessage:false,
            loading : false
        }
        case INVITE_ACCOUNT_USER_LIST: 
        return {
            ...state,
            inviteAccountUserList : action.inviteAccountUserList,
            allowRedirect :false,
            showMessage:false,
            loading : false
        }
        case ACCOUNT_USER_MESSAGE:
            return {
                ...state,
                message : messageRead(action),
                status : action.response.status,
                showMessage:true,
                allowRedirect: false,
                loading : false
            }
        case SHOW_LOADING: {
            return {
                ...state,
                loading: true
            }
        }    
        case HIDE_LOADING: {
            return {
                ...state,
                loading: false
            }
        }    
        case NOTIFICATION_LIST:{
            return{
                ...state,
                notificationDataList : action.notificationDataList,
                allowRedirect: false,
                loading : false
            }
        }
        default:
            return state
    }
}

const messageRead=(action)=>{
    if(action?.response?.status==401 || action?.response?.response?.status){
        return "Unauthorized"
    }
	if(action?.response && action?.response?.response && action?.response?.response?.data && action?.response?.response?.message){
	 return action?.response?.response?.data?.message;
	}else if(action?.response && action?.response?.data && action?.response?.data?.message){
		return action?.response?.data?.message;
	}else if(action?.response && action?.response?.data && action?.response?.data?.message){
		return action?.response?.data?.message;
	}else if(action?.response && action?.response?.data){
		return action?.response?.data;
	}else if(action?.data?.message){
		return action?.data?.message;
	}else if(action?.data){
		return action?.data;
	}else{
		return "something went wrong"
	}
}

export default user;