import { COLUMN_LIST, DATABASE_LIST, DATA_MIGRATION_MESSAGE, SET_ADD_EDIT,TABLE_LIST } from "redux/constants/dataMigration";

const initState = {
    loading : true,
    databaseList : [],
    tableList :[],
    columnList :[],
    addEditValue:false,
    message: '',
    showMessage: false,
    redirect : "",
    allowRedirect:false,
    status:0
}

const dataMigration =(state=initState,action) =>{
    switch(action?.type){
        case DATABASE_LIST:
         return{
             ...state,
             databaseList : action.databaseList,
             allowRedirect :false,
             addEditValue: false,
             loading:false,
             message:""
         }
         case SET_ADD_EDIT:
            return {
              ...state,
              addEditValue: action.addEditValue,
              loading: false,
            };
        case  TABLE_LIST:
           return {
            ...state,
            tableList : action.tableList,
            allowRedirect :false,
            addEditValue: false,
            loading:false,
            message:""
           }
         case COLUMN_LIST:
          return {
            ...state,
            columnList : action.columnList,
            allowRedirect :false,
            addEditValue: false,
            loading:false,
            message:""
           }
          case DATA_MIGRATION_MESSAGE:
            return {
              allowRedirect :false,
              addEditValue: false,
              loading:false,
              message:messageRead(action)
            }
         default:
           return state;
    }
}
const messageRead=(action)=>{
  if(action?.response?.status==401 || action?.response?.response?.status){
    return "Unauthorized"
  }
	if(action?.response && action?.response?.response && action?.response?.response?.data && action?.response?.response?.message){
	 return action?.response?.response?.data?.message;
	}else if(action?.response && action?.response?.data && action?.response?.data?.message){
		return action?.response?.data?.message;
	}else if(action?.response && action?.response?.data && action?.response?.data?.message){
		return action?.response?.data?.message;
	}else if(action?.response && action?.response?.data){
		return action?.response?.data;
	}else if(action?.data?.message){
		return action?.data?.message;
	}else if(action?.data){
		return action?.data;
	}else{
		return "something went wrong"
	}
}
export default dataMigration;