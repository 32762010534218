export const GET_APP_LIST = "GET_APP_LIST";
export const APP_LIST = "APP_LIST";
export const SAVE_APP = "SAVE_APP";
export const APP_REGISTER_SUCCESS = "APP_REGISTER_SUCCESS";
export const APP_MESSAGE = "APP_MESSAGE";
export const DELETE_APP = "DELETE_APP";
export const PUBLISH_APP = "PUBLISH_APP";
export const EDIT_APP = "EDIT_APP";
export const APP_DATA = "APP_DATA";
export const GET_PUBLISHED_APP_LIST= "GET_PUBLISHED_APP_LIST";
export const PUBLISHED_APP_LIST= "PUBLISHED_APP_LIST";
export const SUBSCRIBE_APP = "SUBSCRIBE_APP";
export const GET_SUBSCRIBED_APP_LIST="GET_SUBSCRIBED_APP_LIST";
export const SUBSCRIBED_APP_LIST = "SUBSCRIBED_APP_LIST";
export const GET_DEVELOPER_APP = "GET_DEVELOPER_APP";
export const DEVELOPER_APP = "DEVELOPER_APP";
export const GET_MFA_SETUP_HOME="GET_MFA_SETUP_HOME";
export const GET_MFA_SETUP_HOME_SUCCESS="GET_MFA_SETUP_HOME_SUCCESS";
export const APP_LOGIN_AUTHENTICATION="APP_LOGIN_AUTHENTICATION";
export const USING_2FA="USING_2FA";
export const TOGGLE_2FA="TOGGLE_2FA";
export const GET_USING_2FA_VALUE = "GET_USING_2FA_VALUE";
export const USING_2FA_VALUE = "USING_2FA_VALUE";
export const ADD_APP_PERMISSION= "ADD_APP_PERMISSION";
export const ADD_APP_ROLE = "ADD_APP_ROLE";
export const GET_APP_PERMISSIONS="GET_APP_PERMISSIONS";
export const APP_PERMISSION_LIST="APP_PERMISSION_LIST";
export const DELETE_APP_PERMISSION='DELETE_APP_PERMISSION';
export const UPDATE_APP_PERMISSION = 'UPDATE_APP_PERMISSION';
export const GET_APP_ROLES='GET_APP_ROLES';
export const APP_ROLE_LIST='APP_ROLE_LIST';
export const DELETE_APP_ROLE='DELETE_APP_ROLE_PERMISSION';
export const UPDATE_APP_ROLE='UPDATE_APP_ROLE';
export const DELETE_APP_ROLE_PERMISSION='DELETE_APP_ROLE_PERMISSION';
export const UPDATE_APP_ROLE_PERMISSION='UPDATE_APP_ROLE_PERMISSION';
export const GET_APP_ROLE_PERMISSIONS='GET_APP_ROLE_PERMISSIONS';
export const APP_ROLE_PERMISSION_LIST='APP_ROLE_PERMISSION_LIST';
export const SET_ADD_EDIT = 'SET_ADD_EDIT';
export const APP_SETTINGS_JSON = 'APP_SETTINGS_JSON';
export const ADD_APP_SETTINGS_JSON = 'ADD_APP_SETTINGS_JSON';
export const GET_APP_SETTINGS_DETAILS = 'GET_APP_SETTINGS_DETAILS';
export const GET_APP_INSTANCE_DATA="GET_APP_INSTANCE_DATA";
export const APP_INSTANCE_DATA="APP_INSTANCE_DATA";
export const SHOW_LOADING='SHOW_LOADING';
export const HIDE_LOADING="HIDE_LOADING";
export const UI_PROPERTIES_JSON = "UI_PROPERTIES_JSON";
export const APP_UI_PROPERTIES="APP_INSTANCE_UI_PROPERTIES";
export const ADD_APP_UI_PROPERTIES="ADD_APP_INSTANCE_UI_PROPERTIES";
export const EDIT_APP_UI_PROPERTIES="EDIT_APP_INSTANCE_UI_PROPERTIES";
export const DELETE_APP_UI_PROPERTIES="DELETE_APP_INSTANCE_UI_PROPERTIES";
export const GET_ADMIN_APP_UI_PROPERTIES="GET_ADMIN_APP_UI_PROPERTIES";
export const SUBSCRIBE_APP_DETAIL_PAGE="SUBSCRIBE_APP_DETAIL_PAGE";
export const SUBSCRIBE_APP_SETTINGS_PAGE="SUBSCRIBE_APP_SETTINGS_PAGE";
export const SUBSCRIBED_APP_DATA="SUBSCRIBED_APP_DATA";
export const GET_ACCOUNT_USER_APP_INSTANCE_ROLES="GET_ACCOUNT_USER_APP_INSTANCE_ROLES";
export const ACCOUNT_USER_APP_INSTANCE_ROLES="ACCOUNT_USER_APP_INSTANCE_ROLES";