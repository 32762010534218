import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import {
  Card,
  Table,
  Tag,
  Tooltip,
  Button,
  Modal,
  Row,
  Col,
  Empty,
  Input,
  Alert,
  Switch,
  Menu,
  Dropdown,
} from "antd";
import {
  EyeOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
  EditOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import moment from "moment";
import AppView from "../AppView";
import {
  getApps,
  deleteApp,
  publishApp,
  appRegister,
  getDeveloperApp,
  getSubscribedApps,
} from "redux/actions/app";
import { Link } from "react-router-dom";
import PageHeaderAlt from "components/layout-components/PageHeaderAlt";
import Flex from "components/shared-components/Flex";
import ListGridView from "../../../../market-place/subscribedApps/ListGridView";
import { debounce } from "lodash";
import { motion } from "framer-motion";
import CustomTable from "components/util-components/CustomTable";
import { showLoading } from "redux/actions/app";
import SkeletonLoader from "components/util-components/SkeletonLoader";
import { useTranslation } from "react-i18next";
import { AUTH_TOKEN } from "redux/constants/Auth";
import axios from "redux/sagas/initialize";
import { API_BASE_DEVELOPER_APP, AUTH_PREFIX_PATH } from "configs/AppConfig";
import Loader from "components/util-components/Loader";

const Apps = (props) => {
  const {
    getApps,
    deleteApp,
    publishApp,
    appRegister,
    getDeveloperApp,
    developerApp,
    allowRedirect,
    redirect,
    getSubscribedApps,
    app,
    showLoading,
  } = props;
  const { subscribedApps, apps, message, showMessage, token, status, loading } =
    app;
  // const appsSize = apps.content.length;
  const [selectedApp, setSelectedapp] = useState([]);
  const [parentAppId, setParentAppId] = useState("");
  const [loadingSpin,setLoadingSpin] =useState(false);
  const [appProfileVisible, setAppProfileVisible] = useState(false);
  const [query, setQuery] = useState(undefined);
  let history = useHistory();
  const { t } = useTranslation();
  const { type } = props;
  const { accountId}= useParams();
  //    const [totalElements,setTotalElements] = useState(0);
  //    if(apps.totalElements>0)
  //     setTotalElements(apps.totalElements)
  const totalElements = apps.totalElements ? apps.totalElements : 0;
  //  const [loading,setLoading]=useState(true);
  const [pagination, setPagination] = useState({current:1,pageSize:10});
  const permissions  = localStorage.getItem("permissions")!=""?JSON.parse(localStorage.getItem("permissions")):"";
  const [appData, setAppData] = useState({
    name: `DeveloperApp_${localStorage.getItem("accountId")}`,
    description: "Developer App",
    status: "ACTIVE",
    developerAccountId: localStorage.getItem("accountId"),
    logo: "https://tse4.mm.bing.net/th?id=OIP.fHI5e16o8FdC5umCjqLHSQHaC2&pid=Api&P=0",
    logoType: "URL",
    userCreated: localStorage.getItem("userId"),
    userUpdated: localStorage.getItem("userId"),
    parentAppId: parentAppId,
  });
  let filteredApps = [];

  const ListOption = ({ name, selector, disabled, vertical }) => (
    <div
      className={`my-2 mr-2 ${
        vertical ? "" : "d-flex align-items-center justify-content-between"
      }`}
    >
      <Tooltip title={t("impaqx.adminApp.stopNewSubscription")}>
        <div
          className={`${disabled ? "opacity-0-3" : ""} ${
            vertical ? "mb-3" : ""
          }`}
        >
          {name}
        </div>
        <div>{selector}</div>
      </Tooltip>
    </div>
  );

  const EllipsisDropdown = (props) => {
    const { menu, placement } = props;
    return (
      <>
        <Dropdown overlay={menu} placement={placement} trigger={["click"]}>
          {/* <div className="d-flex align-items-center custom-button" style={{width: '100%',justifyContent: 'flex-end'}}> */}
          <span
            style={{marginLeft: '82%', marginBottom:'5px', justifyContent: "flex-end"}}
            className="ant-btn ant-btn-primary ant-btn ant-btn-primary ant-btn-sm mt-2"
          >
            {t("impaqx.adminApp.add")}
          </span>
          {/* </div> */}
        </Dropdown>
      </>
    );
  };

  if (apps && apps.content && apps.content.length > 0) {
    filteredApps = apps.content.filter((app) => {
      return app.name !== `DeveloperApp_${localStorage.getItem("accountId")}`;
    });
  }
  useEffect(async () => {
    if(permissions.allpermissions || permissions.view_apps || permissions.view_services || permissions.view_connectors){
    }else{
      history.push(`${AUTH_PREFIX_PATH}/401`);
    }
    if (allowRedirect) {
      history.push(redirect);
    }
    const res = await axios.get(
      `${API_BASE_DEVELOPER_APP}/developerApp/parent-app?appName=AdminApp`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
          "Content-Type": "application/json",
          'accountId':localStorage.getItem("accountId"),
          'appInstanceId':localStorage.getItem("appInstanceId"),
          'accountUserId':localStorage.getItem("accountUserId"),
        },
      }
    );
    setParentAppId(res.data.id);
    if (totalElements < 1) showLoading(true);
    //  setLoading(false)
    // getSubscribedApps();
    ///getDeveloperApp();
  }, []);

  useEffect(async () => {
    showLoading(true);
    await getApps({ type, pagination,accountId });
  }, [type]);

  useEffect(() => {
    if(apps.totalElements>=0)
    setLoadingSpin(false);
  }, [apps,app]);

  const searchOnClick = debounce((e) => {
    const searchValue = e.target.value;
    setQuery(searchValue);
    showLoading();
    getApps({ type,query: searchValue, pagination,accountId });
  }, 500);

  const onSortData = (pagination, filters, sorter) => {
    console.log(pagination);
  };

  const showUserProfile = (userInfo) => {
    setAppProfileVisible(true);
    setSelectedapp(userInfo);
  };

  const closeAppProfile = () => {
    setAppProfileVisible(false);
    setSelectedapp(null);
  };

  const onBoardDelete = (app) => {
    Modal.confirm({
      title: `Do you want to delete this ${app.name} App?`,
      icon: <ExclamationCircleOutlined />,
      okText: "Yes",
      cancelText: "Cancel",
      onOk() {
        setLoadingSpin(true);
        deleteApp(app);
      },
    });
    setQuery(undefined);
  };

  const toggle = (defaultValue, app) => {
    Modal.confirm({
      title: `Do you want to ${
        !app.stopNewSubscriptions
          ? "Stop New Subscription"
          : "Enable New Subscriptions"
      } for this App?`,
      icon: <ExclamationCircleOutlined />,
      okText: "Yes",
      cancelText: "Cancel",
      onOk() {
        app["stopNewSubscriptions"] = defaultValue;
        setLoadingSpin(true);
        publishApp(app);
      },
    });
  };
  const onBoardPublish = (app) => {
    Modal.confirm({
      title: `Do you want to ${
        app.published && app.published != "UNPUBLISHED"
          ? "UnPublish"
          : "Publish"
      } this App?`,
      icon: <ExclamationCircleOutlined />,
      okText: "Yes",
      cancelText: "Cancel",
      onOk() {
        if (app.published && app.published === "PUBLISHED") {
          app["published"] = "UNPUBLISHED";
        } else {
          app["published"] = "PUBLISHED";
        }
        setLoadingSpin(true);
        publishApp(app);
      },
    });
  };

  const onEdit = (app) => {
    localStorage.setItem("appName", app.name);
    setLoadingSpin(true);
    history.push(`/app/pages/edit-app/${app.id}?type=${app.type}`);
  };

  const subscribeDeveloperApp = () => {
    showLoading();
    appRegister(appData);
  };

  const handleTableChange = (pagination, filters, sorter) => {
    console.log(pagination);
    console.log(filters);
    console.log(sorter);
    showLoading();
    getApps({ type,query, sorter, pagination,accountId });
    setPagination(pagination);
  };

  const tableColumns = [
    {
      title: t("impaqx.adminApp.serialNumber"),
      dataIndex: 'serialNumber',
      render: (text, record, index) =>
				  (pagination?.current - 1) * pagination?.pageSize + index + 1,
    },
    {
      //title:  t("impaqx.adminApp.appName"),
      title: "Name",
      dataIndex: "name",

      sorter: onSortData,
      // {
      //    compare: (a, b) => {
      //        a = a.name.toLowerCase();
      //        b = b.name.toLowerCase();
      //        return a > b ? -1 : b > a ? 1 : 0;
      //    },
      // },
    },
    // {
    //  title:  t("impaqx.adminApp.description"),
    //  dataIndex: 'description',
    //  span: 3,
    //  sorter: onSortData,
    //  render: description => (
    //       <div  className="mb-0" style={{wordBreak:'break-all'}}
    //      dangerouslySetInnerHTML={{__html : description}}></div>
    //  ),
    // },
    {
      title: t("impaqx.adminApp.logo"),
      dataIndex: "logo",

      sorter: (a, b) =>
        moment(a.lastOnline).unix() - moment(b.lastOnline).unix(),
    },
    {
      title: t("impaqx.adminApp.logoType"),
      dataIndex: "logoType",
      sorter: onSortData,
    },
    {
      title: t("impaqx.adminApp.published"),
      dataIndex: "published",
      render: (published) => (
        <Tag
          className="text-capitalize"
          color={published === "PUBLISHED" ? "cyan" : "red"}
        >
          {published === null ? "Not Published" : published}
        </Tag>
      ),
      sorter: onSortData,
    },
    {
      title: t("impaqx.adminApp.mode"),
      dataIndex: "mode",
      sorter: onSortData,
    },
    {
      title: t("impaqx.adminApp.type"),
      dataIndex: "type",
      sorter: onSortData,
    },
    {
      title: t("impaqx.adminApp.status"),
      dataIndex: "status",
      render: (status) => (
        <Tag
          className="text-capitalize"
          color={status === "ACTIVE" ? "cyan" : "red"}
        >
          {status}
        </Tag>
      ),
      sorter: onSortData,
    },
    {
      title: "Actions",
      dataIndex: "actions",
      render: (_, elm) => (
        <div className="text-right d-flex justify-content-end">
         {permissions?.allpermissions || permissions[`update_${elm.type}`]?<><Tooltip title={t("impaqx.adminApp.edit")}>
            <Button
              className="mr-2"
              icon={<EditOutlined />}
              onClick={() => {
                onEdit(elm);
              }}
              size="small"
            />
          </Tooltip>
          <Tooltip
            title={
              elm.published == "PUBLISHED"
                ? t("impaqx.adminApp.unPublish")
                : t("impaqx.adminApp.publish")
            }
          >
            <Button
              className="mr-2"
              icon={<ExclamationCircleOutlined />}
              onClick={() => {
                onBoardPublish(elm);
              }}
              size="small"
            />
          </Tooltip></>:null}
          <Tooltip title={t("impaqx.adminApp.preview")}>
            <Button
              type="primary"
              className="mr-2"
              icon={<EyeOutlined />}
              onClick={() => {
                showUserProfile(elm);
              }}
              size="small"
            />
          </Tooltip>
          <ListOption
            name=""
            selector={
              <Switch
                checked={elm.stopNewSubscriptions}
                onChange={(e) => toggle(e, elm)}
              />
            }
          />
       {permissions?.allpermissions || permissions?.delete_app?
          <Tooltip title={t("impaqx.adminApp.delete")}>
            <Button
              danger
              icon={<DeleteOutlined />}
              disabled={elm.name=='AdminApp'}
              onClick={() => {
                onBoardDelete(elm);
              }}
              size="small"
            />
          </Tooltip> :null}
        </div>
      ),
    },
  ];

  return (
    <>
        {loadingSpin? <Loader spinning={loadingSpin} cover="content"  />:null}
      {/* <motion.div
        initial={{ opacity: 0, marginBottom: 0 }}
        animate={{
          opacity: showMessage ? 1 : 0,
          marginBottom: showMessage ? 20 : 0,
        }}
      >
        {message && message !== "" ? (
          status === 200 ? (
            <Alert type="success" showIcon message={message}></Alert>
          ) : (
            <Alert type="error" showIcon message={message}></Alert>
          )
        ) : null}
      </motion.div> */}
      <div style={{display: 'flex'}}>
      {((!loading && totalElements > 1) || (query !== undefined)) && (
            <div
              className="chat-menu-toolbar search"
            >
              <Input
                placeholder="Search"
                onChange={searchOnClick}
                prefix={<SearchOutlined className="font-size-lg mr-2" />}
              />
            </div>
        )}
        {((!loading && totalElements > 1) || (query !== undefined)) && (<>
         <EllipsisDropdown
            menu={
              <Menu>
               {permissions?.allpermissions || permissions?.add_app? <Menu.Item key="0">
                  <Link
                    to={`/app/pages/add-app?type=app`}
                    className="text-right d-flex justify-content-end"
                  >
                    {/* <Button type="primary" className="ant-btn ant-btn-primary ant-btn-sm"> */}
                    <span>{t("impaqx.adminApp.app")}</span>
                    {/* </Button> */}
                  </Link>
                </Menu.Item>:null}
                {permissions?.allpermissions || permissions?.add_service? <Menu.Item key="1">
                  <Link
                    to={`/app/pages/add-app?type=service`}
                    className="text-right d-flex justify-content-end"
                  >
                    {/* <Button type="primary" className="ant-btn ant-btn-primary ant-btn-sm"> */}
                    <span>{t("impaqx.adminApp.service")}</span>
                    {/* </Button> */}
                  </Link>
                </Menu.Item>:null}
                {permissions?.allpermissions || permissions?.add_connector?<Menu.Item key="2">
                  <Link
                    to={`/app/pages/add-app?type=connector`}
                    className="text-right d-flex justify-content-end"
                  >
                    {/* <Button type="primary" className="ant-btn ant-btn-primary ant-btn-sm"> */}
                    {/* <span>{t("impaqx.adminApp.connector")}</span> */}
                    <span>Connector</span>
                    {/* </Button> */}
                  </Link>
                </Menu.Item>:null}
              </Menu>
            }
          /></>
        ) }
        </div>
        <SkeletonLoader columns={tableColumns} loading={loading} />
        {((!loading && totalElements > 1) || (!loading && query !== undefined)) && (
            <Card bodyStyle={{ padding: "0px" }}>
              <div className="table-responsive">
                <Table
                  columns={tableColumns}
                  dataSource={filteredApps}
                  className="customTable"
                  rowKey="id"
                  pagination={{current: pagination.current,
                    pageSize: pagination.pageSize, // Number of items per page
                    total :filteredApps?.totalElements,
                    showSizeChanger: true, // Option to change page size
                 // showQuickJumper: true, // Option to jump to a page
                  showTotal: (total, range) => `Showing ${range[0]}-${range[1]} of ${total} items`, // Display total items
                  }}
                  onChange={handleTableChange}
                />
              </div>
              <AppView
                data={selectedApp}
                visible={appProfileVisible}
                close={() => {
                  closeAppProfile();
                }}
              />
            </Card>
        )}
        {totalElements &&
        totalElements === 1 &&
        !loading &&
        query === undefined ? (
          <Row justify="center" gutter={24}>
            <Col xs={24} sm={24} md={12} lg={12} className="gutter-row">
              <Card style={{ textAlign: "center" }}>
                <Empty
                  image="https://gw.alipayobjects.com/mdn/miniapp_social/afts/img/A*pevERLJC9v0AAAAAAAAAAABjAQAAAQ/original"
                  imageStyle={{
                    height: 60,
                  }}
                  description={
                    <span>{t("impaqx.adminApp.noAppsAvailableCreateNow").replaceAll("Apps",type.charAt(0).toUpperCase() + type?.slice(1))}</span>
                  }
                >
                 {permissions?.allpermissions || permissions?.add_app? <Link
                    to={`/app/pages/add-app?type=${type}`}
                    className="text-right d-flex justify-content-center"
                  >
                    <Button type="primary">
                      {t("impaqx.adminApp.createNow")}
                    </Button>
                  </Link>:null}
                </Empty>
              </Card>
            </Col>
          </Row>
        ) : (
          <SkeletonLoader loading={loading} />
        )}
        {(!loading && totalElements < 1 && query === undefined) ||
        (!loading && totalElements === undefined && query === undefined) ? (
          <Row justify="center" gutter={24}>
            <Col xs={24} sm={24} md={12} lg={12} className="gutter-row">
              <Card style={{ textAlign: "center" }}>
                <Empty
                  image="https://gw.alipayobjects.com/mdn/miniapp_social/afts/img/A*pevERLJC9v0AAAAAAAAAAABjAQAAAQ/original"
                  imageStyle={{
                    height: 60,
                  }}
                  description={
                    <span>
                      {t(
                        "impaqx.adminApp.developerPortalAvailableSubscribetoDeveloperPortal"
                      )}
                    </span>
                  }
                >
                  {/* <Link to={`/app/pages/create-account`} className="text-right d-flex justify-content-center"> */}
                  {permissions?.allpermissions || permissions?.add_app?  <Button
                    type="primary"
                    onClick={() => {
                      subscribeDeveloperApp();
                    }}
                  >
                    {t("impaqx.adminApp.subscribeToDeveloperPortalNow")}
                  </Button>:null}
                  {/* </Link> */}
                </Empty>
              </Card>
            </Col>
          </Row>
        ) : (
          <SkeletonLoader loading={loading} />
        )}
    </>
  );
};

const mapStateToProps = (state) => ({
  app: state.app,
});
export default connect(mapStateToProps, {
  getApps,
  deleteApp,
  publishApp,
  appRegister,
  getDeveloperApp,
  getSubscribedApps,
  showLoading,
})(Apps);