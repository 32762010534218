import { HIDE_LOADING, SHOW_LOADING } from "redux/constants/Auth";
import { DATABASE_LIST, DATA_MIGRATION_MESSAGE, DELETE_DATABASE, GET_DATABASE_LIST, SAVE_DATABASE, SAVE_DATABASE_SUCCESS_MESSAGE, UPDATE_DATABASE,SET_ADD_EDIT,
    GET_TABLE_LIST,TABLE_LIST,GET_COLUMN_LIST,COLUMN_LIST,COPY_DATABASE_DATA,COPY_BLOB_STORAGE_FILE } from "redux/constants/dataMigration";


export const getDatabaseList = (requestData)=>{
    return{
        type : GET_DATABASE_LIST,
        requestData : requestData
    }
}

export const getTables = (requestData)=>{
    return{
        type : GET_TABLE_LIST,
        requestData :requestData
    }
}

export const getColumns = (requestData)=>{
    return{
        type : GET_COLUMN_LIST,
        requestData :requestData
    }
}
export const databaseList=(data)=>{
    return{
        type : DATABASE_LIST,
        databaseList : data
    }

}

export const tableList = (data)=>{
    return{
        type : TABLE_LIST,
        tableList : data
    }
}

export const columnList = (data)=>{
    return{
        type : COLUMN_LIST,
        columnList : data
    }
}

export const copyDatabaseData = (data)=>{
    return{
        type : COPY_DATABASE_DATA,
        payload : data
    }
}

export const copyDatabaseDataFile=(data)=>{
    return{
        type : COPY_BLOB_STORAGE_FILE,
        payload : data
    }
}

export const showLoading = () => {
    return {
      type: SHOW_LOADING,
    };
  };

  export const hideLoading=()=>{
    return {
      type : HIDE_LOADING
    }
  }

export const saveDatabse=(data)=>{
    return{
        type : SAVE_DATABASE,
        payload : data
    }
}

export const updateDatabase = (data)=>{
    return {
        type : UPDATE_DATABASE,
        payload : data
    }
}

export const saveDatabaseSuccessMessage=(response)=>{
    return{
        type : SAVE_DATABASE_SUCCESS_MESSAGE,
        response
    }
}

export const showDataMigrationMessage=(response)=>{
    return{
        type : DATA_MIGRATION_MESSAGE,
        response
    }
}

export const deleteDatabase=(data)=>{
    return{
        type : DELETE_DATABASE,
        payload : data
    }
}

export const setAddEdit = (value) => {
    return {
      type: SET_ADD_EDIT,
      addEditValue: value,
    };
  };