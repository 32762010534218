import { all, takeEvery, put, fork } from 'redux-saga/effects';

//import axios from 'axios';
import axios from './initialize'
import { Modal } from 'antd';
import {CheckCircleFilled} from  '@ant-design/icons';

import {  GET_CONNECTORS_API_LIST,UPDATE_CONNECTOR_API,DELETE_CONNECTOR_API,ADD_CONNECTOR_API,TEST_CONNECTOR, GET_INSTANCE_CONNECTORS_API_LIST, UPDATE_INSTANCE_CONNECTOR_API} from "redux/constants/connector";

import { connectorList, showConnectorApisMessage,testConnectorData,connectorData,registerSuccess, deleteConnector,connectorInstanceList } from "redux/actions/connector";
import { showUnAutherizeMessage} from "redux/actions/Auth";
import { CONNECTOR_BASE_URL } from 'configs/AppConfig';
import {AUTH_TOKEN} from '../constants/Auth';
import {PATH_VARIABLE} from 'constants/ApiConstant';
import { t } from 'i18next';


export function* getConnector(){
    yield takeEvery (GET_CONNECTORS_API_LIST, function*  ({requestData}){
		const {query, sorter,pagination,appId} = requestData;
      const config = {
		headers: { "Content-Type": 'application/json',"Access-Control-Allow-Origin": "*",
		"Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",Authorization : `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
		accountUserId: localStorage.getItem("accountUserId") }
	  };
		try {
		const res = yield  axios.get(`${CONNECTOR_BASE_URL}/connector/v1/connector-apis?appId=${appId}&query=${query}&sortfield=${sorter?.field}&sortorder=${sorter?.order}&page=${pagination?.current-1}&size=${pagination?.pageSize}`,
		config);
			if (res.status===200) {
				yield put(connectorList(res.data));
			} 
		} catch (err) {
			console.log(err);
			if(err?.response?.data?.status==401){
				yield put(showConnectorApisMessage(err.response));
			}
			yield put(showConnectorApisMessage(err.response));
		}
    }

    )
}

export function* testConnector(){
    yield takeEvery (TEST_CONNECTOR, function*  ({requestData}){
		const {query, sorter,pagination,appId,appInstanceId,values,requestJsonData } = requestData;
      const config = {
		headers: { "Content-Type": 'application/json',"Access-Control-Allow-Origin": "*",
		"Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",Authorization : `${localStorage.getItem(AUTH_TOKEN)}`,
		"accountId" : localStorage.getItem("accountId"),
		accountUserId: localStorage.getItem("accountUserId"),
		"appId":appId }
	  };
		try {
		const res = yield  axios.post(`${CONNECTOR_BASE_URL}/connector/v1/test-connector-apis?appId=${appId}&appInstanceId=${appInstanceId}&query=${query}&sortfield=${sorter?.field}&
		sortorder=${sorter?.order}&page=${pagination?.current-1}&size=${pagination?.pageSize}`,requestData,
		config);
			if (res.status===200) {
				yield put(testConnectorData(res.data));
			} 
		} catch (err) {
			console.log(err);
			if(err.response.data.status==401){
				yield put(showConnectorApisMessage(err.response));
			}
			yield put(showConnectorApisMessage(err.response));
		}
    }

    )
}

export function* saveConnector() {
	yield takeEvery(ADD_CONNECTOR_API, function* ({ payload }) {
	  try {
	//	const accountId = localStorage.getItem("accountId");
		const {query, sorter,pagination,values} = payload;
		const config = {
		  headers: {
			"Content-Type": "application/json",
			"Access-Control-Allow-Origin": "*",
			"Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
			Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
			"accountId" : localStorage.getItem("accountId"),
			"appInstanceId":localStorage.getItem("appInstanceId"),
			accountUserId: localStorage.getItem("accountUserId")
		  },
		};
		const response = yield axios.post(
		  `${CONNECTOR_BASE_URL}/connector/v1/connector-api`,
		  values,
		  config
		);
		if (response.status === 200) {
			const res = yield  axios.get(`${CONNECTOR_BASE_URL}/connector/v1/connector-apis?appId=${response.data.connectorApi.connectorId}&query=${query}&sortfield=${sorter?.field}
				 &sortorder=${sorter?.order}&page=${pagination?.current-1}&size=${pagination?.pageSize}`,
				 {headers: {
					'Authorization': `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
					'Access-Control-Allow-Origin' : '*',
					'Accept': 'application/json',
					'Content-Type': 'application/json',
					accountUserId: localStorage.getItem("accountUserId")
				  }});
					if (res.status===200) {
		  Modal.success({
			title: t("impaqx.adminApp.connectorApiAddMessage"),
			icon: <CheckCircleFilled />,
			okText: t("impaqx.adminApp.close"),
			//cancelText: 'Cancel',
			onOk() {
			  //deleteApp(id);
			},
		  });
		  yield put(connectorList(res.data));
		}
		}
		
	  } catch (err) {
		console.log(err);
		yield put(showConnectorApisMessage(err.response));
	  }
	});
  }
export function* updateConnector(){
    yield takeEvery (UPDATE_CONNECTOR_API,function* ({payload}){
		const {query, sorter,pagination,values} = payload;
        try {
            const res = yield axios.put(`${CONNECTOR_BASE_URL}/connector/v1/connector-api/${values.id}`, values,{
				headers: {
				  'Authorization': `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
				  'Access-Control-Allow-Origin' : '*',
				  'Accept': 'application/json',
				  'Content-Type': 'application/json',
				  accountUserId: localStorage.getItem("accountUserId")
				}
			  });
             if (res.status===200) {
					const response = yield  axios.get(`${CONNECTOR_BASE_URL}/connector/v1/connector-apis?appId=${res.data.connectorApi.connectorId}&query=${query}&sortfield=${sorter?.field}
						 &sortorder=${sorter?.order}&page=${pagination?.current-1}&size=${pagination?.pageSize}`,
						 {headers: {
							'Authorization': `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
							'Access-Control-Allow-Origin' : '*',
							'Accept': 'application/json',
							'Content-Type': 'application/json'
						  }});
							if (response.status===200) {
				Modal.success({
					title: t("impaqx.adminApp.connectorCreateMessage"),
					icon: <CheckCircleFilled />,
					okText: t("impaqx.adminApp.close"),
					//cancelText: 'Cancel',
					onOk() {
					  //deleteApp(id);
					},
				  });
				  yield put(connectorList(response.data));
				} 
		}
        } catch (error) {
            console.log(error);
			if(error.response.data.status==401){
				yield put(showConnectorApisMessage(error.response));
			}
			yield put(showConnectorApisMessage(error.response));
        }
    }
    )
}

export function* deleteConnectorApi(){
	yield takeEvery(DELETE_CONNECTOR_API, function*({payload}){
         try {
			const {query, sorter,pagination,connector} = payload;
			 const res = yield axios.delete(`${CONNECTOR_BASE_URL}/connector/v1/connector-api/${connector.id}`,{
				headers: {
				  'Authorization': `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
				  'Access-Control-Allow-Origin' : '*',
				  'Accept': 'application/json',
				  'Content-Type': 'application/json',
				  accountUserId: localStorage.getItem("accountUserId")
				}
			  });
			 if(res.status===200){
				 //yield put(showAccountMessage(res));
				 const res = yield  axios.get(`${CONNECTOR_BASE_URL}/connector/v1/connector-apis?appId=${connector.connectorId}&query=${query}&sortfield=${sorter?.field}
				 &sortorder=${sorter?.order}&page=${pagination?.current-1}&size=${pagination?.pageSize}`,
				 {headers: {
					'Authorization': `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
					'Access-Control-Allow-Origin' : '*',
					'Accept': 'application/json',
					'Content-Type': 'application/json'
				  }});
					if (res.status===200) {
						Modal.success({
							title: `${connector.name}  ${t("impaqx.adminApp.deleteConnectorApiMessage")}`,
							icon: <CheckCircleFilled />,
							okText: 'Close',
							//cancelText: 'Cancel',
							onOk() {
								//deleteApp(id);
							},
						});
						yield put(connectorList(res.data));
					} 
			 }
		 } catch (error) {
			 console.log(error);
			 if(error.response.data.status==401){
				yield put(showConnectorApisMessage(error.response));
			}
			 yield put(showConnectorApisMessage(error.response));
		 }
	})
}

export function* getInstanceConnector(){
    yield takeEvery (GET_INSTANCE_CONNECTORS_API_LIST, function*  ({requestData}){
		const {query, sorter,pagination,appId,appInstanceId} = requestData;
      const config = {
		headers: { "Content-Type": 'application/json',"Access-Control-Allow-Origin": "*",
		"Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",Authorization : `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
		accountUserId: localStorage.getItem("accountUserId") }
	  };
		try {
		const res = yield  axios.get(`${CONNECTOR_BASE_URL}/connector/v1/connector-instance-api?appId=${appId}&appInstanceId=${appInstanceId}&query=${query}&
		sortfield=${sorter?.field}&sortorder=${sorter?.order}&page=${pagination?.current-1}&size=${pagination?.pageSize}`,
		config);
			if (res.status===200) {
				yield put(connectorInstanceList(res.data));
			} 
		} catch (err) {
			console.log(err);
			if(err?.response?.data?.status==401){
				yield put(showConnectorApisMessage(err.response));
			}
			yield put(showConnectorApisMessage(err.response));
		}
    }

    )
}

export function* updateInstanceConnector(){
    yield takeEvery (UPDATE_INSTANCE_CONNECTOR_API,function* ({payload}){
		const {query, sorter,pagination,values,appId,appInstanceId} = payload;
		const config = {
			headers: { "Content-Type": 'application/json',"Access-Control-Allow-Origin": "*",
			"Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",Authorization : `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
			accountUserId: localStorage.getItem("accountUserId") }
		  };
        try {
			var response;
			if(values.id){
				response = yield axios.put(`${CONNECTOR_BASE_URL}/connector/v1/connector-instance-api/${values.id}`, values,config);
			}else{
                response = yield axios.post(`${CONNECTOR_BASE_URL}/connector/v1/connector-instance-api`, values,config);
			}
             if (response.status===200) {
				const res = yield  axios.get(`${CONNECTOR_BASE_URL}/connector/v1/connector-instance-api?appId=${appId}&appInstanceId=${appInstanceId}&query=${query}&
				sortfield=${sorter?.field}&sortorder=${sorter?.order}&page=${pagination?.current-1}&size=${pagination?.pageSize}`,
				config);
					if (res.status===200) {
				Modal.success({
					title: t("impaqx.adminApp.connectorCreateMessage"),
					icon: <CheckCircleFilled />,
					okText: t("impaqx.adminApp.close"),
					//cancelText: 'Cancel',
					onOk() {
					  //deleteApp(id);
					},
				  });
				  yield put(connectorInstanceList(res.data));
				} 
		}
        } catch (error) {
            console.log(error);
			if(error.response.data.status==401){
				yield put(showConnectorApisMessage(error.response));
			}
			yield put(showConnectorApisMessage(error.response));
        }
    }
    )
}

export default function* rootSaga(){
    yield all([
		  fork(getConnector),
		  fork(deleteConnectorApi),
		  fork(saveConnector),
		  fork(updateConnector),
		  fork(testConnector),
		  fork(getInstanceConnector),
		  fork(updateInstanceConnector)
    ])
    
}