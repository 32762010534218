import { ACCOUNT_LIST,ACCOUNT_MESSAGE,ACCOUNT_REGISTER_SUCCESS,UNAUTHERIZE_MESSAGE,DEFAULT_ACCOUNT,SHOW_LOADING,HIDE_LOADING } from "redux/constants/account";
import { AUTH_PREFIX_PATH} from 'configs/AppConfig'

const initState = {
    loading : true,
    accountList : [],
    account:{},
    message: '',
    showMessage: false,
    redirect : "",
    allowRedirect:false,
    status:0,
    token:null
}

const account =(state=initState,action) =>{
    switch(action?.type){
        case ACCOUNT_LIST:
         return{
             ...state,
             accountList : action?.accountList,
             allowRedirect :false,
             loading:false,
             message:""
         }
         case ACCOUNT_MESSAGE:
         return{
             ...state,
             message : messageRead(action),
             status:action?.response?.status,
             showMessage : true,
             loading: false,
             allowRedirect : false
         }
         case ACCOUNT_REGISTER_SUCCESS:
            return{
                ...state,
                message : messageRead(action),
                status:action?.response?.status,
                showMessage : true,
                loading: false,
                allowRedirect :true,
                redirect : "/app/pages/account-list?mode=view-all"
            }
         case SHOW_LOADING: {
			return {
				...state,
				loading: true
			}
		}
        case HIDE_LOADING:{
			return{
				...state,
				loading:false
			}
		}
         default:
           return state;
    }
}

const messageRead=(action)=>{
    if(action?.response?.status==401 || action?.response?.response?.status){
        return "Unauthorized"
    }
	if(action?.response && action?.response?.response && action?.response?.response?.data && action?.response?.response?.message){
	 return action?.response?.response?.data?.message;
	}else if(action?.response && action?.response?.data && action?.response?.data?.message){
		return action?.response?.data?.message;
	}else if(action?.response && action?.response?.data && action?.response?.data?.message){
		return action?.response?.data?.message;
	}else if(action?.data?.message){
		return action?.data?.message;
	}else if(action?.data){
		return action?.data;
	}else{
		return "something went wrong"
	}
}

export default account;