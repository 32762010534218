export const DATABASE_LIST="DATABASE_LIST";
export const GET_DATABASE_LIST="GET_DATABASE_LIST";
export const SHOW_LOADING="SHOW_LOADING";
export const HIDE_LOADING="HIDE_LOADING";
export const SAVE_DATABASE="SAVE_DATABASE";
export const UPDATE_DATABASE="UPDATE_DATABASE";
export const SAVE_DATABASE_SUCCESS_MESSAGE="SAVE_DATABASE_SUCCESS_MESSAGE";
export const DELETE_DATABASE="DELETE_DATABASE";
export const DATA_MIGRATION_MESSAGE="DATA_MIGRATION_MESSAGE";
export const SET_ADD_EDIT = "SET_ADD_EDIT";
export const GET_TABLE_LIST="GET_TABLE_LIST";
export const TABLE_LIST="TABLE_LIST";
export const GET_COLUMN_LIST="GET_COLUMN_LIST";
export const COLUMN_LIST="COLUMN_LIST";
export const COPY_DATABASE_DATA="COPY_DATABASE_DATA";
export const COPY_BLOB_STORAGE_FILE = "COPY_BLOB_STORAGE_FILE";