import React, { useState } from "react";

import {
  Layout,
  Button,
  Col,
  Row,
  Form,
  Input,
  Card,
  Typography,
} from "antd";

import { connect } from "react-redux";

import EditField from "components/AdminComponents/Edit-Field";

import AddField from "components/AdminComponents/Add-Field";

import AddNewGroup from "components/AdminComponents/AddNew-group";

import JSONInput from "react-json-editor-ajrm";

import locale from "react-json-editor-ajrm/locale/en";

import { addAppSettingsJson, getAppSettingsDetails } from "redux/actions/app";

import { v4 as uuidv4 } from "uuid";

import SchemaForm from "antd-schema-form";
import Loader from "components/util-components/Loader";

const { Title } = Typography;

const SettingsConfigure = (props) => {
  const [activeTabKey, setActiveTabKey] = useState("builder");

  const _id = uuidv4();

  const { param, addAppSettingsJson, getAppSettingsDetails, appSettingsJson } =
    props;

  const [formData, setFormData] = React.useState([]);
  const [loading,setLoading]=useState(true);

  const [objectList, setObjectList] = useState([]);
  const [uniqueError, setUniqueError] = useState([]);
  const [preLengthCount, setPreLengthCount] = useState();

  const [isObjectDuplicate, setIsObjectDuplicate] = useState(false);

  const { id } = param;

  React.useEffect(() => {
    getAppSettingsDetails(id);
  }, []);

  React.useEffect(() => {
    if (appSettingsJson && appSettingsJson?.settingsSchema) {
      setFormData(JSON.parse(appSettingsJson?.settingsSchema));
      const preDefArray = JSON.parse(appSettingsJson?.settingsSchema);
      setPreLengthCount(preDefArray.length);
    }
    let errorList = [];
    // JSON.parse(appSettingsJson?.settingsSchema).map((object, index) =>{
    //   errorList.push(false);
    // })
    setUniqueError(errorList)
  }, [appSettingsJson]);

  React.useEffect(() => {
    if(appSettingsJson!=null)
    setLoading(false);
  },[appSettingsJson])
  const tabList = [
    {
      key: "builder",

      tab: "Developer Options",
    },

    {
      key: "editSchema",

      tab: "Developer Mode",
    },
  ];

  const onTabChange = (key) => {
    setActiveTabKey(key);
  };

  const handleJsonEditor = (data) => {
    if (!data?.error) {
      console.log("JSON OBJECT", data.jsObject);

      setFormData(data.jsObject);
    }
  };

  const saveChanges = () => {
    const formList = [...formData];

    objectList &&
      objectList.length > 0 &&
      objectList?.map((objectData, index) => {
        let newData = { ...formData[index] };

        let schema = { ...formData[index].properties };

        objectList[index] &&
          Object.keys(objectList[index]) &&
          Object.keys(objectList[index])?.length > 0 &&
          Object.keys(objectList[index])?.map((keyName) => {
            let renmeObjectKey = (object) => {
              object[`${objectList[index][`${keyName}`]}`] = {
                ...object[`${keyName}`],

                id: `${objectList[index][`${keyName}`]}`,
              };

              delete object[`${keyName}`];
            };

            renmeObjectKey(schema);

            newData.properties = schema;
          });

        formList[index] = { ...newData };
      });

    const settingsSchema = formList ? JSON.stringify(formList) : "";

    const settingsDefaultValues = appSettingsJson.settingsDefaultValues;

    addAppSettingsJson({ id, settingsSchema, settingsDefaultValues });

    setObjectList({});
  };

  const handleFormTitle = (event, index) => {
    const formList = [...formData];
    const found = formData.find(element => element.title === event.target.value);
    let errorList = [...uniqueError]
    if(!found){
      formList[index].title = event.target.value;
      setFormData([...formList]);
      errorList[index] = false;
      setIsObjectDuplicate(false)
    } else {
      errorList[index] = true;
      setIsObjectDuplicate(true)
    }
    setUniqueError([...errorList]);
    //inventory.find(isCherries)
  };

  const deleteGroup = (indexValue) => {
    const newArray = [...formData]
    newArray.splice(indexValue, 1);
    setFormData([...newArray])
  }

  return (
    <>
       {loading? <Loader spinning={loading} cover="content"  />:<>
      <Card
        style={{
          width: "100%",
        }}
        tabList={tabList}
        activeTabKey={activeTabKey}
        onTabChange={(key) => {
          onTabChange(key);
        }}
      >
        {activeTabKey === "builder" ? (
          <Layout layout="vertical">
            {formData &&
              formData.length > 0 &&
              formData.map((formDetails, indexValue) => {
                return (
                  <Card
                    key={indexValue}
                    bodyStyle={{ display: "flex", flexDirection: "column" }}
                  >
                    <Title level={4}>{"CONFIGURATION GROUP NAME"}</Title>
                    <Row justify="center" gutter={24}>
                    <Col xs={24} sm={24} md={12} lg={12} className="gutter-row">

                    <Form.Item
                    {...(uniqueError[indexValue] && { validateStatus: "error" })}
                    {...(uniqueError[indexValue] && {
                      help: "This group name already used try with another name",
                    })}
                  >
                   <Input
                      onChange={(event) => handleFormTitle(event, indexValue)}
                      placeholder={"Enter form name"}
                      defaultValue={formDetails?.title}
                      style={{ width: "50%", marginBottom: 10 }}
                      // disabled={preLengthCount < indexValue + 1 ? false : true}
                    />
                  </Form.Item>
                    </Col>
                        <Col xs={24} sm={24} md={12} lg={12} className="gutter-row">
                   {/* <Title level={4}>{formDetails.id}</Title> */}
                   <Button
                    type="primary"
                    style={{ marginTop: 10 }}
                    danger
                    ghost
                    onClick={() => deleteGroup(indexValue)}
                    disabled={preLengthCount < indexValue + 1 ? false : true}
                  >
                    Delete Group
                  </Button>
                   </Col>
                   </Row>
                    {Object.keys(formDetails.properties) &&
                      Object.keys(formDetails.properties)?.length > 0 &&
                      Object.keys(formDetails.properties)?.map(
                        (keyname, index) => (
                          <EditField
                            key={`obj-${index}- ${keyname}`}
                            fieldData={formDetails}
                            setFieldData={setFormData}
                            formList={formData}
                            preDefValue={formDetails?.properties[`${keyname}`]}
                            objectValue={`${keyname}`}
                            setIsObjectDuplicate={setIsObjectDuplicate}
                            isObjectDuplicate={isObjectDuplicate}
                            setObjectList={setObjectList}
                            objectList={objectList}
                            position={indexValue}
                          />
                        )
                      )}
                    <div style={{ alignSelf: "center" }}>
                      <AddField
                        fieldData={formData}
                        setFieldData={setFormData}
                        position={indexValue}
                      />
                    </div>
                  </Card>
                );
              })}
            <AddNewGroup
              fieldData={formData}
              setFieldData={setFormData}
              appId={id}
            />
          </Layout>
        ) : (
          <div style={{ display: "flex" }}>
            <Card style={{ width: "50%", marginRight: 5 }}>
              <JSONInput
                locale={locale}
                placeholder={formData}
                onChange={handleJsonEditor}
                theme={"light_mitsuketa_tribute"}
                style={{
                  outerBox: {
                    height: "100%",

                    maxHeight: "600px",

                    width: "100%",
                  },

                  container: {
                    height: "100%",

                    width: "100%",

                    overflow: "hide",
                  },

                  body: { minHeight: "300px", width: "100%", height: "35rem" },
                }}
              />
            </Card>
            <div
              style={{
                width: "50%",

                height: "100%",

                maxHeight: "600px",

                overflow: "auto",
              }}
            >
              {formData &&
                formData.length > 0 &&
                formData.map((formDetails, index) => (
                  <div style={{ marginBottom: 10 }}>
                    <SchemaForm
                      json={formDetails}
                      okText="Submit"
                      key={index}
                    />
                  </div>
                ))}
            </div>
          </div>
        )}
      </Card>
      <Button type="primary" onClick={saveChanges} disabled={isObjectDuplicate}>
         Save Changes 
      </Button></>}
    </>
  );
};

const mapStateToProps = ({ app }) => {
  const { appSettingsJson } = app;

  return {
    appSettingsJson,

    app,
  };
};

const mapDispatchToProps = {
  addAppSettingsJson,

  getAppSettingsDetails,
};

export default connect(mapStateToProps, mapDispatchToProps)(SettingsConfigure);