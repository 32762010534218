import { all } from 'redux-saga/effects';
import Auth from './Auth';
import account from './account';
import app from './app';
import user from './user';
import appInstance from './appInstance';
import appParameter from './appParameter';
import connector from './connector'
import dataMigration from './dataMigration';
import dataExport from './dataExport';
// import licensePricing from './licensePricing'
// import accountAppLicense from './accountAppLicense'

export default function* rootSaga(getState) {
  yield all([
    Auth(),
    account(),
    app(),
    user(),
    appInstance(),
    appParameter(),
    connector(),
    dataMigration(),
    dataExport(),
    // licensePricing(),
    // accountAppLicense()
  ]);
}
