import {
    GET_APP_PARAMETERS_LIST,APP_PARAMETERS_MESSAGE,DELETE_APP_PAREMETER,SHOW_LOADING,HIDE_LOADING,APP_PARAMETERS_LIST, UPDATE_APP_PARAMETER,ADD_APP_PARAMETER,
    APP_PARAMETER_REGISTER_SUCCESS
  } from "redux/constants/appParameters";
  
  export const getAppParametersList = (requestData) => {
    return {
      type: GET_APP_PARAMETERS_LIST,
      requestData:requestData
    };
  };

  export const showLoading = () => {
    return {
      type: SHOW_LOADING,
    };
  };

  export const hideLoading=()=>{
    return {
      type : HIDE_LOADING
    }
  }

  export const showAppParametersMessage=(response)=>{
    return{
        type : APP_PARAMETERS_MESSAGE,
        response
    }
  }

  export const appParametersList = (data) => {
    return {
      type: APP_PARAMETERS_LIST,
      appParametersList: data,
    };
  };

 export const deleteAppParameters = (appParameter) => {
    return {
      type: DELETE_APP_PAREMETER,
      payload: appParameter,
    };
  };

  export const addAppParameter = (data) => {
    return {
      type: ADD_APP_PARAMETER,
      payload: data,
    };
  };

  export const updateAppParameter = (data) => {
    return {
      type: UPDATE_APP_PARAMETER,
      payload: data,
    };
  };

  export const registerSuccess = (response) => {
    return {
      type: APP_PARAMETER_REGISTER_SUCCESS,
      response,
    };
  };
  