import { GET_DATA_EXPORT_TABLE_LIST,SHOW_LOADING,HIDE_LOADING,GET_DATA_EXPORT_SCHEMA_LIST,DATA_EXPORT_SCHEMA_LIST,GET_VIEW_DATA,VIEW_DATA, DATA_MIGRATION_MESSAGE,
     DELETE_DATABASE,SAVE_DATABASE, SAVE_DATABASE_SUCCESS_MESSAGE, UPDATE_DATABASE,SET_ADD_EDIT,COLUMN_LIST,COPY_DATABASE_DATA,COPY_BLOB_STORAGE_FILE,
      DATA_EXPORT_TABLE_LIST,EXPORT_DATA,GET_DATA_EXPORT_LIST,DATA_EXPORT_LIST,DELETE_DATA_EXPORT,ADD_DATA_EXPORT,UPDATE_DATA_EXORT,
      GET_DATA_EXPORT_TABLE_VIEW_LIST,DATA_EXPORT_TABLE_VIEW_LIST} from "redux/constants/dataExport";

export const getDataExportList = (requestData)=>{
    return{
        type : GET_DATA_EXPORT_LIST,
        requestData : requestData
    }
}

export const dataExportList = (data)=>{
    return{
        type : DATA_EXPORT_LIST,
        dataExportList : data
    }
}
export const getExportTableList = (requestData)=>{
    return{
        type : GET_DATA_EXPORT_TABLE_LIST,
        requestData : requestData
    }
}

export const dataExportTableList=(data)=>{
    return{
        type : DATA_EXPORT_TABLE_LIST,
        dataExportTableList : data
    }

}

export const getExportSchemaList = (requestData)=>{
    return{
        type : GET_DATA_EXPORT_SCHEMA_LIST,
        requestData : requestData
    }
}

export const schemaList = (data)=>{
    return{
        type : DATA_EXPORT_SCHEMA_LIST,
        schemaList : data
    }
}

export const getTableViewsList=(data)=>{
    return{
        type : GET_DATA_EXPORT_TABLE_VIEW_LIST,
        requestData : data
    }
}

export const tableViewList=(data)=>{
    return{
        type : DATA_EXPORT_TABLE_VIEW_LIST,
        tableViewList : data
    }
}

export const getViewData = (data)=>{
    return{
        type : GET_VIEW_DATA,
        payload : data
    }
}

export const exportData = (data)=>{
    return{
        type : EXPORT_DATA,
        payload : data
    }
}

export const viewData = (data)=>{
    return{
        type : VIEW_DATA,
        data : data
    }
}

export const showLoading = () => {
    return {
      type: SHOW_LOADING,
    };
  };

  export const hideLoading=()=>{
    return {
      type : HIDE_LOADING
    }
  }

export const addDataExport=(data)=>{
    return{
        type : ADD_DATA_EXPORT,
        payload : data
    }
}

export const updateDataExport = (data)=>{
    return {
        type : UPDATE_DATA_EXORT,
        payload : data
    }
}

export const saveDatabaseSuccessMessage=(response)=>{
    return{
        type : SAVE_DATABASE_SUCCESS_MESSAGE,
        response
    }
}

export const showDataMigrationMessage=(response)=>{
    return{
        type : DATA_MIGRATION_MESSAGE,
        response
    }
}

export const deleteDataExport=(data)=>{
    return{
        type : DELETE_DATA_EXPORT,
        payload : data
    }
}

export const setAddEdit = (value) => {
    return {
      type: SET_ADD_EDIT,
      addEditValue: value,
    };
  };