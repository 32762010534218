import { all, takeEvery, put, fork } from 'redux-saga/effects';

//import axios from 'axios';
import axios from './initialize'
import { Modal } from 'antd';
import {CheckCircleFilled} from  '@ant-design/icons';

import {  SAVE_ACCOUNT, DELETE_ACCOUNT, UPDATE_ACCOUNT,GET_DEFAULT_ACCOUNT,GET_ACCOUNT_LIST,UPDATE_DEFAULT_ACCOUNT} from "redux/constants/account";

import { accountList, showAccountMessage,registerSuccess,account } from "redux/actions/account";
import { noDefaultAccount } from 'redux/actions/Auth'
import { showUnAutherizeMessage} from "redux/actions/Auth";

import { API_BASE_URL, APP_PREFIX_PATH, MANAGE_PREPIX_PATH } from 'configs/AppConfig';
import {
	AUTH_TOKEN,
} from '../constants/Auth';
import {PATH_VARIABLE} from 'constants/ApiConstant';
import { t } from "i18next";


export function* getAccountList(){
    yield takeEvery (GET_ACCOUNT_LIST, function*  ({requestData}){
	    const {query, sorter,pagination,userId} = requestData;
	   //const userId = localStorage.getItem("userId");
const config = {
		headers: { "Content-Type": 'application/json',"Access-Control-Allow-Origin": "*",
		"Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",Authorization : `Bearer ${localStorage.getItem(AUTH_TOKEN)}` }
	  };
		try {
		const res = yield  axios.get(`${API_BASE_URL}${MANAGE_PREPIX_PATH}${APP_PREFIX_PATH}/accounts?userId=${userId}&query=${query}&sortfield=${sorter?.field}&sortorder=${sorter?.order}&page=${pagination?.current-1}&size=${pagination?.pageSize}`,
		config);
			if (res.status===200) {
				yield put(accountList(res.data));
			} 
		} catch (err) {
			console.log(err);
			if(err.response.data.status==401){
				yield put(showUnAutherizeMessage(err.response));
			}
			yield put(showAccountMessage(err.response));
		}
    }

    )
}

export  function* accountRegister(){
	yield takeEvery(SAVE_ACCOUNT, function* ({payload}){''
		try {
			//const header = ;
			const res = yield  axios.post(`${API_BASE_URL}${MANAGE_PREPIX_PATH}${APP_PREFIX_PATH}/account`,payload,{headers :{
				'Authorization': `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
				'Access-Control-Allow-Origin' : '*',
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				 'accountId':localStorage.getItem("accountId"),
				 accountUserId: localStorage.getItem("accountUserId")
			  }});
				if (res.status===200) {
					var accountUser = {};
					if(res.data.account){
						// accountUser["accountId"] = res.data.account.id;
						// accountUser["userId"]=res.data.account.userId;
						// accountUser["status"]="ACTIVE";
						// accountUser["userCreated"] = res.data.account.userId;
						// accountUser["userUpdated"] = res.data.account.userId;
						// const response = yield  axios.post(`${API_BASE_URL}${MANAGE_PREPIX_PATH}${APP_PREFIX_PATH}/accountUser`,accountUser,{headers :{
						// 	'Authorization': `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
						// 	'Access-Control-Allow-Origin' : '*',
						// 	'Accept': 'application/json',
						// 	'Content-Type': 'application/json'
						// }});
						if (res.status===200) {
							Modal.success({
								title: `${payload.businessName}  ${t("impaqx.adminApp.accountSaveMessage")}`,
								icon: <CheckCircleFilled />,
								okText: 'Close',
								//cancelText: 'Cancel',
								onOk() {
									//deleteApp(id);
								},
							});
								yield put(registerSuccess(res))
						} else{
							yield put(showAccountMessage(res));
						}
					}
				}else{
					yield put(showAccountMessage(res));
				}
			} catch (err) {
				console.log(err);
				if(err.response.data.status==401){
					yield put(showUnAutherizeMessage(err.response));
				}
				yield put(showAccountMessage(err.response));
			}
	}
	)
}

export function* getDefaultAccount(){
    yield takeEvery (GET_DEFAULT_ACCOUNT,function* ({requestData}){
        try {
			const {query, sorter,pagination} = requestData;
            const userId = localStorage.getItem("userId");
            const res = yield axios.get(`${API_BASE_URL}${MANAGE_PREPIX_PATH}${APP_PREFIX_PATH}/account?userId=${userId}`, {
				headers: {
				  'Authorization': `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
				  'Access-Control-Allow-Origin' : '*',
				  'Accept': 'application/json',
				  'Content-Type': 'application/json',
				  'accountId':localStorage.getItem("accountId")
				}
			  });
             if (res.status===200) {
				const account = res.data;
				if(account&& account!=""){
					localStorage.setItem("accountId",account.id);
					localStorage.setItem("accountCode",account.accountCode);
					localStorage.setItem("disableSelection",account.disableSelection);
					localStorage.setItem("businessName",account.businessName);
					//history.replace(`${MANAGE_PREPIX_PATH}${APP_PREFIX_PATH}/${account.id}/my-apps`)
					window.location.replace(`${PATH_VARIABLE}${APP_PREFIX_PATH}/${account.id}/my-apps`);
				}
             } 
			 const response = yield  axios.get(`${API_BASE_URL}${MANAGE_PREPIX_PATH}${APP_PREFIX_PATH}/accounts?userId=${userId}&query=${query}&sortfield=${sorter?.field}&sortorder=${sorter?.order}&page=${pagination?.current-1}&size=${pagination?.pageSize}`,
		{headers: {
			'Authorization': `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
			'Access-Control-Allow-Origin' : '*',
			'Accept': 'application/json',
			'Content-Type': 'application/json'
		  }});
			if (response.status===200) {
				yield put(accountList(response.data));
			} 
        } catch (error) {
            console.log(error);
			if(error.response.data.status==401){
				yield put(showUnAutherizeMessage(error.response));
			}
			yield put(showAccountMessage(error.response));
        }
    }
    )
}


export function* updateAccount(){
    yield takeEvery (UPDATE_ACCOUNT,function* ({payload}){
        try {
            const res = yield axios.put(`${API_BASE_URL}${MANAGE_PREPIX_PATH}${APP_PREFIX_PATH}/account/${payload.id}`, payload,{
				headers: {
				  'Authorization': `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
				  'Access-Control-Allow-Origin' : '*',
				  'Accept': 'application/json',
				  'Content-Type': 'application/json'
				}
			  });
             if (res.status===200) {
					Modal.success({
						title: `${payload.businessName}  ${t("impaqx.adminApp.accountUpdatedMessage")}`,
						icon: <CheckCircleFilled />,
						okText: 'Close',
						//cancelText: 'Cancel',
						onOk() {
							//deleteApp(id);
						},
					});
                 yield put(registerSuccess(res));
             } 
        } catch (error) {
            console.log(error);
			if(error.response.data.status==401){
				yield put(showUnAutherizeMessage(error.response));
			}
			yield put(showAccountMessage(error.response));
        }
    }
    )
}

export function* deleteAccounts(){
	yield takeEvery(DELETE_ACCOUNT, function*({payload}){
         try {
			const {query, sorter,pagination,id,businessName} = payload;
			 const res = yield axios.delete(`${API_BASE_URL}${MANAGE_PREPIX_PATH}${APP_PREFIX_PATH}/account/${id}?userId=${localStorage.getItem("userId")}`,{
				headers: {
				  'Authorization': `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
				  'Access-Control-Allow-Origin' : '*',
				  'Accept': 'application/json',
				  'Content-Type': 'application/json',
				  'accountId':localStorage.getItem("accountId"),
				  accountUserId: localStorage.getItem("accountUserId")
				}
			  });
			 if(res.status===200){
				 const res = yield  axios.get(`${API_BASE_URL}${MANAGE_PREPIX_PATH}${APP_PREFIX_PATH}/accounts?userId=${localStorage.getItem("userId")}&query=${query}&sortfield=${sorter?.field}&sortorder=${sorter?.order}&page=${pagination?.current-1}&size=${pagination?.pageSize}`,
				 {headers: {
					'Authorization': `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
					'Access-Control-Allow-Origin' : '*',
					'Accept': 'application/json',
					'Content-Type': 'application/json',
					accountUserId: localStorage.getItem("accountUserId")
				  }});
					if (res.status===200) {
						Modal.success({
							title: `${businessName}  ${t("impaqx.adminApp.accountDeleteMessage")}`,
							icon: <CheckCircleFilled />,
							okText: 'Close',
							//cancelText: 'Cancel',
							onOk() {
								//deleteApp(id);
							},
						});
						yield put(accountList(res.data));
					} 
			 }
		 } catch (error) {
			 console.log(error);
			 if(error.response.data.status==401){
				yield put(showUnAutherizeMessage(error.response));
			}
			 yield put(showAccountMessage(error.response));
		 }
	})
}

export function* updateDefaultAccount(){
    yield takeEvery (UPDATE_DEFAULT_ACCOUNT,function* ({payload}){
        try {
            const res = yield axios.put(`${API_BASE_URL}${MANAGE_PREPIX_PATH}${APP_PREFIX_PATH}/account-user/default-account/${payload.accountData.id}?userId=${localStorage.getItem("userId")}&defaultValue=${payload.defaultValue}`, payload,{
				headers: {
				  'Authorization': `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
				  'Access-Control-Allow-Origin' : '*',
				  'Accept': 'application/json',
				  'Content-Type': 'application/json',
				  accountUserId: localStorage.getItem("accountUserId")
				}
			  });
             if (res.status===200) {
				localStorage.setItem("defaultAccountId",res.data.defaultAccountId);
				localStorage.setItem("defaultBusinessName",payload.accountData.businessName);
                 yield put(registerSuccess(res));
             } 
        } catch (error) {
            console.log(error);
			if(error.response.data.status==401){
				yield put(showUnAutherizeMessage(error.response));
			}
			yield put(showAccountMessage(error.response));
        }
    }
    )
}

export default function* rootSaga(){
    yield all([
		  fork(getAccountList),
		  fork(accountRegister),
		  fork(deleteAccounts),
		  fork(updateAccount),
		  fork(getDefaultAccount),
		  fork(updateDefaultAccount)
    ])
    
}