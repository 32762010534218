import { APP_INSTANCE_MESSAGE,APP_SETTINGS_JSON,ADD_APP_INSTANCE_SETTINGS_JSON,APP_INSTANCE_ROLE_LIST,SET_ADD_EDIT,APP_INSTANCE_ROLE_PERMISSION_LIST,
  SET_MODAL_POPUP_DATA,SET_MODAL_ROLE_PERMISSION_POPUP,SHOW_LOADING,APP_INSTANCE_API_KEY,ASSIGNED_INSTANCE_USER_ROLES,APP_INSTANCE_DETAILS,APP_INSTANCE_UI_PROPERTIES_JSON,
  APP_INSTANCE_UI_PROPERTIES,ADD_APP_INSTANCE_UI_PROPERTIES,EDIT_APP_INSTANCE_UI_PROPERTIES,DELETE_APP_INSTANCE_UI_PROPERTIES, LINKED_APP_INSTANCE_CONNECTOR, CONNECTOR_SETTINGS_JSON, APP_ROLES_INSTANCE_ROLE_LIST, APP_INSTANCE_API_KEYS, HIDE_LOADING } from "redux/constants/appInstance";

const initState = {
    loading : true,
    appInstanceRoles : [],
    appRolesInstanceRoles:[],
    appInstanceRolePermissions:[],
    appInstanceApiKey:[],
    appInstanceDetails:{},
    instanceUserRoles:[],
    appInstanceApiKeys:[],
    linkedConnectorList:{},
    message: '',
    showMessage: false,
    redirect : "",
    allowRedirect:false,
    status:0,
    token:null,
    addEditValue: false,
    connectorSettings : false
}

const appInstance =(state=initState,action) =>{
    switch(action?.type){
       
         case APP_INSTANCE_MESSAGE:
         return{
             ...state,
             message : messageRead(action),
             status:action.response.status,
             showMessage : true,
             loading: false,
             allowRedirect : false
         }
         case APP_SETTINGS_JSON:
            return {
              ...state,
              appSettingsJson: action.appSettingsJson,
              loading: false,
              message: "",
            };
            case CONNECTOR_SETTINGS_JSON:
              return {
                ...state,
                connectorSettingsJson: action.connectorSettingsJson,
                connectorSettings : true,
                loading: false,
                message: "",
              };
            case ADD_APP_INSTANCE_SETTINGS_JSON:
                return {
                  ...state,
                  loading: false,
                  resData: {},
                };
          case APP_INSTANCE_ROLE_LIST:
            return {
              ...state,
              appInstanceRoles: action.appInstanceRoles,
              allowRedirect: false,
              loading:false,
              message: "",
            };
            case APP_ROLES_INSTANCE_ROLE_LIST:
              return {
                ...state,
                appRolesInstanceRoles: action.appRolesInstanceRoles,
                allowRedirect: false,
                loading:false,
                message: "",
              };
            case SHOW_LOADING: {
              return {
                ...state,
                loading: true
              }
            }
            case HIDE_LOADING: {
              return {
                ...state,
                loading: false,
              };
            }
            case SET_ADD_EDIT:
              return {
                ...state,
                loading: false,
                addEditValue: action.addEditValue,
                appInstanceRolePermissions: [],
              };
              case APP_INSTANCE_ROLE_PERMISSION_LIST:
                return {
                  ...state,
                  appInstanceRolePermissions: action.appInstanceRolePermissions,
                  modalRolePermissionVisible:true,
                  allowRedirect: false,
                  loading:false,
                  message: "",
                };
                case SET_MODAL_POPUP_DATA:
                  return {
                    ...state,
                    modalRoleVisible:action.modalRoleVisible,
                    allowRedirect: false,
                    loading: false,
                    message: "",
                  };
                  case SET_MODAL_ROLE_PERMISSION_POPUP:
                    return {
                      ...state,
                      modalRolePermissionVisible:action.data,
                      allowRedirect: false,
                      loading: false,
                      message: "",
                    };
                  case APP_INSTANCE_API_KEY:
                    return {
                      ...state,
                      appInstanceApiKey : action.appInstanceApiKey,
                      loading: false,
                    }
                    case APP_INSTANCE_API_KEYS:
                      return {
                        ...state,
                        appInstanceApiKeys : action.appInstanceApiKeys,
                        loading: false,
                        addEditValue:false
                      }
                    case ASSIGNED_INSTANCE_USER_ROLES:
                      return {
                        ...state,
                        instanceUserRoles : action.instanceUserRoles?.instnaceUserRoles,
                        loading: false,
                      }
                    case APP_INSTANCE_DETAILS:
                      return {
                        ...state,
                        appInstanceDetails : action.appInstance,
                        loading: false
                      }
                      case LINKED_APP_INSTANCE_CONNECTOR :
                        return {
                          ...state,
                        linkedAppInstane : action.linkedAppInstane,
                        loading: false
                        }
                      case APP_INSTANCE_UI_PROPERTIES:
                        return { ...state, loading: false, resData: {} };
                      case ADD_APP_INSTANCE_UI_PROPERTIES:
                        return { ...state, loading: false, resData: {} };
                      case EDIT_APP_INSTANCE_UI_PROPERTIES:
                        return { ...state, loading: false, resData: {} };
                      case DELETE_APP_INSTANCE_UI_PROPERTIES:
                        return { ...state, loading: false, resData: {} };
                        case APP_INSTANCE_UI_PROPERTIES_JSON:
                        return {
                          ...state,
                          uiPropertiesJsonData: action.uiPropertiesJsonData,
                          loading: false,
                          message: "",
                        };
                      
             default:
           return state;
    }
}
const messageRead=(action)=>{
  if(action?.response?.status==401 || action?.response?.response?.status){
    return "Unauthorized"
  }
	if(action?.response && action?.response?.response && action?.response?.response?.data && action?.response?.response?.message){
	 return action?.response?.response?.data?.message;
	}else if(action?.response && action?.response?.data && action?.response?.data?.message){
		return action?.response?.data?.message;
	}else if(action?.response && action?.response?.data && action?.response?.data?.message){
		return action?.response?.data?.message;
	}else if(action?.response && action?.response?.data){
		return action?.response?.data;
	}else if(action?.data?.message){
		return action?.data?.message;
	}else if(action?.data){
		return action?.data;
	}else{
		return "something went wrong"
	}
}
export default appInstance;