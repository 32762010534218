export const ACCOUNT_LIST = 'ACCOUNT_LIST';
export const GET_ACCOUNT_LIST = 'GET_ACCOUNT_LIST';
export const SAVE_ACCOUNT='SAVE_ACCOUNT';
export const ACCOUNT_REGISTER_SUCCESS='ACCOUNT_REGISTER_SUCCESS';
export const DELETE_ACCOUNT='DELETE_ACCOUNT';
export const ACCOUNT_MESSAGE='ACCOUNT_MESSAGE';
export const UPDATE_ACCOUNT='UPDATE_ACCOUNT';
export const UNAUTHERIZE_MESSAGE='UNAUTHERIZE_MESSAGE';
export const GET_DEFAULT_ACCOUNT="GET_DEFAULT_ACCOUNT";
export const DEFAULT_ACCOUNT="DEFAULT_ACCOUNT";
export const AUTHENTICATED_NO_DEFAULT_ACCOUNT="AUTHENTICATED_NO_DEFAULT_ACCOUNT";
export const UPDATE_DEFAULT_ACCOUNT="UPDATE_DEFAULT_ACCOUNT";
export const SHOW_LOADING="SHOW_LOADING";
export const NO_DEFAULT_ACCOUNT="NO_DEFAULT_ACCOUNT";
export const HIDE_LOADING="HIDE_LOADING";