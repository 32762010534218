export const SIGNIN = 'SIGNIN'; 
export const SIGNIN_TOTP='SIGNIN_TOTP';
export const SIGNIN_SUCCESS='SIGNIN_SUCCESS';
export const AUTHENTICATED = 'AUTHENTICATED';
export const SIGNOUT = 'SIGNOUT';
export const SIGNOUT_EXTREMEB2B='SIGNOUT_EXTREMEB2B';
export const SIGNOUT_SUCCESS = 'SIGNOUT_SUCCESS'
export const SIGNUP = 'SIGNUP';
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS'
export const SHOW_AUTH_MESSAGE = 'SHOW_AUTH_MESSAGE'
export const HIDE_AUTH_MESSAGE = 'HIDE_AUTH_MESSAGE'
export const SHOW_LOADING = 'SHOW_LOADING'
export const AUTH_TOKEN = 'auth_token'
export const SIGNIN_WITH_GOOGLE = 'SIGNIN_WITH_GOOGLE'
export const SIGNIN_WITH_GOOGLE_AUTHENTICATED = 'SIGNIN_WITH_GOOGLE_AUTHENTICATED'
export const SIGNIN_WITH_FACEBOOK = 'SIGNIN_WITH_FACEBOOK'
export const SIGNIN_WITH_FACEBOOK_AUTHENTICATED = 'SIGNIN_WITH_FACEBOOK_AUTHENTICATED'
export const SEND_MAIL = 'SEND_MAIL';
export const VERIFY_MAIL = 'VERIFY_MAIL';
export const EMAIL_ID_CHECK = 'EMAIL_ID_CHECK';
export const CHECK_MAIL='CHECK_MAIL';
export const RESET_PASSWORD_MAIL='RESET_PASSWORD_MAIL';
export const RESET_PASSWORD='RESET_PASSWORD';
export const PASS_VALIDATE='PASS_VALIDATE';
export const CHECK_PASS_VALIDATE='CHECK_PASS_VALIDATE';
export const SIGNIN_WITH_MICROSOFT='SIGNIN_WITH_MICROSOFT';
export const FETCH_USER_DETAILS='FETCH_USER_DETAILS';
export const GET_MFA_SETUP="GET_MFA_SETUP";
export const CHANEGE_PASSWORD="CHANEGE_PASSWORD";
export const USING_2FA="USING_2FA";
export const TOGGLE_2FA="TOGGLE_2FA";
export const VERIFY_SUCCESS_SIGNIN="VERIFY_SUCCESS_SIGNIN";
export const UNAUTHERIZE_MESSAGE="UNAUTHERIZE_MESSAGE";
export const AUTHENTICATED_NO_DEFAULT_ACCOUNT="AUTHENTICATED_NO_DEFAULT_ACCOUNT";
export const TOKEN_CHECK="TOKEN_CHECK";
export const GET_USER_DETAILS="GET_USER_DETAILS";
export const USER_DETAILS="USER_DETAILS";
export const HIDE_LOADING="HIDE_LOADING";
export const UPDATE_USER="UPDATE_USER";
export const SIGNUP_WITH_MICROSOFT = "SIGNUP_WITH_MICROSOFT";
export const SIGNUP_WITH_GOOGLE="SIGNUP_WITH_GOOGLE";
export const SIGNIN_WITH_LINKEDIN="SIGNIN_WITH_LINKEDIN";
export const SIGNUP_WITH_LINKEDIN="SIGNUP_WITH_LINKEDIN";
export const OPEN_MODAL="OPEN_MODAL";