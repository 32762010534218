import React, { useState, useEffect } from "react";
import { useHistory, Link } from "react-router-dom";
import { connect } from "react-redux";
import { Layout } from "antd";
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  SearchOutlined,
  SwapOutlined
} from "@ant-design/icons";
import Logo from "./Logo";
import NavNotification from "./NavNotification";
import NavProfile from "./NavProfile";
import NavLanguage from "./NavLanguage";
import NavPanel from "./NavPanel";
import NavSearch from "./NavSearch";
import SearchInput from "./NavSearch/SearchInput.js";
import { toggleCollapsedNav, onMobileNavToggle } from "redux/actions/Theme";
import { tokenCheck } from "redux/actions/Auth";
import {
  NAV_TYPE_TOP,
  SIDE_NAV_COLLAPSED_WIDTH,
  SIDE_NAV_WIDTH,
} from "constants/ThemeConstant";
import utils from "utils";
import { APP_PREFIX_PATH, AUTH_PREFIX_PATH } from "configs/AppConfig";
import { useTranslation } from "react-i18next";
import { AUTH_TOKEN } from "redux/constants/Auth";

const { Header } = Layout;

export const HeaderNav = (props) => {
  const {
    navCollapsed,
    mobileNav,
    navType,
    headerNavColor,
    toggleCollapsedNav,
    onMobileNavToggle,
    isMobile,
    currentTheme,
    direction,
    tokenCheck,
  } = props;
  const [searchActive, setSearchActive] = useState(false);
  const isMobileNav = window.innerWidth < 568;
  const { t } = useTranslation();
  const permissions  = localStorage.getItem("permissions")!=""?JSON.parse(localStorage.getItem("permissions")):"";

  const onSearchActive = () => {
    setSearchActive(true);
  };

  const onSearchClose = () => {
    setSearchActive(false);
  };

  const onToggle = () => {
    if (!isMobile) {
      toggleCollapsedNav(!navCollapsed);
    } else {
      onMobileNavToggle(!mobileNav);
    }
  };

  const isNavTop = navType === NAV_TYPE_TOP ? true : false;
  const mode = () => {
    if (!headerNavColor) {
      return utils.getColorContrast(
        currentTheme === "dark" ? "#00000" : "#ffffff"
      );
    }
    return utils.getColorContrast(headerNavColor);
  };
  const navMode = mode();
  const getNavWidth = () => {
    if (isNavTop || isMobile) {
      return "0px";
    }
    if (navCollapsed) {
      return `${SIDE_NAV_COLLAPSED_WIDTH}px`;
    } else {
      return `${SIDE_NAV_WIDTH}px`;
    }
  };

  useEffect(() => {
    if (!localStorage.getItem("langId") || !localStorage.getItem("langName")) {
      localStorage.setItem("langId", "en");
      localStorage.setItem("langName","English")
    }
    if (!isMobile) {
      onSearchClose();
    }
  }, []);

  return (
    <Header
      className={`app-header ${navMode}`}
      style={{ backgroundColor: headerNavColor, height: "fit-content" }}
    >
      <div className={`app-header-wrapper ${isNavTop ? "layout-top-nav" : ""}`}>
        <Link to={`${APP_PREFIX_PATH}/pages/account-list?mode=view-all`}>
          <Logo logoType={navMode} />
        </Link>
        <div className="nav" style={{ width: `calc(100% - ${getNavWidth()})` }}>
          <div
            className="nav-left"
            style={isMobileNav ? { width: "30px" } : {}}
          >
            <ul className="ant-menu ant-menu-root ant-menu-horizontal nav-custom-flex">
              {isNavTop && !isMobile ? null : (
                <li
                  className="ant-menu-item ant-menu-item-only-child"
                  onClick={() => {
                    onToggle();
                  }}
                >
                  {navCollapsed || isMobile ? (
                    <MenuUnfoldOutlined className="nav-icon" />
                  ) : (
                    <MenuFoldOutlined className="nav-icon" />
                  )}
                </li>
              )}
              {isMobile ? (
                <li
                  className="ant-menu-item ant-menu-item-only-child"
                  onClick={() => {
                    onSearchActive();
                  }}
                >
                  {/* <SearchOutlined /> */}
                </li>
              ) : (
                <li
                  className="ant-menu-item ant-menu-item-only-child"
                  style={{ cursor: "auto" }}
                >
                  {/* <SearchInput mode={mode} isMobile={isMobile} /> */}
                </li>
              )}
            </ul>
          </div>
          {/* {localStorage.getItem("selectedUser")?
          			<div className="d-flex flex-column justify-content-center h-100" style={{paddingLeft: '10px'}}>
                <h5>Admin user : {localStorage.getItem("username")}</h5>
        <h5>Selected user : {localStorage.getItem("selectedUser")}</h5></div>:null} */}
          <div className="nav-right">
            <div style={{display:"flex",flexDirection:"column",justifyContent: "space-around"}}>
                {localStorage.getItem("businessName") &&
                localStorage.getItem("businessName") != "" ? (
                  <h5 style={{lineHeight:"15px",fontWeight: "bold",textWrap:"wrap"}}>
                    {localStorage.getItem("businessName")&&localStorage.getItem("businessName").trim()!=""?`${t("impaqx.adminApp.account")} -`:""}
                    {localStorage.getItem("businessName")?.length > 440  ? localStorage.getItem("businessName")?.substring ( 0 , 440 - 1 ).concat ( "…" ) : localStorage.getItem("businessName")?
                    localStorage.getItem("businessName"):""}
                  </h5>
                ) : null}
                <Link style={{lineHeight:"10px"}} to={`${APP_PREFIX_PATH}/pages/account-list?mode=view-all`}>
                <span ><SwapOutlined /> Switch account 
                </span>
              </Link>
            </div>
            <NavNotification />
            <NavLanguage />
            <NavProfile />
            <NavPanel direction={direction} />
          </div>
          <NavSearch active={searchActive} close={onSearchClose} />
        </div>
      </div>
    </Header>
  );
};

const mapStateToProps = ({ theme }) => {
  const {
    navCollapsed,
    navType,
    headerNavColor,
    mobileNav,
    currentTheme,
    direction,
  } = theme;
  return {
    navCollapsed,
    navType,
    headerNavColor,
    mobileNav,
    currentTheme,
    direction,
  };
};

export default connect(mapStateToProps, {
  toggleCollapsedNav,
  onMobileNavToggle,
  tokenCheck,
})(HeaderNav);
