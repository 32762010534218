import React, { lazy, Suspense } from "react";

import { Switch, Route, Redirect } from "react-router-dom";

import Loading from "components/shared-components/Loading";

import { AUTH_PREFIX_PATH, CUSTOM_CART } from "configs/AppConfig";

export const orderViews = () => {
  return (
    <Suspense fallback={<Loading cover="page" />}>
      <Switch>
        <Route
          path={`${CUSTOM_CART}/orders/approve/:id`}
          component={lazy(() => import(`./OrderApprove`))}
        />

        <Route
          path={`${CUSTOM_CART}/orders/decline/:id`}
          component={lazy(() => import(`./OrderApprove`))}
        />

        <Redirect from={`${CUSTOM_CART}`} to={`${AUTH_PREFIX_PATH}/login`} />
      </Switch>
    </Suspense>
  );
};

export default orderViews;
