import { combineReducers } from 'redux';
import auth from './Auth';
import Theme from './Theme';
import account from './account';
import app from './app';
import user from './user'
import appInstance from './appInstance'
import appParameter from './appParameter';
import connector from './connector';
import dataMigration from './dataMigration';
import dataExport from './dataExport';

const reducers = combineReducers({
    theme: Theme,
    auth: auth,
    account:account,
    app : app,
    user : user,
    appInstance:appInstance,
    appParameter:appParameter,
    connector : connector,
    dataMigration : dataMigration,
    dataExport : dataExport
});

export default reducers;