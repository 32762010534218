import { APP_PARAMETERS_LIST,SHOW_LOADING,HIDE_LOADING,APP_PARAMETERS_MESSAGE,APP_PARAMETER_REGISTER_SUCCESS } from "redux/constants/appParameters";
import { APP_PREFIX_PATH} from 'configs/AppConfig'

const initState = {
    loading : true,
    appParametersList : [],
    account:{},
    message: '',
    showMessage: false,
    redirect : "",
    allowRedirect:false,
    status:0,
    token:null
}

const appParameter =(state=initState,action) =>{
    switch(action?.type){
        case APP_PARAMETERS_LIST:
         return{
             ...state,
             appParametersList : action.appParametersList,
             allowRedirect :false,
             loading:false,
             message:""
         }
         case APP_PARAMETER_REGISTER_SUCCESS:
            return {
              ...state,
              //message: messageRead(action),
              status: action.response.status,
              showMessage: true,
              loading: false,
              allowRedirect: true,
              redirect: `${APP_PREFIX_PATH}/pages/app-parameters`,
            };
         case APP_PARAMETERS_MESSAGE:
         return{
             ...state,
             message : messageRead(action),
             status:action.response.status,
             showMessage : true,
             loading: false,
             allowRedirect : false
         }
         case SHOW_LOADING: {
			return {
				...state,
				loading: true
			}
		}
        case HIDE_LOADING:{
			return{
				...state,
				loading:false
			}
		}
         default:
           return state;
    }
}

const messageRead=(action)=>{
    if(action?.response?.status==401 || action?.response?.response?.status){
        return "Unauthorized"
    }
	if(action?.response && action?.response?.response && action?.response?.response?.data && action?.response?.response?.message){
	 return action?.response?.response?.data?.message;
	}else if(action?.response && action?.response?.data && action?.response?.data?.message){
		return action?.response?.data?.message;
	}else if(action?.response && action?.response?.data && action?.response?.data?.message){
		return action?.response?.data?.message;
	}else if(action?.response && action?.response?.data){
		return action?.response?.data;
	}else if(action?.data?.message){
		return action?.data?.message;
	}else if(action?.data){
		return action?.data;
	}else{
		return "something went wrong"
	}
}

export default appParameter;