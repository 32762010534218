import React from "react";

import { Switch, Route } from "react-router-dom";

import OrderViews from "views/order-views";

import Loading from "components/shared-components/Loading";

import { useThemeSwitcher } from "react-css-theme-switcher";

export const OrderLayout = () => {
  const { status } = useThemeSwitcher();

  if (status === "loading") {
    return <Loading cover="page" />;
  }

  return (
    <div className="auth-container">
      <Switch>
        <Route path="" component={OrderViews} />
      </Switch>
    </div>
  );
};

export default OrderLayout;
