import { all, takeEvery, put, fork } from 'redux-saga/effects';

import axios from './initialize'
import { Modal } from 'antd';
import {CheckCircleFilled} from  '@ant-design/icons';

import {  SAVE_DATABASE, DELETE_DATABASE, UPDATE_DATABASE,GET_DATABASE_LIST, GET_TABLE_LIST, GET_COLUMN_LIST, COPY_DATABASE_DATA, COPY_BLOB_STORAGE_FILE} from "redux/constants/dataMigration";

import {  showDataMigrationMessage, databaseList,tableList,columnList } from "redux/actions/dataMigration";
import { showUnAutherizeMessage} from "redux/actions/Auth";

import { API_ENDPOINT_DATA_MIGRATION, DATA_MIGRATION, VERSION, MANAGE_PREPIX_PATH, API_ENDPOINT_BLOG_STORAGE_URL, API_BASE_DEVELOPER_APP } from 'configs/AppConfig';
import {
	AUTH_TOKEN,
} from '../constants/Auth';
import { t } from "i18next";
import { BLOB_STORAGE_URL } from 'constants/ApiConstant';


export function* getDatabaseList(){
    yield takeEvery (GET_DATABASE_LIST, function*  ({requestData}){
	    const {query, sorter,pagination} = requestData;
const config = {
		headers: { "Content-Type": 'application/json',"Access-Control-Allow-Origin": "*",
		"Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",Authorization : `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
		accountUserId: localStorage.getItem("accountUserId") }
	  };
		try {
		const res = yield  axios.get(`${API_ENDPOINT_DATA_MIGRATION}${DATA_MIGRATION}${VERSION}/databaseList?query=${query}&sortfield=${sorter?.field}&sortorder=${sorter?.order}&page=${pagination?.current-1}&size=${pagination?.pageSize}`,
		config);
			if (res.status===200) {
				yield put(databaseList(res.data));
			} 
		} catch (err) {
			console.log(err);
			if(err?.response?.data?.status==401){
				yield put(showUnAutherizeMessage(err.response));
			}
			yield put(showDataMigrationMessage(err.response));
		}
    }

    )
}

export function* getTableList(){
    yield takeEvery (GET_TABLE_LIST, function*  ({requestData}){
	    const {database} = requestData;
const config = {
		headers: { "Content-Type": 'application/json',"Access-Control-Allow-Origin": "*",
		"Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",Authorization : `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
		accountUserId: localStorage.getItem("accountUserId") }
	  };
		try {
		const res = yield  axios.post(`${API_ENDPOINT_DATA_MIGRATION}${DATA_MIGRATION}${VERSION}/tableList`,database,
		config);
			if (res.status===200) {
				yield put(tableList(res.data));
			} 
		} catch (err) {
			console.log(err);
			if(err?.response?.data?.status==401){
				yield put(showUnAutherizeMessage(err.response));
			}
			yield put(showDataMigrationMessage(err.response));
		}
    }

    )
}

export function* getColumnList(){
    yield takeEvery (GET_COLUMN_LIST, function*  ({requestData}){
	    const {database,tableName} = requestData;
const config = {
		headers: { "Content-Type": 'application/json',"Access-Control-Allow-Origin": "*",
		"Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",Authorization : `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
		accountUserId: localStorage.getItem("accountUserId") }
	  };
		try {
		const res = yield  axios.post(`${API_ENDPOINT_DATA_MIGRATION}${DATA_MIGRATION}${VERSION}/columnList?tableName=${tableName}`,database,
		config);
			if (res.status===200) {
				yield put(columnList(res.data));
			} 
		} catch (err) {
			console.log(err);
			if(err?.response?.data?.status==401){
				yield put(showUnAutherizeMessage(err.response));
			}
			yield put(showDataMigrationMessage(err.response));
		}
    }

    )
}

export function* copyDatabaseData(){
    yield takeEvery (COPY_DATABASE_DATA, function*  ({payload}){
	    const {formData} = payload;
const config = {
		headers: { "Content-Type": 'application/json',"Access-Control-Allow-Origin": "*",
		"Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",Authorization : `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
		accountUserId: localStorage.getItem("accountUserId") }
	  };
		try {
		const res = yield  axios.post(`${API_ENDPOINT_DATA_MIGRATION}${DATA_MIGRATION}${VERSION}/migrate-data`,formData,
		config);
			if (res.status===200) {
				Modal.success({
					title: t("impaqx.adminApp.dataMigraitonSuccessMessage"),
					icon: <CheckCircleFilled />,
					okText: t("impaqx.adminApp.close"),
					onOk() {
					},
				  });
				yield put(showDataMigrationMessage(res.data));
			} 
		} catch (err) {
			console.log(err);
			if(err?.response?.data?.status==401){
				yield put(showUnAutherizeMessage(err.response));
			}
			yield put(showDataMigrationMessage(err.response));
		}
    }

    )
}


export function* copyFileData(){
    yield takeEvery (COPY_BLOB_STORAGE_FILE, function*  ({payload}){
	    const {container,containerType,formData} = payload;
const config = {
		headers: { "Content-Type": 'application/json',"Access-Control-Allow-Origin": "*",
		"Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",Authorization : `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
		accountId: localStorage.getItem("accountId"),
		appInstanceId: localStorage.getItem("appInstanceId"),
		accountUserId: localStorage.getItem("accountUserId"),
		container : container
	    }
	  };
		try {
		const res = yield  axios.post(`${API_BASE_DEVELOPER_APP}/developerApp/upload/copy-container?type=${containerType}`,
	JSON.stringify(formData),
		config);
			if (res.status===200) {
				Modal.success({
					//title: t("impaqx.adminApp.fileMigraitonSuccessMessage"),
					title :res?.data?res?.data:res?.response?.data?.message,
					icon: <CheckCircleFilled />,
					okText: t("impaqx.adminApp.close"),
					onOk() {
					},
				  });
				yield put(showDataMigrationMessage(res.data));
			} 
		} catch (err) {
			console.log(err);
			Modal.error({
				title: err?.response?.data?.message?err?.response?.data?.message:err?.response?.response?.data?.message,
				icon: <CheckCircleFilled />,
				okText: t("impaqx.adminApp.close"),
				onOk() {
				},
			  });
			if(err.response.data.status==401){
				yield put(showUnAutherizeMessage(err.response));
			}
			yield put(showDataMigrationMessage(err.response));
		}
    }

    )
}

export  function* saveDatabse(){
	yield takeEvery(SAVE_DATABASE, function* ({payload}){
		const {values,query,sorter,pagination}=payload
		try {
			const res = yield  axios.post(`${API_ENDPOINT_DATA_MIGRATION}${DATA_MIGRATION}${VERSION}/database`,values,{headers :{
				'Authorization': `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
				'Access-Control-Allow-Origin' : '*',
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				accountUserId: localStorage.getItem("accountUserId")
			  }});
				if (res.status===200) {
					const response = yield  axios.get(`${API_ENDPOINT_DATA_MIGRATION}${DATA_MIGRATION}${VERSION}/databaseList?query=${query}&sortfield=${sorter?.field}&sortorder=${sorter?.order}&page=${pagination?.current-1}&size=${pagination?.pageSize}`,
					{headers: {
					   'Authorization': `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
					   'Access-Control-Allow-Origin' : '*',
					   'Accept': 'application/json',
					   'Content-Type': 'application/json',
					   accountUserId: localStorage.getItem("accountUserId")
					 }});
					   if (response.status===200) {
							Modal.success({
								title: `${values.databaseName}  ${t("impaqx.adminApp.databaseSaveMessage")}`,
								icon: <CheckCircleFilled />,
								okText: 'Close',
								onOk() {
								},
							});
						}
								yield put(databaseList(response.data))
				}else{
					yield put(showDataMigrationMessage(res));
				}
			} catch (err) {
				console.log(err);
				if(err.response.data.status==401){
					yield put(showUnAutherizeMessage(err.response));
				}
				yield put(showDataMigrationMessage(err.response));
			}
	}
	)
}


export function* updateDatabase(){
    yield takeEvery (UPDATE_DATABASE,function* ({payload}){
		const {values,query,sorter,pagination} = payload;
        try {
            const res = yield axios.put(`${API_ENDPOINT_DATA_MIGRATION}${DATA_MIGRATION}${VERSION}/database/${values.id}`, values,{
				headers: {
				  'Authorization': `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
				  'Access-Control-Allow-Origin' : '*',
				  'Accept': 'application/json',
				  'Content-Type': 'application/json',
				  accountUserId: localStorage.getItem("accountUserId")
				}
			  });
             if (res.status===200) {
				const response = yield  axios.get(`${API_ENDPOINT_DATA_MIGRATION}${DATA_MIGRATION}${VERSION}/databaseList?query=${query}&sortfield=${sorter?.field}&sortorder=${sorter?.order}&page=${pagination?.current-1}&size=${pagination?.pageSize}`,
				{headers: {
				   'Authorization': `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
				   'Access-Control-Allow-Origin' : '*',
				   'Accept': 'application/json',
				   'Content-Type': 'application/json',
				   accountUserId: localStorage.getItem("accountUserId")
				 }});
				   if (response.status===200) {
					Modal.success({
						title: `${values.databaseName}  ${t("impaqx.adminApp.databaseUpdatedMessage")}`,
						icon: <CheckCircleFilled />,
						okText: 'Close',
						onOk() {
						},
					});
				}
                 yield put(databaseList(response.data));
             } 
        } catch (error) {
            console.log(error);
			if(error.response.data.status==401){
				yield put(showUnAutherizeMessage(error.response));
			}
			yield put(showDataMigrationMessage(error.response));
        }
    }
    )
}

export function* deleteDatabase(){
	yield takeEvery(DELETE_DATABASE, function*({payload}){
         try {
			const {query, sorter,pagination,database} = payload;
			 const res = yield axios.delete(`${API_ENDPOINT_DATA_MIGRATION}${DATA_MIGRATION}${VERSION}/database/${database.id}`,{
				headers: {
				  'Authorization': `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
				  'Access-Control-Allow-Origin' : '*',
				  'Accept': 'application/json',
				  'Content-Type': 'application/json',
				  accountUserId: localStorage.getItem("accountUserId")
				}
			  });
			 if(res.status===200){
				 const response = yield  axios.get(`${API_ENDPOINT_DATA_MIGRATION}${DATA_MIGRATION}${VERSION}/databaseList?query=${query}&sortfield=${sorter?.field}&sortorder=${sorter?.order}&page=${pagination?.current-1}&size=${pagination?.pageSize}`,
				 {headers: {
					'Authorization': `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
					'Access-Control-Allow-Origin' : '*',
					'Accept': 'application/json',
					'Content-Type': 'application/json',
					accountUserId: localStorage.getItem("accountUserId")
				  }});
					if (response.status===200) {
						Modal.success({
							title: `${database.databaseName}  ${t("impaqx.adminApp.databaseDeleteMessage")}`,
							icon: <CheckCircleFilled />,
							okText: 'Close',
							onOk() {
							},
						});
						yield put(databaseList(response.data));
					} 
			 }
		 } catch (error) {
			 console.log(error);
			 if(error.response.data.status==401){
				yield put(showUnAutherizeMessage(error.response));
			}
			 yield put(showDataMigrationMessage(error.response));
		 }
	})
}


export default function* rootSaga(){
    yield all([
		  fork(getDatabaseList),
		  fork(saveDatabse),
		  fork(deleteDatabase),
		  fork(updateDatabase),
		  fork(getTableList),
		  fork(getColumnList),
		  fork(copyDatabaseData),
		  fork(copyFileData)
    ])
    
}