import {
    GET_APP_INSTANCE_SETTINGS_DETAILS,APP_SETTINGS_JSON,APP_INSTANCE_MESSAGE,ADD_APP_INSTANCE_SETTINGS_JSON,GET_APP_INSTANCE_PERMISSIONS,GET_APP_INSTANCE_ROLE_PERMISSIONS,
    ADD_APP_INSTANCE_ROLE,UPDATE_APP_INSTANCE_ROLE,APP_INSTANCE_ROLE_LIST,SET_ADD_EDIT,DELETE_APP_INSTANCE_ROLE,GET_APP_INSTANCE_ROLES,APP_INSTANCE_ROLE_PERMISSION_LIST,
    ASSIGN_APP_ROLE_TO_USER,SET_MODAL_POPUP,SET_MODAL_POPUP_DATA,SET_MODAL_ROLE_PERMISSION_POPUP,SHOW_LOADING,SAVE_APP_INSTANCE_API_KEY,APP_INSTANCE_API_KEY,
    GET_APP_INSTANCE_API_KEY,GET_ASSIGN_INSTANCE_USER_ROLES,ASSIGNED_INSTANCE_USER_ROLES,GET_APP_INSTANCE_DETAILS,APP_INSTANCE_DETAILS,APP_INSTANCE_UI_PROPERTIES_JSON,APP_INSTANCE_UI_PROPERTIES,
    ADD_APP_INSTANCE_UI_PROPERTIES,EDIT_APP_INSTANCE_UI_PROPERTIES,DELETE_APP_INSTANCE_UI_PROPERTIES,GET_APP_ROLES_INSTANCE_ROLES,APP_INSTANCE_CONNECTOR_DETAILS,
    GET_APP_INSTANCE_CONNECTOR_DETAILS,LINK_APP_TO_CONNECTOR,GET_LINKED_APP_INSTANCE_CONNECTOR,LINKED_APP_INSTANCE_CONNECTOR,CONNECTOR_SETTINGS_JSON,
     GET_CONNECTOR_INSTANCE_SETTINGS_DETAILS,ADD_CONNECTOR_INSTANCE_SETTINGS_JSON,APP_ROLES_INSTANCE_ROLE_LIST,GET_APP_INSTANCE_ROLE_PERMISSIONS_LIST,GET_APP_INSTANCE_API_KEYS,
     DELETE_APP_INSTANCE_API_KEY,APP_INSTANCE_API_KEYS,UPDATE_APP_INSTANCE_API_KEY,HIDE_LOADING
  } from "redux/constants/appInstance";
  
  export const getAppSettingsDetails = (data) => {
    return {
      type: GET_APP_INSTANCE_SETTINGS_DETAILS,
      payload: data,
    };
  };

  export const getConnectorSettingsDetails = (data) => {
    return {
      type: GET_CONNECTOR_INSTANCE_SETTINGS_DETAILS,
      payload: data,
    };
  };

  export const addAppSettingsJson = (value) => {
    return {
      type: ADD_APP_INSTANCE_SETTINGS_JSON,
      payload: value,
    };
  }

  export const addConnectorSettingsJson = (value) => {
    return {
      type: ADD_CONNECTOR_INSTANCE_SETTINGS_JSON,
      payload: value,
    };
  }
  export const getAppInstance=(appInstanceId)=> {
    return {
       type: GET_APP_INSTANCE_DETAILS,
       payload : appInstanceId
    }
  }

  export const appInstaneData=(data)=>{
    return {
      type : APP_INSTANCE_DETAILS,
      appInstance : data
    }
  }

  export const getAppInstanceConnector=(data)=>{
    return {
      type : GET_APP_INSTANCE_CONNECTOR_DETAILS,
      payload : data
    }
  }
  export const showLoading = () => {
    return {
      type: SHOW_LOADING,
    };
  };

  export const hideLoading = () => {
    return {
      type: HIDE_LOADING,
    };
  };

  export const appSettingsJson = (data) => {
    return {
      type: APP_SETTINGS_JSON,
      appSettingsJson: data,
    };
  };

  export const connectorSettingsJson =(data)=>{
    return {
       type : CONNECTOR_SETTINGS_JSON,
       connectorSettingsJson : data
    }
  }

  export const showAppInstanceMessage=(response)=>{
    return{
        type : APP_INSTANCE_MESSAGE,
        response
    }
  }

  export const appInstanceRoles = (data) => {
    return {
      type: APP_INSTANCE_ROLE_LIST,
      appInstanceRoles: data,
    };
  };
  
  export const appRolesInstanceRoles = (data) => {
    return {
      type: APP_ROLES_INSTANCE_ROLE_LIST,
      appRolesInstanceRoles: data,
    };
  };
  
  export const getAppPermissions = (appId) => {
    return {
      type: GET_APP_INSTANCE_PERMISSIONS,
      payload: appId,
    };
  };

  export const getAppInstanceRolePermissions = (appInstanceRoleId) => {
    return {
      type: GET_APP_INSTANCE_ROLE_PERMISSIONS,
      payload: appInstanceRoleId,
    };
  };

  export const getAppInstanceRolePermissionsList = (appInstanceRoleId) => {
    return {
      type: GET_APP_INSTANCE_ROLE_PERMISSIONS_LIST,
      payload: appInstanceRoleId,
    };
  };

  export const addAppInstanceRole = (data) => {
    return {
      type: ADD_APP_INSTANCE_ROLE,
      payload: data,
    };
  };

  export const updateAppInstanceRole = (data) => {
    return {
      type: UPDATE_APP_INSTANCE_ROLE,
      payload: data,
    };
  };

  export const setAddEdit = (value) => {
    return {
      type: SET_ADD_EDIT,
      addEditValue: value,
    };
  };

 export const deleteAppRole = (appInstanceRole) => {
    return {
      type: DELETE_APP_INSTANCE_ROLE,
      payload: appInstanceRole,
    };
  };

  export const getAppInstanceRoles = (appInstanceId) => {
    return {
      type: GET_APP_INSTANCE_ROLES,
      payload: appInstanceId,
    };
  };
  
  export const getAppRolesInstanceRoles = (appInstanceId) => {
    return {
      type: GET_APP_ROLES_INSTANCE_ROLES,
      payload: appInstanceId,
    };
  };

  export const appInstanceRolePermissions = (data) => {
    return {
      type: APP_INSTANCE_ROLE_PERMISSION_LIST,
      appInstanceRolePermissions: data,
    };
  };

  export const assignAppInstanceRoleToUser = (data) => {
    return {
      type: ASSIGN_APP_ROLE_TO_USER,
      payload: data,
    };
  };

  export const setModalRolePopUp = (data) => {
    return {
      type: SET_MODAL_POPUP,
      payload: data,
    };
  };

  
  export const setModalRolePermissionPopup = (data) => {
    return {
      type: SET_MODAL_ROLE_PERMISSION_POPUP,
      data: data,
    };
  };

  export const setModalRolePopUpData = (data) => {
    return {
      type: SET_MODAL_POPUP_DATA,
      modalRoleVisible: data,
    };
  };

  export const viewRolePermissions = (appInstanceRoleId) => {
    return {
      type: GET_APP_INSTANCE_ROLE_PERMISSIONS,
      payload: appInstanceRoleId,
    };
  };

  export const saveAppInstanceApiKey=(apiKey)=>{
    return {
      type : SAVE_APP_INSTANCE_API_KEY,
      payload : apiKey
    }
  }

  export const updateAppInstanceApiKey=(apiKey)=>{
    return {
      type : UPDATE_APP_INSTANCE_API_KEY,
      payload : apiKey
    }
  }

  export const getAppInstanceApiKeys=(apiKey)=>{
    return {
      type : GET_APP_INSTANCE_API_KEYS,
      payload : apiKey
    }
  }

  export const deleteAppInstanceApiKey=(apiKey)=>{
    return {
      type : DELETE_APP_INSTANCE_API_KEY,
      payload : apiKey
    }
  }

  export const appInstanceApiKey=(appInstanceApiKey)=>{
    return{
      type : APP_INSTANCE_API_KEY,
      appInstanceApiKey:appInstanceApiKey
    }
  }

  export const appInstanceApiKeys=(appInstanceApiKeys)=>{
    return{
      type : APP_INSTANCE_API_KEYS,
      appInstanceApiKeys:appInstanceApiKeys
    }
  }

  export const getAppInstanceApiKey=(appInstanceId)=>{
    return{
      type : GET_APP_INSTANCE_API_KEY,
      payload : appInstanceId
    }
  }

  export const getAssignedRoles=(accountUser)=>{
    return{
      type : GET_ASSIGN_INSTANCE_USER_ROLES,
      payload : accountUser
    }
  }

  export const appInstanceUserRoles=(instanceUserRoles)=>{
    return{
      type : ASSIGNED_INSTANCE_USER_ROLES,
      instanceUserRoles : instanceUserRoles
    }
  }

  export const addAppInstanceUiProperties = (value) => {
    return {
      type: ADD_APP_INSTANCE_UI_PROPERTIES,
      payload: value,
    };
  };
  export const editAppInstanceUiProperties = (value) => {
    return {
      type: EDIT_APP_INSTANCE_UI_PROPERTIES,
      payload: value,
    };
  };
  export const deleteAppInstanceUiProperties = (value) => {
    return {
      type: DELETE_APP_INSTANCE_UI_PROPERTIES,
      payload: value,
    };
  };
  export const appInstanceUiPropertiesJson = (data) => {
    return {
      type: APP_INSTANCE_UI_PROPERTIES_JSON,
      uiPropertiesJsonData: data,
    };
  };
  export const getAppInstanceUiProperties = (data) => {
    return {
      type: APP_INSTANCE_UI_PROPERTIES,
      payload: data,
    };
  };

export const linkAppToConnectors = (data)=>{
    return {
      type : LINK_APP_TO_CONNECTOR,
      payload :data
    }
}

export const getLinkedAppInstanceConnector = (data)=>{
  return {
     type : GET_LINKED_APP_INSTANCE_CONNECTOR,
     payload : data
  }
}

export const linkedAppInstaneData = (data)=>{
  return {
    type : LINKED_APP_INSTANCE_CONNECTOR,
    linkedAppInstane : data
  }
}
