import React, {useEffect,useState} from "react";
import { useHistory,Link } from "react-router-dom";
import { Menu, Grid, Input,Button, Modal } from "antd";
import { DatabaseOutlined,ShopOutlined,AppstoreOutlined,FileProtectOutlined,UserOutlined,SettingOutlined} from  "@ant-design/icons";
import IntlMessage from "../util-components/IntlMessage";
import Icon from "../util-components/Icon";
import navigationConfig from "configs/NavigationConfig";
import { connect } from "react-redux";
import { SIDE_NAV_LIGHT, NAV_TYPE_SIDE } from "constants/ThemeConstant";
import utils from 'utils'
import { onMobileNavToggle,getApps,getSubscribedApps } from "redux/actions/Theme";
import {appLoginAuthentication} from "redux/actions/app";
import { getAccountUserList } from "redux/actions/user"
//import { getApps } from "redux/actions/app";
import app from "redux/reducers/app";
import apps from "views/app-views/pages/apps/apps";
import { debounce } from "lodash";
import { EyeOutlined,ExclamationCircleOutlined,SearchOutlined } from '@ant-design/icons';
import { API_BASE_DEVELOPER_APP, API_BASE_URL, APP_PREFIX_PATH, AUTH_PREFIX_PATH,versionNumber } from 'configs/AppConfig'
import { useTranslation } from "react-i18next";
import { AUTH_TOKEN } from "redux/constants/Auth";
import axios from "axios";


const { SubMenu } = Menu;
const { useBreakpoint } = Grid;

const setLocale = (isLocaleOn, localeKey) =>
  isLocaleOn ? <IntlMessage id={localeKey} /> : localeKey.toString();

const setDefaultOpen = (key) => {
  let keyList = [];
  let keyString = "";
  if (key) {
    const arr = key.split("-");
    for (let index = 0; index < arr.length; index++) {
      const elm = arr[index];
      index === 0 ? (keyString = elm) : (keyString = `${keyString}-${elm}`);
      keyList.push(keyString);
    }
  }
  return keyList;
};

const SideNavContent = (props) => {
	const { sideNavTheme, routeInfo, hideGroupTitle, localization, onMobileNavToggle,getApps,apps,subscribedApps,getSubscribedApps,appLoginAuthentication } = props;
	const isMobile = !utils.getBreakPoint(useBreakpoint()).includes('lg');
  const [pagination, setPagination] =useState({});
  let history = useHistory();
  const { t } = useTranslation();
  const permissions  = localStorage.getItem("permissions")!=""?JSON.parse(localStorage.getItem("permissions")):"";

	const closeMobileNav = () => {
		if (isMobile) {
			onMobileNavToggle(false)
		}
	}
  // if(apps.totalElements>0){
  //   routeInfo["submenu"]=[];
  //    var appNav ={};
  //    for(var i=0;i<apps.totalElements;i++){
  //     appNav["key"] = apps.content[0].name;
  //     routeInfo.submenu.push(appNav)

  //    }
      
  //}
  useEffect(() => {
     axios.get(`${API_BASE_URL}/manage/auth/version`, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
        'Access-Control-Allow-Origin' : '*',
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
      }).then(response => {
      if (response?.status===200) {
         localStorage.setItem("versionNumber", response?.data);
      }
    });
 },[]);
 const searchOnClick = debounce((e)  => {
	const query = e.target.value; 
	getApps({query,"type":"app"});
},500);
const searchOnClickMarketPlace = debounce((e) =>{
  const query = e.target.value;
	getSubscribedApps({query,"type":"app"});
},500);
const  searchOnClickMyApps = debounce((e)=>{
  const query = e.target.value;
	getSubscribedApps({query,"type":"app"});
},500);

const onTitleClickDeveloperApp =(e)=>{
  getApps({pagination});
  history.push(`${APP_PREFIX_PATH}/${localStorage.getItem("accountId")}/developer-app`)
}

const onTitleClickMarketPlace =(e)=>{
  getAccountUserList();
  history.push(`${APP_PREFIX_PATH}/${localStorage.getItem("accountId")}/market-place`)
}

const onTitleClickMyApps=e=>{
  getSubscribedApps({"type":"app"});
  history.push(`${APP_PREFIX_PATH}/${localStorage.getItem("accountId")}/my-apps`)
}

const onTitleClickAccountUser=(e)=>{
  getAccountUserList();
  history.push(`${APP_PREFIX_PATH}/${localStorage.getItem("accountId")}/users`)
}

const onTitleClickAppParameters =(e)=>{
  history.push(`${APP_PREFIX_PATH}/pages/app-parameters`)
}

const onTitleClickDataMigration =(e)=>{
  history.push(`${APP_PREFIX_PATH}/data-migration`)
}

const onTitleClickDataExport=(e)=>{
  history.push(`${APP_PREFIX_PATH}/data-export`)
}
const onTitleClickswagger=(e)=>{
  history.push(`${APP_PREFIX_PATH}/api-documents`)
}
const OnClickApp=async (app) =>{
  console.log(app);
  //console.log(subscriptionTerm);
  //app["subscriptionTerm"] = subscriptionTerm;
  const rolesResponse = await axios.get(
    `${API_BASE_DEVELOPER_APP}/developerApp/accountUser-appInstance-role?accountUserId=${localStorage.getItem("accountUserId")}&appInstanceId=${app.id}`,
    {headers: {
    'Authorization': `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
    'Content-Type': 'application/json',
    'accountId':localStorage.getItem("accountId"),
    'id':localStorage.getItem("appInstanceId"),
    'accountUserId':localStorage.getItem("accountUserId")
    }}
    );
    if(rolesResponse.status==200){
    const roles = await rolesResponse.data;
    if(roles.length>0){
      appLoginAuthentication(app);
    }else{
        Modal.error({
          title: t("impaqx.adminApp.noRoledAssigned"),
          icon: <ExclamationCircleOutlined />,
          okText: 'Close',
          // cancelText: 'Cancel',
          onOk() {
          },
        });
  }
}
}

  return (
    <div>
{localStorage.getItem("businessName") &&
                localStorage.getItem("businessName") != "" ?<Menu
      theme={sideNavTheme === SIDE_NAV_LIGHT ? "light" : "dark"}
      mode="inline"
      style={{ height: "100%", borderRight: 0 }}
      defaultSelectedKeys={[routeInfo?.key]}
      defaultOpenKeys={setDefaultOpen(routeInfo?.key)}
      className={hideGroupTitle ? "hide-group-title" : ""}
    >
      {navigationConfig.map((menu) =>
         menu.submenu.length > 0 ? (
          <Menu.ItemGroup
            key={menu.key}
            title={setLocale(localization, menu.title)}
          >
            {menu.submenu.map((subMenuFirst) =>
              subMenuFirst.submenu.length > 0 ? (
                <SubMenu
                  icon={
                    subMenuFirst.icon ? (
                      <Icon type={subMenuFirst?.icon} />
                    ) : null
                  }
                  key={subMenuFirst.key}
                  title={setLocale(localization, subMenuFirst.title)}
                >
                               {/* <div>{subMenuFirst}dfd</div>  */}
                  {subMenuFirst.submenu.map((subMenuSecond) => (
                    <Menu.Item key={subMenuSecond.key}>
                      {subMenuSecond.icon ? (
                        <Icon type={subMenuSecond?.icon} />
                      ) : null}
                      <span>
                        {setLocale(localization, subMenuSecond.title)}
                      </span>
                      <Link onClick={() => closeMobileNav()} to={subMenuSecond.path} />
                    </Menu.Item>
                  ))}
                </SubMenu>
              ) : (
                <Menu.Item key={subMenuFirst.key}>
                  {subMenuFirst.icon ? <Icon type={subMenuFirst.icon} /> : null}
                  <span>{setLocale(localization, subMenuFirst.title)}</span>
                  <Link onClick={() => closeMobileNav()} to={subMenuFirst.path} />
                </Menu.Item>
              )
            )}
          </Menu.ItemGroup>
        ) : (
          <Menu.Item key={menu.key}>
            {menu.icon ? <Icon type={menu?.icon} /> : null}
            <span>{setLocale(localization, menu?.title)}</span>
            {menu.path ? <Link onClick={() => closeMobileNav()} to={menu.path} /> : null}
          </Menu.Item>
        )
      )}
  
     
        {/* <Link to={`${APP_PREFIX_PATH}/developer-app`} /> */}
        {permissions?.allpermissions || permissions?.view_myapps_apps || permissions?.view_myapps_services || permissions?.view_myapps_connectors?
 <>
        {subscribedApps?.totalElements>0?
  <SubMenu
            key={'myApps'}
            title={t("impaqx.adminApp.myApps")}
            icon={<AppstoreOutlined/>}
            onTitleClick={onTitleClickMyApps}
        >
          {subscribedApps?.totalElements>10?<Menu.Item>
          <Input  placeholder="Search" onChange={searchOnClickMyApps} />
          </Menu.Item>:null}
            {subscribedApps?.totalElements>0?subscribedApps?.content.map((subMenuFirst) =>
            <>{subMenuFirst.name!="DeveloperApp_"+localStorage.getItem("accountId")?<Menu.Item
              key={`myApps_${subMenuFirst.name}`}
              title={subMenuFirst.name}
            >  <a  onClick={()=>OnClickApp(subMenuFirst)}><span>{subMenuFirst.name}</span></a>
            </Menu.Item>:null}</>):null}
        </SubMenu> : <Menu.Item
            key={'myApps'}
            title={'t("impaqx.adminApp.myApps")'}
            icon={<AppstoreOutlined/>}
        >  <a onClick={() => onTitleClickMyApps()} >{t("impaqx.adminApp.myApps")}</a>
        </Menu.Item>}</>:null}
        {permissions?.allpermissions || permissions?.view_marketplace_apps || permissions?.view_marketplace_apps || permissions?.view_marketplace_apps?
        <Menu.Item
            key={'marketPlace'}
            title={t("impaqx.adminApp.marketPlace")}
            icon={<ShopOutlined/>}
        >  <a onClick={() => onTitleClickMarketPlace()} >{t("impaqx.adminApp.marketPlace")}</a>
        </Menu.Item>:null}
      {permissions?.allpermissions || permissions?.view_apps || permissions?.view_services || permissions?.view_connectors?
      <Menu.Item
            key={'developerPortal'}
            title={t("impaqx.adminApp.developerPortal")}
            icon={<FileProtectOutlined/>}
        > <a onClick={() => onTitleClickDeveloperApp()} >{t("impaqx.adminApp.developerPortal")}</a>
        </Menu.Item>:null}
        {permissions?.allpermissions || permissions?.view_user?<Menu.Item
            key={'users'}
            title={t("impaqx.adminApp.users")}
            icon={<UserOutlined/>}
        > <a onClick={() => onTitleClickAccountUser()} >{t("impaqx.adminApp.users")}</a>
      
        </Menu.Item>:null}
        {/* {permissions?.allpermissions || permissions?.view_app_parameters? <Menu.Item
            key={'appParameters'}
            title={t("impaqx.adminApp.appParameters")}
            icon={<SettingOutlined/>}
        > <a onClick={() => onTitleClickAppParameters()} >{t("impaqx.adminApp.appParameters")}</a>
      
        </Menu.Item>:null} */}
        {permissions?.allpermissions || permissions?.view_data_migration?<Menu.Item
            key={'dataMigration'}
            title={t("impaqx.appAdmin.dataMigration")}
             icon={<DatabaseOutlined/>}
        > <a  onClick={() => onTitleClickDataMigration()} >{t("impaqx.appAdmin.dataMigration")}</a>
      
        </Menu.Item>:null}
        {permissions?.allpermissions || permissions?.view_data_export?<Menu.Item
            key={'dataExport'}
            title={t("impaqx.appAdmin.dataExport")}
             icon={<DatabaseOutlined/>}
        > <a  onClick={() => onTitleClickDataExport()} >{t("impaqx.appAdmin.dataExport")}</a>
          </Menu.Item>:null}
          {/* {permissions?.allpermissions || permissions?.view_swagger ? (
                    <Menu.Item key={'swagger'} title={t("impaqx.appAdmin.apiDocuments")} icon={<DatabaseOutlined/>}>
                        <a onClick={() => onTitleClickswagger()}>{t("impaqx.appAdmin.apiDocuments")}</a>
                    </Menu.Item>
                ) : null} */}
</Menu> : null}
</div>

  );
};

const TopNavContent = (props) => {
  const { topNavColor, localization,getApps,apps,subscribedApps,getSubscribedApps,appLoginAuthentication,permissions } = props;
  const [pagination, setPagination] =useState({});
  let history = useHistory();
  const { t } = useTranslation();

  const searchOnClick = debounce((e)  => {
    const query = e.target.value;
    getApps({query,"type":"app"});
  },500);
  const searchOnClickMarketPlace = debounce((e) =>{
    const query = e.target.value;
    getSubscribedApps({query,"type":"app"});
  },500);
  const  searchOnClickMyApps = debounce((e)=>{
    const query = e.target.value;
    getSubscribedApps({query,"type":"app"});
  },500);
  
  const onTitleClickDeveloperApp =(e)=>{
    getApps({pagination});
    history.push(`${APP_PREFIX_PATH}/${localStorage.getItem("accountId")}/developer-app`)
  }
  
  const onTitleClickAppParameters =(e)=>{
    history.push(`${APP_PREFIX_PATH}/pages/app-parameters`)
  }

  const onTitleClickDataMigration =(e)=>{
    history.push(`${APP_PREFIX_PATH}/data-migration`)
  }
  
  const onTitleClickMarketPlace =(e)=>{
    getSubscribedApps({"type":"app"});
    history.push(`${APP_PREFIX_PATH}/${localStorage.getItem("accountId")}/market-place`)
  }
  
  const onTitleClickMyApps=e=>{
    getSubscribedApps({"type":"app"});
    history.push(`${APP_PREFIX_PATH}/${localStorage.getItem("accountId")}/my-apps`)
  }
  
  const onTitleClickAccountUser=(e)=>{
    getAccountUserList();
    history.push(`${APP_PREFIX_PATH}/${localStorage.getItem("accountId")}/users`)
  }

  const onTitleClickDataExport=(e)=>{
    history.push(`${APP_PREFIX_PATH}/data-export`)
  }
  const onTitleClickswagger=(e)=>{
    history.push(`${APP_PREFIX_PATH}/api-documents`)
  }
  const OnClickApp= (app) =>{
    console.log(app);
    //console.log(subscriptionTerm);
    //app["subscriptionTerm"] = subscriptionTerm;
    appLoginAuthentication(app);
  }
  return (<>
    {localStorage.getItem("businessName") &&
                localStorage.getItem("businessName") != "" ? <Menu mode="horizontal" style={{ backgroundColor: topNavColor }}>
      {navigationConfig.map((menu) =>
        menu.submenu.length > 0 ? (
          <SubMenu
            key={menu.key}
            popupClassName="top-nav-menu"
            title={
              <span className="d-flex align-items-center">
                {menu.icon ? <Icon type={menu?.icon} /> : null}
                <span>{setLocale(localization, menu.title)}</span>
              </span>
            }
          >
            {menu.submenu.map((subMenuFirst) =>
              subMenuFirst.submenu.length > 0 ? (
                <SubMenu
                  key={subMenuFirst.key}
                  popupClassName="top-nav-menu"
                  icon={
                    subMenuFirst.icon ? (
                      <Icon type={subMenuFirst?.icon} />
                    ) : null
                  }
                  title={setLocale(localization, subMenuFirst.title)}
                >
                  {subMenuFirst.submenu.map((subMenuSecond) => (
                    <Menu.Item key={subMenuSecond.key}>
                      <span>
                        {setLocale(localization, subMenuSecond.title)}
                      </span>
                      <Link to={subMenuSecond.path} />
                    </Menu.Item>
                  ))}
                </SubMenu>
              ) : (
                <Menu.Item key={subMenuFirst.key}>
                  {subMenuFirst.icon ? (
                    <Icon type={subMenuFirst?.icon} />
                  ) : null}
                  <span>{setLocale(localization, subMenuFirst.title)}</span>
                  <Link to={subMenuFirst.path} />
                </Menu.Item>
              )
            )}
          </SubMenu>
        ) : (
          <Menu.Item key={menu.key}>
            {menu.icon ? <Icon type={menu?.icon} /> : null}
            <span>{setLocale(localization, menu?.title)}</span>
            {menu.path ? <Link to={menu.path} /> : null}
          </Menu.Item>
        )
      )}
       {/* <Link to={`${APP_PREFIX_PATH}/developer-app`} /> */}
       {permissions?.allpermissions || permissions?.view_myapps_apps || permissions?.view_myapps_services || permissions?.view_myapps_connectors?
<SubMenu
            key={'myApps'}
            title={t("impaqx.adminApp.myApps")}
            icon={<AppstoreOutlined/>}
            onTitleClick={onTitleClickMyApps}
        >
          {subscribedApps?.totalElements>10?<Menu.Item>
          <Input  placeholder="Search" onChange={searchOnClickMyApps} />
          </Menu.Item>:null}
            {subscribedApps?.totalElements>0?subscribedApps?.content.map((subMenuFirst) =>
            <>{subMenuFirst.name!="DeveloperApp_"+localStorage.getItem("accountId")?<Menu.Item
              // icon={
              //   subMenuFirst.icon ? (
              //     <Icon type={subMenuFirst?.icon} />
              //   ) : null
              // }
              key={`myApps_${subMenuFirst.name}`}
              title={subMenuFirst.name}
            >  <a  onClick={()=>OnClickApp(subMenuFirst)}><span>{subMenuFirst.name}</span></a>
            </Menu.Item>:null}</>):null}
        </SubMenu>:null}
        {permissions?.allpermissions || permissions?.view_marketplace_apps || permissions?.view_marketplace_apps || permissions?.view_marketplace_apps?
         <Menu.Item
            key={'marketPlace'}
            title={t("impaqx.adminApp.marketPlace")}
            icon={<ShopOutlined/>}
           // onTitleClick={onTitleClickMarketPlace}
        >  <a style={{color: 'rgba(255,255,255,.8)'}} onClick={() => onTitleClickMarketPlace()} >{t("impaqx.adminApp.marketPlace")}</a>
          {/* <Menu.Item>
          <Input  placeholder="Search" onChange={searchOnClickMarketPlace} />
          </Menu.Item>
            {subscribedApps?.totalElements>0?subscribedApps?.content.map((subMenuFirst) =>
            <Menu.Item
              // icon={
              //   subMenuFirst.icon ? (
              //     <Icon type={subMenuFirst?.icon} />
              //   ) : null
              // }
              key={`marketPlace_${subMenuFirst.name}`}
              title={subMenuFirst.name}
            > <span>{subMenuFirst.name}</span></Menu.Item>):null} */}
        </Menu.Item>:null}
        {permissions?.allpermissions || permissions?.view_apps || permissions?.view_services || permissions?.view_connectors?<Menu.Item
            key={'developerPortal'}
            title={t("impaqx.adminApp.developerPortal")}
            icon={<FileProtectOutlined/>}
         //   onTitleClick={onTitleClickDeveloperApp}
        > <a style={{color: 'rgba(255,255,255,.8)'}} onClick={() => onTitleClickDeveloperApp()} >{t("impaqx.adminApp.developerPortal")}</a>
         {/* {apps?.totalElements>10?  <Menu.Item>
          <Input  placeholder="Search" onChange={searchOnClick} />
          </Menu.Item>:null}
            {apps?.totalElements>0?apps.content.map((subMenuFirst) =>
            <Menu.Item
              // icon={
              //   subMenuFirst.icon ? (
              //     <Icon type={subMenuFirst?.icon} />
              //   ) : null
              // }
              key={`developerApp_${subMenuFirst.name}`}
              title={subMenuFirst.name}
            > <span>{subMenuFirst.name}</span></Menu.Item>):null} */}
        </Menu.Item>:null}
        {permissions?.allpermissions || permissions?.view_user? <Menu.Item
            key={'users'}
            title={t("impaqx.adminApp.users")}
            icon={<UserOutlined/>}
        > <a style={{color: 'rgba(255,255,255,.8)'}} onClick={() => onTitleClickAccountUser()} >{t("impaqx.adminApp.users")}</a>
        </Menu.Item>:null}
        {/* {permissions?.allpermissions || permissions?.view_app_parameters?<Menu.Item
            key={'appParameters'}
            title={t("impaqx.adminApp.appParameters")}
            icon={<SettingOutlined/>}
        > <a style={{color: 'rgba(255,255,255,.8)'}} onClick={() => onTitleClickAppParameters()} >{t("impaqx.adminApp.appParameters")}</a>
      
        </Menu.Item>:null} */}
        {permissions?.allpermissions || permissions?.view_data_migration?<Menu.Item
            key={'dataMigration'}
            title={t("impaqx.appAdmin.dataMigration")}
             icon={<DatabaseOutlined/>}
        > <a onClick={() => onTitleClickDataMigration()} >{t("impaqx.appAdmin.dataMigration")}</a>
      
        </Menu.Item>:null}
        {permissions?.allpermissions || permissions?.view_data_export?<Menu.Item
            key={'dataExport'}
            title={t("impaqx.appAdmin.dataExport")}
             icon={<DatabaseOutlined/>}
        > <a  onClick={() => onTitleClickDataExport()} >{t("impaqx.appAdmin.dataExport")}</a>
      
        </Menu.Item>:null}
        {/* {permissions?.allpermissions || permissions?.view_swagger ? (
                    <Menu.Item key={'swagger'} title={t("impaqx.appAdmin.apiDocuments")} icon={<DatabaseOutlined/>}>
                        <a onClick={() => onTitleClickswagger()}>{t("impaqx.appAdmin.apiDocuments")}</a>
                    </Menu.Item>
                ) : null} */}
    </Menu>:null}</>
  );
};

const MenuContent = (props) => {
  return props.type === NAV_TYPE_SIDE ? (
    <SideNavContent {...props} />
  ) : (
    <TopNavContent {...props} />
  );
};

const mapStateToProps = ({ theme ,app}) => {
  const { sideNavTheme, topNavColor } = theme;
  const { apps,subscribedApps,permissions } = app;
  return { sideNavTheme, topNavColor,apps,subscribedApps,permissions};
};

export default connect(mapStateToProps, { onMobileNavToggle,getApps,getSubscribedApps,appLoginAuthentication })(MenuContent);
