import {
  SIDE_NAV_LIGHT,
  NAV_TYPE_SIDE,
  DIR_LTR,
} from "constants/ThemeConstant";
import { env } from "./EnvironmentConfig";

export const APP_NAME = "Extremeb2b";
export const API_BASE_URL = env.API_ENDPOINT_URL;
export const API_BASE_DEVELOPER_APP = env.API_ENDPOINT_DEVELOPERAPP_URL;
export const API_ENDPOINT_PIKCLIX_URL= env.API_ENDPOINT_PIKCLIX_URL;
export const API_ENDPOINT_UI_URL= env.API_ENDPOINT_UI_URL;
export const API_ENDPOINT_BLOG_STORAGE_URL = env.API_ENDPOINT_BLOG_STORAGE_URL;
export const CONNECTOR_BASE_URL = env.CONNECTOR_BASE_URL;
export const API_ENDPOINT_DATA_MIGRATION = env.API_ENDPOINT_DATA_MIGRATION;
export const API_ENDPOINT_DATA_EXPORT = env.API_ENDPOINT_DATA_EXPORT;
export const MANAGE_PREPIX_PATH = "/manage";
export const APP_PREFIX_PATH = "/app";
export const AUTH_PREFIX_PATH = "/auth";
export const USERS_PREPIX_PATH = '/users'
export const DATA_MIGRATION = "/data-migrate";
export const DATA_EXPORT = "/data-export";
export const VERSION = "/v1";
export const API = "/api";
export const CUSTOM_CART = "/custom-cart";
export const IMAGE_PATH = "D:/Images/";
export const versionNumber = 'version_number';

export const THEME_CONFIG = {
  navCollapsed: false,
  sideNavTheme: SIDE_NAV_LIGHT,
  locale: "en",
  navType: NAV_TYPE_SIDE,
  topNavColor: "#3e82f7",
  headerNavColor: "",
  mobileNav: false,
  currentTheme: "light",
  direction: DIR_LTR,
};
