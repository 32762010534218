import { data } from "react-phone-code";
import {
  GET_APP_LIST,
  APP_LIST,
  SAVE_APP,
  APP_REGISTER_SUCCESS,
  APP_MESSAGE,
  DELETE_APP,
  PUBLISH_APP,
  EDIT_APP,
  APP_DATA,
  GET_PUBLISHED_APP_LIST,
  PUBLISHED_APP_LIST,
  SUBSCRIBE_APP,
  GET_SUBSCRIBED_APP_LIST,
  SUBSCRIBED_APP_LIST,
  GET_DEVELOPER_APP,
  DEVELOPER_APP,
  GET_MFA_SETUP_HOME,
  GET_MFA_SETUP_HOME_SUCCESS,
  APP_LOGIN_AUTHENTICATION,
  USING_2FA,
  TOGGLE_2FA,
  GET_USING_2FA_VALUE,
  USING_2FA_VALUE,
  ADD_APP_PERMISSION,
  ADD_APP_ROLE,
  GET_APP_PERMISSIONS,
  APP_PERMISSION_LIST,
  DELETE_APP_PERMISSION,
  GET_APP_ROLES,
  APP_ROLE_LIST,
  UPDATE_APP_PERMISSION,
  DELETE_APP_ROLE,
  UPDATE_APP_ROLE,
  GET_APP_ROLE_PERMISSIONS,
  APP_ROLE_PERMISSION_LIST,
  SET_ADD_EDIT,
  APP_SETTINGS_JSON,
  ADD_APP_SETTINGS_JSON,
  GET_APP_SETTINGS_DETAILS,
  GET_APP_INSTANCE_DATA,
  APP_INSTANCE_DATA,
  SHOW_LOADING,
  HIDE_LOADING,
  UI_PROPERTIES_JSON,
  APP_UI_PROPERTIES,
  ADD_APP_UI_PROPERTIES,
  EDIT_APP_UI_PROPERTIES,
  DELETE_APP_UI_PROPERTIES,
  GET_ADMIN_APP_UI_PROPERTIES,
  SUBSCRIBE_APP_DETAIL_PAGE,
  SUBSCRIBED_APP_DATA,
  SUBSCRIBE_APP_SETTINGS_PAGE,
  GET_ACCOUNT_USER_APP_INSTANCE_ROLES,
  ACCOUNT_USER_APP_INSTANCE_ROLES
} from "redux/constants/app";

export const getApps = (requestData) => {
  return {
    type: GET_APP_LIST,
    requestData: requestData,
  };
};

export const publishApp = (data) => {
  return {
    type: PUBLISH_APP,
    payload: data,
  };
};

export const getPublishedApps = (published) => {
  return {
    type: GET_PUBLISHED_APP_LIST,
    payload: published,
  };
};

export const showLoading = () => {
  return {
    type: SHOW_LOADING,
  };
};

export const hideLoading = () => {
  return {
    type: HIDE_LOADING,
  };
};

export const getSubscribedApps = (requestData) => {
  return {
    type: GET_SUBSCRIBED_APP_LIST,
    requestData: requestData,
  };
};

export const getAccountUserAppInstanceRoles = () => {
  return {
    type: GET_ACCOUNT_USER_APP_INSTANCE_ROLES,
  };
};

export const accountUserAppInstanceRoles = (data) => {
  return {
    type: ACCOUNT_USER_APP_INSTANCE_ROLES,
    accountUserRoles: data
  };
};

export const getDeveloperApp = () => {
  return {
    type: GET_DEVELOPER_APP,
  };
};

export const editApp = (appId) => {
  return {
    type: EDIT_APP,
    payload: appId,
  };
};
export const apps = (data) => {
  return {
    type: APP_LIST,
    apps: data,
  };
};

export const publishedAppList = (data) => {
  return {
    type: PUBLISHED_APP_LIST,
    publishedApps: data,
  };
};

export const subscribedAppList = (data) => {
  return {
    type: SUBSCRIBED_APP_LIST,
    subscribedApps: data,
  };
};
export const developerApp = (data) => {
  return {
    type: DEVELOPER_APP,
    developerApp: data,
  };
};

export const subscribeApp = (app) => {
  return {
    type: SUBSCRIBE_APP,
    payload: app,
  };
};

export const subscribeAppDetailPage = (app) => {
  return {
    type: SUBSCRIBE_APP_DETAIL_PAGE,
    payload: app,
  };
};

export const subscribeSettingsApp = (app) => {
  return {
    type: SUBSCRIBE_APP_SETTINGS_PAGE,
    payload: app,
  };
};



export const subscribedApp = (appInstanceData)=>{
  return {
    type: SUBSCRIBED_APP_DATA,
    appInstanceData: appInstanceData,
  };
}

export const app = (data) => {
  return {
    type: APP_DATA,
    app: data,
  };
};
export const appRegister = (data) => {
  return {
    type: SAVE_APP,
    payload: data,
  };
};

export const registerSuccess = (response) => {
  return {
    type: APP_REGISTER_SUCCESS,
    response,
  };
};

export const showAppMessage = (response) => {
  return {
    type: APP_MESSAGE,
    response,
  };
};

export const deleteApp = (app) => {
  return {
    type: DELETE_APP,
    payload: app,
  };
};

export const getMfaSetupHome = (username) => {
  return {
    type: GET_MFA_SETUP_HOME,
    username: username,
  };
};
export const twoFactorPageSuccess = (data) => {
  return {
    type: GET_MFA_SETUP_HOME_SUCCESS,
    data,
  };
};

export const appLoginAuthentication = (app) => {
  return {
    type: APP_LOGIN_AUTHENTICATION,
    app,
  };
};

export const onUsing2fa = (using2fa) => {
  return {
    type: USING_2FA,
    using2fa,
  };
};
export const toggle2fa = (using2fa) => {
  return {
    type: TOGGLE_2FA,
    using2fa,
  };
};
export const getUsing2faValue = () => {
  return {
    type: GET_USING_2FA_VALUE,
  };
};
export const addAppPermission = (data) => {
  return {
    type: ADD_APP_PERMISSION,
    payload: data,
  };
};
export const addAppRole = (data) => {
  return {
    type: ADD_APP_ROLE,
    payload: data,
  };
};
export const getAppPermissions = (appId) => {
  return {
    type: GET_APP_PERMISSIONS,
    payload: appId,
  };
};

export const appPermissions = (data) => {
  return {
    type: APP_PERMISSION_LIST,
    appPermissions: data,
  };
};

export const deletePermission = (permission) => {
  return {
    type: DELETE_APP_PERMISSION,
    payload: permission,
  };
};

export const getAppRoles = (appId) => {
  return {
    type: GET_APP_ROLES,
    payload: appId,
  };
};

export const appRoles = (data) => {
  return {
    type: APP_ROLE_LIST,
    appRoles: data,
  };
};

export const updateAppPermission = (data) => {
  return {
    type: UPDATE_APP_PERMISSION,
    payload: data,
  };
};

export const deleteAppRole = (appRole) => {
  return {
    type: DELETE_APP_ROLE,
    payload: appRole,
  };
};

export const updateAppRole = (data) => {
  return {
    type: UPDATE_APP_ROLE,
    payload: data,
  };
};

export const getAppRolePermissions = (appRoleId) => {
  return {
    type: GET_APP_ROLE_PERMISSIONS,
    payload: appRoleId,
  };
};

export const appRolePermissions = (data) => {
  return {
    type: APP_ROLE_PERMISSION_LIST,
    appRolePermissions: data,
  };
};

export const setAddEdit = (value) => {
  return {
    type: SET_ADD_EDIT,
    addEditValue: value,
  };
};

export const appSettingsJson = (data) => {
  return {
    type: APP_SETTINGS_JSON,
    appSettingsJson: data,
  };
};

export const addAppSettingsJson = (value) => {
  return {
    type: ADD_APP_SETTINGS_JSON,
    payload: value,
  };
};

export const getAppSettingsDetails = (data) => {
  return {
    type: GET_APP_SETTINGS_DETAILS,
    payload: data,
  };
};

export const fetchAppInstanceData = (appInstanceId) => {
  return {
    type: GET_APP_INSTANCE_DATA,
    payload: appInstanceId,
  };
};

export const appData = (appInstance) => {
  return {
    type: APP_INSTANCE_DATA,
    appInstanceData: appInstance,
  };
};

export const uiPropertiesJson = (data) => {
  return {
    type: UI_PROPERTIES_JSON,
    uiPropertiesJsonData: data,
  };
};
export const getUiProperties = (data) => {
  return {
    type: APP_UI_PROPERTIES,
    payload: data,
  };
};

export const getAdminUiProperties = (data) => {
  return {
    type: GET_ADMIN_APP_UI_PROPERTIES,
    payload: data,
  };
};
export const addUiProperties = (value) => {
  return {
    type: ADD_APP_UI_PROPERTIES,
    payload: value,
  };
};
export const editUiProperties = (value) => {
  return {
    type: EDIT_APP_UI_PROPERTIES,
    payload: value,
  };
};
export const deleteUiProperties = (value) => {
  return {
    type: DELETE_APP_UI_PROPERTIES,
    payload: value,
  };
};
