import React from 'react'
import { Dropdown, Menu,Button } from 'antd';
import { EllipsisOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types'
import { t } from "i18next";

const EllipsisDropdown = props => {
	const { menu,placement} = props;
	return (<>
		<Dropdown overlay={menu} placement={placement} trigger={['click']}>
			{/* <div className="ellipsis-dropdown">
				<EllipsisOutlined />
			</div> */}
			<div className="d-flex align-items-center custom-button" style={{width: '100%',justifyContent: 'flex-end'}}>
			<span className="ant-btn ant-btn-primary ant-btn ant-btn-primary ant-btn-sm mt-2" >{ t("impaqx.adminApp.subscribe")}</span>
			</div>
		</Dropdown>
		</>
	)
}

EllipsisDropdown.propTypes = {
	trigger: PropTypes.string,
	placement: PropTypes.string
}

EllipsisDropdown.defaultProps = {
	trigger: 'click',
	placement: 'bottomRight',
	menu: <Menu/>
};

export default EllipsisDropdown
