/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { Card } from "antd";
import { withTranslation } from 'react-i18next';
import Apps from "./apps";


const contentList = {
    apps: <Apps type="app" />,
    service: <Apps type="service" />,
    connector: <Apps type="connector"/>
};


export class Tabs extends Component {
  
  permissions  = localStorage.getItem("permissions")!=""?JSON.parse(localStorage.getItem("permissions")):"";

   appPermission = this.permissions?.allpermissions || this.permissions?.view_apps;
     servicePermission = this.permissions?.allpermissions || this.permissions?.view_services;
     connectorPermission = this.permissions?.allpermissions || this.permissions?.view_connectors;

  state = {
    key: this.appPermission?"apps":"",
    noTitleKey: "app"
  };

  onTabChange = (key, type) => {
    console.log(key, type);
    this.setState({ [type]: key });
  };

  render() {
    const { t } = this.props;
    const tabList = [
      this.appPermission? 
      {
        key: "apps",
        tab: t('impaqx.appAdmin.apps')
      } :"",
      ,
      this.servicePermission?{
        key: "service",
       tab:t('impaqx.appAdmin.services')
      }:"",
     this.connectorPermission?{
        key: "connector",
        tab:t('impaqx.appAdmin.connectors')
      }:""
    ];
    
    return (
      <div>
        <Card
          style={{ width: "100%" }}
         // title= {t('impaqx.appAdmin.developerPortal')}
         title="Developer Portal"
         // extra={<a href="#">More</a>}
          tabList={tabList}
          activeTabKey={this.state.key}
          onTabChange={key => {
            this.onTabChange(key, "key");
          }}
        >
          {contentList[this.state.key]}
        </Card>
        <br />
      </div>
    );
  }
}

export default  withTranslation()(Tabs);
