import { all, takeEvery, put, fork } from 'redux-saga/effects';

//import axios from 'axios';
import axios from './initialize'
import { Modal } from 'antd';
import {CheckCircleFilled} from  '@ant-design/icons';

import {  GET_APP_PARAMETERS_LIST,UPDATE_APP_PARAMETER,DELETE_APP_PAREMETER,ADD_APP_PARAMETER} from "redux/constants/appParameters";

import { appParametersList, showAppParametersMessage,registerSuccess } from "redux/actions/appParameters";
import { showUnAutherizeMessage} from "redux/actions/Auth";

import { API_BASE_URL, APP_PREFIX_PATH, MANAGE_PREPIX_PATH } from 'configs/AppConfig';
import {
	AUTH_TOKEN,
} from '../constants/Auth';
import {PATH_VARIABLE} from 'constants/ApiConstant';
import { t } from 'i18next';


export function* getAppParametrsList(){
    yield takeEvery (GET_APP_PARAMETERS_LIST, function*  ({requestData}){
		const {query, sorter,pagination} = requestData;
      const config = {
		headers: { "Content-Type": 'application/json',"Access-Control-Allow-Origin": "*",
		"Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",Authorization : `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
		accountUserId: localStorage.getItem("accountUserId") }
	  };
		try {
		const res = yield  axios.get(`${API_BASE_URL}${MANAGE_PREPIX_PATH}${APP_PREFIX_PATH}/parameter/appParameterList?query=${query}&sortfield=${sorter?.field}
		                                      &sortorder=${sorter?.order}&page=${pagination?.current-1}&size=${pagination?.pageSize}`,
		config);
			if (res.status===200) {
				yield put(appParametersList(res.data));
			} 
		} catch (err) {
			console.log(err);
			if(err.response.data.status==401){
				yield put(showAppParametersMessage(err.response));
			}
			yield put(showAppParametersMessage(err.response));
		}
    }

    )
}

export function* saveAppParameter() {
	yield takeEvery(ADD_APP_PARAMETER, function* ({ payload }) {
	  try {
		const accountId = localStorage.getItem("accountId");
		const {query, sorter,pagination} = payload;
		const config = {
		  headers: {
			"Content-Type": "application/json",
			"Access-Control-Allow-Origin": "*",
			"Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
			Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
			"accountId" : localStorage.getItem("accountId"),
			"appInstanceId":localStorage.getItem("appInstanceId"),
			accountUserId: localStorage.getItem("accountUserId")
		  },
		};
		const response = yield axios.post(
		  `${API_BASE_URL}${MANAGE_PREPIX_PATH}${APP_PREFIX_PATH}/parameter/appParameter`,
		  payload,
		  config
		);
		if (response.status === 200) {
		  Modal.success({
			title: t("impaqx.adminApp.appParameterCreateMessage"),
			icon: <CheckCircleFilled />,
			okText: t("impaqx.adminApp.close"),
			//cancelText: 'Cancel',
			onOk() {
			  //deleteApp(id);
			},
		  });
		  yield put(registerSuccess(response));
		}
		
	  } catch (err) {
		console.log(err);
		yield put(showAppParametersMessage(err.response));
	  }
	});
  }
export function* updateAppParameter(){
    yield takeEvery (UPDATE_APP_PARAMETER,function* ({payload}){
        try {
            const res = yield axios.put(`${API_BASE_URL}${MANAGE_PREPIX_PATH}${APP_PREFIX_PATH}/parameter/appParameter/${payload.id}`, payload,{
				headers: {
				  'Authorization': `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
				  'Access-Control-Allow-Origin' : '*',
				  'Accept': 'application/json',
				  'Content-Type': 'application/json',
				  accountUserId: localStorage.getItem("accountUserId")
				}
			  });
             if (res.status===200) {
				yield put(registerSuccess(res));
				Modal.success({
					title: t("impaqx.adminApp.appParameterUpdateMessage"),
					icon: <CheckCircleFilled />,
					okText: t("impaqx.adminApp.close"),
					//cancelText: 'Cancel',
					onOk() {
					  //deleteApp(id);
					},
				  });
				} 
        } catch (error) {
            console.log(error);
			if(error.response.data.status==401){
				yield put(showAppParametersMessage(error.response));
			}
			yield put(showAppParametersMessage(error.response));
        }
    }
    )
}

export function* deleteAppParameters(){
	yield takeEvery(DELETE_APP_PAREMETER, function*({payload}){
         try {
			const {query, sorter,pagination} = payload;
			 const res = yield axios.delete(`${API_BASE_URL}${MANAGE_PREPIX_PATH}${APP_PREFIX_PATH}/parameter/appParameter/${payload.id}`,{
				headers: {
				  'Authorization': `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
				  'Access-Control-Allow-Origin' : '*',
				  'Accept': 'application/json',
				  'Content-Type': 'application/json',
				  accountUserId: localStorage.getItem("accountUserId")
				}
			  });
			 if(res.status===200){
				 //yield put(showAccountMessage(res));
				 const res = yield  axios.get(`${API_BASE_URL}${MANAGE_PREPIX_PATH}${APP_PREFIX_PATH}/parameter/appParameterList?query=${query}&sortfield=${sorter?.field}
				 &sortorder=${sorter?.order}&page=${pagination?.current-1}&size=${pagination?.pageSize}`,
				 {headers: {
					'Authorization': `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
					'Access-Control-Allow-Origin' : '*',
					'Accept': 'application/json',
					'Content-Type': 'application/json',
					accountUserId: localStorage.getItem("accountUserId")
				  }});
					if (res.status===200) {
						Modal.success({
							title: `${payload.parameterName}  ${t("impaqx.adminApp.deleteParameterMessage")}`,
							icon: <CheckCircleFilled />,
							okText: 'Close',
							//cancelText: 'Cancel',
							onOk() {
								//deleteApp(id);
							},
						});
						yield put(appParametersList(res.data));
					} 
			 }
		 } catch (error) {
			 console.log(error);
			 if(error.response.data.status==401){
				yield put(showAppParametersMessage(error.response));
			}
			 yield put(showAppParametersMessage(error.response));
		 }
	})
}



export default function* rootSaga(){
    yield all([
		  fork(getAppParametrsList),
		  fork(deleteAppParameters),
		  fork(saveAppParameter),
		  fork(updateAppParameter)
    ])
    
}