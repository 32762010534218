import React, { Component } from 'react';
import { Drawer, Divider } from 'antd';
import { 
	MobileOutlined, 
	MailOutlined, 
	UserOutlined, 
	CompassOutlined,
	CalendarOutlined
} from '@ant-design/icons';
import { withTranslation } from 'react-i18next';

export class AppView extends Component {
	render() {
		const { data, visible, close} = this.props;
		const { t } = this.props;
		return (
			<Drawer
				width={500}
				placement="right"
				onClose={close}
				closable={false}
				visible={visible}
			>
				<div className="text-center mt-3">
					{/* <Avatar size={80} src={data?.img} /> */}
					<h3 className="mt-2 mb-0">{data?.name}</h3>
					<span className="text-muted">{data?.name}</span>
				</div>
				<Divider dashed />
				<div className="">
				<p>
					<h6 className="text-muted text-uppercase mb-3">{t('impaqx.adminApp.appDetails')}</h6>
					</p>
					<p>
						<span className="text-dark mb-3"><b>{t('impaqx.adminApp.id')} :</b> {data?.id}</span>
					</p>
					<p><div>
						<span className="text-dark mb-3" ><b style={{float:"left",wordWrap:"break-word"}}>{t('impaqx.adminApp.description')} :</b> <div dangerouslySetInnerHTML={{__html : data?.description}} /></span>
						</div></p>
				{/* </div>
				<div className="mt-5"> */}
					<p>
						{/* <MobileOutlined /> */}
						<span className="text-dark mb-3"><b>{t('impaqx.adminApp.logo')} :</b> {data?.logo}</span>
					</p>
					<p>
						{/* <MailOutlined /> */}
						<span className="text-dark mb-3"><b> {t('impaqx.adminApp.favicon')} :</b> {data?.favicon? data?.favicon: '-'}</span>
					</p>
					<p>
						{/* <CompassOutlined /> */}
						<span className="text-dark mb-3"><b>{t('impaqx.adminApp.accessUrl')} :</b> {data?.accessUrl}</span>
					</p>
				</div>
				{/* <div className="mt-5">
					<h6 className="text-muted text-uppercase mb-3">Social profiles</h6>
					<p>
						<FacebookOutlined />
						<a href="/#" className="ml-3 text-dark">{data?.personalInfo.facebook? data?.personalInfo.facebook : '-'}</a>
					</p>
					<p>
						<TwitterOutlined />
						<a href="/#" className="ml-3 text-dark">{data?.personalInfo.twitter? data?.personalInfo.twitter : '-'}</a>
					</p>
					<p>
						<InstagramOutlined />
						<a href="/#" className="ml-3 text-dark">{data?.personalInfo.instagram? data?.personalInfo.instagram : '-'}</a>
					</p>
					<p>
						<GlobalOutlined />
						<a href="/#" className="ml-3 text-dark">{data?.personalInfo.site? data?.personalInfo.site : '-'}</a>
					</p>
				</div> */}
			</Drawer>
		)
	}
}

export default withTranslation()(AppView)
