
import React, {  useState,useEffect} from 'react'
import { Card, Table, Tag, Tooltip, Button,Modal, Row, Col, Empty,Radio,Menu,Avatar,Progress, Typography } from 'antd';
import { AppstoreOutlined, UnorderedListOutlined, PlusOutlined,	PaperClipOutlined, 
	CheckCircleOutlined, 
	ClockCircleOutlined,
	EyeOutlined, 
	EditOutlined,
	DeleteOutlined } from '@ant-design/icons';
    import { COLORS } from 'constants/ChartConstant';
import EllipsisDropdown from 'components/shared-components/EllipsisDropdown'
import utils from 'utils';
import Flex from 'components/shared-components/Flex';
import {IMAGE_PATH} from 'configs/AppConfig';
import {BASE_URL,BLOB_STORAGE_URL } from '../../../../constants/ApiConstant';
import { FontAwesomeIcon,icon } from '@fortawesome/react-fontawesome'
import { PATH_VARIABLE } from '../../../../constants/ApiConstant';
import settingsConfigure from 'views/app-views/pages/apps/settings-configure';


    const ItemAction = ({t,app, OnClickApp,unSubscribe,settings,accountUserRoles,requestAccess}) => {
        // <EllipsisDropdown 
        //     menu={
        //         <Menu>
        //             <Menu.Item key="0"  onClick={() => subscribe("monthly",app)}>
        //                 {/* <EyeOutlined /> */}
        //                 <span className="ml-2">monthly</span>
        //             </Menu.Item>
        //             <Menu.Item key="1" onClick={() => subscribe("quarterly",app)}>
        //                 {/* <EditOutlined /> */}
        //                 <span className="ml-2">quarterly</span>
        //             </Menu.Item>
        //             {/* <Menu.Divider /> */}
        //             <Menu.Item key="2" onClick={() => subscribe("id",app)}>
        //                 {/* <DeleteOutlined /> */}
        //                 <span className="ml-2">yearly</span>
        //             </Menu.Item>
        //         </Menu>
        //     }
        // />
        const permissions  = localStorage.getItem("permissions")!=""?JSON.parse(localStorage.getItem("permissions")):"";
        return(<>
        <div className="d-flex align-items-center custom-button" style={{width: '100%',justifyContent: 'flex-end'}}>
        {app.type=='app' && accountUserRoles[app.id] || accountUserRoles[app?.appId]?<Button className="ant-btn ant-btn-primary ant-btn ant-btn-primary ant-btn-sm mt-2 mr-2" onClick={()=>OnClickApp(app)}>{t("impaqx.adminApp.open")}</Button>:null}
        {app.type=='app' &&(permissions?.allpermissions ||permissions[`update_${app.type}_settings`] || permissions[`view_${app.type}_settings`]|| permissions[`update_subscriber_setttings`] ) && 
       (accountUserRoles[app.id] || accountUserRoles[app?.appId])?
        <Button className="ant-btn ant-btn ant-btn-sm mt-2 mr-2" onClick={()=>settings(app)}>{t("impaqx.adminApp.settings")}</Button>:null}
        {app.type!='app' && (permissions?.allpermissions ||permissions[`update_${app.type}_settings`] || permissions[`view_${app.type}_settings`]|| permissions[`update_subscriber_setttings`])?
        <Button className="ant-btn ant-btn ant-btn-sm mt-2 mr-2" onClick={()=>settings(app)}>{t("impaqx.adminApp.settings")}</Button>:null}
        {/* <Button className="ant-btn ant-btn ant-btn-sm mt-2 mr-2" onClick={()=>unSubscribe(app)}>{t("impaqx.adminApp.unsubscribe")}</Button> */}
        </div>
        {accountUserRoles[app.id] || accountUserRoles[app?.appId] || app.type!='app'?null:<Button className="ant-btn ant-btn ant-btn-sm mt-2 mr-2" onClick={()=>requestAccess(app)}>{t("impaqx.adminApp.requestAccess")}</Button>}
        </>)
    }
    
    const ItemHeader = ({t,app,subscriptionTerm,showMore,accountUserRoles,requestMessage,setRequestMessage}) => {
     return(
       <><div style={{minHeight:'265px'}}>
            <h4 style={{wordBreak:'break-all'}} className="mb-2"> {app.name}</h4>
            {/* <p className="mb-2"> {description}</p> */}
            {/* <div className="mb-2" style={{wordBreak:'break-all'}}
           dangerouslySetInnerHTML={{__html : description}}></div> */}
            <div> <div  className="mb-0" style={{wordBreak:'break-all'}}
           dangerouslySetInnerHTML={{__html : (app?.description?.length > 440 ) ? app?.description?.substring ( 0 , 440 - 1 ).concat ( "…" ) : app?.description?app?.description:""}}></div>
           {app?.description?.length > 250?<a className="btn btn-primary" onClick={()=>showMore(app)}>{t("impaqx.adminApp.showMore")}</a>:null}</div>
            <span className="text-muted">{app.category}</span>
            <h5 className="mt-3">{t("impaqx.adminApp.subscriptionTerm")}: <span style={{fontWeight: "400"}}> {app.subscriptionTerm}</span> </h5> 
            {(accountUserRoles[app.id] || accountUserRoles[app?.appId])?null:app?.type=='app'?<h5 style={{color:'red'}}>{t("impaqx.adminApp.noRoledAssigned").replace("app",app.type)}</h5>:null}
        </div>
        {/* <span className="mt-2 mr-2" >Developed by : {app.businessName} </span> */}
        </> 
     )
    }
    
    const ItemInfo = ({attachmentCount, completedTask, totalTask, statusColor, dayleft}) => (
        <Flex alignItems="center">
            <div className="mr-3">
                <Tooltip title="Attachment">
                    <PaperClipOutlined className="text-muted font-size-md"/>
                    <span className="ml-1 text-muted">{attachmentCount}</span>
                </Tooltip>
            </div>
            <div className="mr-3">
                <Tooltip title="Task Completed">
                    <CheckCircleOutlined className="text-muted font-size-md"/>
                    <span className="ml-1 text-muted">{completedTask}/{totalTask}</span>
                </Tooltip>
            </div>
            <div>
            <Tag className={statusColor === "none"? 'bg-gray-lightest' : ''} color={statusColor !== "none"? statusColor : ''}>
                <ClockCircleOutlined />
                <span className="ml-2 font-weight-semibold">{dayleft} days left</span>
            </Tag>
            </div>
        </Flex>
    )
    
    const ItemProgress = ({progression}) => (
        <Progress percent={progression} strokeColor={getProgressStatusColor(progression)} size="small"/>
    )
    
    // const ItemMember = ({member}) => (
    //     <>
    //         {member.map((elm, i) => (
    //                 i <= 2?
    //             <Tooltip title={elm.name} key={`avatar-${i}`}>
    //                 <Avatar size="small" className={`ml-1 cursor-pointer ant-avatar-${elm.avatarColor}`} src={elm.img} >
    //                     {elm.img? '' : <span className="font-weight-semibold font-size-sm">{utils.getNameInitial(elm.name)}</span>}
    //                 </Avatar>
    //             </Tooltip>
    //             :
    //             null
    //         ))}
    //         {member.length > 3 ?
    //             <Tooltip title={`${member.length - 3} More`}>
    //                 <Avatar size={25} className="ml-1 cursor-pointer bg-white border font-size-sm">
    //                     <span className="text-gray-light font-weight-semibold">+{member.length - 3}</span>
    //                 </Avatar>
    //             </Tooltip>
    //             :
    //             null
    //         }
    //     </>
    // )
    
    
    
    
    const getProgressStatusColor = progress => {
        if(progress >= 80) {
            return COLORS[1]
        }
        if(progress < 60 && progress > 30) {
            return COLORS[3]
        }
        if(progress < 30) {
            return COLORS[2]
        }
        return COLORS[0]
    }

const ListItem = ({ data, removeId }) => (
	<div className="bg-white rounded p-3 mb-3 border">
		<Row align="middle">
    	<Col xs={24} sm={24} md={8}>
				<ItemHeader name={data.name} category={data.category} />
			</Col>
			<Col xs={24} sm={24} md={6}>
				<ItemInfo 
					attachmentCount={data.attachmentCount}
					completedTask={data.completedTask}
					totalTask={data.totalTask}
					statusColor={data.statusColor}
					dayleft={data.dayleft}
				/>
			</Col>
			<Col xs={24} sm={24} md={5}>
				<ItemProgress progression={data.progression} />
			</Col>
			<Col xs={24} sm={24} md={3}>
				<div className="ml-0 ml-md-3">
					{/* <ItemMember member={data.member}/> */}
				</div>
			</Col>
			<Col xs={24} sm={24} md={2}>
				<div className="text-right">
					<ItemAction id={data.id} removeId={removeId} />
				</div>
			</Col>
		</Row>
	</div>
)

const GridItem = ({t, data, OnClickApp,unSubscribe,showMore,settings,accountUserRoles,requestAccess,requestMessage,setRequestMessage}) => (
	<Card style={{height: '100%'}}>
		<Flex justifyContent="between" className='flex-lg-row flex-column custom-grid'>
        <div className="d-flex align-items-baseline ant-col ant-col-xs-24 ant-col-sm-24 ant-col-lg-24 ant-col-xl-5">
            <div className='productImg'>
           {data.logoType=="URL"?<img style={{width: '100%',}} src={data.logo} 
            onError={(e) => {
                e.target.src = `${BLOB_STORAGE_URL}/extremeb2b/appLogo/noimage.jpg`
                e.target.style = 'width: 100%'
             }}
             alt={data.logo}/>:null}
           {data.logoType=="IMAGE"? <img style={{width: '100%'}} src={`${BLOB_STORAGE_URL}/extremeb2b/appLogo/${data.logo}`}
        //    {data.logoType=="IMAGE"? <img style={{width: '100%'}} src={`${BASE_URL}/pilot/images/app/${data.logo}`}
                                     onError={(e) => {
                                        e.target.src = `${BLOB_STORAGE_URL}/extremeb2b/appLogo/noimage.jpg`
                                        e.target.style = 'width: 100%'
                                     }}
                                            alt={data.logo}/>:null}
           {data.logoType=="FONTAWESOME"?<div> <span className={`${data.logo}`} style={{fontSize:'50px'}}></span></div>:null}
                {/* <span className="font-weight-semibold ml-2">{elm.logo}</span> */}
                </div>
        </div>
        <div className="pl-4 ant-col ant-col-xs-24 ant-col-sm-24 ant-col-lg-24 ant-col-xl-19 pl-lg-0 mt-lg-2 pl-xl-4">
			<ItemHeader t={t} app={data} showMore={showMore} accountUserRoles={accountUserRoles}  requestMessage={requestMessage} setRequestMessage={setRequestMessage}/>
            <ItemAction t={t} app={data} OnClickApp={OnClickApp} requestAccess={requestAccess} unSubscribe={unSubscribe} settings={settings} accountUserRoles={accountUserRoles}/> 
            </div>
        </Flex>

      <Typography style={{position: 'relative', top: 20}}>{t("impaqx.adminApp.developedBy")} : {data.businessName}</Typography>      
	  {data.accountStatus=='INACTIVE'?<Typography style={{position: 'relative', top: 20}}>{t("impaqx.adminApp.inActiveDeveloperMessage")}</Typography>:null}
	</Card>
)

const VIEW_LIST = 'LIST';
const VIEW_GRID = 'GRID';
const ListGridView =(props)=>{
    const [view, setView] = useState(VIEW_GRID);
 const { t,subscribedApps,deleteItem,onClickApplication,onclickUnSubscribe,showMore,settings,accountUserRoles,onClickRequestAccess,requestMessage,setRequestMessage } = props;
    return(
    <div className={`my-4 ${view === VIEW_LIST? 'container' : 'container-fluid'}`} style={{width:"100%"}}>
				{
					view === VIEW_LIST ?
					subscribedApps?.map(elm => <ListItem data={elm} removeId={id => deleteItem(id)} key={elm.id}/>)
					:
					<Row gutter={16}>
						{subscribedApps?.map(elm => (
							<>{elm.name!="DeveloperApp_"+localStorage.getItem("accountId")?<Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={8} className="mb-3" key={elm.id}>
								<GridItem t={t} data={elm} removeId={id => deleteItem(id)} OnClickApp={() => onClickApplication(elm)} unSubscribe={()=>onclickUnSubscribe(elm)}
                                 showMore={()=>showMore(elm)} settings={settings} accountUserRoles={accountUserRoles} requestAccess={()=>onClickRequestAccess(elm)} requestMessage={requestMessage} setRequestMessage={setRequestMessage}/>
							</Col>:null}</>
						))}
					</Row>
				}
			</div>
    )
}

export default ListGridView;