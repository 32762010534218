import { CONNECTORS_API_LIST,SHOW_LOADING,HIDE_LOADING,CONNECTOR_API_MESSAGE,CONNECTOR_API_REGISTER_SUCCESS,SET_ADD_EDIT,CONNECTOR_DATA,
    TEST_CONNECTOR_DATA,
    SHOW_HIDE_TEST_CONNECTOR,
    INSTANCE_CONNECTORS_API_LIST} from "redux/constants/connector";
import { APP_PREFIX_PATH} from 'configs/AppConfig'

const initState = {
    loading : true,
    connectorData : [],
    testConnectorData:[],
    showTestConnector:false,
    connectorsList : [],
    connectorsInstanceList:[],
    message: '',
    showMessage: false,
    redirect : "",
    allowRedirect:false,
    status:0,
    token:null
}

const connector =(state=initState,action) =>{
    switch(action?.type){
        case CONNECTORS_API_LIST:
         return{
             ...state,
             connectorsList : action.connectorsList,
             allowRedirect :false,
             addEditValue : false,
             loading:false,
             message:""
         }
         case INSTANCE_CONNECTORS_API_LIST:
         return{
             ...state,
             connectorsInstanceList : action.connectorsInstanceList,
             allowRedirect :false,
             addEditValue : false,
             loading:false,
             message:""
         }
         case CONNECTOR_DATA:
         return{
            ...state,
            connectorData : action.connectorData,
            loading:false,
         }
         case TEST_CONNECTOR_DATA:
         return{
            ...state,
            testConnectorData : action.testConnectorData,
            showTestConnector : true,
            loading:false
         }
         case CONNECTOR_API_REGISTER_SUCCESS:
            return {
              ...state,
              message: messageRead(action),
              status: action.response.status,
              showMessage: true,
              loading: false,
              allowRedirect: true,
              addEditValue : false,
              redirect: `${APP_PREFIX_PATH}/pages/edit-app/${localStorage.getItem("appId")}`,
            };
            case SET_ADD_EDIT:
                return {
                  ...state,
                  addEditValue: action.addEditValue,
                  loading: false,
                  appRolePermissions: [],
                };
         case CONNECTOR_API_MESSAGE:
         return{
             ...state,
             message : messageRead(action),
             status:action.response.status,
             showMessage : true,
             loading: false,
             allowRedirect : false
         }
         case SHOW_LOADING: {
			return {
				...state,
				loading: true
			}
		}
        case HIDE_LOADING:{
			return{
				...state,
				loading:false
			}
		}
        case SHOW_HIDE_TEST_CONNECTOR:{
            return{
                ...state,
                showTestConnector:action.data
            }
        }
         default:
           return state;
    }
}
const messageRead=(action)=>{
    if(action?.response?.status==401 || action?.response?.response?.status){
        return "Unauthorized"
    }
	if(action?.response && action?.response?.response && action?.response?.response?.data && action?.response?.response?.message){
	 return action?.response?.response?.data?.message;
	}else if(action?.response && action?.response?.data && action?.response?.data?.message){
		return action?.response?.data?.message;
	}else if(action?.response && action?.response?.data && action?.response?.data?.message){
		return action?.response?.data?.message;
	}else if(action?.response && action?.response?.data){
		return action?.response?.data;
	}else if(action?.data?.message){
		return action?.data?.message;
	}else if(action?.data){
		return action?.data;
	}else{
		return "something went wrong"
	}
}

export default connector;