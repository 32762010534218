import React, { lazy, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Loading from 'components/shared-components/Loading';
import { AUTH_PREFIX_PATH,APP_PREFIX_PATH } from 'configs/AppConfig'
import { LinkedInCallback } from 'react-linkedin-login-oauth2';

export const AppViews = () => {
  return (
    <Suspense fallback={<Loading cover="page"/>}>
      <Switch>
        <Route path={`${AUTH_PREFIX_PATH}/login`} component={lazy(() => import(`./authentication/login`))} />
        <Route path={`${AUTH_PREFIX_PATH}/login-1`} component={lazy(() => import(`./authentication/login-1`))} />
        <Route path={`${AUTH_PREFIX_PATH}/login-2`} component={lazy(() => import(`./authentication/login-2`))} />
        <Route path={`${AUTH_PREFIX_PATH}/two-factor-auth`} component={lazy(() => import(`./authentication/two-factor-auth`))} />
        <Route path={`${AUTH_PREFIX_PATH}/verifymail`} component={lazy(() => import(`./authentication/verifymail`))} />
        <Route path={`${AUTH_PREFIX_PATH}/verify`} component={lazy(() => import(`./authentication/verify`))} />
        <Route path={`${AUTH_PREFIX_PATH}/register`} component={lazy(() => import(`./authentication/register-1`))} />
        <Route path={`${AUTH_PREFIX_PATH}/register-2`} component={lazy(() => import(`./authentication/register-2`))} />
        <Route path={`${AUTH_PREFIX_PATH}/register-success`} component={lazy(() => import(`./authentication/register-success`))} />
        <Route path={`${AUTH_PREFIX_PATH}/forgot-password`} component={lazy(() => import(`./authentication/forgot-password`))} />
        <Route path={`${AUTH_PREFIX_PATH}/reset-password`} component={lazy(() => import(`./authentication/reset-password`))} />
        <Route path={`${AUTH_PREFIX_PATH}/error-1`} component={lazy(() => import(`./errors/error-page-1`))} />
        <Route path={`${AUTH_PREFIX_PATH}/error-2`} component={lazy(() => import(`./errors/error-page-2`))} />
        <Route path={`${AUTH_PREFIX_PATH}/salsify`} component={lazy(() => import(`../app-views/salsify-app`))} />
        <Route path={`${AUTH_PREFIX_PATH}/accept-invite`} component={lazy(() => import(`../app-views/pages/account-user/accept-invitation`))} />
        <Route path={`${AUTH_PREFIX_PATH}/resend-invite`} component={lazy(() => import(`../app-views/pages/account-user/resend-invitation`))} />
        <Route path={`${AUTH_PREFIX_PATH}/test-mapper`} component={lazy(() => import(`../app-views/pages/apps/connector-settings/add-connector/Testmap`))} />
        <Route path={`${AUTH_PREFIX_PATH}/linkedin`} component={LinkedInCallback} />
        <Route path={`${AUTH_PREFIX_PATH}/404`} component={lazy(() => import(`../auth-views/errors/error-page-1`))} />
        <Route path={`${AUTH_PREFIX_PATH}/401`} component={lazy(() => import(`../auth-views/errors/unautherized`))} />
        <Route path={`${AUTH_PREFIX_PATH}/loading`} component={lazy(() => import(`../../components/shared-components/Loading`))} />
        <Route path={`${AUTH_PREFIX_PATH}/access-decline`} component={lazy(() => import(`./components/access-decline-message`))} />
        {/* <Route path="*" component={lazy(() => import(`./errors/error-page-1`))} /> */}
        <Route path="*"><Redirect to={`${AUTH_PREFIX_PATH}/404`} /></Route>
        <Redirect from={`${AUTH_PREFIX_PATH}`}  to={`${AUTH_PREFIX_PATH}/login`}/>
      </Switch>
    </Suspense>
  )
}

export default AppViews;

