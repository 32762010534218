import React, { useState, useEffect } from "react";
import {Table} from 'antd';
import SkeletonLoader from 'components/util-components/SkeletonLoader'

const CustomTable = props => {

  const { columns,dataSource,pagination,handleTableChange,loading,query} = props

  useEffect(() => {
    
  });

  return (<>
  <SkeletonLoader  columns={columns}  loading={loading}/>
  {!loading && dataSource?.totalElements>0 || !loading &&  query!=undefined?<Table loading={loading} columns={columns} className="customTable" dataSource={dataSource?.content} rowKey='id' pagination={pagination} 
        onChange={handleTableChange} />:null}
        </>
  )
}

export default CustomTable;
