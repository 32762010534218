import { all, takeEvery, put, fork, call } from 'redux-saga/effects';

import {
	AUTH_TOKEN,
	SIGNIN,
	SIGNOUT,
	SIGNOUT_EXTREMEB2B,
	SEND_MAIL,
	VERIFY_MAIL,
	SIGNUP,
	SIGNIN_WITH_GOOGLE,
	SIGNIN_WITH_FACEBOOK,
	EMAIL_ID_CHECK,
	RESET_PASSWORD_MAIL,
	RESET_PASSWORD,
	SIGNIN_TOTP,
	PASS_VALIDATE,
	SIGNIN_WITH_MICROSOFT,
	FETCH_USER_DETAILS,
	GET_MFA_SETUP,
	CHANEGE_PASSWORD,
	USING_2FA,
	TOKEN_CHECK,
	GET_USER_DETAILS,
	UPDATE_USER,
	SSO_SIGNUP,
	SIGNUP_WITH_GOOGLE,
	SIGNUP_WITH_MICROSOFT,
	SIGNUP_WITH_LINKEDIN,
	SIGNIN_WITH_LINKEDIN
} from '../constants/Auth';
import {
	showAuthMessage,
	authenticated,
	signOutSuccess,
	signUpSuccess,
	signInWithGoogleAuthenticated,
	signInWithFacebookAuthenticated,
	checkEmailMessage,
	signInSuccess,
	checkpasswordValidate,
	toggle2fa,
	verifySuccessSignIn,
	userData,
} from "../actions/Auth";
import {BASE_URL,LINKEDIN_CLIENT_ID, LINKEDIN_CLIENT_SECRETE} from 'constants/ApiConstant'

import axios from 'axios';
import { API, API_BASE_DEVELOPER_APP, API_BASE_URL, APP_PREFIX_PATH, MANAGE_PREPIX_PATH, USERS_PREPIX_PATH, VERSION } from 'configs/AppConfig'
import { PATH_VARIABLE } from 'constants/ApiConstant';
import FirebaseService from 'services/FirebaseService'
import { AUTH_PREFIX_PATH } from 'configs/AppConfig'
import {getAdminUiProperties} from '../actions/app'
import { Modal } from 'antd';
import {CheckCircleFilled} from '@ant-design/icons';
import { showLoading } from 'redux/actions/Auth';



export function* tokenCheck() {
	yield takeEvery(TOKEN_CHECK, function* () {
		const token = localStorage.getItem(AUTH_TOKEN);
		  try {
		  const res = yield  axios.post(`${API_BASE_URL}${MANAGE_PREPIX_PATH}${AUTH_PREFIX_PATH}/tokenCheck`, token);
			  if (res.status===200) {

			  }else{
				window.location.replace("/admin"+AUTH_PREFIX_PATH+"/login");
			  }
		  } catch (error) {
			window.location.replace("/admin"+AUTH_PREFIX_PATH+"/login");
			  console.log(error.response);
			  yield put(showAuthMessage(error.response));
		  }
	  });
  }

export function* signInWithFBEmail() {
  yield takeEvery(SIGNIN, function* ({payload}) {
		try {
		const res = yield  axios.post(`${API_BASE_URL}${MANAGE_PREPIX_PATH}${AUTH_PREFIX_PATH}/login`, payload);
			if (res.status===200) {
				if( res.data.token && res.data.token.accessToken && res.data.token.accessToken!=null){
					sessionStorage.clear();
					localStorage.setItem("businessName","");
					//localStorage.clear();
					localStorage.setItem(AUTH_TOKEN, res.data.token.accessToken);
					localStorage.setItem("refresh_token",res.data.token.refreshToken);
					localStorage.setItem("userId",res.data.userId);
					localStorage.setItem("username",res.data.username);
                    localStorage.setItem('TOKEN_GEN_TIME', new Date());
					localStorage.setItem("defaultAccountId",res.data.defaultAccountId);
					localStorage.setItem("profilePicture",res.data.profilePicture);
					console.log(res.data);
					const accountRequest = axios.get(`${API_BASE_URL}${MANAGE_PREPIX_PATH}${APP_PREFIX_PATH}/account?userId=${res.data.userId}`, {
						headers: {
						  'Authorization': `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
						  'Access-Control-Allow-Origin' : '*',
						  'Accept': 'application/json',
						  'Content-Type': 'application/json'
						}
					  });
						  const propertiesRequest = axios.get(
							`${API_BASE_DEVELOPER_APP}/developerApp/properties/admin-ui-properties?appName=AdminApp&query=&sortfield=undefined&sortorder=undefined`,
							{headers: {
								'Authorization': `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
								'Content-Type': 'application/json'
							}}
						  );
						const [response, propertiesResponse] = yield axios.all([accountRequest, propertiesRequest]);
					  if (propertiesResponse.status === 200) {
						const enData = {};
						const frData = {};
						const spData = {};
						const jpData = {};
						propertiesResponse?.data?.map((uiDetails, index) => {
						  if (uiDetails.locale) {
							enData[`${uiDetails.propertyName}`] = uiDetails?.locale?.en;
							frData[`${uiDetails.propertyName}`] = uiDetails?.locale?.fr;
							spData[`${uiDetails.propertyName}`] = uiDetails?.locale?.sp;
							jpData[`${uiDetails.propertyName}`] = uiDetails?.locale?.jp;
						  }
						});
						localStorage.setItem("ENGLISH-DATA", JSON.stringify(enData));
						localStorage.setItem("FRENCH-DATA", JSON.stringify(frData));
						localStorage.setItem("SPANISH-DATA", JSON.stringify(spData));
						localStorage.setItem("JAPNESE-DATA", JSON.stringify(jpData));
					}
					 if (response.status===200) {
						const account = response.data;
						if(account&& account!=""){
							localStorage.setItem("accountId",account.id);
							localStorage.setItem("accountCode",account.accountCode);
							localStorage.setItem("disableSelection",account.disableSelection);
							localStorage.setItem("businessName",account.businessName);
							localStorage.setItem("defaultBusinessName",account.businessName);
							localStorage.setItem("accountUserId",account.accountUserId);
	                        localStorage.setItem("userType",account?.userType);         
							var appData = {};
							appData["username"] = localStorage.getItem("username");
							appData["accountId"] = localStorage.getItem("accountId");
							appData["accountUserId"] = localStorage.getItem("accountUserId");
							var code = Date.now();
							const appAuthResponse =yield axios.post(
								`${API_BASE_URL}${MANAGE_PREPIX_PATH}${API}${VERSION}${USERS_PREPIX_PATH}/appLoginAuthentication?code=${code}`,
								appData,
								{
									headers: {
										'Authorization': `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
										'Access-Control-Allow-Origin' : '*',
										'Accept': 'application/json',
										'Content-Type': 'application/json'
									  }
								}
							);
							if (appAuthResponse.status === 200) {
								//localStorage.setItem(AUTH_TOKEN, appAuthResponse.data.accessToken);
								localStorage.setItem("accountId", appAuthResponse.data.accountId);
							}
							const roleRequest = axios.get(`${API_BASE_DEVELOPER_APP}/developerApp/userRolesPermissionList?accountUserId=${account?.accountUserId}&appName=AdminApp&accountId=${account?.id}`, {
								headers: {
								  'Authorization': `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
								  'Access-Control-Allow-Origin' : '*',
								  'Accept': 'application/json',
								  'Content-Type': 'application/json'
								}
							  });
							const [roleResponse] = yield axios.all([roleRequest]);
							 if (roleResponse?.status===200) {
								localStorage.setItem("permissions", roleResponse?.data?.appUserRolesPermissions?JSON.stringify(roleResponse?.data?.appUserRolesPermissions):"");
								//if(roleResponse?.data?.appUserRolesPermissions?.allpermissions || roleResponse?.data?.appUserRolesPermissions?.view_myapps_apps){
									window.location.replace(`${PATH_VARIABLE}/app/${account.id}/my-apps`);   
								//   }else{
								// 	window.location.replace(`${PATH_VARIABLE}/app/${account.id}/users`); 
								//   } 
							 }
							//history.replace(`/app/${account.id}/my-apps`)
						}else{
							window.location.assign(`${PATH_VARIABLE}/app/pages/account-list?mode=view-all`);
					       yield put(authenticated(res.data.token.accessToken));
						}
					 }else{
						window.location.assign(`${PATH_VARIABLE}/app/pages/account-list?mode=view-all`);
					     yield put(authenticated(res.data.token.accessToken));
					 }
			//	}
				}else{
					//localStorage.clear();
					localStorage.setItem("username",payload.username);
					localStorage.setItem("password",payload.password);
					localStorage.setItem("userId",res.data.userId);
					localStorage.setItem("defaultAccountId",res.data.defaultAccountId);
					localStorage.setItem('TOKEN_GEN_TIME', new Date());
					console.log(res.data);
					yield put(signInSuccess(res.data.username));
				}
			}
		} catch (error) {
			console.log(error.response);
			yield put(showAuthMessage(error.response));
		}
	});
}

export function* signInWithTOTP(){
  yield takeEvery(SIGNIN_TOTP,function*({payload}){
	  try {
		const res = yield  axios.post(`${API_BASE_URL}${MANAGE_PREPIX_PATH}${AUTH_PREFIX_PATH}/twoFactorAuth`, payload);
			if(res.status===200){
				//localStorage.clear();
				sessionStorage.clear();
				localStorage.setItem("businessName","");
				localStorage.setItem(AUTH_TOKEN, res.data.token.accessToken);
				localStorage.setItem("refresh_token",res.data.token.refreshToken);
				localStorage.setItem("userId",res.data.userId);
				localStorage.setItem("username",res.data.username);
				localStorage.setItem("defaultAccountId",res.data.defaultAccountId);
				localStorage.setItem('TOKEN_GEN_TIME', new Date());
				localStorage.setItem("profilePicture",res.data.profilePicture);
				console.log(res.data);
				const accountRequest = axios.get(`${API_BASE_URL}${MANAGE_PREPIX_PATH}${APP_PREFIX_PATH}/account?userId=${res.data.userId}`, {
					headers: {
					  'Authorization': `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
					  'Access-Control-Allow-Origin' : '*',
					  'Accept': 'application/json',
					  'Content-Type': 'application/json'
					}
				  });
					  const propertiesRequest = axios.get(
						`${API_BASE_DEVELOPER_APP}/developerApp/properties/admin-ui-properties?appName=AdminApp&query=&sortfield=undefined&sortorder=undefined`,
						{headers: {
							'Authorization': `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
							'Content-Type': 'application/json'
						}}
					  );
					const [response, propertiesResponse] = yield axios.all([accountRequest, propertiesRequest]);
				  if (propertiesResponse.status === 200) {
					const enData = {};
					const frData = {};
					const spData = {};
					const jpData = {};
					propertiesResponse?.data?.map((uiDetails, index) => {
					  if (uiDetails.locale) {
						enData[`${uiDetails.propertyName}`] = uiDetails?.locale?.en;
						frData[`${uiDetails.propertyName}`] = uiDetails?.locale?.fr;
						spData[`${uiDetails.propertyName}`] = uiDetails?.locale?.sp;
						jpData[`${uiDetails.propertyName}`] = uiDetails?.locale?.jp;
					  }
					});
					localStorage.setItem("ENGLISH-DATA", JSON.stringify(enData));
					localStorage.setItem("FRENCH-DATA", JSON.stringify(frData));
					localStorage.setItem("SPANISH-DATA", JSON.stringify(spData));
					localStorage.setItem("JAPNESE-DATA", JSON.stringify(jpData));
				  }
				 if (response.status===200) {
					const account = response.data;
					if(account&& account!=""){
						localStorage.setItem("accountId",account.id);
						localStorage.setItem("accountCode",account.accountCode);
						localStorage.setItem("disableSelection",account.disableSelection);
						localStorage.setItem("businessName",account.businessName);
						localStorage.setItem("defaultBusinessName",account.businessName);
						localStorage.setItem("accountUserId",account.accountUserId);
						localStorage.setItem("userType",account?.userType);  
						//history.replace(`/app/${account.id}/my-apps`)
						var appData = {};
							appData["username"] = localStorage.getItem("username");
							appData["accountId"] = localStorage.getItem("accountId");
							appData["accountUserId"] = localStorage.getItem("accountUserId");
							var code = Date.now();
							const appAuthResponse =yield axios.post(
								`${API_BASE_URL}${MANAGE_PREPIX_PATH}${API}${VERSION}${USERS_PREPIX_PATH}/appLoginAuthentication?code=${code}`,
								appData,
								{
									headers: {
										'Authorization': `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
										'Access-Control-Allow-Origin' : '*',
										'Accept': 'application/json',
										'Content-Type': 'application/json'
									  }
								}
							);
							if (appAuthResponse.status === 200) {
								//localStorage.setItem(AUTH_TOKEN, appAuthResponse.data.accessToken);
								localStorage.setItem("accountId", appAuthResponse.data.accountId);
							}
						const roleRequest = yield axios.get(`${API_BASE_DEVELOPER_APP}/developerApp/userRolesPermissionList?accountUserId=${account?.accountUserId}&appName=AdminApp&accountId=${account?.id}`, {
								headers: {
								  'Authorization': `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
								  'Access-Control-Allow-Origin' : '*',
								  'Accept': 'application/json',
								  'Content-Type': 'application/json'
								}
							  });
							  const [roleResponse] = yield axios.all([roleRequest]);
							 if (roleResponse?.status===200) {
								localStorage.setItem("permissions", roleResponse?.data?.appUserRolesPermissions?JSON.stringify(roleResponse?.data?.appUserRolesPermissions):"");
							 }
							// if(roleResponse?.data?.appUserRolesPermissions?.allpermissions || roleResponse?.data?.appUserRolesPermissions?.view_myapps_apps){
								window.location.replace(`${PATH_VARIABLE}/app/${account.id}/my-apps`);   
							//   }else{
							// 	window.location.replace(`${PATH_VARIABLE}/app/${account.id}/users`); 
							//   }  
					}else{
						window.location.assign(`${PATH_VARIABLE}/app/pages/account-list?mode=view-all`);
				     yield put(authenticated(res.data.token.accessToken));
					}
				}else{
					window.location.assign(`${PATH_VARIABLE}/app/pages/account-list?mode=view-all`);
				   yield put(authenticated(res.data.token.accessToken));
				}
			//}
		//  }
			
			}
	  } catch (error) {
		console.log(error.response);
		  const obj= {"message" : "Invalid credentials provided."};
		  const res= {"data" : obj} ;
		yield put(showAuthMessage(res));
	  }
  })
}

export function* sendVerificationEmail() {
	yield takeEvery(SEND_MAIL, function* ({payload}) {
		  try {
		  const res = yield  axios.post(`${API_BASE_URL}${MANAGE_PREPIX_PATH}${AUTH_PREFIX_PATH}/verifyMail`, payload);
			  if (res.status===200) {
				 // localStorage.setItem(AUTH_TOKEN, res.data.token.token);
				  yield put(showAuthMessage(res));
			  } else {
				  yield put(showAuthMessage(res));
			  }
		  } catch (err) {
			  console.log(err.response);
			  yield put(showAuthMessage(err.response));
		  }
	  });
  }

  export function* getUserDetails() {
	yield takeEvery(GET_USER_DETAILS, function* ({payload}) {
		  try {
			const config = {
				headers: {
				  "Content-Type": "application/json",
				  "Access-Control-Allow-Origin": "*",
				  "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
				  Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
				  accountId: localStorage.getItem("accountId"),
				},
			  };
		  const res = yield  axios.get(`${API_BASE_URL}${MANAGE_PREPIX_PATH}${AUTH_PREFIX_PATH}/getUserDetails?username=${payload}`,config);
			  if (res.status===200) {
				  yield put(userData(res.data));
			  } 
		  } catch (err) {
			  console.log(err);
			  yield put(showAuthMessage(err));
		  }
	  });
  }
  export function* verifyEMail() {
	yield takeEvery(VERIFY_MAIL, function* ({payload}) {
		  const {username, generatedDate} = payload;
		  try {
			  const res = yield  axios.get(`${API_BASE_URL}${MANAGE_PREPIX_PATH}${AUTH_PREFIX_PATH}/verify?username=${username}&generatedDate=${generatedDate}`);
  
			  if (res.status===200) {
				sessionStorage.setItem("verifyMessage","Your email has been validated successfully, please login.");
				window.location.replace("/admin/auth/login");
				//yield put(verifySuccessSignIn(res));
			  }
		  } catch (error) {
			  console.log(error.response);
			  yield put(showAuthMessage(error.response));
		  }
	  }
	  );
  }


export function* signOut() {
  yield takeEvery(SIGNOUT, function* () {
	  const headers = {"Authorization" : `${localStorage.getItem(AUTH_TOKEN)}`,
	  "refreshToken" : `${localStorage.getItem("refresh_token")}`,
	  "username" : `${localStorage.getItem("username")}`}
		try {
			yield put(showLoading());
			const res = yield  axios.get(`${API_BASE_URL}${MANAGE_PREPIX_PATH}${AUTH_PREFIX_PATH}/adminLogout`,{headers});
			 if (res !== undefined) {
				yield put(signOutSuccess(res));
			 } 
			 localStorage.clear();
			 sessionStorage.clear();
			 window.location.replace(`${PATH_VARIABLE}${AUTH_PREFIX_PATH}/login`);
		} catch (err) {
			yield put(showAuthMessage(err.response));
			window.location.replace(`${PATH_VARIABLE}${AUTH_PREFIX_PATH}/login`);
		}
	});
}

export function* signOutExtremeb2b() {
	yield takeEvery(SIGNOUT_EXTREMEB2B, function* () {
		const headers = {"Authorization" : `${localStorage.getItem(AUTH_TOKEN)}`,
		"refreshToken" : `${localStorage.getItem("refresh_token")}`,
		"username" : `${localStorage.getItem("username")}`}
		  try {
			  yield put(showLoading());
			  const res = yield  axios.get(`${API_BASE_URL}${MANAGE_PREPIX_PATH}${AUTH_PREFIX_PATH}/logout`,{headers});
			   if (res !== undefined) {
				  yield put(signOutSuccess(res));
			   } 
			   localStorage.removeItem(AUTH_TOKEN);
			   localStorage.removeItem("selectedUser");
			   sessionStorage.clear();
			   window.location.replace(`${PATH_VARIABLE}${AUTH_PREFIX_PATH}/login`);
		  } catch (err) {
			  yield put(showAuthMessage(err.response));
			  window.location.replace(`${PATH_VARIABLE}${AUTH_PREFIX_PATH}/login`);
		  }
	  });
  }

export function* signUpWithFBEmail() {
  yield takeEvery(SIGNUP, function* ({payload}) {
		try {
			const res = yield  axios.post(`${API_BASE_URL}${MANAGE_PREPIX_PATH}${AUTH_PREFIX_PATH}/register?key=${payload?.key}`, payload?.values);

			if (res.status===200) {
				// if(payload.key){
				// 	const res = yield  axios.put(`${API_BASE_URL}${MANAGE_PREPIX_PATH}${AUTH_PREFIX_PATH}/add-account?key=${payload?.key}`);
				// }
				Modal.success({
					title: "User registered successfully",
					/// Please download sample xlsx file and try again.`,
					icon: <CheckCircleFilled/>,
					okText: "Close",
					//cancelText: 'Cancel',
					onOk() {
					},
					});
				yield put(signUpSuccess(res.data));
			}
		} catch (error) {
			yield put(showAuthMessage(error.response));
		}
	}
	);
}

export function* signUpWithGoogle() {
	yield takeEvery(SIGNUP_WITH_GOOGLE, function* ({payload}) {
		  try {
			  const user = yield call(FirebaseService.signInWithEmailAndPassword);
			  console.log("user",user);
			  if(user && user?.additionalUserInfo && user?.additionalUserInfo?.profile ){
				  const data = {"username":user.additionalUserInfo.profile.email,"firstName":user.additionalUserInfo.profile.name,
							  "ssoSystem":user.additionalUserInfo.providerId, "sso" : false,
							  "ssoUserId":user.additionalUserInfo.profile.id
							  }
				  if (user.additionalUserInfo.profile.verified_email) {
					  const res = yield  axios.post(`${API_BASE_URL}${MANAGE_PREPIX_PATH}${AUTH_PREFIX_PATH}/ssoRegister?key=${payload?.key}`, data);
						  if (res.status===200) {
							// if(payload.key){
							// 	const res = yield  axios.put(`${API_BASE_URL}${MANAGE_PREPIX_PATH}${AUTH_PREFIX_PATH}/add-account?key=${payload?.key}`);
							// }
							Modal.success({
								title: "User registered successfully",
								/// Please download sample xlsx file and try again.`,
								icon: <CheckCircleFilled/>,
								okText: "Close",
								//cancelText: 'Cancel',
								onOk() {
								},
								});
							yield put(signUpSuccess(res.data));
						}
					}
			      }
				} catch (error) {
						yield put(showAuthMessage(error.response));
					}
				}
				);
			}


export function* signUpWithMicroSoft() {
	yield takeEvery(SIGNUP_WITH_MICROSOFT, function* ({payload}) {
		  try {
			const data = {"username":payload?.user?.account?.username,"firstName":payload?.user?.account?.name,
							   "ssoSystem":payload?.user?.authority, "sso" : false,
							   "ssoUserId":payload?.user?.uniqueId
							}
				if (data?.username) {
			  const res = yield  axios.post(`${API_BASE_URL}${MANAGE_PREPIX_PATH}${AUTH_PREFIX_PATH}/ssoRegister?key=${payload?.key}`, data);
  
			  if (res.status===200) {
				//   if(payload.key){
				// 	  const res = yield  axios.put(`${API_BASE_URL}${MANAGE_PREPIX_PATH}${AUTH_PREFIX_PATH}/add-account?key=${payload?.key}`);
				//   }
				  Modal.success({
					  title: "User registered successfully",
					  /// Please download sample xlsx file and try again.`,
					  icon: <CheckCircleFilled/>,
					  okText: "Close",
					  //cancelText: 'Cancel',
					  onOk() {
					  },
					  });
				  yield put(signUpSuccess(res.data));
			  }
			}
		  } catch (error) {
			  yield put(showAuthMessage(error.response));
		  }
	  }
	  );
  }

  export   function* sinUpWithLinkedIn(){
	yield takeEvery(SIGNUP_WITH_LINKEDIN,function* ({payload}){
		 try {
			 let accessToken = null;
			 const qs = require('query-string');
			 const parameters = {
			   url :"https://www.linkedin.com/oauth/v2/accessToken",
			   grant_type: "authorization_code",
			   code: payload?.code,
			   redirect_uri: `${BASE_URL}${PATH_VARIABLE}/auth/linkedin`,
			   client_id: LINKEDIN_CLIENT_ID,
			   client_secret: LINKEDIN_CLIENT_SECRETE,
			 };
			 const config = {
				 headers: { "Content-Type": 'application/x-www-form-urlencoded',"Access-Control-Allow-Origin": "*",
				 "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS" }
			   };
			   const response = yield axios
			   .post(`${API_BASE_URL}${MANAGE_PREPIX_PATH}${AUTH_PREFIX_PATH}/getLinkedInSSOToken`,parameters,     { mode: "no-cors"},
				 config);
				 if (response.status===200) {
				 accessToken = response.data.accessToken;
			 const config = {
				 headers: {
				 Authorization: `Bearer ${accessToken}`,
				 }
			 }
			 const token = `oauth2_access_token=${accessToken}`;
			 const responseData = yield axios
				 .get(`${API_BASE_URL}${MANAGE_PREPIX_PATH}${AUTH_PREFIX_PATH}/getLinkedInSSOData?token=${token}`);
				 if (responseData.status===200) {
					 const email = responseData.data.elements[0]["handle~"].emailAddress;
				 console.log("email"+email);
				 const data = {"username":email,"firstName":email,
										 "ssoSystem":"LinkedIn.com", "sso" : false,
										 "ssoUserId":responseData.data.elements[0]["handle"].split(':')[3]
										 }
							 if (data.username) {
								const res = yield  axios.post(`${API_BASE_URL}${MANAGE_PREPIX_PATH}${AUTH_PREFIX_PATH}/ssoRegister?key=${payload?.key}`, data);
  
								if (res.status===200) {
									// if(payload.key){
									// 	const res = yield  axios.put(`${API_BASE_URL}${MANAGE_PREPIX_PATH}${AUTH_PREFIX_PATH}/add-account?key=${payload?.key}`);
									// }
									Modal.success({
										title: "User registered successfully",
										/// Please download sample xlsx file and try again.`,
										icon: <CheckCircleFilled/>,
										okText: "Close",
										//cancelText: 'Cancel',
										onOk() {
										},
										});
									yield put(signUpSuccess(res.data));
								}
							}
						}
				     }
					} catch (error) {
								yield put(showAuthMessage(error.response));
					}
         });
		}
				  
export function* updateUser() {
	yield takeEvery(UPDATE_USER, function* ({payload}) {
		  try {
			const config = {
				headers: {
				  "Content-Type": "application/json",
				  "Access-Control-Allow-Origin": "*",
				  "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
				  Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
				  accountId: localStorage.getItem("accountId"),
				  appInstanceId: localStorage.getItem("appInstanceId"),
				},
			  };
			  const res = yield  axios.post(`${API_BASE_URL}${MANAGE_PREPIX_PATH}${API}${VERSION}${USERS_PREPIX_PATH}/update-user`, payload,config);
  
			  if (res.status===200) {
				Modal.success({
					title: "User updated successfully",
					/// Please download sample xlsx file and try again.`,
					icon: <CheckCircleFilled/>,
					okText: "Close",
					//cancelText: 'Cancel',
					onOk() {
						window.location.assign(`${PATH_VARIABLE}/app/edit-profile`);
					},
					});
				 // yield put(signUpSuccess(res.data));
			  }
		  } catch (error) {
			  yield put(showAuthMessage(error.response));
		  }
	  }
	  );
  }

export function* signInWithSSOGoogle() {
  yield takeEvery(SIGNIN_WITH_GOOGLE, function* ({payload}) {
		try {
			const user = yield call(FirebaseService.signInWithEmailAndPassword);
			console.log("user",user);
			if(user && user?.additionalUserInfo && user?.additionalUserInfo?.profile ){
				const data = {"username":user.additionalUserInfo.profile.email,"firstName":user.additionalUserInfo.profile.name,
							"ssoSystem":user.additionalUserInfo.providerId, "sso" : false,
							"ssoUserId":user.additionalUserInfo.profile.id
							}
				if (user.additionalUserInfo.profile.verified_email) {
					const res = yield  axios.post(`${API_BASE_URL}${MANAGE_PREPIX_PATH}${AUTH_PREFIX_PATH}/ssoLogin`, data);
						if (res.status===200) {
							yield put(showLoading());
							// if(res.data.mfaCode && res.data.mfaCode!=null){
							// 	yield put(signUpSuccess(res.data));
							// }else 
							if(payload?.key && payload?.key!="null"){
								const res = yield  axios.put(`${API_BASE_URL}${MANAGE_PREPIX_PATH}${AUTH_PREFIX_PATH}/add-account?key=${payload.key}`);
							}
							if( res.data.token && res.data.token.accessToken && res.data.token.accessToken!=null){
								//localStorage.clear();
								yield put(showLoading());
								sessionStorage.clear();
								localStorage.setItem("businessName","");
								localStorage.setItem(AUTH_TOKEN, res.data.token.accessToken);
								localStorage.setItem("refresh_token",res.data.token.refreshToken);
								localStorage.setItem("userId",res.data.userId);
								localStorage.setItem("defaultAccountId",res.data.defaultAccountId);
								localStorage.setItem("username",res.data.username);
								localStorage.setItem('TOKEN_GEN_TIME', new Date());
								localStorage.setItem("profilePicture",res.data.profilePicture);
								console.log(res.data);
								const accountRequest = axios.get(`${API_BASE_URL}${MANAGE_PREPIX_PATH}${APP_PREFIX_PATH}/account?userId=${res.data.userId}`, {
									headers: {
									  'Authorization': `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
									  'Access-Control-Allow-Origin' : '*',
									  'Accept': 'application/json',
									  'Content-Type': 'application/json'
									}
								  });
									  const propertiesRequest = axios.get(
										`${API_BASE_DEVELOPER_APP}/developerApp/properties/admin-ui-properties?appName=AdminApp&query=&sortfield=undefined&sortorder=undefined`,
										{headers: {
											'Authorization': `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
											'Content-Type': 'application/json'
										}}
									  );
									const [response, propertiesResponse] = yield axios.all([accountRequest, propertiesRequest]);
								  if (propertiesResponse.status === 200) {
									yield put(showLoading());
									const enData = {};
									const frData = {};
									const spData = {};
									const jpData = {};
									propertiesResponse?.data?.map((uiDetails, index) => {
									  if (uiDetails.locale) {
										enData[`${uiDetails.propertyName}`] = uiDetails?.locale?.en;
										frData[`${uiDetails.propertyName}`] = uiDetails?.locale?.fr;
										spData[`${uiDetails.propertyName}`] = uiDetails?.locale?.sp;
										jpData[`${uiDetails.propertyName}`] = uiDetails?.locale?.jp;
									  }
									});
									localStorage.setItem("ENGLISH-DATA", JSON.stringify(enData));
									localStorage.setItem("FRENCH-DATA", JSON.stringify(frData));
									localStorage.setItem("SPANISH-DATA", JSON.stringify(spData));
									localStorage.setItem("JAPNESE-DATA", JSON.stringify(jpData));
							}
							 if (response.status===200) {
								const account = response.data;
								if(account&& account!=""){
									localStorage.setItem("accountId",account.id);
									localStorage.setItem("accountCode",account.accountCode);
									localStorage.setItem("disableSelection",account.disableSelection);
									localStorage.setItem("businessName",account.businessName);
									localStorage.setItem("defaultBusinessName",account.businessName);
									localStorage.setItem("accountUserId",account.accountUserId);
									localStorage.setItem("userType",account?.userType);
									//history.replace(`/app/${account.id}/my-apps`)
									yield put(showLoading());
									var appData = {};
									appData["username"] = localStorage.getItem("username");
									appData["accountId"] = localStorage.getItem("accountId");
									appData["accountUserId"] = localStorage.getItem("accountUserId");
									var code = Date.now();
									const appAuthResponse =yield axios.post(
										`${API_BASE_URL}${MANAGE_PREPIX_PATH}${API}${VERSION}${USERS_PREPIX_PATH}/appLoginAuthentication?code=${code}`,
										appData,
										{
											headers: {
												'Authorization': `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
												'Access-Control-Allow-Origin' : '*',
												'Accept': 'application/json',
												'Content-Type': 'application/json'
											  }
										}
									);
									if (appAuthResponse.status === 200) {
										//localStorage.setItem(AUTH_TOKEN, appAuthResponse.data.accessToken);
										localStorage.setItem("accountId", appAuthResponse.data.accountId);
									}
									const roleRequest = yield axios.get(`${API_BASE_DEVELOPER_APP}/developerApp/userRolesPermissionList?accountUserId=${account?.accountUserId}&appName=AdminApp&accountId=${account?.id}`, {
										headers: {
										'Authorization': `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
										'Access-Control-Allow-Origin' : '*',
										'Accept': 'application/json',
										'Content-Type': 'application/json'
										}
									});
									const [roleResponse] = yield axios.all([roleRequest]);
									if (roleResponse?.status===200) {
										localStorage.setItem("permissions", roleResponse?.data?.appUserRolesPermissions?JSON.stringify(roleResponse?.data?.appUserRolesPermissions):"");
									}
									//if(roleResponse?.data?.appUserRolesPermissions?.allpermissions || roleResponse?.data?.appUserRolesPermissions?.view_myapps_apps){
										window.location.replace(`${PATH_VARIABLE}/app/${account.id}/my-apps`);   
									//   }else{
									// 	window.location.replace(`${PATH_VARIABLE}/app/${account.id}/users`); 
									//   }  
								}else{
									window.location.assign(`${PATH_VARIABLE}/app/pages/account-list?mode=view-all`);
									yield put(authenticated(res.data.token.accessToken));
								}
							}else{
								window.location.assign(`${PATH_VARIABLE}/app/pages/account-list?mode=view-all`);
								yield put(authenticated(res.data.token.accessToken));
							}
						 // }
							
							}else{
								//localStorage.clear();
								localStorage.setItem("username",res.data.username);
								localStorage.setItem("password",res.data.password);
								localStorage.setItem("userId",res.data.userId);
								localStorage.setItem("defaultAccountId",res.data.defaultAccountId);
								localStorage.setItem('TOKEN_GEN_TIME', new Date());
								console.log(res.data);
								yield put(signInSuccess(res.data.username));
							}
							}
				} else {
					//localStorage.setItem(AUTH_TOKEN, user.user.uid);
					yield put(signInWithGoogleAuthenticated(user.user.uid));
				}
		 }
		} catch (error) {
			console.log(error.response);
		  yield put(showAuthMessage(error.response));
		}
	});
}

export function* signInWithSSOMicroSoft() {
	yield takeEvery(SIGNIN_WITH_MICROSOFT, function* ({payload}) {
		  try {
				const data = {"username":payload?.user?.account?.username,"firstName":payload?.user?.account?.name,
							   "ssoSystem":payload?.user?.authority, "sso" : false,
							   "ssoUserId":payload?.user?.uniqueId
							}
				if (data?.username) {
					const res = yield  axios.post(`${API_BASE_URL}${MANAGE_PREPIX_PATH}${AUTH_PREFIX_PATH}/ssoLogin`, data);
						if (res.status===200) {
							// if(res.data.mfaCode && res.data.mfaCode!=null){
							 	yield put(showLoading());
							// }else 
							if(payload?.key && payload?.key!="null"){
								const accountResponse= yield  axios.put(`${API_BASE_URL}${MANAGE_PREPIX_PATH}${AUTH_PREFIX_PATH}/add-account?key=${payload.key}`);
							}
							if(res?.data?.token &&res?.data?.token?.accessToken && res?.data?.token?.accessToken!=null){
								//localStorage.clear();
								sessionStorage.clear();
								localStorage.setItem("businessName","");
								localStorage.setItem(AUTH_TOKEN, res.data.token.accessToken);
								localStorage.setItem("refresh_token",res.data.token.refreshToken);
								localStorage.setItem("userId",res.data.userId);
								localStorage.setItem("username",res.data.username);
								localStorage.setItem("defaultAccountId",res.data.defaultAccountId);
								localStorage.setItem('TOKEN_GEN_TIME', new Date());
								localStorage.setItem("profilePicture",res.data.profilePicture);
								const accountRequest = axios.get(`${API_BASE_URL}${MANAGE_PREPIX_PATH}${APP_PREFIX_PATH}/account?userId=${res.data.userId}`, {
									headers: {
									  'Authorization': `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
									  'Access-Control-Allow-Origin' : '*',
									  'Accept': 'application/json',
									  'Content-Type': 'application/json'
									}
								  });
									  const propertiesRequest = axios.get(
										`${API_BASE_DEVELOPER_APP}/developerApp/properties/admin-ui-properties?appName=AdminApp&query=&sortfield=undefined&sortorder=undefined`,
										{headers: {
											'Authorization': `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
											'Content-Type': 'application/json'
										}}
									  );
									const [response, propertiesResponse] = yield axios.all([accountRequest, propertiesRequest]);
								  if (propertiesResponse.status === 200) {
									const enData = {};
									const frData = {};
									const spData = {};
									const jpData = {};
									propertiesResponse?.data?.map((uiDetails, index) => {
									  if (uiDetails.locale) {
										enData[`${uiDetails.propertyName}`] = uiDetails?.locale?.en;
										frData[`${uiDetails.propertyName}`] = uiDetails?.locale?.fr;
										spData[`${uiDetails.propertyName}`] = uiDetails?.locale?.sp;
										jpData[`${uiDetails.propertyName}`] = uiDetails?.locale?.jp;
									  }
									});
									localStorage.setItem("ENGLISH-DATA", JSON.stringify(enData));
									localStorage.setItem("FRENCH-DATA", JSON.stringify(frData));
									localStorage.setItem("SPANISH-DATA", JSON.stringify(spData));
									localStorage.setItem("JAPNESE-DATA", JSON.stringify(jpData));
								}
								 if (response.status===200) {
									const account = response.data;
									if(account&& account!=""){
										yield put(showLoading());
										localStorage.setItem("accountId",account.id);
										localStorage.setItem("accountCode",account.accountCode);
										localStorage.setItem("disableSelection",account.disableSelection);
										localStorage.setItem("businessName",account.businessName);
										localStorage.setItem("defaultBusinessName",account.businessName);
										localStorage.setItem("accountUserId",account.accountUserId);
										localStorage.setItem("userType",account?.userType);
										//history.replace(`/app/${account.id}/my-apps`)
										var appData = {};
										appData["username"] = localStorage.getItem("username");
										appData["accountId"] = localStorage.getItem("accountId");
										appData["accountUserId"] = localStorage.getItem("accountUserId");
										var code = Date.now();
										const appAuthResponse =yield axios.post(
											`${API_BASE_URL}${MANAGE_PREPIX_PATH}${API}${VERSION}${USERS_PREPIX_PATH}/appLoginAuthentication?code=${code}`,
											appData,
											{
												headers: {
													'Authorization': `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
													'Access-Control-Allow-Origin' : '*',
													'Accept': 'application/json',
													'Content-Type': 'application/json'
												  }
											}
										);
										if (appAuthResponse.status === 200) {
										//	localStorage.setItem(AUTH_TOKEN, appAuthResponse.data.accessToken);
											localStorage.setItem("accountId", appAuthResponse.data.accountId);
										}
										const roleRequest = yield axios.get(`${API_BASE_DEVELOPER_APP}/developerApp/userRolesPermissionList?accountUserId=${account?.accountUserId}&appName=AdminApp&accountId=${account?.id}`, {
											headers: {
											'Authorization': `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
											'Access-Control-Allow-Origin' : '*',
											'Accept': 'application/json',
											'Content-Type': 'application/json'
											}
										});
										const [roleResponse] = yield axios.all([roleRequest]);
										if (roleResponse?.status===200) {
											localStorage.setItem("permissions", roleResponse?.data?.appUserRolesPermissions?JSON.stringify(roleResponse?.data?.appUserRolesPermissions):"");
										}
										//if(roleResponse?.data?.appUserRolesPermissions?.allpermissions || roleResponse?.data?.appUserRolesPermissions?.view_myapps_apps){
											window.location.replace(`${PATH_VARIABLE}/app/${account.id}/my-apps`);   
										//   }else{
										// 	window.location.replace(`${PATH_VARIABLE}/app/${account.id}/users`); 
										//   } 
									}else{
										window.location.assign(`${PATH_VARIABLE}/app/pages/account-list?mode=view-all`);
							        	yield put(authenticated(res.data.token.accessToken));
									}
								  }else{
									window.location.assign(`${PATH_VARIABLE}/app/pages/account-list?mode=view-all`);
								    yield put(authenticated(res.data.token.accessToken));
								  }
							// }
							//}
							
							}else{
								//localStorage.clear();
								localStorage.setItem("username",res.data.username);
								localStorage.setItem("password",res.data.password);
								localStorage.setItem("userId",res.data.userId);
								localStorage.setItem("defaultAccountId",res.data.defaultAccountId);
								localStorage.setItem('TOKEN_GEN_TIME', new Date());
								console.log(res.data);
								yield put(signInSuccess(res.data.username));
							}

							}
				} 
			
		  } catch (error) {
			  console.log(error.response);
			yield put(showAuthMessage(error.response));
		  }
	  });
  }

export   function* fetchLinkedInUserDetails(){
   yield takeEvery(SIGNIN_WITH_LINKEDIN,function* ({payload}){
        try {
			let accessToken = null;
			const qs = require('query-string');
			const parameters = {
			  url :"https://www.linkedin.com/oauth/v2/accessToken",
			  grant_type: "authorization_code",
			  code: payload?.code,
			  redirect_uri: `${BASE_URL}${PATH_VARIABLE}/auth/linkedin`,
			  client_id: LINKEDIN_CLIENT_ID,
			  client_secret: LINKEDIN_CLIENT_SECRETE,
			};
			const config = {
				headers: { "Content-Type": 'application/x-www-form-urlencoded',"Access-Control-Allow-Origin": "*",
				"Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS" }
			  };
			  const response = yield axios
			  .post(`${API_BASE_URL}${MANAGE_PREPIX_PATH}${AUTH_PREFIX_PATH}/getLinkedInSSOToken`,parameters,     { mode: "no-cors"},
				config);
				if (response.status===200) {
					yield put(showLoading());
				accessToken = response.data.accessToken;
			const config = {
				headers: {
				Authorization: `Bearer ${accessToken}`,
				}
			}
			const token = `oauth2_access_token=${accessToken}`;
			// const responseLinkedINData = yield  axios
			// .get(`https://api.linkedin.com/v2/me?${token}&projection=(id,localizedFirstName,localizedLastName,profilePicture(displayImage~digitalmediaAsset:playableStreams))`,{mode: 'no-cors'}, config);
			// if (responseLinkedINData.status===200) {	
			//   userProfile.firstName = response.data["localizedFirstName"];
			//   userProfile.lastName = responseLinkedINData.data["localizedLastName"];
			//   userProfile.profileImageURL = responseLinkedINData.data.profilePicture["displayImage~"].elements[0].identifiers[0].identifier;
			// }
			const responseData = yield axios
				.get(`${API_BASE_URL}${MANAGE_PREPIX_PATH}${AUTH_PREFIX_PATH}/getLinkedInSSOData?token=${token}`);
				if (responseData.status===200) {
					yield put(showLoading());
					const email = responseData.data.elements[0]["handle~"].emailAddress;
				console.log("email"+email);
				const data = {"username":email,"firstName":email,
										"ssoSystem":"LinkedIn.com", "sso" : false,
										"ssoUserId":responseData.data.elements[0]["handle"].split(':')[3]
										}
							if (data.username) {
								const res = yield axios.post(`${API_BASE_URL}${MANAGE_PREPIX_PATH}${AUTH_PREFIX_PATH}/ssoLogin`, data);
								// if(res.data.mfaCode && res.data.mfaCode!=null){
								// 	yield put(signUpSuccess(res.data));
								// }else
								yield put(showLoading());
								if(payload?.key && payload?.key!="null"){
									const accountResponse = yield  axios.put(`${API_BASE_URL}${MANAGE_PREPIX_PATH}${AUTH_PREFIX_PATH}/add-account?key=${payload.key}`);
								}
								 if(res.data.token && res.data.token.accessToken && res.data.token.accessToken!=null){
									//localStorage.clear();
									sessionStorage.clear();
									localStorage.setItem("businessName","");
									localStorage.setItem(AUTH_TOKEN, res.data.token.accessToken);
									localStorage.setItem("refresh_token",res.data.token.refreshToken);
									localStorage.setItem("userId",res.data.userId);
									localStorage.setItem("username",res.data.username);
									localStorage.setItem("defaultAccountId",res.data.defaultAccountId);
									localStorage.setItem('TOKEN_GEN_TIME', new Date());
									localStorage.setItem("profilePicture",res.data.profilePicture);
									console.log(res.data);
									const accountRequest = axios.get(`${API_BASE_URL}${MANAGE_PREPIX_PATH}${APP_PREFIX_PATH}/account?userId=${res.data.userId}`, {
										headers: {
										  'Authorization': `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
										  'Access-Control-Allow-Origin' : '*',
										  'Accept': 'application/json',
										  'Content-Type': 'application/json'
										}
									  });
										  const propertiesRequest = axios.get(
											`${API_BASE_DEVELOPER_APP}/developerApp/properties/admin-ui-properties?appName=AdminApp&query=&sortfield=undefined&sortorder=undefined`,
											{headers: {
												'Authorization': `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
												'Content-Type': 'application/json'
											}}
										  );
										const [response, propertiesResponse] = yield axios.all([accountRequest, propertiesRequest]);
									  if (propertiesResponse.status === 200) {
										const enData = {};
										const frData = {};
										const spData = {};
										const jpData = {};
										propertiesResponse?.data?.map((uiDetails, index) => {
										  if (uiDetails.locale) {
											enData[`${uiDetails.propertyName}`] = uiDetails?.locale?.en;
											frData[`${uiDetails.propertyName}`] = uiDetails?.locale?.fr;
											spData[`${uiDetails.propertyName}`] = uiDetails?.locale?.sp;
											jpData[`${uiDetails.propertyName}`] = uiDetails?.locale?.jp;
										  }
										});
										localStorage.setItem("ENGLISH-DATA", JSON.stringify(enData));
										localStorage.setItem("FRENCH-DATA", JSON.stringify(frData));
										localStorage.setItem("SPANISH-DATA", JSON.stringify(spData));
										localStorage.setItem("JAPNESE-DATA", JSON.stringify(jpData));
									}
									 if (response.status===200) {
										const account = response.data;
										if(account&& account!=""){
											localStorage.setItem("accountId",account.id);
											localStorage.setItem("accountCode",account.accountCode);
											localStorage.setItem("disableSelection",account.disableSelection);
											localStorage.setItem("businessName",account.businessName);
											localStorage.setItem("defaultBusinessName",account.businessName);
											localStorage.setItem("accountUserId",account.accountUserId);
											localStorage.setItem("userType",account?.userType);
											//history.replace(`/app/${account.id}/my-apps`)
											yield put(showLoading());
											var appData = {};
											appData["username"] = localStorage.getItem("username");
											appData["accountId"] = localStorage.getItem("accountId");
											appData["accountUserId"] = localStorage.getItem("accountUserId");
											var code = Date.now();
											const appAuthResponse =yield axios.post(
												`${API_BASE_URL}${MANAGE_PREPIX_PATH}${API}${VERSION}${USERS_PREPIX_PATH}/appLoginAuthentication?code=${code}`,
												appData,
												{
													headers: {
														'Authorization': `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
														'Access-Control-Allow-Origin' : '*',
														'Accept': 'application/json',
														'Content-Type': 'application/json'
													  }
												}
											);
											if (appAuthResponse.status === 200) {
												localStorage.setItem(AUTH_TOKEN, appAuthResponse.data.accessToken);
												localStorage.setItem("accountId", appAuthResponse.data.accountId);
											}
											const roleRequest = yield axios.get(`${API_BASE_DEVELOPER_APP}/developerApp/userRolesPermissionList?accountUserId=${account?.accountUserId}&appName=AdminApp&accountId=${account?.id}`, {
												headers: {
												'Authorization': `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
												'Access-Control-Allow-Origin' : '*',
												'Accept': 'application/json',
												'Content-Type': 'application/json'
												}
											});
											const [roleResponse] = yield axios.all([roleRequest]);
											if (roleResponse?.status===200) {
												localStorage.setItem("permissions", roleResponse?.data?.appUserRolesPermissions?JSON.stringify(roleResponse?.data?.appUserRolesPermissions):"");
											}
											//if(roleResponse?.data?.appUserRolesPermissions?.allpermissions || roleResponse?.data?.appUserRolesPermissions?.view_myapps_apps){
												window.location.replace(`${PATH_VARIABLE}/app/${account.id}/my-apps`);   
											//   }else{
											// 	window.location.replace(`${PATH_VARIABLE}/app/${account.id}/users`); 
											//   }  
										}else{
											window.location.assign(`${PATH_VARIABLE}/app/pages/account-list?mode=view-all`);
								            yield put(authenticated(res.data.token.accessToken));
										}
									}else{
										window.location.href(`${PATH_VARIABLE}${APP_PREFIX_PATH}/pages/account-list?mode=view-all`)
										yield put(authenticated(res.data.token.accessToken));
									}
								// }
							//	}
								}else{
									//localStorage.clear();
									localStorage.setItem("username",res.data.username);
									localStorage.setItem("password",res.data.password);
									localStorage.setItem("userId",res.data.userId);
									localStorage.setItem("defaultAccountId",res.data.defaultAccountId);
									localStorage.setItem('TOKEN_GEN_TIME', new Date());
									console.log(res.data);
									yield put(signInSuccess(res.data.username));
								}
	
							} 
						}

		
						 
			
}
		} catch (error) {
			console.log("Error getting LinkedIn access token"+error.response);
			yield put(showAuthMessage(error.response));
		}
   });
}

export function* signInWithFacebook() {
  yield takeEvery(SIGNIN_WITH_FACEBOOK, function* () {
		try {
			const user = yield call(FirebaseService.signInFacebookRequest);
			if (user.message) {
				yield put(showAuthMessage(user.message));
			} else {
				localStorage.setItem(AUTH_TOKEN, user.user.uid);
				yield put(signInWithFacebookAuthenticated(user.user.uid));
			}
		} catch (error) {
			yield put(showAuthMessage(error.response));
		}
	});
}

export function* getEmailID(){
    yield takeEvery (EMAIL_ID_CHECK, function*  ({payload}){
		try {
		const res = yield  axios.get(`${API_BASE_URL}${MANAGE_PREPIX_PATH}${AUTH_PREFIX_PATH}/emailCheck?username=${payload}`);
			if (res.status===200) {
				yield put(checkEmailMessage(true));
			}
		} catch (err) {
			console.log(err.response);
			yield put(checkEmailMessage(false));
		}
    }

    )
}

export function* getPasswordValidate(){
    yield takeEvery (PASS_VALIDATE, function*  ({payload}){
		try {
		const res = yield  axios.post(`${API_BASE_URL}${MANAGE_PREPIX_PATH}${AUTH_PREFIX_PATH}/passwordValidate`,payload);
			if (res.status===200) {
				yield put(checkpasswordValidate(res.data));
			} 
		} catch (err) {
			console.log(err.response);
			yield put(checkpasswordValidate(err.response));
		}
    }

    )
}

export function* resetPasswordEMail(){
	yield takeEvery (RESET_PASSWORD_MAIL, function* ({payload}){
		try{
			const res = yield  axios.post(`${API_BASE_URL}${MANAGE_PREPIX_PATH}${AUTH_PREFIX_PATH}/resetPasswordMail`, payload);
			  if (res.status===200) {
				  yield put(showAuthMessage(res));
			  } else {
				  yield put(showAuthMessage(res));
			  }
		} catch(err){
			console.log(err.response);
			yield put(showAuthMessage(err.response));

		}
	});
}

export function* resetPassword(){
	yield takeEvery (RESET_PASSWORD, function* ({payload}){
		try {
			const res = yield  axios.post(`${API_BASE_URL}${MANAGE_PREPIX_PATH}${AUTH_PREFIX_PATH}/resetPassword`, payload);

			if (res.status===200) {
				 sessionStorage.setItem("passwordUpdateMessage","Password updated, Please login with new password.");
				 window.location.replace("/admin/auth/login");
				 yield put(showAuthMessage(res));
			}
		} catch (error) {
			console.log(error.response);
			yield put(showAuthMessage(error.response));
		}

	});
}

export function* changePassword(){
	yield takeEvery (CHANEGE_PASSWORD, function* ({payload}){
		try {
			const config = {
				headers: {
				  "Content-Type": "application/json",
				  "Access-Control-Allow-Origin": "*",
				  "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
				  Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
				  accountId: localStorage.getItem("accountId"),
				},
			  };
			const res = yield  axios.post(`${API_BASE_URL}${MANAGE_PREPIX_PATH}${API}${VERSION}${USERS_PREPIX_PATH}/changePassword`, payload,config);

			if (res.status===200) {
				sessionStorage.setItem("verifyMessage","Password update successfully, please login with new password.");
				window.location.replace("/admin/auth/login");
				 yield put(showAuthMessage(res));
			}
		} catch (error) {
			console.log(error.response);
			yield put(showAuthMessage(error.response));
		}

	});
}

export function* getMfaSetup(){
	yield takeEvery(GET_MFA_SETUP, function* ({username}){
		const config = {
			headers: {
			  "Content-Type": "application/json",
			  "Access-Control-Allow-Origin": "*",
			  "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
			  Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
			  accountId: localStorage.getItem("accountId"),
			  appInstanceId: localStorage.getItem("appInstanceId"),
			},
		  };
		try {
			const res = yield  axios.get(`${API_BASE_URL}${MANAGE_PREPIX_PATH}${API}${VERSION}${USERS_PREPIX_PATH}/mfasetup?username=${username}`,config);
			if (res.status===200) {
				 yield put(signUpSuccess(res.data));
			}
		} catch (error) {
			console.log(error.response);
			yield put(showAuthMessage(error.response));
		}
	}
	);
}

export function* onUsing2fa(){
	yield takeEvery(USING_2FA,function* ({using2fa}){
		const username =localStorage.getItem("username");
		const config = {
			headers: {
			  "Content-Type": "application/json",
			  "Access-Control-Allow-Origin": "*",
			  "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
			  Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
			  accountId: localStorage.getItem("accountId"),
			  appInstanceId: localStorage.getItem("appInstanceId"),
			},
		  };
		try {
			const res = yield  axios.get(`${API_BASE_URL}${MANAGE_PREPIX_PATH}${API}${VERSION}${USERS_PREPIX_PATH}/using2fa?using2fa=${using2fa}&username=${username}`,config);
			if (res.status===200) {
				 yield put(toggle2fa(using2fa));
			}
		} catch (error) {
			console.log(error.response);
			yield put(showAuthMessage(error.response));
		}
	}
	);
}

export default function* rootSaga() {
  yield all([
		fork(signInWithFBEmail),
		fork(signOut),
		fork(signOutExtremeb2b),
		fork(signUpWithFBEmail),
		fork(signInWithSSOGoogle),
		fork(signInWithFacebook),
		fork(sendVerificationEmail),
		fork(verifyEMail),
		fork(getEmailID),
		fork(resetPasswordEMail),
		fork(resetPassword),
		fork(signInWithTOTP),
		fork(getPasswordValidate),
		fork(signInWithSSOMicroSoft),
		fork(fetchLinkedInUserDetails),
		fork(getMfaSetup),
		fork(changePassword),
		fork(onUsing2fa),
		fork(tokenCheck),
		fork(getUserDetails),
		fork(updateUser),
		fork(signUpWithMicroSoft),
		fork(signUpWithGoogle),
		fork(sinUpWithLinkedIn)
  ]);
}
