import React, { lazy, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Loading from 'components/shared-components/Loading';
import { APP_PREFIX_PATH,AUTH_PREFIX_PATH } from 'configs/AppConfig'

export const AppViews = () => {
  const permissions  = localStorage.getItem("permissions")!=""?JSON.parse(localStorage.getItem("permissions")):"";
  const page404 =<Redirect to={`${AUTH_PREFIX_PATH}/404`} />;
  return (
    <Suspense fallback={<Loading cover="content"/>}>
      <Switch>
        <Route path={`${APP_PREFIX_PATH}/dashboards`} component={lazy(() => import(`./dashboards`))} />
        <Route path={`${APP_PREFIX_PATH}/apps`} component={lazy(() => import(`./apps`))} />
        <Route path={`${APP_PREFIX_PATH}/components`} component={lazy(() => import(`./components`))} />
        <Route path={`${APP_PREFIX_PATH}/pages`} component={lazy(() => import(`./pages`))} />
        <Route path={`${APP_PREFIX_PATH}/maps`} component={lazy(() => import(`./maps`))} />
        <Route path={`${APP_PREFIX_PATH}/charts`} component={lazy(() => import(`./charts`))} />
        <Route path={`${APP_PREFIX_PATH}/docs`} component={lazy(() => import(`./docs`))} />
        <Route path={`${APP_PREFIX_PATH}/:accountId/market-place`} component={lazy(() => import(`./market-place/market-place`))} />
        <Route path={`${APP_PREFIX_PATH}/data-migration`} component={lazy(() => import(`./data-migration`))} />
        <Route path={`${APP_PREFIX_PATH}/data-export`} component={lazy(() => import(`./data-export`))} />
        <Route path={`${APP_PREFIX_PATH}/:accountId/developer-app`} component={lazy(() => import(`./pages/apps/apps`))} />
        <Route path={`${APP_PREFIX_PATH}/:accountId/my-apps`} component={lazy(() => import(`./my-apps`))} />
        <Route path={`${APP_PREFIX_PATH}/two-factor`} component={lazy(() => import(`./two-factor`))} />
        <Route path={`${APP_PREFIX_PATH}/change-password`} component={lazy(() => import(`./change-password`))} />
        <Route path={`${APP_PREFIX_PATH}/edit-profile`} component={lazy(() => import(`./edit-profile`))} />
        <Route path={`${APP_PREFIX_PATH}/:accountId/users`} component={lazy(() => import(`./pages/account-user/account-user-list`))} />
        <Route path={`${APP_PREFIX_PATH}/:accountId/:appId/:appInstanceId/settings`} component={lazy(() => import(`./market-place/app-instance-settings`))} />
        <Route path={`${APP_PREFIX_PATH}/:accountId/:appId/detialPage`} component={lazy(() => import(`./my-app`))} />
        <Route path={`${APP_PREFIX_PATH}/:accountId/:appId/:appInstanceId/detialPage`} component={lazy(() => import(`./my-app`))} />
        <Route path={`${APP_PREFIX_PATH}/api-documents`} component={lazy(() => import(`./swagger/Swagger`))} />
        <Route path={`${APP_PREFIX_PATH}/picklixlink`} component={lazy(() => import(`./swagger/pikclix`))} />
        <Route path={`${APP_PREFIX_PATH}/extremeb2blink`} component={lazy(() => import(`./swagger/exterme`))} />
        <Route path={`${APP_PREFIX_PATH}/developerapplink`} component={lazy(() => import(`./swagger/developer`))} />
        <Route path={`${APP_PREFIX_PATH}/helppage`} component={lazy(() => import(`./swagger/Help`))} />
        <Route path="*"><Redirect to={`${AUTH_PREFIX_PATH}/404`} /></Route>
        {localStorage.getItem("token")?<Route path={`${APP_PREFIX_PATH}/pages/account-list`} component={lazy(() => import(`./pages/accounts/account-list`))} />:<Redirect from={`${APP_PREFIX_PATH}`} to={`${AUTH_PREFIX_PATH}/login`} />}
      </Switch>
    </Suspense>
  )
}

export default React.memo(AppViews);
