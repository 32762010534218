const dev = {
	API_ENDPOINT_URL: 'http://localhost:8080',
	API_ENDPOINT_DEVELOPERAPP_URL: 'http://localhost:8083',
	API_ENDPOINT_PIKCLIX_URL:'http://localhost:8081',
    API_ENDPOINT_UI_URL: 'http://localhost:3000',
	CONNECTOR_BASE_URL: 'http://localhost:8782',
	API_ENDPOINT_DATA_MIGRATION : 'http://localhost:8784',
	API_ENDPOINT_BLOG_STORAGE_URL: 'http://localhost:8085',
	API_ENDPOINT_DATA_EXPORT : 'http://localhost:8090'
};

const stage = {
	API_ENDPOINT_URL: 'https://api.extremeb2b.com',
	API_ENDPOINT_DEVELOPERAPP_URL: 'https://api.extremeb2b.com',
	API_ENDPOINT_UI_URL: 'https://console.extremeb2b.com',
    API_ENDPOINT_PIKCLIX_URL:'https://api.extremeb2b.com',
	API_ENDPOINT_DATA_MIGRATION: 'https://api.extremeb2b.com',
	API_ENDPOINT_BLOG_STORAGE_URL: 'https://api.extremeb2b.com',
	CONNECTOR_BASE_URL: 'https://api.extremeb2b.com',
	API_ENDPOINT_DATA_EXPORT : 'https://api.extremeb2b.com'
  };

const prod = {
  API_ENDPOINT_URL: 'https://api.extremeb2b.com',
  API_ENDPOINT_DEVELOPERAPP_URL: 'https://api.extremeb2b.com',
  API_ENDPOINT_UI_URL: 'https://console.extremeb2b.com',
  API_ENDPOINT_PIKCLIX_URL:'https://api.extremeb2b.com',
  API_ENDPOINT_DATA_MIGRATION: 'https://api.extremeb2b.com',
  API_ENDPOINT_BLOG_STORAGE_URL: 'https://api.extremeb2b.com',
  CONNECTOR_BASE_URL: 'https://api.extremeb2b.com',
  API_ENDPOINT_DATA_EXPORT : 'https://api.extremeb2b.com'
};

const test = {
	API_ENDPOINT_URL: 'http://localhost:8080',
	API_ENDPOINT_DEVELOPERAPP_URL: 'http://localhost:8083',
	API_ENDPOINT_PIKCLIX_URL:'http://localhost:8081',
    API_ENDPOINT_UI_URL: 'http://localhost:3000',
	CONNECTOR_BASE_URL: 'http://localhost:8083',
	API_ENDPOINT_DATA_MIGRATION: 'http://localhost:8784',
	API_ENDPOINT_BLOG_STORAGE_URL: 'http://localhost:8085',
	API_ENDPOINT_DATA_EXPORT : 'http://localhost:8090'
};

const getEnv = () => {
	switch (process.env.NODE_ENV) {
		case 'development':
			return dev
		case 'stage':
			return stage
		case 'production':
			return prod
		case 'test':
			return test
		default:
			break;
	}
}

export const env = getEnv()
