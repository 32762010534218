export const DATA_EXPORT_TABLE_LIST="DATA_EXPORT_TABLE_LIST";
export const GET_DATA_EXPORT_TABLE_LIST="GET_DATA_EXPORT_TABLE_LIST";
export const SHOW_LOADING="SHOW_LOADING";
export const HIDE_LOADING="HIDE_LOADING";
export const DATA_EXPORT_SCHEMA_LIST="DATA_EXPORT_SCHEMA_LIST";
export const GET_DATA_EXPORT_SCHEMA_LIST="GET_DATA_EXPORT_SCHEMA_LIST"
export const VIEW_DATA="VIEW_DATA";
export const GET_VIEW_DATA="GET_VIEW_DATA"
export const EXPORT_DATA = "EXPORT_DATA";
export const GET_DATA_EXPORT_LIST="GET_DATA_EXPORT_LIST";
export const DATA_EXPORT_LIST="DATA_EXPORT_LIST";
export const ADD_DATA_EXPORT="ADD_DATA_EXPORT";
export const UPDATE_DATA_EXORT="UPDATE_DATA_EXORT";
export const DATA_EXPORT_TABLE_VIEW_LIST="DATA_EXPORT_TABLE_VIEW_LIST";
export const GET_DATA_EXPORT_TABLE_VIEW_LIST="GET_DATA_EXPORT_TABLE_VIEW_LIST";
export const SAVE_DATABASE_SUCCESS_MESSAGE="SAVE_DATABASE_SUCCESS_MESSAGE";
export const DELETE_DATA_EXPORT="DELETE_DATA_EXPORT";
export const DATA_MIGRATION_MESSAGE="DATA_MIGRATION_MESSAGE";
export const SET_ADD_EDIT = "SET_ADD_EDIT";
export const GET_TABLE_LIST="GET_TABLE_LIST";
export const TABLE_LIST="TABLE_LIST";
export const GET_COLUMN_LIST="GET_COLUMN_LIST";
export const COLUMN_LIST="COLUMN_LIST";
export const COPY_DATABASE_DATA="COPY_DATABASE_DATA";
export const COPY_BLOB_STORAGE_FILE = "COPY_BLOB_STORAGE_FILE";