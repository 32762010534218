import React, { useState } from "react";
import { Card, Collapse } from "antd";
import {
  Button,
  Checkbox,
  Input,
  Row,
  Col,
  Typography,
  Select,
  DatePicker,
  Switch,
  InputNumber,
  Form,
  Modal,
  Space,
} from "antd";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { CaretRightOutlined } from "@ant-design/icons";
import { debounce } from "lodash";
import { EditOutlined } from "@ant-design/icons";
const { Panel } = Collapse;
const typeOptions = [
  { value: "boolean", label: "Checkbox", type: "boolean", option: "boolean" },
  { value: "string", label: "String", type: "string", option: "string" },
  { value: "textArea", label: "Text Area", type: "textArea", option: "string" },
  { value: "number", label: "Number", type: "number", option: "number" },
  { value: "date", label: "Date-time", type: "date", option: "string" },
  { value: "multiple", label: "Dropdown", type: "multiple", option: "array" },
];
const AddEditFormField = ({
  fieldData,
  setFieldData,
  preDefValue,
  objectValue,
  setIsObjectDuplicate,
  isObjectDuplicate,
  objectList,
  setObjectList,
  position,
  formList,
}) => {
  const { Text } = Typography;
  const { TextArea } = Input;
  dayjs.extend(customParseFormat);
  const [formData, setFormData] = useState(preDefValue);
  const [objectName, setObjectName] = useState(objectValue);
  console.log("TEST DATA EDIT FIELD", fieldData, position);
  const handleObjectName = (event) => {
    setObjectName(event.target.value);
    const latestObjectList = [...objectList];
    latestObjectList[position] = {
      ...latestObjectList[position],
      [`${objectValue}`]: event.target.value,
    };
    setObjectList([...latestObjectList]);
    if (
      fieldData?.properties?.hasOwnProperty(`${event?.target?.value}`) &&
      event.target.value != objectValue
    ) {
      setIsObjectDuplicate(true);
    } else {
      setIsObjectDuplicate(false);
    }
  };
  const fetchType = (fetchData) => {
    let object = {};
    typeOptions.map((info, index) => {
      if (info.value == fetchData) {
        object = info;
      }
    });
    return object;
  };
  const handleFieldType = (value) => {
    const selObj = fetchType(value);
    let newObject = {};
    if (selObj.option == "array") {
      newObject = {
        ...formData,
        [`$componentType`]: selObj.type,
        [`type`]: "array",
        [`$options`]: [{ label: "option1", value: "opt-1" }],
        [`$defaultValue`]: ["opt-1"],
      };
    } else {
      newObject = {
        ...formData,
        [`$componentType`]: selObj.type,
        [`type`]: selObj.option,
      };
    }
    setFormData({ ...newObject });
    handleEditField({ ...newObject });
  };
  const handleFormData = (value, key) => {
    const newFormData = { ...formData, [`${key}`]: value };
    setFormData({ ...formData, [`${key}`]: value });
    handleEditField(newFormData);
  };
  const handleEditField = (newData) => {
    if (!isObjectDuplicate) {
      const newProperties = {
        ...fieldData.properties,
        [`${objectValue}`]: { ...newData, id: `${objectValue}` },
      };
      const formDataList = [...formList];
      formDataList[position] = {
        ...fieldData,
        properties: { ...newProperties },
      };
      setFieldData([...formDataList]);
    }
  };
  const handleDelete = (delValue) => {
    const newProperties = {
      ...fieldData.properties,
      [`${objectName}`]: formData,
    };
    delete newProperties[`${delValue}`];
    const formListData = [...formList];
    formListData[position] = { ...fieldData, properties: { ...newProperties } };
    setFieldData([...formListData]);
  };
  const [showMoreSettings, setShowMoreSettings] = useState(false);
  const closeShowMoreSettings = () => {
    setShowMoreSettings(false);
  };
  const openShowMoreSettings = () => {
    setShowMoreSettings(true);
  };
  return (
    <>
      <Collapse
        bordered={true}
        expandIcon={({ isActive }) => (
          <CaretRightOutlined rotate={isActive ? 90 : 0} />
        )}
        className="site-collapse-custom-collapse"
      >
        <Panel
          header={formData?.title}
          key={objectValue}
          className="site-collapse-custom-panel"
        >
          <Form>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <Row gutter={16}>
                <Col className="gutter-row" span={12}>
                  <Text strong>Display Name </Text>
                  <Input
                    onChange={(event) =>
                      handleFormData(event.target.value, "title")
                    }
                    defaultValue={formData?.title}
                  />
                </Col>
                <Col className="gutter-row" span={12}>
                  <Text strong>Object Name</Text>
                  <Form.Item
                    {...(isObjectDuplicate && { validateStatus: "error" })}
                    {...(isObjectDuplicate && {
                      help: "this Object value already used!",
                    })}
                  >
                    <Input value={objectName} onChange={handleObjectName} />
                  </Form.Item>
                </Col>
                <Col className="gutter-row" span={12}>
                  <Text strong>Description</Text>
                  <Input
                    onChange={(event) =>
                      handleFormData(event.target.value, "description")
                    }
                    defaultValue={formData?.description}
                  />
                </Col>
                <Col
                  className="gutter-row"
                  span={12}
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <Text strong>Input Type</Text>
                  <Select
                    onChange={(value) => handleFieldType(value)}
                    defaultValue={formData?.$componentType}
                    options={typeOptions}
                  />
                </Col>
                <Col className="gutter-row" span={24} style={{ marginTop: 10 }}>
                  {formData.type == "boolean" ? (
                    <Checkbox
                      onChange={(event) =>
                        handleFormData(event.target.checked, "$defaultValue")
                      }
                      defaultChecked={formData?.$defaultValue}
                    >
                      {formData.title}
                    </Checkbox>
                  ) : formData.type == "string" &&
                    formData?.$componentType == "string" ? (
                    <Input
                      onChange={(event) =>
                        handleFormData(event.target.value, "$defaultValue")
                      }
                      defaultValue={formData?.$defaultValue}
                    />
                  ) : formData.type == "string" &&
                    formData?.$componentType == "textArea" ? (
                    <TextArea
                      rows={4}
                      onChange={(event) =>
                        handleFormData(event.target.value, "$defaultValue")
                      }
                      defaultValue={formData?.$defaultValue}
                    />
                  ) : formData?.type == "number" &&
                    formData?.$componentType == "number" ? (
                    <InputNumber
                      defaultValue={formData?.$defaultValue}
                      onChange={(value) =>
                        handleFormData(value, "$defaultValue")
                      }
                    />
                  ) : formData?.type == "radio" ? (
                    <Switch
                      onChange={(value) =>
                        handleFormData(value, "$defaultValue")
                      }
                      defaultChecked={formData?.$defaultValue}
                    />
                  ) : formData?.type == "string" &&
                    formData?.$componentType == "date" ? (
                    <DatePicker
                      format="YYYY-MM-DD HH:mm:ss"
                      onChange={(date, dateString) =>
                        handleFormData(dateString, "$defaultValue")
                      }
                      showTime={{ defaultValue: dayjs("00:00:00", "HH:mm:ss") }}
                    />
                  ) : (
                    <Select
                      onChange={(value) =>
                        handleFormData(value, "$defaultValue")
                      }
                      options={formData?.$options}
                      style={{ width: "50%" }}
                      defaultValue={formData?.$defaultValue}
                    />
                  )}
                </Col>
              </Row>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div>
                  <Checkbox
                    onChange={(event) =>
                      handleFormData(event.target.checked, "$required")
                    }
                    style={{ paddingTop: 10 }}
                    defaultValue={formData?.$required}
                  >
                    {"Is Required"}
                  </Checkbox>
                  {/* <Button type="primary" icon={<EditOutlined />} size={"small"} onClick={openShowMoreSettings} > More </Button> */}
                </div>
                <div>
                  <Button
                    type="primary"
                    style={{ marginTop: 10 }}
                    onClick={() => handleDelete(objectValue)}
                    danger
                    ghost
                  >
                    Delete
                  </Button>
                </div>
              </div>
            </div>
          </Form>
        </Panel>
      </Collapse>
      <Modal
        title="More Settings"
        visible={showMoreSettings}
        footer={null}
        destroyOnClose={true}
        onCancel={closeShowMoreSettings}
      >
        <Space size={5}>
          <div>
            <Text strong>Placeholder : </Text>
            <Input
              onChange={(event) =>
                handleFormData(event.target.value, "$placeholder")
              }
              defaultValue={formData?.$placeholder}
            />
          </div>
          <div>
            <Text strong>Required Message: </Text>
            <Input
              onChange={(event) =>
                handleFormData(event.target.value, "$requiredMessage")
              }
              defaultValue={formData?.$requiredMessage}
            />
          </div>
        </Space>
        <Space size={5} style={{ marginTop: 10 }}>
          <div>
            <Text strong>Min Length</Text>
            <InputNumber
              defaultValue={formData?.minLength}
              onChange={(value) => handleFormData(value, "minLength")}
            />
          </div>
          <div>
            <Text strong>Min length Message</Text>
            <Input
              onChange={(event) =>
                handleFormData(event.target.value, "$minLengthMessage")
              }
              defaultValue={formData?.$minLengthMessage}
            />
          </div>
        </Space>
        <Space size={5} style={{ marginTop: 10 }}>
          <div>
            <Text strong>Max Length</Text>
            <InputNumber
              defaultValue={formData?.maxLength}
              onChange={(value) => handleFormData(value, "maxLength")}
            />
          </div>
          <div>
            <Text strong>Max length Message</Text>
            <Input
              onChange={(event) =>
                handleFormData(event.target.value, "$maxLengthMessage")
              }
              defaultValue={formData?.$maxLengthMessage}
            />
          </div>
        </Space>
      </Modal>
    </>
  );
};
export default React.memo(AddEditFormField);
