import { all, takeEvery, put, fork } from 'redux-saga/effects';

import axios from './initialize'
import { Modal } from 'antd';
import {CheckCircleFilled} from  '@ant-design/icons';

import {  GET_DATA_EXPORT_TABLE_LIST,SAVE_DATABASE, DELETE_DATABASE, UPDATE_DATABASE, GET_TABLE_LIST, GET_COLUMN_LIST, COPY_DATABASE_DATA, COPY_BLOB_STORAGE_FILE, GET_DATA_EXPORT_SCHEMA_LIST, GET_VIEW_DATA, EXPORT_DATA, DELETE_DATA_EXPORT, GET_DATA_EXPORT_LIST, ADD_DATA_EXPORT, UPDATE_DATA_EXORT, GET_DATA_EXPORT_TABLE_VIEW_LIST} from "redux/constants/dataExport";

import {  showDataMigrationMessage, dataExportTableList,viewData,columnList, schemaList, dataExportList, tableViewList } from "redux/actions/dataExport";
import { showUnAutherizeMessage} from "redux/actions/Auth";

import { API_ENDPOINT_DATA_MIGRATION, DATA_MIGRATION, VERSION, MANAGE_PREPIX_PATH, API_ENDPOINT_BLOG_STORAGE_URL, API_BASE_DEVELOPER_APP, API_ENDPOINT_DATA_EXPORT, DATA_EXPORT } from 'configs/AppConfig';
import {
	AUTH_TOKEN,
} from '../constants/Auth';
import { t } from "i18next";
import { saveAs } from 'file-saver';


export function* getDataExportTableList(){
    yield takeEvery (GET_DATA_EXPORT_TABLE_LIST, function*  ({requestData}){
	    const {query, sorter,pagination,appId,appInstanceId} = requestData;
const config = {
		headers: { "Content-Type": 'application/json',"Access-Control-Allow-Origin": "*",
		"Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",Authorization : `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
		accountUserId: localStorage.getItem("accountUserId") }
	  };
		try {
		const res = yield  axios.get(`${API_ENDPOINT_DATA_EXPORT}${DATA_EXPORT}${VERSION}/export-table?appId=${appId}&appInstanceId=${appInstanceId}&query=${query}&sortfield=${sorter?.field}&sortorder=${sorter?.order}&page=${pagination?.current-1}&size=${pagination?.pageSize}`,
		config);
			if (res.status===200) {
				yield put(dataExportTableList(res.data));
			} 
		} catch (err) {
			console.log(err);
			if(err?.response?.data?.status==401){
				yield put(showUnAutherizeMessage(err.response));
			}
			yield put(showDataMigrationMessage(err.response));
		}
    }

    )
}

export function* getDataViewTableList(){
    yield takeEvery (GET_DATA_EXPORT_TABLE_VIEW_LIST, function*  ({requestData}){
	    const {query, sorter,pagination,schemaName} = requestData;
const config = {
		headers: { "Content-Type": 'application/json',"Access-Control-Allow-Origin": "*",
		"Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",Authorization : `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
		accountUserId: localStorage.getItem("accountUserId") }
	  };
		try {
		const res = yield  axios.get(`${API_ENDPOINT_DATA_EXPORT}${DATA_EXPORT}${VERSION}/table?schemaName=${schemaName}`,
		config);
			if (res.status===200) {
				yield put(tableViewList(res.data));
			} 
		} catch (err) {
			console.log(err);
			if(err?.response?.data?.status==401){
				yield put(showUnAutherizeMessage(err.response));
			}
			yield put(showDataMigrationMessage(err.response));
		}
    }

    )
}

export function* getSchemaList(){
    yield takeEvery (GET_DATA_EXPORT_SCHEMA_LIST, function*  ({requestData}){
const config = {
		headers: { "Content-Type": 'application/json',"Access-Control-Allow-Origin": "*",
		"Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",Authorization : `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
		accountUserId: localStorage.getItem("accountUserId") }
	  };
		try {
		const res = yield  axios.get(`${API_ENDPOINT_DATA_EXPORT}${DATA_EXPORT}${VERSION}/schema`,
		config);
			if (res.status===200) {
				yield put(schemaList(res.data));
			} 
		} catch (err) {
			console.log(err);
			if(err?.response?.data?.status==401){
				yield put(showUnAutherizeMessage(err.response));
			}
			yield put(showDataMigrationMessage(err.response));
		}
    }

    )
}

export function* getViewData(){
    yield takeEvery (GET_VIEW_DATA, function*  ({payload}){
	    const {appInstanceId,schemaName,tableName,appId} = payload;
const config = {
		headers: { "Content-Type": 'application/json',"Access-Control-Allow-Origin": "*",
		"Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",Authorization : `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
		accountUserId: localStorage.getItem("accountUserId"),accountId: localStorage.getItem("accountId") }
	  };
		try {
		const res = yield  axios.get(`${API_ENDPOINT_DATA_EXPORT}${DATA_EXPORT}${VERSION}/viewdata?tableName=${tableName}&
        schemaName=${schemaName}&appInstanceId=${appInstanceId}&appId=${appId}`,
		config);
			if (res.status===200) {
				yield put(viewData(res.data));
			} 
		} catch (err) {
			console.log(err);
			if(err?.response?.data?.status==401){
				yield put(showUnAutherizeMessage(err.response));
			}
			yield put(showDataMigrationMessage(err.response));
		}
    }

    )
}


export function* getExportData(){
    yield takeEvery (EXPORT_DATA, function*  ({payload}){
	    const {appInstanceId,schemaName,tableName,appId} = payload;
const config = {
		headers: { "Content-Type": 'application/json',"Access-Control-Allow-Origin": "*",
		"Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",Authorization : `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
		accountUserId: localStorage.getItem("accountUserId"),
		responseType: 'blob',
	 }
	  };
		try {
		const res = yield  axios.get(`${API_ENDPOINT_DATA_EXPORT}${DATA_EXPORT}${VERSION}/send-to-rabbitmq-and-export?tableName=${tableName}&
		schemaName=${schemaName}&appInstanceId=${appInstanceId}&appId=${appId}`,
		config);
			if (res.status===200) {
				const arrayBuffer =  res.arrayBuffer();
				const blob = new Blob([arrayBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
	
				// Create a Blob and trigger the download
				const url = window.URL.createObjectURL(blob);
				const link = document.createElement('a');
				link.href = url;
				link.setAttribute('download', 'example.xlsx');
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);
			} 
		} catch (err) {
			console.log(err);
			if(err?.response?.data?.status==401){
				yield put(showUnAutherizeMessage(err.response));
			}
			yield put(showDataMigrationMessage(err.response));
		}
    }

    )
}
export function* getDataExportList(){
    yield takeEvery (GET_DATA_EXPORT_LIST, function*  ({requestData}){
	    const {query, sorter,pagination,appId,appInstanceId} = requestData;
const config = {
		headers: { "Content-Type": 'application/json',"Access-Control-Allow-Origin": "*",
		"Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",Authorization : `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
		accountUserId: localStorage.getItem("accountUserId") }
	  };
		try {
		const res = yield  axios.get(`${API_ENDPOINT_DATA_EXPORT}${DATA_EXPORT}${VERSION}/export-table?appId=${appId}&appInstanceId=${appInstanceId}
		&query=${query}&sortfield=${sorter?.field}&sortorder=${sorter?.order}&page=${pagination?.current-1}&size=${pagination?.pageSize}`,
		config);
			if (res.status===200) {
				yield put(dataExportList(res.data));
			} 
		} catch (err) {
			console.log(err);
			if(err?.response?.data?.status==401){
				yield put(showUnAutherizeMessage(err.response));
			}
			yield put(showDataMigrationMessage(err.response));
		}
    }

    )
}


export  function* addDataExport(){
	yield takeEvery(ADD_DATA_EXPORT, function* ({payload}){
		const {values,query,sorter,pagination,appId,appInstanceId}=payload
		try {
			const res = yield  axios.post(`${API_ENDPOINT_DATA_EXPORT}${DATA_EXPORT}${VERSION}/export-table`,values,{headers :{
				'Authorization': `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
				'Access-Control-Allow-Origin' : '*',
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				accountUserId: localStorage.getItem("accountUserId")
			  }});
				if (res.status===200) {
					const response = yield  axios.get(`${API_ENDPOINT_DATA_EXPORT}${DATA_EXPORT}${VERSION}/export-table?appId=${appId}&appInstanceId=${appInstanceId}&query=${query}&sortfield=${sorter?.field}&sortorder=${sorter?.order}&page=${pagination?.current-1}&size=${pagination?.pageSize}`,
					{headers: {
					   'Authorization': `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
					   'Access-Control-Allow-Origin' : '*',
					   'Accept': 'application/json',
					   'Content-Type': 'application/json',
					   accountUserId: localStorage.getItem("accountUserId")
					 }});
					   if (response.status===200) {
							Modal.success({
								title: `${values.tableName}  ${t("impaqx.adminApp.dataExortAddMessage")}`,
								icon: <CheckCircleFilled />,
								okText: 'Close',
								onOk() {
								},
							});
						}
								yield put(dataExportList(response.data))
				}else{
					yield put(showDataMigrationMessage(res));
				}
			} catch (err) {
				console.log(err);
				if(err.response.data.status==401){
					yield put(showUnAutherizeMessage(err.response));
				}
				yield put(showDataMigrationMessage(err.response));
			}
	}
	)
}


export function* updateDataExport(){
    yield takeEvery (UPDATE_DATA_EXORT,function* ({payload}){
		const {values,query,sorter,pagination,appId,appInstanceId} = payload;
        try {
            const res = yield axios.put(`${API_ENDPOINT_DATA_EXPORT}${DATA_EXPORT}${VERSION}/export-table/${values.id}`, values,{
				headers: {
				  'Authorization': `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
				  'Access-Control-Allow-Origin' : '*',
				  'Accept': 'application/json',
				  'Content-Type': 'application/json',
				  accountUserId: localStorage.getItem("accountUserId")
				}
			  });
             if (res.status===200) {
				const response = yield  axios.get(`${API_ENDPOINT_DATA_EXPORT}${DATA_EXPORT}${VERSION}/export-table?appId=${appId}&appInstanceId=${appInstanceId}&query=${query}&sortfield=${sorter?.field}&sortorder=${sorter?.order}&page=${pagination?.current-1}&size=${pagination?.pageSize}`,
				{headers: {
				   'Authorization': `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
				   'Access-Control-Allow-Origin' : '*',
				   'Accept': 'application/json',
				   'Content-Type': 'application/json',
				   accountUserId: localStorage.getItem("accountUserId")
				 }});
				   if (response.status===200) {
					Modal.success({
						title: `${values.tableName}  ${t("impaqx.adminApp.dataExportUpdatedMessage")}`,
						icon: <CheckCircleFilled />,
						okText: 'Close',
						onOk() {
						},
					});
				}
                 yield put(dataExportList(response.data));
             } 
        } catch (error) {
            console.log(error);
			if(error.response.data.status==401){
				yield put(showUnAutherizeMessage(error.response));
			}
			yield put(showDataMigrationMessage(error.response));
        }
    }
    )
}

export function* deleteDataExport(){
	yield takeEvery(DELETE_DATA_EXPORT, function*({payload}){
         try {
			const {dataExport,query, sorter,pagination,appId,appInstanceId} = payload;
			 const res = yield axios.delete(`${API_ENDPOINT_DATA_EXPORT}${DATA_EXPORT}${VERSION}/export-table/${dataExport.id}`,{
				headers: {
				  'Authorization': `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
				  'Access-Control-Allow-Origin' : '*',
				  'Accept': 'application/json',
				  'Content-Type': 'application/json',
				  accountUserId: localStorage.getItem("accountUserId")
				}
			  });
			 if(res.status===200){
				const response = yield  axios.get(`${API_ENDPOINT_DATA_EXPORT}${DATA_EXPORT}${VERSION}/export-table?appId=${appId}&appInstanceId=${appInstanceId}&query=${query}&sortfield=${sorter?.field}&sortorder=${sorter?.order}&page=${pagination?.current-1}&size=${pagination?.pageSize}`,
				{headers: {
				   'Authorization': `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
				   'Access-Control-Allow-Origin' : '*',
				   'Accept': 'application/json',
				   'Content-Type': 'application/json',
				   accountUserId: localStorage.getItem("accountUserId")
				 }});
				   if (response.status===200) {
					Modal.success({
						title: `${dataExport.tableName}  ${t("impaqx.adminApp.dataExportDeleteMessage")}`,
						icon: <CheckCircleFilled />,
						okText: 'Close',
						onOk() {
						},
					});
				}
                 yield put(dataExportList(response.data));
			 }
		 } catch (error) {
			 console.log(error);
			 if(error.response.data.status==401){
				yield put(showUnAutherizeMessage(error.response));
			}
			 yield put(showDataMigrationMessage(error.response));
		 }
	})
}


export default function* rootSaga(){
    yield all([
		  fork(getDataExportTableList),
		  fork(addDataExport),
		  fork(deleteDataExport),
		  fork(updateDataExport),
		  fork(getSchemaList),
		  fork(getViewData),
		  fork(getExportData),
		  fork(getDataExportList),
		  fork(getDataViewTableList)
    ])
    
}