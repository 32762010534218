import React, { useState } from "react";
import { Menu, Dropdown, Avatar, Button,Modal as AntModal } from "antd";
import { connect } from 'react-redux'
import { 
  EditOutlined, 
  SettingOutlined, 
  ShopOutlined, 
  QuestionCircleOutlined, 
  LogoutOutlined,
  SecurityScanOutlined,
  LockOutlined,
  ExclamationCircleOutlined
} from '@ant-design/icons';
import Icon from 'components/util-components/Icon';
import { signOut,openModal,signOutExtremeb2b } from 'redux/actions/Auth';
import {  APP_PREFIX_PATH } from 'configs/AppConfig'
import { BLOB_STORAGE_URL, PATH_VARIABLE } from 'constants/ApiConstant'
import { t } from "i18next";
import Loading from "components/shared-components/Loading";
import Loader from "components/util-components/Loader";
//import { Modal } from "react-bootstrap";
//import "bootstrap/dist/css/bootstrap.min.css";

const menuItem = [
	{
		title: "Edit Profile",
    permission : "edit_profile",
		icon: EditOutlined ,
		path: `${PATH_VARIABLE}${APP_PREFIX_PATH}/edit-profile`
    },
    
    // {
		// title: "Account Setting",
		// icon: SettingOutlined,
		// path: `${PATH_VARIABLE}${APP_PREFIX_PATH}/pages/account-list?mode=view-all`
    // },
  //   {
	// 	title: "Billing",
	// 	icon: ShopOutlined ,
	// 	path: "/"
	// },
  //   {
	// 	title: "Help Center",
	// 	icon: QuestionCircleOutlined,
	// 	path: "/"
	// }
  // ,
  {
    title :"Security",
    permission : "security",
    icon :SecurityScanOutlined,
    path :`${PATH_VARIABLE}${APP_PREFIX_PATH}/two-factor`
  },
  {
    title :"Change Password",
    permission : "change_password",
    icon :LockOutlined,
    path :`${PATH_VARIABLE}${APP_PREFIX_PATH}/change-password`
  }
]

const NavProfile = (props) => {
  const { signOut,openModal,auth,signOutExtremeb2b} = props;
  const {openModalValue}=auth;
  let profileImg ;
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  if(localStorage.getItem("profilePicture")){
    profileImg = `${BLOB_STORAGE_URL}/extremeb2b/profile/${localStorage.getItem("profilePicture")}`;
  }else{
    profileImg = `${PATH_VARIABLE}/img/profile-picture.png`;
  }

  const logoutAllApps = () => {
    openModal(false);
    AntModal.confirm({
			title: t("impaqx.adminApp.secondSignoutPopup"),
			icon: <ExclamationCircleOutlined />,
			okText: 'Yes',
			cancelText: 'No',
			onOk() {
        setLoading(true);
				signOut();
			},
		});
  };

  const handleOk = () => {
    setLoading(true);
    signOutExtremeb2b();
  };

  const handleCancel = () => {
    openModal(false);
  };


	const  logout = ()=> {
    openModal(true);
 }

 const handleCustomButton = (modal) => {
  console.log('Custom Button Clicked');
  modal.destroy(); // To close the modal
};

  const profileMenu = (<>
    <div className="nav-profile nav-dropdown">
      <div className="nav-profile-header">
        <div className="d-flex">
          {/* <Avatar size={45} src={profileImg} /> */}
          <div className="text-center">
            <h4 className="mb-0 text-break">{localStorage.getItem("username")}</h4>
            {/* <span className="text-muted">Frontend Developer</span> */}
          </div>
        </div>
      </div>
      <div className="nav-profile-body">
        <Menu>
          {menuItem.map((el, i) => {
            return ( 
             <>{( el.title=="Change Password") && localStorage.getItem("username")?.startsWith("sso_") ?
              
              null :<>{<Menu.Item key={i}>
               <a href={el.path}>
                    <Icon type={el.icon} />
                    <span className="font-weight-normal">{el.title}</span>
                  </a>
                </Menu.Item>}</>}</>
            );
          })}
          <Menu.Item key={menuItem.length + 1} onClick={e => logout()}>
            <span>
              <LogoutOutlined />
              <span className="font-weight-normal">Sign Out</span>
            </span>
          </Menu.Item>
        </Menu>
      </div>
    </div>
    
    </>
  );
  return (<>
    {loading? <Loader spinning={loading} cover="content"  />:null}
    <Dropdown placement="bottomRight" overlay={profileMenu} trigger={["click"]}>
      <Menu className="d-flex align-item-center" mode="horizontal">
        <Menu.Item key="profile">
          <Avatar src={profileImg} />
        </Menu.Item>
      </Menu>
    </Dropdown>
    <AntModal
        visible={openModalValue}
        closable={false}
        footer={[
          <Button className="ant-btn ant-btn-primary ant-btn ant-btn-primary ant-btn-sm mt-2 mr-2"  onClick={handleOk}>Yes</Button>,
          <Button className="ant-btn ant-btn-primary ant-btn-sm mt-2 mr-2"  onClick={handleCancel}>No</Button>,
          <Button className="ant-btn ant-btn-primary ant-btn ant-btn-primary ant-btn-sm mt-2 mr-2"  onClick={logoutAllApps}>Logout of all Apps</Button>
        ]}
      >
          <span className="ant-modal-confirm-title" icon={<ExclamationCircleOutlined/>}>{t("impaqx.adminApp.firstSignoutPopup")}</span>
      </AntModal>
    {/* <AntModal
        show={openModalValue}
        centered
      >
        <AntModal.Body style={{marginBottom: "15px"}}>
          <span className="ant-modal-confirm-title">{t("impaqx.adminApp.firstSignoutPopup")}</span>
          <div className="d-flex align-items-center custom-button" style={{width: '100%',justifyContent: 'flex-end'}}>
          <Button className="ant-btn ant-btn-primary ant-btn ant-btn-primary ant-btn-sm mt-2 mr-2"  onClick={handleOk}>Yes</Button>
          <Button className="ant-btn ant-btn-primary ant-btn-sm mt-2 mr-2"  onClick={handleCancel}>No</Button>
          <Button className="ant-btn ant-btn-primary ant-btn ant-btn-primary ant-btn-sm mt-2 mr-2"  onClick={logoutAllApps}>Logout of all Apps</Button>
          </div>
          </AntModal.Body>
      </AntModal> */}
    </>
  );
}
const mapStateToProps = state => ({
	auth: state.auth,
});

const mapDispatchToProps = {
	signOut,openModal,signOutExtremeb2b
}
export default connect(mapStateToProps, {signOut,openModal,signOutExtremeb2b})(NavProfile)
