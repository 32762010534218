export const GET_ACCOUNT_USER_LIST = "GET_ACCOUNT_USER_LIST";
export const ACCOUNT_USER_LIST = "ACCOUNT_USER_LIST";
export const ACCOUNT_USER_MESSAGE = "ACCOUNT_USER_MESSAGE";
export const REMOVE_ACCOUNT_USER= "REMOVE_ACCOUNT_USER";
export const SEND_INVITATION_MAIL="SEND_INVITATION_MAIL";
export const SHOW_LOADING="SHOW_LOADING";
export const HIDE_LOADING="HIDE_LOADING";
export const ACCEPT_INVITATION = "ACCEPT_INVITATION";
export const GET_NOTIFICATION="GET_NOTIFICATION";
export const NOTIFICATION_LIST="NOTIFICATION_LIST";
export const CLEAR_NOTIFICATION="CLEAR_NOTIFICATION";
export const READ_NOTIFICATION="READ_NOTIFICATION";
export const GET_INVITE_ACCOUNT_USER_LIST="GET_INVITE_ACCOUNT_USER_LIST";
export const INVITE_ACCOUNT_USER_LIST="INVITE_ACCOUNT_USER_LIST";
export const RESTORE_USER_TO_ACCOUNT='RESTORE_USER_TO_ACCOUNT';
export const UNAUTHERIZE_MESSAGE='UNAUTHERIZE_MESSAGE';
export const DELETE_NOTIFICATION='DELETE_NOTIFICATION';
export const DELETE_NOTIFICATIONS='DELETE_NOTIFICATIONS';
export const RESEND_INVITATION='RESEND_INVITATION';
export const DELETE_USER_INVITATION='DELETE_USER_INVITATION';
export const ALL_USER_LIST="ALL_USER_LIST";
export const GET_ALL_USER_LIST="GET_ALL_USER_LIST";