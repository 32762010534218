import { DEFAULT_ACCOUNT } from 'redux/constants/account';
import {
  SIGNIN,
  SIGNIN_TOTP,
  AUTHENTICATED,
  SIGNOUT,
  SIGNOUT_SUCCESS,
  SHOW_AUTH_MESSAGE,
  HIDE_AUTH_MESSAGE,
  SIGNUP,
  SIGNUP_SUCCESS,
  SHOW_LOADING,
  SIGNIN_WITH_GOOGLE,
  SIGNIN_WITH_GOOGLE_AUTHENTICATED,
  SIGNIN_WITH_FACEBOOK,
  SIGNIN_WITH_FACEBOOK_AUTHENTICATED,
  SEND_MAIL,
  VERIFY_MAIL,
  EMAIL_ID_CHECK,
  CHECK_MAIL,
  RESET_PASSWORD_MAIL,
  RESET_PASSWORD,
  SIGNIN_SUCCESS,
  PASS_VALIDATE,
  CHECK_PASS_VALIDATE,
  SIGNIN_WITH_MICROSOFT,
  SIGNIN_WITH_LINKEDIN,
  SIGNUP_WITH_LINKEDIN,
  FETCH_USER_DETAILS,
  GET_MFA_SETUP,
  GET_MFA_SETUP_HOME,
  GET_MFA_SETUP_HOME_SUCCESS,
  CHANEGE_PASSWORD,
  USING_2FA,
  TOGGLE_2FA,
  VERIFY_SUCCESS_SIGNIN,
  UNAUTHERIZE_MESSAGE,
  TOKEN_CHECK,
  AUTHENTICATED_NO_DEFAULT_ACCOUNT,
  GET_USER_DETAILS,
  USER_DETAILS,
  HIDE_LOADING,
  UPDATE_USER,
  SIGNUP_WITH_MICROSOFT,
  SIGNUP_WITH_GOOGLE,
  OPEN_MODAL,
  SIGNOUT_EXTREMEB2B
} from '../constants/Auth';

export const signIn = (user) => {
  return {
    type: SIGNIN,
    payload: user
  }
};

export const tokenCheck = () => {
  return {
    type: TOKEN_CHECK,
  }
};

export const signInSuccess =(username)=>{
  return{
    type : SIGNIN_SUCCESS,
    payload: username
  }
}

export const getMfaSetup =(username)=>{
  return{
    type : GET_MFA_SETUP,
    username:username
  }
}

export const onUsing2fa=(using2fa)=>{
  return{
    type : USING_2FA,
    using2fa
  }
}
export const toggle2fa=(using2fa)=>{
  return{
    type : TOGGLE_2FA,
    using2fa
  }
}
export const signInWithTOTP =(value)=>{
  return {
    type: SIGNIN_TOTP,
    payload: value
  }
}

export const checkEmail = (emailId)=>{
  return{
    type: EMAIL_ID_CHECK,
    payload: emailId
  }

}

export const passwordValidate =(data)=>{
  return{
    type:PASS_VALIDATE,
    payload:data
  }
}

export const checkpasswordValidate = (value) =>{
  return{
    type : CHECK_PASS_VALIDATE,
    payload : value
  }
}

export const checkEmailMessage = (value) =>{
  return{
    type : CHECK_MAIL,
    payload : value
  }
}

export const authenticated = (token) => {
  return {
    type: AUTHENTICATED,
    token
  }
};

export const signOut = () => {
  return {
    type: SIGNOUT
  };
};

export const signOutSuccess = () => {
  return {
    type: SIGNOUT_SUCCESS,
  }
};

export const signUp = (user) => {
  return {
    type: SIGNUP,
    payload: user
  };
};

export const signUpWithMicroSoft = (user)=>{
  return{
    type : SIGNUP_WITH_MICROSOFT,
    payload : user
  }
}

export const signUpWithGoogle = (user)=>{
  return{
    type : SIGNUP_WITH_GOOGLE,
    payload : user
  }
}

export const updateUser = (user) => {
  return {
    type: UPDATE_USER,
    payload: user
  };
};

export const sendMail = (user) => {
  return {
    type: SEND_MAIL,
    payload: user
  };
};

export const verify = (data) =>{
  return{
    type : VERIFY_MAIL,
    payload : data
  }
}

export const resetPasswordMail = (user) => {
  return {
    type: RESET_PASSWORD_MAIL,
    payload: user
  };
};

export const resetPassword= (user) => {
  return{
    type : RESET_PASSWORD,
    payload: user
  }
}

export const changePassword = (user)=>{
  return{
    type : CHANEGE_PASSWORD,
    payload: user
  }
}

export const signUpSuccess = (data) => {
  return {
    type: SIGNUP_SUCCESS,
    data 
  };
};

export const signInWithGoogle = (data) => {
  return {
    type: SIGNIN_WITH_GOOGLE,
    payload : data
  };
};

export const signInWithGoogleAuthenticated = (token) => {
  return {
    type: SIGNIN_WITH_GOOGLE_AUTHENTICATED,
    token
  };
};

export const signInWithMicroSoft=(data)=>{
  return{
    type : SIGNIN_WITH_MICROSOFT,
    payload:data
  }
}
export const signUpWithGoogleLinkedIn=(data)=>{
  return {
    type : SIGNUP_WITH_LINKEDIN,
    payload : data
  }
}
export const signInWithFacebook = () => {
  return {
    type: SIGNIN_WITH_FACEBOOK
  };
};
export const fetchLinkedInUserDetails=(data)=>{
  return{
    type : SIGNIN_WITH_LINKEDIN,
    payload : data
  }
}
export const signInWithFacebookAuthenticated = (token) => {
  return {
    type: SIGNIN_WITH_FACEBOOK_AUTHENTICATED,
    token
  };
};

export const showAuthMessage = (response) => {
  return {
    type: SHOW_AUTH_MESSAGE,
    response
  };
};

export const hideAuthMessage = () => {
  return {
    type: HIDE_AUTH_MESSAGE,
  };
};

export const showLoading = () => {
  return {
    type: SHOW_LOADING,
  };
};

export const hideLoading=()=>{
  return {
    type : HIDE_LOADING
  }
}
export const verifySuccessSignIn = (response) =>{
  return {
    type: VERIFY_SUCCESS_SIGNIN,
    response
  }
}
export const showUnAutherizeMessage=(data)=>{
  return{
      type : UNAUTHERIZE_MESSAGE,
      data
  }
}

export const noDefaultAccount=()=>{
  return{
      type:AUTHENTICATED_NO_DEFAULT_ACCOUNT
  }
}

export const getUserDetails=(username)=>{
  return{
     type : GET_USER_DETAILS,
     payload:username
  }
}

export const userData=(data)=>{
  return{
     type : USER_DETAILS,
     user:data
  }
}

export const getDefaultAccountByUserId=()=>{
  return{
     type : DEFAULT_ACCOUNT,
  }
}

export const openModal=(data)=>{
   return{
    type : OPEN_MODAL,
    data:data
   }
}

export const signOutExtremeb2b=()=>{
   return{
    type : SIGNOUT_EXTREMEB2B
   }
}