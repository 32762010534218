import { DATA_EXPORT_TABLE_LIST, DATA_MIGRATION_MESSAGE, SET_ADD_EDIT,TABLE_LIST,COLUMN_LIST, DATA_EXPORT_SCHEMA_LIST, VIEW_DATA, DATA_EXPORT_LIST, DATA_EXPORT_TABLE_VIEW_LIST } from "redux/constants/dataExport";

const initState = {
    loading : true,
    dataExportTableList : [],
    tableList :[],
    data :[],
    schemaList:[],
    dataExportList : [],
    tableViewList : [],
    addEditValue:false,
    message: '',
    showMessage: false,
    redirect : "",
    allowRedirect:false,
    status:0
}

const dataMigration =(state=initState,action) =>{
    switch(action?.type){
        case DATA_EXPORT_TABLE_LIST:
         return{
             ...state,
             dataExportTableList : action.dataExportTableList,
             allowRedirect :false,
             addEditValue: false,
             loading:false,
             message:""
         }
         case SET_ADD_EDIT:
            return {
              ...state,
              addEditValue: action.addEditValue,
              loading: false,
            };
        case  DATA_EXPORT_SCHEMA_LIST:
           return {
            ...state,
            schemaList : action.schemaList,
            allowRedirect :false,
            addEditValue: true,
            loading:false,
            message:""
           }
         case VIEW_DATA:
          return {
            ...state,
            data : action.data,
            allowRedirect :false,
            addEditValue: false,
            loading:false,
            message:""
           }
          case DATA_EXPORT_LIST : 
           return {
            ...state,
            dataExportList : action.dataExportList,
            allowRedirect :false,
            addEditValue: false,
            loading:false,
            message:""
           }
          case DATA_EXPORT_TABLE_VIEW_LIST : 
          return {
            ...state,
            tableViewList : action.tableViewList,
            allowRedirect :false,
            addEditValue: true,
            loading:false,
            message:""
           }
          case DATA_MIGRATION_MESSAGE:
            return {
              allowRedirect :false,
              addEditValue: false,
              loading:false,
              message:messageRead(action)
            }
         default:
           return state;
    }
}
const messageRead=(action)=>{
  if(action?.response?.status==401 || action?.response?.response?.status){
    return "Unauthorized"
  }
	if(action?.response && action?.response?.response && action?.response?.response?.data && action?.response?.response?.message){
	 return action?.response?.response?.data?.message;
	}else if(action?.response && action?.response?.data && action?.response?.data?.message){
		return action?.response?.data?.message;
	}else if(action?.response && action?.response?.data && action?.response?.data?.message){
		return action?.response?.data?.message;
	}else if(action?.response && action?.response?.data){
		return action?.response?.data;
	}else if(action?.data?.message){
		return action?.data?.message;
	}else if(action?.data){
		return action?.data;
	}else{
		return "something went wrong"
	}
}
export default dataMigration;