import {
	AUTH_TOKEN,
	AUTHENTICATED,
	SIGNIN_SUCCESS,
	SHOW_AUTH_MESSAGE,
	HIDE_AUTH_MESSAGE,
	SIGNOUT_SUCCESS,
	SIGNUP_SUCCESS,
	SHOW_LOADING,
	CHECK_MAIL,
	CHECK_PASS_VALIDATE,
	SIGNIN_WITH_GOOGLE_AUTHENTICATED,
    SIGNIN_WITH_FACEBOOK_AUTHENTICATED,
	GET_MFA_SETUP_HOME_SUCCESS,
	TOGGLE_2FA,
	VERIFY_SUCCESS_SIGNIN,
	UNAUTHERIZE_MESSAGE,
	AUTHENTICATED_NO_DEFAULT_ACCOUNT,
	USER_DETAILS,
	HIDE_LOADING,
	OPEN_MODAL
} from '../constants/Auth';
import { APP_PREFIX_PATH,AUTH_PREFIX_PATH } from 'configs/AppConfig';
import {PATH_VARIABLE} from 'constants/ApiConstant'

const initState = {
  loading: true,
  message: '',
  qrCode:'',
  mfaCode:'',
  using2fa:false,
  emailValue : false,
  passwordValidateValue:'',
  showMessage: false,
  allowRedirect:false,
  redirect: '',
  token: localStorage.getItem(AUTH_TOKEN),
  status : '',
  verified:false,
  user:{},
  openModalValue:false
}

const auth = (state = initState, action) => {
	switch (action?.type) {
		case AUTHENTICATED:
			return {
				...state,
				loading: false,
				allowRedirect: true,
				redirect: `${APP_PREFIX_PATH}/pages/account-list?mode=view-all`,
				token: action.token
			}
		case SIGNIN_SUCCESS:
		    return{
				...state,
				loading: false,
				allowRedirect : true,
				message : "",
				redirect: `${AUTH_PREFIX_PATH}/two-factor-auth`,
				username : action?.payload,
                token : "",
			}
		case SHOW_AUTH_MESSAGE: 
			return {
				...state,
				//message: action?.response?.data?.message?action?.response?.data?.message:action?.response?.data?.error,
				message : messageRead(action),
				status:action?.response?.status,
				showMessage: true,
				loading: false
			}
		case HIDE_AUTH_MESSAGE: 
			return {
				...state,
				message: '',
				showMessage: false,
				loading: false,
				allowRedirect : false,
			}
		case SIGNOUT_SUCCESS: {
			return {
				...state,
				token: null,
				allowRedirect : false,
				redirect: '/',
				message:"",
				showMessage:false,
				loading: false
			}
		}
		case VERIFY_SUCCESS_SIGNIN: {
			return {
				...state,
				token: null,
				allowRedirect : true,
				redirect: `${AUTH_PREFIX_PATH}/login`,
				message: "",
				verified: action?.response?.data?.verified,
				showMessage:false,
				loading: false
			}
		}
		case SIGNUP_SUCCESS: {
			return {
			  ...state,
			  loading: false,
			  token: action?.data?.token,
			  message : action?.data?.message,
			  mfaCode: action?.data?.mfaCode,
			  qrCode:action?.data?.qrCode,
			  showMessage:true,
			  allowRedirect:true,
			  redirect: `${AUTH_PREFIX_PATH}/register-success`
			}
		}
        case TOGGLE_2FA:{
			return{
			 ...state,
			 loading:false,
			 using2fa: action?.using2fa
			}
		}
		case SHOW_LOADING: {
			return {
				...state,
				loading: true
			}
		}
		case HIDE_LOADING:{
			return{
				...state,
				loading:false
			}
		}
		case SIGNIN_WITH_GOOGLE_AUTHENTICATED: {
			return {
				...state,
				loading: false,
				token: action?.token,
				redirect: `${AUTH_PREFIX_PATH}/two-factor-auth`
			}
		}
		case SIGNIN_WITH_FACEBOOK_AUTHENTICATED: {
			return {
				...state,
				loading: false,
				token: action?.token
			}
		}
		case CHECK_MAIL:{
			return{
				...state,
				loading: false,
				emailValue: action?.payload
			}
		}
		case CHECK_PASS_VALIDATE : {
			return{
				...state,
				loading: false,
				passwordValidateValue: action?.payload
			}
		}
		case UNAUTHERIZE_MESSAGE :
			return{
			   ...state,
				   loading: false,
				   allowRedirect: true,
				   redirect: `${AUTH_PREFIX_PATH}/login`,
				   token: null
			}
			case AUTHENTICATED_NO_DEFAULT_ACCOUNT:
			return {
				...state,
				loading: false,
				allowRedirect: true,
				redirect: `${APP_PREFIX_PATH}/pages/account-list?mode=view-all`,
				token: localStorage.getItem(AUTH_TOKEN)
			}
			case USER_DETAILS:
				return {
					...state,
					loading: false,
					allowRedirect: false,
					user: action.user
					}
			case OPEN_MODAL:
				return{
					...state,
					openModalValue : action?.data
				}
		default:
			return state;
	}
}

const messageRead=(action)=>{
	if(action?.response?.status==401 || action?.response?.response?.status){
        return "Unauthorized"
    }
	if(action?.response && action?.response?.response && action?.response?.response?.data && action?.response?.response?.data?.message){
	 return action?.response?.response?.data?.message;
	}else if(action?.response && action?.response?.data && action?.response?.data?.message){
		return action?.response?.data?.message;
	}else if(action?.response && action?.response?.data && action?.response?.data?.message){
		return action?.response?.data?.message;
	}else if(action?.response && action?.response?.data){
		return action?.response?.data;
	}else if(action?.data?.message){
		return action?.data?.message;
	}else if(action?.data){
		return action?.data;
	}else{
		return "something went wrong"
	}
}
export default auth;