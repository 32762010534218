import { GET_ACCOUNT_USER_LIST,ACCOUNT_USER_LIST,ACCOUNT_USER_MESSAGE,REMOVE_ACCOUNT_USER,SEND_INVITATION_MAIL,SHOW_LOADING,
    ACCEPT_INVITATION,GET_NOTIFICATION,NOTIFICATION_LIST,CLEAR_NOTIFICATION,READ_NOTIFICATION,GET_INVITE_ACCOUNT_USER_LIST,
    INVITE_ACCOUNT_USER_LIST,RESTORE_USER_TO_ACCOUNT,UNAUTHERIZE_MESSAGE, DELETE_NOTIFICATIONS, DELETE_NOTIFICATION,RESEND_INVITATION,DELETE_USER_INVITATION,HIDE_LOADING,
    GET_ALL_USER_LIST,ALL_USER_LIST} from "redux/constants/user";

export const getAccountUserList = (requestData)=>{
    return{
        type : GET_ACCOUNT_USER_LIST,
        requestData : requestData
    }
}

export const getAllUserList = (requestData)=>{
    return{
        type : GET_ALL_USER_LIST,
        requestData : requestData
    }
}

export const getInviteAccountUserList = (requestData)=>{
    return{
        type : GET_INVITE_ACCOUNT_USER_LIST,
        requestData : requestData
    }
}

export const accountUserList = (data)=>{
    return{
        type : ACCOUNT_USER_LIST,
        accountUserList : data
    }
}

export const allUserList = (data)=>{
    return{
        type : ALL_USER_LIST,
        allUserList : data
    }
}

export const reStoreUser = (data)=>{
    return{
        type : RESTORE_USER_TO_ACCOUNT,
        accountUser : data
    }
}

export const inviteAccountUserList = (data)=>{
    return{
        type : INVITE_ACCOUNT_USER_LIST,
        inviteAccountUserList : data
    }
}

export const showAccountUserMessage=(response)=>{
    return{
        type : ACCOUNT_USER_MESSAGE,
        response
    }
}

export const removeFromAccount=(data)=>{
    return{
        type : REMOVE_ACCOUNT_USER,
        payload : data
    }
}

export const sendInvitationMail=(data)=>{
    return{
        type : SEND_INVITATION_MAIL,
        payload : data
    }
}

export const deleteUserInvitation=(data)=>{
    return{
        type : DELETE_USER_INVITATION,
        payload : data
    }
}

export const showLoading = () => {
    return {
      type: SHOW_LOADING,
    };
  };

  export const hideLoading = () => {
    return {
      type: HIDE_LOADING,
    };
  };

export const acceptInvitation = (key)=>{
    return{
        type : ACCEPT_INVITATION,
        payload : key
    }
}

export const getNotificationData = ()=>{
    return{
        type : GET_NOTIFICATION
    }
}

export const notificatonList = (data)=>{
    return{
        type : NOTIFICATION_LIST,
        notificationDataList : data
    }
}

export const clearNotification = () =>{
    return{
        type : CLEAR_NOTIFICATION
    }
}
export const readNotification = (notification) =>{
    return{
        type : READ_NOTIFICATION,
        payload : notification
    }
}

export const deleteNotifications = () =>{
    return{
        type : DELETE_NOTIFICATIONS
    }
}

export const deleteNotification = (notification) =>{
    return{
        type : DELETE_NOTIFICATION,
        payload : notification
    }
}

export const showUnAutherizeMessage=(data)=>{
    return{
        type : UNAUTHERIZE_MESSAGE,
        data
    }
  }

export const resendInvitation=(key)=>{
    return{
        type : RESEND_INVITATION,
        payload : key
    }
}