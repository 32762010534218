import React from "react";
import { CheckOutlined, GlobalOutlined, DownOutlined  } from '@ant-design/icons';
import { Menu, Dropdown } from "antd";
import lang from "assets/data/language.data.json";
import { connect } from "react-redux";
import { onLocaleChange } from 'redux/actions/Theme'
import { PATH_VARIABLE } from 'constants/ApiConstant'
import i18n from "i18next";


function getLanguageDetail (locale) {
	const data = lang.filter(elm => (elm.langId === locale))
	return data[0]
}

const SelectedLanguage = ({ locale }) => {
	const language = getLanguageDetail(locale)
	const {langName, icon} = language
	return (
		<div className="d-flex align-items-center">
			{/* <img style={{maxWidth: '20px'}} src={`${PATH_VARIABLE}/img/flags/${icon}.png`} alt={langName}/> */}
			<span className="font-weight-semibold ml-2">{langName} <DownOutlined className="font-size-xs"/></span>
		</div>
	)
}

export const NavLanguage = ({ locale, configDisplay, onLocaleChange }) => {
	
		  const handleLanguage = (elm) => {
		    onLocaleChange(elm.langId);
		    console.log("TEST LANGUAGE", elm);
		    localStorage.setItem("langId",elm.langId)
			localStorage.setItem("langName",elm.langName)
		    i18n.changeLanguage(elm.langId)
		  };
	
	const languageOption = (
		<Menu>
			{
				lang.map((elm, i) => {return (
					<Menu.Item 
						key={elm.langName} 
						className={locale === elm.langId? 'ant-dropdown-menu-item-active': ''} 
						onClick={() => handleLanguage(elm)}
					>
						<span className="d-flex justify-content-between align-items-center">
							<div>
								{/* <img style={{maxWidth: '20px'}} src={`${PATH_VARIABLE}/img/flags/${elm.icon}.png`} alt={elm.langName}/> */}
								<span className="font-weight-normal ml-2">{elm.langName}</span>
							</div>
							{localStorage.getItem("langId") === elm.langId? <CheckOutlined className="text-success" /> : null}
						</span>
					</Menu.Item>
				)})
			}
		</Menu>
	)
	return (
		<Dropdown placement="bottomRight" overlay={languageOption} trigger={["click"]}>
			{
				configDisplay ?
				(
					<a href="#/" className="text-gray" onClick={e => e.preventDefault()}>
						<SelectedLanguage locale={locale}/>
					</a>
				)
				:
				(
					// <Menu mode="horizontal">
					// 	<Menu.Item key="language">
							<a href="#/" onClick={e => e.preventDefault()}>
							{localStorage.getItem("langName")}
							</a>
					// 	</Menu.Item>
					// </Menu>
				)
			}
		</Dropdown>
	)
}

const mapStateToProps = ({ theme }) => {
  const { locale } =  theme;
  return { locale }
};

export default connect(mapStateToProps, {onLocaleChange})(NavLanguage);
