import React, { useState,useEffect } from 'react';
import { connect } from 'react-redux';
import { Menu, Dropdown, Badge, Avatar, List, Button, Modal, Tag } from 'antd';
import { 
  MailOutlined, 
  BellOutlined, 
  WarningOutlined,
  CheckCircleOutlined,
  ExclamationCircleOutlined
} from '@ant-design/icons';
//import notificationData from "assets/data/notification.data.json";
import Flex from 'components/shared-components/Flex';
import { getNotificationData,clearNotification,readNotification,deleteNotifications,deleteNotification,resendInvitation } from "redux/actions/user";
import { API_ENDPOINT_UI_URL } from 'configs/AppConfig';


const getIcon =  icon => {
  switch (icon) {
    case 'mail':
      return <MailOutlined />;
    case 'alert':
      return <WarningOutlined />;
    case 'check':
      return <CheckCircleOutlined />
    default:
      return <MailOutlined />;
  }
}


const getNotificationBody = (list,readNotification,deleteNotification,resendInvitation) => {
  const handleClick = (item) =>{
    readNotification(item);
  }

  const onResendInvitation = (key)=>{
    resendInvitation({key});
  }
  const onDeleteNotification=(notification)=>{
    var title =`Do you want to delete the notification?`;
		Modal.confirm({
			title: title,
			icon: <ExclamationCircleOutlined />,
			okText: 'Yes',
			cancelText: 'Cancel',
      visible:true,
      style:{},
			onOk() {
        deleteNotification(notification);
			},
		});
  }
  return list.length > 0 ?
  <List
    size="small"
    itemLayout="horizontal"
    dataSource={list}
    renderItem={item => (
      <List.Item className="list-clickable">
        {/* <div onClick={()=>handleClick(item)}> */}
        <div>
        <Flex alignItems="center">
          <div className="pr-3" >
            {item.img? <Avatar style={{backgroundColor:"#699dff"}}  src={`/img/avatars/${item.img}`} /> : <Avatar style={{backgroundColor:"#699dff"}} className={`ant-avatar-${item.type}`} icon={getIcon(item.icon)} />}
            </div>
          <div className="pr-3">
            {/* <span  style={item.readDatetime === null ? { fontWeight: 'bold',color:'blue' } : {fontWeight: 'bold'}} className="ml-auto">{item.module} : </span> */}
            <span style={item.readDatetime === null ? { color:'blue' } : {}} className="ml-auto">{item.message}</span>
          </div>
          <div className="mr-3">
          <small style={item.readDatetime === null ? {color:'blue' } : {}} className="ml-auto">{item.sentDatetime}</small>
          </div>
          {/* <div className="mr-3"> */}
            {item?.websiteUrl?.includes("key")?<div className="mr-3">
        {item.message.includes(" request to resend invitaion")? 
          <button type="primary" className="ant-btn ant-btn-primary ant-btn-sm" onClick={()=>{onResendInvitation(item.key)}} >Resend</button>
          :<a href={`${item.websiteUrl}`} rel="noopener noreferrer">
          {/* {new Date(item.expiresDatetime) > new Date() || item.actionDatetime!=undefined && item.actionDatetime!=null && item.actionDatetime.trim()!=""? */}
        <button type="primary" disabled={item.actionDatetime!=undefined && item.actionDatetime!=null && item.actionDatetime.trim()!=""} className="ant-btn ant-btn-primary ant-btn-sm">Accept</button>
      {/* :<Tag
      className="text-capitalize"
      color={"red"}
     >
      Invitation expired
   </Tag>} */}
        </a>}</div>:<>
        <div className="mr-3">
          <a href={decodeURIComponent(item.websiteUrl)} target="_blank" rel="noopener noreferrer">
              <Button type="primary" className="ant-btn ant-btn-primary ant-btn-sm">{item?.message?.includes("requesting access")?'Grant':'Download'}</Button> 
          </a>
          </div>
          <div className="mr-3">
          <a href={`${API_ENDPOINT_UI_URL}/admin/auth/access-decline?nId=${item?.id}`} target="_blank" rel="noopener noreferrer">
              <Button  type="primary" className="ant-btn ant-btn-primary ant-btn-sm">{item?.message?.includes("requesting access")?'Deny':null}</Button> 
          </a>
       </div>
       </>
        }
{/* </div> */}
<div className="mr-3">
<button type="primary" className="ant-btn ant-btn-primary ant-btn-sm" style={{background: "#fff1f0",borderColor: "#ffa39e",color: "#cf1322"}} onClick={() => onDeleteNotification(item)}>Delete</button>
          </div>
        </Flex>
        </div>
      </List.Item>
    )}
  />
  :
  <div className="empty-notification">
    <img src="https://gw.alipayobjects.com/zos/rmsportal/sAuJeJzSKbUmHfBQRzmZ.svg" alt="empty" />
    <p className="mt-3">You have viewed all notifications</p>
  </div>;
}

export const NavNotification = (props) => {
	const { notificationData,getNotificationData,clearNotification,readNotification,deleteNotifications,deleteNotification,resendInvitation } = props;
  const { notificationDataList,message,loading} = notificationData
  const [visible, setVisible] = useState(false);
  // [data, setData] = useState(notificationDataList)

  const handleVisibleChange = (flag) => {
    setVisible(flag);
  }

  const clearNotificationData = () =>{
    clearNotification();
  }

  const deleteNotificationData=()=>{
    var title =`Do you want to delete all notification?`;
		Modal.confirm({
			title: title,
			icon: <ExclamationCircleOutlined />,
			okText: 'Yes',
			cancelText: 'Cancel',
			onOk() {
				deleteNotifications()
			},
		});
  }
  useEffect(() => {
    getNotificationData();
  },[])

  let filteredNotificationDataList =[];
  if(notificationDataList && notificationDataList.length>0){
    filteredNotificationDataList= notificationDataList.filter(notification => {
    return notification.readDatetime === null;
    });
    }

  const notificationList = (
    <div className="nav-dropdown nav-notification">
      <div className="nav-notification-header d-flex justify-content-between align-items-center">
        <h4 className="mb-0">Notification</h4>
        <div>
         <Button disabled={notificationDataList.length<=0} className="text-primary" type="text" onClick={() => clearNotificationData()} size="small">Read all </Button> 
         <Button disabled={notificationDataList.length<=0} className="text-primary" type="text" onClick={() => deleteNotificationData()} size="small">Delete all </Button> 
         </div>
      </div>
      <div className="nav-notification-body">
        {getNotificationBody(notificationDataList,readNotification,deleteNotification,resendInvitation)}
      </div>
      {
        notificationDataList.length > 0 ? 
        <div className="nav-notification-footer">
          {/* <a className="d-block" href="#/">View all</a> */}
        </div>
        :
        null
      }
    </div>
  );

  return (<>
    <Dropdown 
      placement="bottomRight"
      overlay={notificationList}
      onVisibleChange={handleVisibleChange}
      visible={visible}
      trigger={['click']}
    >
      <Menu mode="horizontal">
        <Menu.Item key="notification">
          <Badge count={filteredNotificationDataList.length}>
            <BellOutlined className="nav-icon mx-auto" type="bell" />
          </Badge>
        </Menu.Item>
      </Menu>
    </Dropdown>
    </>
  )
}

const mapStateToProps = state => ({
	notificationData: state.user,
});
export default connect(
  mapStateToProps,
  {  getNotificationData,clearNotification,readNotification,deleteNotifications,deleteNotification,resendInvitation}
)(NavNotification);

