import React, { useState } from "react";

import { Button, Tooltip } from "antd";

import { PlusSquareOutlined } from "@ant-design/icons";

const AddEditFormField = ({ fieldData, setFieldData, position }) => {
  const [count, setCount] = useState(0);

  const preDefinedValue = {
    title: "New Title",

    type: "string",

    description: "New Description",

    $defaultValue: "",

    id: `object${count + 1}`,

    $componentType: "string",
  };

  const handleAddField = () => {
    setCount(count + 1);

    const newProperties = {
      ...fieldData[position]?.properties,

      [`object${count + 1}`]: preDefinedValue,
    };

    const formsList = [...fieldData];

    formsList[position] = {
      ...fieldData[position],

      properties: { ...newProperties },
    };

    setFieldData([...formsList]);
  };

  return (
    <>
           {" "}
      <Tooltip placement="bottom" title={"Click to add new Form Field"}>
             {" "}
        <Button
          type="primary"
          icon={<PlusSquareOutlined />}
          size={"large"}
          onClick={handleAddField}
        />
             {" "}
      </Tooltip>
         {" "}
    </>
  );
};

export default AddEditFormField;
