export const GET_APP_INSTANCE_SETTINGS_DETAILS="GET_APP_INSTANCE_SETTINGS_DETAILS";
export const APP_SETTINGS_JSON="APP_SETTINGS_JSON";
export const APP_INSTANCE_MESSAGE="APP_INSTANCE_MESSAGE";
export const ADD_APP_INSTANCE_SETTINGS_JSON="ADD_APP_INSTANCE_SETTINGS_JSON";
export const GET_APP_INSTANCE_PERMISSIONS="GET_APP_INSTANCE_PERMISSIONS";
export const GET_APP_INSTANCE_ROLE_PERMISSIONS="GET_APP_INSTANCE_ROLE_PERMISSIONS"
export const ADD_APP_INSTANCE_ROLE="ADD_APP_INSTANCE_ROLE";
export const UPDATE_APP_INSTANCE_ROLE="UPDATE_APP_INSTANCE_ROLE";
export const APP_INSTANCE_ROLE_LIST="APP_INSTANCE_ROLE_LIST";
export const SET_ADD_EDIT="SET_ADD_EDIT";
export const DELETE_APP_INSTANCE_ROLE="DELETE_APP_INSTANCE_ROLE";
export const GET_APP_INSTANCE_ROLES="GET_APP_INSTANCE_ROLES";
export const APP_INSTANCE_ROLE_PERMISSION_LIST="APP_INSTANCE_ROLE_PERMISSION_LIST";
export const ASSIGN_APP_ROLE_TO_USER="ASSIGN_APP_ROLE_TO_USER";
export const SET_MODAL_POPUP="SET_MODAL_POPUP";
export const SET_MODAL_POPUP_DATA="SET_MODAL_POPUP_DATA";
export const SET_MODAL_ROLE_PERMISSION_POPUP="SET_MODAL_ROLE_PERMISSION_POPUP";
export const SHOW_LOADING="SHOW_LOADING";
export const HIDE_LOADING="HIDE_LOADING";
export const SAVE_APP_INSTANCE_API_KEY="SAVE_APP_INSTANCE_API_KEY";
export const APP_INSTANCE_API_KEY="APP_INSTANCE_API_KEY";
export const GET_APP_INSTANCE_API_KEY="GET_APP_INSTANCE_API_KEY";
export const GET_ASSIGN_INSTANCE_USER_ROLES="GET_ASSIGN_INSTANCE_USER_ROLES";
export const ASSIGNED_INSTANCE_USER_ROLES="ASSIGNED_INSTANCE_USER_ROLES";
export const GET_APP_INSTANCE_DETAILS="GET_APP_INSTANCE_DETAILS";
export const APP_INSTANCE_DETAILS="APP_INSTANCE_DETAILS";
export const APP_INSTANCE_UI_PROPERTIES_JSON = "APP_INSTANCE_UI_PROPERTIES_JSON";
export const APP_INSTANCE_UI_PROPERTIES="APP_INSTANCE_UI_PROPERTIES";
export const ADD_APP_INSTANCE_UI_PROPERTIES="ADD_APP_INSTANCE_UI_PROPERTIES";
export const EDIT_APP_INSTANCE_UI_PROPERTIES="EDIT_APP_INSTANCE_UI_PROPERTIES";
export const DELETE_APP_INSTANCE_UI_PROPERTIES="DELETE_APP_INSTANCE_UI_PROPERTIES";
export const GET_APP_ROLES_INSTANCE_ROLES="GET_APP_ROLES_INSTANCE_ROLES";
export const APP_INSTANCE_CONNECTOR_DETAILS="APP_INSTANCE_CONNECTOR_DETAILS";
export const GET_APP_INSTANCE_CONNECTOR_DETAILS="GET_APP_INSTANCE_CONNECTOR_DETAILS";
export const LINK_APP_TO_CONNECTOR = "LINK_APP_TO_CONNECTOR";
export const GET_LINKED_APP_INSTANCE_CONNECTOR="GET_LINKED_APP_INSTANCE_CONNECTOR";
export const LINKED_APP_INSTANCE_CONNECTOR="LINKED_APP_INSTANCE_CONNECTOR";
export const CONNECTOR_SETTINGS_JSON="CONNECTOR_SETTINGS_JSON";
export const GET_CONNECTOR_INSTANCE_SETTINGS_DETAILS="GET_CONNECTOR_INSTANCE_SETTINGS_DETAILS";
export const ADD_CONNECTOR_INSTANCE_SETTINGS_JSON="ADD_CONNECTOR_INSTANCE_SETTINGS_JSON";
export const APP_ROLES_INSTANCE_ROLE_LIST="APP_ROLES_INSTANCE_ROLE_LIST";
export const GET_APP_INSTANCE_ROLE_PERMISSIONS_LIST="GET_APP_INSTANCE_ROLE_PERMISSIONS_LIST";
export const GET_APP_INSTANCE_API_KEYS="GET_APP_INSTANCE_API_KEYS";
export const DELETE_APP_INSTANCE_API_KEY="DELETE_APP_INSTANCE_API_KEY";
export const APP_INSTANCE_API_KEYS="APP_INSTANCE_API_KEYS";
export const UPDATE_APP_INSTANCE_API_KEY="UPDATE_APP_INSTANCE_API_KEY";