import axios from "axios";
import axiosRetry from 'axios-retry';
import * as qs from 'qs';

import { AUTH_TOKEN } from "../constants/Auth";

import addMinutes from "date-fns/addMinutes";

import isWithinInterval from "date-fns/isWithinInterval";

import { AUTH_PREFIX_PATH, MANAGE_PREPIX_PATH } from 'configs/AppConfig'
import history from '../../history'
import { notification } from 'antd';
import { API_BASE_URL } from 'configs/AppConfig'
const ENTRY_ROUTE = '/admin/auth/login'

const axiosConfig = axios.create({

  headers: {

    "Content-Type": "application/json",

    accept: "application/json",

  },

  timeout: 600000,

  responseType: "json",

});

const retryCondition = error =>
!error.response || error.response.status === 504;

axiosRetry(axiosConfig, {
retries: 0,
retryCondition,
});

const parseJwt = (token) => {
  try {
    return JSON.parse(atob(token.split(".")[1]));
  } catch (e) {
    return null;
  }
};

// axiosConfig.interceptors.request.use(

//   async (config) => {

//     const decodedJwt = parseJwt(localStorage.getItem(AUTH_TOKEN));

//     if (decodedJwt.exp * 1000 < Date.now()) {
//      window.location.replace("/admin"+AUTH_PREFIX_PATH+"/login");
//     }else{
//       config.headers.Authorization = `Bearer ${localStorage.getItem(

//             AUTH_TOKEN
    
//           )}`;
    
//           return config;
//       }

//   },

//   (error) => Promise.reject(error)

// );



axiosConfig.interceptors.response.use( (response) => {
	return response
}, async(error) => {

  const originalRequest = error.config;
  if (
    error?.response?.status === 401 &&
    !originalRequest?._retry &&
    (error?.response?.data?.error === 'Unauthorized' || error?.response?.data?.error === 'invalid_token' ) 
  ) {
    originalRequest._retry = true;
    if(localStorage.getItem("refresh_token")){
      let params = qs.stringify({
        refresh_token: localStorage.getItem("refresh_token"),
        grant_type: 'refresh_token',
      });
      let appKey = "oauthclient1";
      let appSecret = "oauthsecret1";

      const authDecoded = appKey + ':' + appSecret;
      const authorization = btoa(authDecoded);
      const data = {'refresh_token': localStorage.getItem("refresh_token"),
                 'access_token': localStorage.getItem(AUTH_TOKEN)};
      return  await axios
        .post(API_BASE_URL+MANAGE_PREPIX_PATH+AUTH_PREFIX_PATH+"/refresh-token", data,  { 
          headers: {'access_token': localStorage.getItem("access_token")}
        })
        .then(({ data }) => {
          originalRequest.headers['Authorization'] =
            'Bearer ' + data.accessToken;
          localStorage.setItem(AUTH_TOKEN, data.accessToken);
          return axios(originalRequest);
        // }else{
        //   window.location.replace("/admin/auth/login");
        // }
       }).catch(({error})=>{
        console.log(error);
         window.location.replace("/admin/auth/login");
        // }
       });
    }else{
      window.location.replace("/admin/auth/login");
    }
  }
	return Promise.reject(error);
});



export default axiosConfig;