import React, { useEffect } from "react";
import { Provider } from "react-redux";
import store from "./redux/store";
import { BrowserRouter as Router, Redirect } from "react-router-dom";
import Views from "./views";
import { Route, Switch } from "react-router-dom";
import { ThemeSwitcherProvider } from "react-css-theme-switcher";
import { MANAGE_PREPIX_PATH, THEME_CONFIG } from "./configs/AppConfig";
import axios from "axios";
import { API_BASE_URL } from "configs/AppConfig";
import { AUTH_TOKEN } from "redux/constants/Auth";
import { APP_PREFIX_PATH, AUTH_PREFIX_PATH } from "configs/AppConfig";
import OrderLayout from "layouts/order-layout";
import orderViews from "views/order-views";

const themes = {
  dark: `${process.env.PUBLIC_URL}/css/dark-theme.css`,
  light: `${process.env.PUBLIC_URL}/css/light-theme.css`,
};

function App() {
  useEffect(async () => {
    const path = window.location.pathname.indexOf("/auth");
    const path2 = window.location.pathname.indexOf("/custom-cart");
    if (path2 != -1) return;
    if (path != -1) return;
    try {
      const headers = {"Authorization" : `${localStorage.getItem(AUTH_TOKEN)}`}
      const res =  await axios.get(`${API_BASE_URL}/manage/auth/tokenCheck`, {headers});
      if (res.status === 200) {
        return;
      } else {
        window.location.href = "/admin" + AUTH_PREFIX_PATH + "/login";
      }
    } catch (error) {
      window.location.href = "/admin" + AUTH_PREFIX_PATH + "/login";
      console.log(error.response);
    }
  }, []);
  return (
    <div className="App">
      <Provider store={store}>
        <ThemeSwitcherProvider
          themeMap={themes}
          defaultTheme={THEME_CONFIG.currentTheme}
          insertionPoint="styles-insertion-point"
        >
          <Router basename="/admin">
            {/* basename="/security" */}
            <Switch>
              <Route path="/" component={Views} />
              <Route path="*" component={Views} >
                <Redirect to={`${AUTH_PREFIX_PATH}/404`} />
              </Route>
            </Switch>
          </Router>
          
        </ThemeSwitcherProvider>
      </Provider>
    </div>
  );
}

export default App;
