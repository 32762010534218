import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import propertiesResponse from "assets/data/language-properties.json";

var enData1 = {};
var frData1 = {};
var spData1 = {};
var zHData1 = {}
var jpData1 = {};


  propertiesResponse?.map((uiDetails, index) => {
    if (uiDetails.locale) {
      enData1[`${uiDetails.propertyName}`] = uiDetails?.locale?.en;
      frData1[`${uiDetails.propertyName}`] = uiDetails?.locale?.fr && uiDetails?.locale?.fr.trim()!=""?uiDetails?.locale?.fr:uiDetails?.locale?.defaultValue
      && uiDetails?.locale?.defaultValue.trim()?uiDetails?.locale?.defaultValue:uiDetails?.locale?.en;
      spData1[`${uiDetails.propertyName}`] = uiDetails?.locale?.sp && uiDetails?.locale?.sp.trim()!=""?uiDetails?.locale?.sp:uiDetails?.locale?.defaultValue
      && uiDetails?.locale?.defaultValue.trim()!=""?uiDetails?.locale?.defaultValue:uiDetails?.locale?.en;
      jpData1[`${uiDetails.propertyName}`] = uiDetails?.locale?.jp && uiDetails?.locale?.jp.trim()!=""?uiDetails?.locale?.jp:uiDetails?.locale?.defaultValue
      && uiDetails?.locale?.defaultValue.trim()!=""?uiDetails?.locale?.defaultValue:uiDetails?.locale?.en;
    }
    });
    var enData = {};
var frData = {};
var spData = {};
var zHData = {}
var jpData = {};
    if(localStorage.getItem('ENGLISH-DATA')){
      enData = localStorage.getItem('ENGLISH-DATA');
     frData = localStorage.getItem('FRENCH-DATA');
     spData = localStorage.getItem('SPANISH-DATA');
     zHData = localStorage.getItem('CHAINESE-DATA');
     jpData = localStorage.getItem('JAPNESE-DATA');
    }else{
      enData = JSON.stringify(enData1);
      frData = JSON.stringify(frData1);
      spData = JSON.stringify(spData1);

}
		// propertiesResponse?.map((uiDetails, index) => {
		// 	if (uiDetails.locale) {
		// 	  enData[`${uiDetails.propertyName}`] = uiDetails?.locale?.en;
		// 	  frData[`${uiDetails.propertyName}`] = uiDetails?.locale?.fr;
		// 	  spData[`${uiDetails.propertyName}`] = uiDetails?.locale?.sp;
		// 	  jpData[`${uiDetails.propertyName}`] = uiDetails?.locale?.jp;
		// 	}
		//   });
		//   localStorage.setItem("ENGLISH-DATA", JSON.stringify(enData));
		//   localStorage.setItem("FRENCH-DATA", JSON.stringify(frData));
		//   localStorage.setItem("SPANISH-DATA", JSON.stringify(spData));
		//   localStorage.setItem("JAPNESE-DATA", JSON.stringify(jpData));



i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      en: {
        translation: JSON.parse(enData),
      },
      fr: {
        translation: JSON.parse(frData),
      },
      sp: {
        translation: JSON.parse(spData),
      },
      // zh: {
      //   translation: JSON.parse(zHData),
      // },
      // ja: {
      //   translation: JSON.parse(jpData),
      // },
    },
    lng: 'en', 
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, 
    },
  });

export default i18n;