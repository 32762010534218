import antdSpSP from 'antd/es/locale/sr_RS';
import frMsg from "../locales/fr_FR.json";

const SpLang = {
  antd: antdSpSP,
  locale: 'sp-SP',
  messages: {
    ...frMsg
  },
};
export default SpLang;
