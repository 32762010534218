import React from 'react'
import { Switch, Route, } from "react-router-dom";
import AuthViews from 'views/auth-views';
import Loading from 'components/shared-components/Loading';
import { useThemeSwitcher } from "react-css-theme-switcher";
import Utils from 'utils';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import HeaderNav from 'components/layout-components/HeaderNav';
import AuthHeaderNav from 'components/layout-components/AuthHeaderNav';
import { Layout } from 'antd';
import { DIR_LTR, DIR_RTL, NAV_TYPE_SIDE, NAV_TYPE_TOP, SIDE_NAV_COLLAPSED_WIDTH, SIDE_NAV_WIDTH } from 'constants/ThemeConstant';
import TopNav from 'components/layout-components/TopNav';
import utils from 'utils';
import navigationConfig from 'configs/NavigationConfig';
import MobileNav from 'components/layout-components/MobileNav';

export const AuthLayout = ({ navCollapsed, navType, location, direction }) => {
	const { status } = useThemeSwitcher();
	const screens = Utils.getBreakPoint(useBreakpoint());
	const isMobile = screens.length === 0 ? false : !screens.includes('lg')
	const currentRouteInfo = utils.getRouteInfo(navigationConfig, location?.pathname)
	const isNavSide = navType === NAV_TYPE_SIDE
	const isNavTop = navType === NAV_TYPE_TOP
	const getLayoutGutter = () => {
	  if(isNavTop || isMobile) {
		return 0
	  }
	  return navCollapsed ? SIDE_NAV_COLLAPSED_WIDTH : SIDE_NAV_WIDTH
	}
  
	if (status === 'loading') {
	  return <Loading cover="page" />
	}
  
	const getLayoutDirectionGutter = () => {
	  if(direction === DIR_LTR) {
		return {paddingLeft: getLayoutGutter()}
	  }  
	  if(direction === DIR_RTL) {
		return {paddingRight: getLayoutGutter()}
	  }
	  return {paddingLeft: getLayoutGutter()}
	}

	return (
		<Layout>
		<AuthHeaderNav isMobile={isMobile}/>
		<div className="auth-container">
			      <AuthHeaderNav isMobile={isMobile}/>
			<Switch>
				<Route path="" component={AuthViews} />
			</Switch>
		</div>
		{isMobile && <MobileNav />}
	  </Layout>
	)
}


export default AuthLayout
