import {
    GET_CONNECTORS_API_LIST,CONNECTOR_API_MESSAGE,DELETE_CONNECTOR_API,SHOW_LOADING,HIDE_LOADING,CONNECTORS_API_LIST, UPDATE_CONNECTOR_API,ADD_CONNECTOR_API,
    CONNECTOR_API_REGISTER_SUCCESS,SET_ADD_EDIT,TEST_CONNECTOR,CONNECTOR_DATA,TEST_CONNECTOR_DATA,SHOW_HIDE_TEST_CONNECTOR,GET_INSTANCE_CONNECTORS_API_LIST, INSTANCE_CONNECTORS_API_LIST,
    UPDATE_INSTANCE_CONNECTOR_API
  } from "redux/constants/connector";
  
  export const getConnectors = (requestData) => {
    return {
      type: GET_CONNECTORS_API_LIST,
      requestData:requestData
    };
  }; 

  export const getInstanceConnectors = (requestData) => {
    return {
      type: GET_INSTANCE_CONNECTORS_API_LIST,
      requestData:requestData
    };
  };

  export const testConnector = (requestData) => {
    return {
      type: TEST_CONNECTOR,
      requestData:requestData
    };
  };

  export const showLoading = () => {
    return {
      type: SHOW_LOADING,
    };
  };

  export const hideLoading=()=>{
    return {
      type : HIDE_LOADING
    }
  }

  export const showConnectorApisMessage=(response)=>{
    return{
        type : CONNECTOR_API_MESSAGE,
        response
    }
  }

  export const setAddEdit = (value) => {
    return {
      type: SET_ADD_EDIT,
      addEditValue: value,
    };
  };

  export const connectorList = (data) => {
    return {
      type: CONNECTORS_API_LIST,
      connectorsList: data,
    };
  };

  export const connectorInstanceList = (data) => {
    return {
      type: INSTANCE_CONNECTORS_API_LIST,
      connectorsInstanceList: data,
    };
  };

  export const connectorData = (data) => {
    return {
      type: CONNECTOR_DATA,
      connectorData: data,
    };
  };

  export const testConnectorData=(data)=> {
    return {
       type : TEST_CONNECTOR_DATA,
       testConnectorData:data
    }
  }

 export const deleteConnectorApi = (connector) => {
    return {
      type: DELETE_CONNECTOR_API,
      payload: connector,
    };
  };

  export const addConnector = (data) => {
    return {
      type: ADD_CONNECTOR_API,
      payload: data,
    };
  };

  export const updateConnector= (data) => {
    return {
      type: UPDATE_CONNECTOR_API,
      payload: data,
    };
  };
  
  export const updateInstanceConnector= (data) => {
    return {
      type: UPDATE_INSTANCE_CONNECTOR_API,
      payload: data,
    };
  };

  export const registerSuccess = (response) => {
    return {
      type: CONNECTOR_API_REGISTER_SUCCESS,
      response,
    };
  };
  
  export const setShowHideTestConnector=(data)=>{
    return {
      type : SHOW_HIDE_TEST_CONNECTOR,
      data:data
    }
  }