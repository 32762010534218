import { all, takeEvery, put, fork } from "redux-saga/effects";

//import axios from 'axios';
import axios from "./initialize";
import { Modal } from "antd";
import {
  ExclamationCircleOutlined,
  CheckCircleFilled,
  WarningFilled
} from "@ant-design/icons";

import {
  GET_APP_INSTANCE_SETTINGS_DETAILS,
  ADD_APP_INSTANCE_ROLE,
  DELETE_APP_INSTANCE_ROLE,
  GET_APP_INSTANCE_ROLES,
  GET_APP_INSTANCE_ROLE_PERMISSIONS,
  UPDATE_APP_INSTANCE_ROLE,
  ASSIGN_APP_ROLE_TO_USER,
  SET_MODAL_POPUP,
  ADD_APP_INSTANCE_SETTINGS_JSON,
  SAVE_APP_INSTANCE_API_KEY,
  GET_APP_INSTANCE_API_KEY,
  GET_ASSIGN_INSTANCE_USER_ROLES,
  GET_APP_INSTANCE_DETAILS,
  ADD_APP_INSTANCE_UI_PROPERTIES,
  APP_INSTANCE_UI_PROPERTIES,
  DELETE_APP_INSTANCE_UI_PROPERTIES,
  GET_APP_ROLES_INSTANCE_ROLES,
  GET_APP_INSTANCE_CONNECTOR_DETAILS,
  LINK_APP_TO_CONNECTOR,
  GET_LINKED_APP_INSTANCE_CONNECTOR,
  GET_CONNECTOR_INSTANCE_SETTINGS_DETAILS,
  ADD_CONNECTOR_INSTANCE_SETTINGS_JSON,
  GET_APP_INSTANCE_ROLE_PERMISSIONS_LIST,
  GET_APP_INSTANCE_API_KEYS,
  DELETE_APP_INSTANCE_API_KEY,
  UPDATE_APP_INSTANCE_API_KEY,
} from "redux/constants/appInstance";

import {
  appSettingsJson,
  connectorSettingsJson,
  showAppInstanceMessage,
  appInstanceRoles,
  appRolesInstanceRoles,
  setAddEdit,
  appInstanceRolePermissions,
  setModalRolePopUpData,
  appInstanceApiKey,
  appInstanceUserRoles,
  appInstaneData,
  appInstanceUiPropertiesJson,
  linkedAppInstaneData,
  appInstanceApiKeys
} from "redux/actions/appInstance";
import { API_BASE_DEVELOPER_APP, API_BASE_URL } from "configs/AppConfig";

import { AUTH_TOKEN } from "../constants/Auth";
import { useTranslation } from "react-i18next";
import { t } from "i18next";
import user from "redux/reducers/user";

export function* getApplicationSettingsDetail() {
  yield takeEvery(GET_APP_INSTANCE_SETTINGS_DETAILS, function* ({ payload }) {
    try {
      const response = yield axios.get(
        `${API_BASE_DEVELOPER_APP}/developerApp/accountAppInstance/settings/${payload?.appInstanceId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
            "Access-Control-Allow-Origin": "*",
            Accept: "application/json",
            "Content-Type": "application/json",
            accountId: localStorage.getItem("accountId"),
            appInstanceId: payload?.appInstanceId,
            accountUserId: localStorage.getItem("accountUserId"),
          },
        }
      );

      if (response.status === 200) {
        if (response?.data != null) {
          yield put(appSettingsJson(response.data));
        }
      }
    } catch (error) {
      console.log(error);
      yield put(showAppInstanceMessage(error.response));
    }
  });
}

export function* getConnectorSettingsDetail() {
  yield takeEvery(
    GET_CONNECTOR_INSTANCE_SETTINGS_DETAILS,
    function* ({ payload }) {
      try {
        const response = yield axios.get(
          `${API_BASE_DEVELOPER_APP}/developerApp/accountAppInstance/settings/${payload?.appInstanceId}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
              "Access-Control-Allow-Origin": "*",
              Accept: "application/json",
              "Content-Type": "application/json",
              accountId: localStorage.getItem("accountId"),
              appInstanceId: payload?.appInstanceId,
              accountUserId: localStorage.getItem("accountUserId"),
            },
          }
        );

        if (response.status === 200) {
          if (response?.data != null) {
            yield put(connectorSettingsJson(response.data));
          }
        }
      } catch (error) {
        console.log(error);

        yield put(showAppInstanceMessage(error.response));
      }
    }
  );
}

export function* getAppInstanceDetails() {
  yield takeEvery(GET_APP_INSTANCE_DETAILS, function* ({ payload }) {
    try {
      const response = yield axios.get(
        `${API_BASE_DEVELOPER_APP}/developerApp/app/appInstance/${payload?.appInstanceId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
            "Access-Control-Allow-Origin": "*",
            Accept: "application/json",
            "Content-Type": "application/json",
            accountId: localStorage.getItem("accountId"),
            appInstanceId: payload?.appInstanceId,
            accountUserId: localStorage.getItem("accountUserId"),
          },
        }
      );

      if (response.status === 200) {
        if (response?.data != null) {
          yield put(appInstaneData(response.data));
        }
      }
    } catch (error) {
      console.log(error);

      yield put(showAppInstanceMessage(error.response));
    }
  });
}

export function* addAppInstanceRole() {
  yield takeEvery(ADD_APP_INSTANCE_ROLE, function* ({ payload }) {
    try {
      const { values, checkedPermission } = payload;
      //const accountId = localStorage.getItem("accountId");
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
          Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
          accountId: localStorage.getItem("accountId"),
          appInstanceId: localStorage.getItem("appInstanceId"),
          accountUserId: localStorage.getItem("accountUserId"),
        },
      };
      const res = yield axios.post(
        `${API_BASE_DEVELOPER_APP}/developerApp/appInstanceRole`,
        values,
        config
      );
      if (res.status === 200) {
        var appInstanceRolePermissionData = {};
        if (res.data.appInstanceRole) {
          Modal.success({
            title: t("impaqx.adminApp.appInstanceRoleCreateMessage"),
            icon: <CheckCircleFilled />,
            okText: "Close",
            //cancelText: 'Cancel',
            onOk() {
              //deleteApp(id);
            },
          });
          const appInstanceRoleResponse = yield axios.get(
            `${API_BASE_DEVELOPER_APP}/developerApp/appInstanceRoles?appInstanceId=${
              res?.data?.appInstanceRole?.appInstanceId
            }&query=${payload?.query}&sortfield=${
              payload?.sorter?.field
            }&sortorder=${payload?.sorter?.order}&page=${
              payload?.pagination?.current - 1
            }&size=${payload?.pagination?.pageSize}`,
            config
          );
          if (appInstanceRoleResponse.status === 200) {
            yield put(appInstanceRoles(appInstanceRoleResponse.data));
            yield put(setAddEdit(false));
          }
          appInstanceRolePermissionData["appInstanceRoleId"] =
            res.data.appInstanceRole.id;
          appInstanceRolePermissionData["appInstanceId"] =
            res.data.appInstanceRole.appInstanceId;
          appInstanceRolePermissionData["appId"] =
            res.data.appInstanceRole.appId;
          appInstanceRolePermissionData["userCreated"] =
            res.data.appInstanceRole.userCreated;
          appInstanceRolePermissionData["userUpdated"] =
            res.data.appInstanceRole.userCreated;
          appInstanceRolePermissionData["checkedPermission"] =
            checkedPermission;
          const response = yield axios.post(
            `${API_BASE_DEVELOPER_APP}/developerApp/appInstanceRolePermission`,
            appInstanceRolePermissionData,
            config
          );
          if (response.status === 200) {
            //	yield put(apps(response.data));
          }
          //});
        }
      }
    } catch (err) {
      console.log(err);
      yield put(showAppInstanceMessage(err?.response));
    }
  });
}

export function* updateAppInstanceRole() {
  yield takeEvery(UPDATE_APP_INSTANCE_ROLE, function* ({ payload }) {
    try {
      const { values, checkedPermission } = payload;
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
          Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
          accountId: localStorage.getItem("accountId"),
          appInstanceId: localStorage.getItem("appInstanceId"),
          accountUserId: localStorage.getItem("accountUserId"),
        },
      };
      const res = yield axios.put(
        `${API_BASE_DEVELOPER_APP}/developerApp/appInstanceRole/${values.id}`,
        values,
        config
      );
      if (res.status === 200) {
        //const response = yield  axios.get(`${API_BASE_DEVELOPER_APP}/developerApp/appDefaultUserRoles?appId=${res?.data?.appId}&query=${payload?.query}&sortfield=${payload?.sorter?.field}&sortorder=${payload?.sorter?.order}&page=${payload?.pagination?.current-1}&size=${payload?.pagination?.pageSize}`);
        //if (response.status===200) {
        var appInstanceRolePermissionData = {};
        appInstanceRolePermissionData["appInstanceRoleId"] =
          res.data.appInstanceRole.id;
        // appDefaultUserRolePermissionData["status"]="ACTIVE";
        appInstanceRolePermissionData["userCreated"] =
          res.data.appInstanceRole.userCreated;
        appInstanceRolePermissionData["userUpdated"] =
          res.data.appInstanceRole.userCreated;
        appInstanceRolePermissionData["appInstanceId"] =
          res.data.appInstanceRole.appInstanceId;
        appInstanceRolePermissionData["appId"] = res.data.appInstanceRole.appId;

        // Object.keys(checkedPermission).forEach(function(appPermissionId) {
        // 	appDefaultUserRolePermissionData["appPermissionsId"]=appPermissionId;
        appInstanceRolePermissionData["checkedPermission"] = checkedPermission;
        const response = yield axios.post(
          `${API_BASE_DEVELOPER_APP}/developerApp/appInstanceRolePermission`,
          appInstanceRolePermissionData,
          config
        );
        if (response.status === 200) {
          Modal.success({
            title: `${values.roleName}  ${t(
              "impaqx.adminApp.updatePermissionMessage"
            )}`,
            icon: <CheckCircleFilled />,
            okText: "Close",
            //cancelText: 'Cancel',
            onOk() {
              //deleteApp(id);
            },
          });
          const appRoleResponse = yield axios.get(
            `${API_BASE_DEVELOPER_APP}/developerApp/appInstanceRoles?appInstanceId=${
              res?.data?.appInstanceRole?.appInstanceId
            }&query=${payload?.query}&sortfield=${
              payload?.sorter?.field
            }&sortorder=${payload?.sorter?.order}&page=${
              payload?.pagination?.current - 1
            }&size=${payload?.pagination?.pageSize}`,
            config
          );
          if (appRoleResponse.status === 200) {
            yield put(appInstanceRoles(appRoleResponse.data));
            yield put(setAddEdit(false));
          }        }
        //});
        //yield put(appRoles(response.data));

        //	}
      }
    } catch (error) {
      console.log(error);
      yield put(showAppInstanceMessage(error.response));
    }
  });
}

export function* deleteAppRole() {
  yield takeEvery(DELETE_APP_INSTANCE_ROLE, function* ({ payload }) {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
          Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
          accountId: localStorage.getItem("accountId"),
          appInstanceId: localStorage.getItem("appInstanceId"),
          accountUserId: localStorage.getItem("accountUserId"),
        },
      };
      const res = yield axios.delete(
        `${API_BASE_DEVELOPER_APP}/developerApp/appInstanceRole/${payload.id}`,
        config
      );
      if (res.status === 200) {
        //yield put(showAccountMessage(res));
        const response = yield axios.get(
          `${API_BASE_DEVELOPER_APP}/developerApp/appInstanceRoles?appInstanceId=${
            payload?.appInstanceId
          }&query=${payload?.query}&sortfield=${
            payload?.sorter?.field
          }&sortorder=${payload?.sorter?.order}&page=${
            payload?.pagination?.current - 1
          }&size=${payload?.pagination?.pageSize}`,
          config
        );
        if (response.status === 200) {
          Modal.success({
            title: `${payload.roleName}  ${t(
              "impaqx.adminApp.roleDeleteMessage"
            )}`,
            icon: <CheckCircleFilled />,
            okText: "Close",
            //cancelText: 'Cancel',
            onOk() {
              //deleteApp(id);
            },
          });
          yield put(appInstanceRoles(response.data));
        }
      }
    } catch (error) {
      console.log(error);
      if(error?.response?.status==403)
      Modal.error({
        title: t("impaqx.adminApp.roleDeletedErrorMessage"),
        icon: <WarningFilled />,
        okText: t("impaqx.adminApp.close"),
        //cancelText: 'Cancel',
        onOk() {
          //deleteApp(id);
        },
      });
      yield put(showAppInstanceMessage(error.response));
    }
  });
}

export function* getAppInstanceRoles() {
  yield takeEvery(GET_APP_INSTANCE_ROLES, function* ({ payload }) {
    const userId = localStorage.getItem("id");
    const accountId = localStorage.getItem("accountId");
    const config = {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
        accountId: localStorage.getItem("accountId"),
        appInstanceId: localStorage.getItem("appInstanceId"),
        accountUserId: localStorage.getItem("accountUserId"),
      },
    };
    try {
      const res = yield axios.get(
        `${API_BASE_DEVELOPER_APP}/developerApp/appInstanceRoles?appInstanceId=${
          payload?.appInstanceId
        }&appId=${payload?.appId}&query=${payload?.query}&sortfield=${
          payload?.sorter?.field
        }&sortorder=${payload?.sorter?.order}&page=${
          payload?.pagination?.current - 1
        }&size=${payload?.pagination?.pageSize}`,
        config
      );
      if (res.status === 200) {
        yield put(appInstanceRoles(res.data));
      }
    } catch (err) {
      console.log(err);
      yield put(showAppInstanceMessage(err.response));
    }
  });
}

export function* getAppRolesInstanceRoles() {
  yield takeEvery(GET_APP_ROLES_INSTANCE_ROLES, function* ({ payload }) {
    const userId = localStorage.getItem("id");
    const accountId = localStorage.getItem("accountId");
    const config = {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
        accountId: localStorage.getItem("accountId"),
        appInstanceId: localStorage.getItem("appInstanceId"),
        accountUserId: localStorage.getItem("accountUserId"),
      },
    };
    try {
      const res = yield axios.get(
        `${API_BASE_DEVELOPER_APP}/developerApp/appRolesInstanceRoles?appInstanceId=${
          payload?.appInstanceId
        }&userType=${payload?.userType}&appId=${payload?.appId}&query=${payload?.query}&sortfield=${
          payload?.sorter?.field
        }&sortorder=${payload?.sorter?.order}&page=${
          payload?.pagination?.current - 1
        }&size=${payload?.pagination?.pageSize}`,
        config
      );
      if (res.status === 200) {
        yield put(appRolesInstanceRoles(res.data));
      }
    } catch (err) {
      console.log(err);
      yield put(showAppInstanceMessage(err.response));
    }
  });
}

export function* getAppInstanceRolePermission() {
  yield takeEvery(GET_APP_INSTANCE_ROLE_PERMISSIONS, function* ({ payload }) {
    const userId = localStorage.getItem("id");
    const accountId = localStorage.getItem("accountId");
    const config = {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
        accountId: localStorage.getItem("accountId"),
        appInstanceId: localStorage.getItem("appInstanceId"),
        accountUserId: localStorage.getItem("accountUserId"),
      },
    };
    try {
      const res = yield axios.get(
        `${API_BASE_DEVELOPER_APP}/developerApp/appInstanceRolePermissons?appInstanceRoleId=${
          payload?.appInstanceRoleId
        }&query=${payload?.query}&sortfield=${
          payload?.sorter?.field
        }&sortorder=${payload?.sorter?.order}&page=${
          payload?.pagination?.current - 1
        }&size=${payload?.pagination?.pageSize}`,
        config
      );
      if (res.status === 200) {
        yield put(appInstanceRolePermissions(res.data));
      }
    } catch (err) {
      console.log(err);
      yield put(showAppInstanceMessage(err.response));
    }
  });
}

export function* getAppInstanceRolePermissionsList() {
  yield takeEvery(GET_APP_INSTANCE_ROLE_PERMISSIONS_LIST, function* ({ payload }) {
    const userId = localStorage.getItem("id");
    const accountId = localStorage.getItem("accountId");
    const config = {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
        accountId: localStorage.getItem("accountId"),
        appInstanceId: localStorage.getItem("appInstanceId"),
        accountUserId: localStorage.getItem("accountUserId"),
      },
    };
    try {
      const res = yield axios.get(
        `${API_BASE_DEVELOPER_APP}/developerApp/appInstanceRolePermissonsList?appInstanceRoleId=${
          payload?.appInstanceRoleId
        }&query=${payload?.query}&sortfield=${
          payload?.sorter?.field
        }&sortorder=${payload?.sorter?.order}&page=${
          payload?.pagination?.current - 1
        }&size=${payload?.pagination?.pageSize}`,
        config
      );
      if (res.status === 200) {
        yield put(appInstanceRolePermissions(res.data));
      }
    } catch (err) {
      console.log(err);
      yield put(showAppInstanceMessage(err.response));
    }
  });
}

export function* getAssignInstanceUserRoles() {
  yield takeEvery(GET_ASSIGN_INSTANCE_USER_ROLES, function* ({ payload }) {
    const { appInstanceId, accountUserId, appId } = payload;
    //  const accountId = localStorage.getItem("accountId");
    const config = {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
        accountId: localStorage.getItem("accountId"),
        appInstanceId: appInstanceId,
        accountUserId: localStorage.getItem("accountUserId"),
      },
    };
    try {
      const res = yield axios.get(
        `${API_BASE_DEVELOPER_APP}/developerApp/userRolesList?appInstanceId=${appInstanceId}&appId=${appId}&accountUserId=${accountUserId}`,
        config
      );
      if (res.status === 200) {
        yield put(appInstanceUserRoles(res.data));
      }
    } catch (err) {
      console.log(err);
      yield put(showAppInstanceMessage(err.response));
    }
  });
}

export function* assignAppInstanceRoleToUser() {
  yield takeEvery(ASSIGN_APP_ROLE_TO_USER, function* ({ payload }) {
    try {
      const {
        appInstanceId,
        appId,
        accountUserId,
        checkedRoles,
        checkedDefaultRoles,
      } = payload;
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
          Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
          accountId: localStorage.getItem("accountId"),
          appInstanceId: localStorage.getItem("appInstanceId"),
          accountUserId: localStorage.getItem("accountUserId"),
        },
      };
      const userRolesList = {};
      userRolesList["accountUserId"] = accountUserId;
      userRolesList["appInstanceId"] = appInstanceId;
      userRolesList["appId"] = appId;
      userRolesList["checkedRoles"] = checkedRoles;
      userRolesList["checkedDefaultRoles"] = checkedDefaultRoles;
      userRolesList["userUpdated"] = localStorage.getItem("userId");
      userRolesList["userCreated"] = localStorage.getItem("userId");
      const response = yield axios.post(
        `${API_BASE_DEVELOPER_APP}/developerApp/assignRolesToUser`,
        userRolesList,
        config
      );
      if (response.status === 200) {
        Modal.success({
          title: t("impaqx.adminApp.appRoleAssignedUser"),
          icon: <CheckCircleFilled />,
          okText: t("impaqx.adminApp.close"),
          //cancelText: 'Cancel',
          onOk() {
            //deleteApp(id);
          },
        });
        yield put(appInstanceRoles(response.data));
      }
    } catch (err) {
      console.log(err);
      yield put(showAppInstanceMessage(err));
    }
  });
}

export function* setModalRolePopUp() {
  yield takeEvery(SET_MODAL_POPUP, function* ({ payload }) {
    yield put(setModalRolePopUpData(payload));
  });
}

export function* addAppSettingsReactJson() {
  yield takeEvery(ADD_APP_INSTANCE_SETTINGS_JSON, function* ({ payload }) {
    const { appInstanceId, settingsValues, customizeSettings } = payload;
    const id = appInstanceId;
    console.log("customizeSettings values", customizeSettings);
    const config = {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
        accountId: localStorage.getItem("accountId"),
        appInstanceId: localStorage.getItem("appInstanceId"),
        accountUserId: localStorage.getItem("accountUserId"),
      },
    };
    try {
      const res = settingsValues
        ? yield axios.put(
            `${API_BASE_DEVELOPER_APP}/developerApp/accountAppInstance/settings/${id}`,
            {
              [`settingsValues`]: settingsValues,
            },
            config
          )
        : yield axios.put(
            `${API_BASE_DEVELOPER_APP}/developerApp/accountAppInstance/settings/${id}`,
            {
              [`customSettings`]: customizeSettings,
            },
            config
          );

      if (res.status === 200) {
        // yield put(appSettingsJson(res.data));
        const response = yield axios.get(
          `${API_BASE_DEVELOPER_APP}/developerApp/accountAppInstance/settings/${payload?.appInstanceId}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
              "Access-Control-Allow-Origin": "*",
              Accept: "application/json",
              "Content-Type": "application/json",
              accountId: localStorage.getItem("accountId"),
              appInstanceId: payload?.appInstanceId,
              accountUserId: localStorage.getItem("accountUserId"),
            },
          }
        );

        if (response.status === 200) {
          if (response?.data != null) {
            yield put(appSettingsJson(response.data));
          }
        }
        Modal.success({
          title: t("impaqx.adminApp.formConfigurationUpdateSuccessMessage"),

          icon: <CheckCircleFilled />,

          okText: t("impaqx.adminApp.close"),
        });
      }
    } catch (err) {
      console.log(err);

      yield put(showAppInstanceMessage(err.response));
    }
  });
}

export function* addConnectorSettingsReactJson() {
  yield takeEvery(
    ADD_CONNECTOR_INSTANCE_SETTINGS_JSON,
    function* ({ payload }) {
      const { appInstanceId, settingsSchema, settingsValues } = payload;
      const id = appInstanceId;
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
          Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
          accountId: localStorage.getItem("accountId"),
          appInstanceId: localStorage.getItem("appInstanceId"),
          accountUserId: localStorage.getItem("accountUserId"),
        },
      };
      try {
        const res = yield axios.put(
          `${API_BASE_DEVELOPER_APP}/developerApp/accountAppInstance/settings/${id}`,
          {
            [`settingsValues`]: settingsValues,
          },
          config
        );

        if (res.status === 200) {
          yield put(connectorSettingsJson(res.data));
          Modal.success({
            title: t("impaqx.adminApp.formConfigurationUpdateSuccessMessage"),

            icon: <CheckCircleFilled />,

            okText: t("impaqx.adminApp.close"),
          });
        }
      } catch (err) {
        console.log(err);

        yield put(showAppInstanceMessage(err.response));
      }
    }
  );
}

export function* saveAppInstanceApiKey() {
  yield takeEvery(SAVE_APP_INSTANCE_API_KEY, function* ({ payload }) {
    const { apiKey } = payload;
    const config = {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
        accountId: localStorage.getItem("accountId"),
        appInstanceId: localStorage.getItem("appInstanceId"),
        accountUserId: localStorage.getItem("accountUserId"),
      },
    };
    try {
      const res = yield axios.post(
        `${API_BASE_DEVELOPER_APP}/developerApp/app-instance-api-key`,
        apiKey,
        config
      );

      if (res.status === 200) {
        const res = yield axios.get(
          `${API_BASE_DEVELOPER_APP}/developerApp/app-instance-api-keys?appInstanceId=${apiKey?.appInstanceId}`,
          config
        );
        if (res.status === 200) {
          yield put(appInstanceApiKeys(res.data));
        }
        yield put(appInstanceApiKey(res.data.appInstanceApiKey));

        Modal.success({
          title: `${apiKey.appName} Customer Api key saved successfully`,

          icon: <CheckCircleFilled />,

          okText: t("impaqx.adminApp.close"),
        });
      }
    } catch (err) {
      console.log(err);

      yield put(showAppInstanceMessage(err.response));
    }
  });
}

export function* getAppInstanceApiKey() {
  yield takeEvery(GET_APP_INSTANCE_API_KEY, function* ({ payload }) {
    const { appInstanceId } = payload;
    const config = {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
        accountId: localStorage.getItem("accountId"),
        appInstanceId: localStorage.getItem("appInstanceId"),
        accountUserId: localStorage.getItem("accountUserId"),
      },
    };
    try {
      const res = yield axios.get(
        `${API_BASE_DEVELOPER_APP}/developerApp/app-instance-api-key?userId=${localStorage.getItem(
          "userId"
        )}&appInstanceId=${appInstanceId}`,
        config
      );

      if (res.status === 200) {
        yield put(appInstanceApiKey(res.data));
      }
    } catch (err) {
      console.log(err);

      yield put(showAppInstanceMessage(err.response));
    }
  });
}

export function* getAppInstanceApiKeys() {
  yield takeEvery(GET_APP_INSTANCE_API_KEYS, function* ({ payload }) {
    const { appInstanceId } = payload;
    const config = {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
        accountId: localStorage.getItem("accountId"),
        appInstanceId: localStorage.getItem("appInstanceId"),
        accountUserId: localStorage.getItem("accountUserId"),
      },
    };
    try {
      const res = yield axios.get(
        `${API_BASE_DEVELOPER_APP}/developerApp/app-instance-api-keys?appInstanceId=${appInstanceId}&query=${payload?.query}&sortfield=${
          payload?.sorter?.field
        }&sortorder=${payload?.sorter?.order}&page=${
          payload?.pagination?.current - 1
        }&size=${payload?.pagination?.pageSize}`,
        config
      );

      if (res.status === 200) {
        yield put(appInstanceApiKeys(res.data));
      }
    } catch (err) {
      console.log(err);

      yield put(showAppInstanceMessage(err.response));
    }
  });
}

export function* updateAppInstanceApiKey() {
  yield takeEvery(UPDATE_APP_INSTANCE_API_KEY, function* ({ payload }) {
    const { apiKey } = payload;
    const config = {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
        accountId: localStorage.getItem("accountId"),
        appInstanceId: localStorage.getItem("appInstanceId"),
        accountUserId: localStorage.getItem("accountUserId"),
      },
    };
    try {
      const res = yield axios.put(
        `${API_BASE_DEVELOPER_APP}/developerApp/app-instance-api-key/${apiKey?.id}`,
        apiKey,
        config
      );

      if (res.status === 200) {
        Modal.success({
          title: `${apiKey.appName}  Customer Api key updated successfully`,

          icon: <CheckCircleFilled />,

          okText: t("impaqx.adminApp.close"),
        });
        const res = yield axios.get(
          `${API_BASE_DEVELOPER_APP}/developerApp/app-instance-api-keys?appInstanceId=${apiKey?.appInstanceId}`,
          config
        );
  
        if (res.status === 200) {
          yield put(appInstanceApiKeys(res.data));
        }
        yield put(appInstanceApiKey(res.data));
      }
    } catch (err) {
      console.log(err);

      yield put(showAppInstanceMessage(err.response));
    }
  });
}

export function* deleteAppInstanceApiKey() {
  yield takeEvery(DELETE_APP_INSTANCE_API_KEY, function* ({ payload }) {
    const { apiKey } = payload;
    const config = {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
        accountId: localStorage.getItem("accountId"),
        appInstanceId: localStorage.getItem("appInstanceId"),
        accountUserId: localStorage.getItem("accountUserId"),
      },
    };
    try {
      const res = yield axios.delete(
        `${API_BASE_DEVELOPER_APP}/developerApp/app-instance-api-key/${apiKey?.id}`,
        config
      );

      if (res.status === 200) {
        const res = yield axios.get(
          `${API_BASE_DEVELOPER_APP}/developerApp/app-instance-api-keys?appInstanceId=${apiKey?.appInstanceId}`,
          config
        );
  
        if (res.status === 200) {
          Modal.success({
            title: `${apiKey.appName} Customer Api key deleted successfully`,
  
            icon: <CheckCircleFilled />,
  
            okText: t("impaqx.adminApp.close"),
          });
          yield put(appInstanceApiKeys(res.data));
        }
        yield put(appInstanceApiKey(res.data));
      }
    } catch (err) {
      console.log(err);

      yield put(showAppInstanceMessage(err.response));
    }
  });
}

export function* addUiInstancePropertiesData() {
  yield takeEvery(ADD_APP_INSTANCE_UI_PROPERTIES, function* ({ payload }) {
    const { appInstanceId, editableProp, query, sortField, sortorder } =
      payload;
    const config = {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
        accountId: localStorage.getItem("accountId"),
        accountUserId: localStorage.getItem("accountUserId"),
      },
    };
    console.log(editableProp);
    try {
      const res = yield axios.put(
        `${API_BASE_DEVELOPER_APP}/developerApp/accountAppInstance/settings/${appInstanceId}`,
        { ["uiPropertyValues"]: JSON.stringify(editableProp) },
        config
      );
      if (res.status === 200) {
        const response = yield axios.get(
          //`${API_BASE_DEVELOPER_APP}/developerApp/properties/app-ui-properties?appInstanceId=${payload?.appInstanceId}&query=${query}&sortfield=${sortField}&sortorder=${sortorder}`,
          `${API_BASE_DEVELOPER_APP}/developerApp/accountAppInstance/ui-properties/${appInstanceId}?query=${query}&sortfield=${sortField}&sortorder=${sortorder}`,
          config
        );
        if (response.status === 200) {
          yield put(appInstanceUiPropertiesJson(response?.data));
        }
        // Modal.success({
        //   title: t("impaqx.adminApp.propertySaveMessage"),
        //   icon: <ExclamationCircleOutlined />,
        //   okText: "Close",
        // });
      }
    } catch (err) {
      console.log(err);
      yield put(showAppInstanceMessage(err.response));
    }
  });
}
export function* getUiPropertiesDetails() {
  yield takeEvery(APP_INSTANCE_UI_PROPERTIES, function* ({ payload }) {
    const { appId, query, sortField, sortorder } = payload;
    const config = {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
        accountId: localStorage.getItem("accountId"),
        appInstanceId: localStorage.getItem("appInstanceId"),
        accountUserId: localStorage.getItem("accountUserId"),
      },
    };
    try {
      const res = yield axios.get(
        //`${API_BASE_DEVELOPER_APP}/developerApp/properties/app-ui-properties?appInstanceId=${payload?.appInstanceId}&query=${query}&sortfield=${sortField}&sortorder=${sortorder}`,
        `${API_BASE_DEVELOPER_APP}/developerApp/accountAppInstance/ui-properties/${payload?.appInstanceId}?query=${query}&sortfield=${sortField}&sortorder=${sortorder}`,
        config
      );
      if (res.status === 200) {
        yield put(appInstanceUiPropertiesJson(res?.data));
      }
    } catch (error) {
      console.log(error);
      yield put(showAppInstanceMessage(error?.response));
    }
  });
}

export function* deleteUiInstancePropertiesData() {
  yield takeEvery(DELETE_APP_INSTANCE_UI_PROPERTIES, function* ({ payload }) {
    const { appInstanceId, editableProp, query, sortField, sortorder } =
      payload;
    const config = {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
        accountId: localStorage.getItem("accountd"),
        accountUserId: localStorage.getItem("accountUserId"),
      },
    };
    console.log(editableProp);
    try {
      const res = yield axios.put(
        `${API_BASE_DEVELOPER_APP}/developerApp/accountAppInstance/ui-properties/${appInstanceId}`,
        { ["uiPropertyValues"]: JSON.stringify(editableProp) },
        config
      );
      if (res.status === 200) {
        const response = yield axios.get(
          `${API_BASE_DEVELOPER_APP}/developerApp/accountAppInstance/ui-properties/${payload?.appInstanceId}?query=${query}&sortfield=${sortField}&sortorder=${sortorder}`,
          config
        );
        //`${API_BASE_DEVELOPER_APP}/developerApp/properties/ui-proerties?appId=${appId}&query=${query}&sortfield=${sortField}&sortorder=${sortorder}`,
        if (response.status === 200) {
          yield put(appInstanceUiPropertiesJson(response?.data));
        }
        Modal.success({
          title: t("impaqx.adminApp.uiPropertyDeleteMessage"),
          icon: <ExclamationCircleOutlined />,
          okText: "Close",
        });
      }
    } catch (err) {
      console.log(err);
      yield put(showAppInstanceMessage(err.response));
    }
  });
}

export function* linkAppToConnector() {
  yield takeEvery(LINK_APP_TO_CONNECTOR, function* ({ payload }) {
    const config = {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
        accountId: localStorage.getItem("accountId"),
        appInstanceId: localStorage.getItem("appInstanceId"),
        accountUserId: localStorage.getItem("accountUserId"),
      },
    };
    try {
      const { connectorId, accountId, appInstanceId, status } = payload;
      const data = {};
      data["appInstanceId"] = appInstanceId;
      data["accountId"] = accountId;
      data["userCreated"] = localStorage.getItem("userId");
      data["userUpdated"] = localStorage.getItem("userId");
      data["connectorAppInstanceId"] = connectorId;
      data["status"] = status;
      const response = yield axios.post(
        `${API_BASE_DEVELOPER_APP}/developerApp/link-connector-app`,
        data,
        config
      );
      if (response.status === 200) {
        var title = "Connector linked to app successfully";
        if (status == "INACTIVE") {
          title = "Connector removed from the app successfully";
        }
        Modal.success({
          title: title,
          icon: <CheckCircleFilled />,
          okText: "Close",
        });
      }
    } catch (err) {
      console.log(err);
      yield put(showAppInstanceMessage(err?.response));
    }
  });
}

export function* getAppInstanceConnectorDetails() {
  yield takeEvery(GET_APP_INSTANCE_CONNECTOR_DETAILS, function* ({ payload }) {
    try {
      const response = yield axios.get(
        `${API_BASE_DEVELOPER_APP}/developerApp/subscribedApps?type=connector&accountId=${localStorage.getItem(
          "accountId"
        )}&query=${payload?.query}&sortfield=${
          payload?.sorter?.field
        }&sortorder=${payload?.sorter?.order}&page=${
          payload?.pagination?.current - 1
        }&size=${payload?.pagination?.pageSize}=`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
            "Access-Control-Allow-Origin": "*",
            Accept: "application/json",
            "Content-Type": "application/json",
            accountId: localStorage.getItem("accountId"),
            appInstanceId: payload?.appInstanceId,
            accountUserId: localStorage.getItem("accountUserId"),
          },
        }
      );

      if (response.status === 200) {
        if (response?.data != null) {
          yield put(appInstaneData(response.data));
        }
      }
    } catch (error) {
      console.log(error);

      yield put(showAppInstanceMessage(error.response));
    }
  });
}

export function* getLinkedAppInstanceConnectorDetails() {
  yield takeEvery(GET_LINKED_APP_INSTANCE_CONNECTOR, function* ({ payload }) {
    const { appInstanceId } = payload;
    try {
      const response = yield axios.get(
        `${API_BASE_DEVELOPER_APP}/developerApp/link-connectors-app?appInstanceId=${appInstanceId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
            "Access-Control-Allow-Origin": "*",
            Accept: "application/json",
            "Content-Type": "application/json",
            accountId: localStorage.getItem("accountId"),
            appInstanceId: payload?.appInstanceId,
            accountUserId: localStorage.getItem("accountUserId"),
          },
        }
      );

      if (response.status === 200) {
        if (response?.data != null) {
          yield put(linkedAppInstaneData(response.data));
        }
      }
    } catch (error) {
      console.log(error);

      yield put(showAppInstanceMessage(error.response));
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(getApplicationSettingsDetail),
    fork(getConnectorSettingsDetail),
    fork(getAppInstanceDetails),
    fork(addAppInstanceRole),
    fork(deleteAppRole),
    fork(getAppInstanceRoles),
    fork(getAppRolesInstanceRoles),
    fork(getAppInstanceRolePermission),
    fork(updateAppInstanceRole),
    fork(assignAppInstanceRoleToUser),
    fork(setModalRolePopUp),
    fork(addAppSettingsReactJson),
    fork(addConnectorSettingsReactJson),
    fork(saveAppInstanceApiKey),
    fork(getAppInstanceApiKey),
    fork(getAssignInstanceUserRoles),
    fork(addUiInstancePropertiesData),
    fork(getUiPropertiesDetails),
    fork(deleteUiInstancePropertiesData),
    fork(getAppInstanceConnectorDetails),
    fork(linkAppToConnector),
    fork(getLinkedAppInstanceConnectorDetails),
    fork(getAppInstanceRolePermissionsList),
    fork(getAppInstanceApiKeys),
    fork(deleteAppInstanceApiKey),
    fork(updateAppInstanceApiKey)
  ]);
}
