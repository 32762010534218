import { ACCOUNT_LIST,GET_ACCOUNT_LIST,SAVE_ACCOUNT,ACCOUNT_MESSAGE,DELETE_ACCOUNT,ACCOUNT_REGISTER_SUCCESS,UPDATE_ACCOUNT,GET_DEFAULT_ACCOUNT,
    DEFAULT_ACCOUNT,AUTHENTICATED_NO_DEFAULT_ACCOUNT,UPDATE_DEFAULT_ACCOUNT,SHOW_LOADING,NO_DEFAULT_ACCOUNT,HIDE_LOADING } from "redux/constants/account";

export const getAccountList = (requestData)=>{
    return{
        type : GET_ACCOUNT_LIST,
        requestData : requestData
    }
}


export const accountList=(data)=>{
    return{
        type : ACCOUNT_LIST,
        accountList : data
    }

}


export const showLoading = () => {
    return {
      type: SHOW_LOADING,
    };
  };

  export const hideLoading=()=>{
    return {
      type : HIDE_LOADING
    }
  }

export const accountRegister=(data)=>{
    return{
        type : SAVE_ACCOUNT,
        payload : data
    }
}

export const updateAccount = (data)=>{
    return {
        type : UPDATE_ACCOUNT,
        payload : data
    }
}

export const registerSuccess=(response)=>{
    return{
        type : ACCOUNT_REGISTER_SUCCESS,
        response
    }
}


export const showAccountMessage=(response)=>{
    return{
        type : ACCOUNT_MESSAGE,
        response
    }
}

export const deleteAccount=(account)=>{
    return{
        type : DELETE_ACCOUNT,
        payload : account
    }
}

export const getDefaultAccount=(requestData)=>{
    return{
        type : GET_DEFAULT_ACCOUNT,
        requestData : requestData
    }
}

export const account=(response)=>{
    return{
        type:DEFAULT_ACCOUNT,
        data:response.data
    }
}

export const updateDefaultAccount=(account)=>{
    return{
        type : UPDATE_DEFAULT_ACCOUNT,
        payload:account
    }
}

