//export const API_BASE_URL = 'https://your-api-url.com/'

let baseUrl = '';
if (process.env.NODE_ENV === 'development') {
  baseUrl = 'http://localhost:3000';
} else {
  baseUrl = `https://${window.location.hostname}`;
}
export const BASE_URL = baseUrl;
export const BLOB_STORAGE_URL="https://impaqxcloudstorage.blob.core.windows.net"
export const PATH_VARIABLE = "/admin"
export const LINKEDIN_CLIENT_ID = "864s5wibtpr6co";
export const LINKEDIN_CLIENT_SECRETE = "MPL9fjrKgFzgJma7";
export const MICROSOFT_CLIENT_ID = "50ff453c-8368-4e2d-8fbe-bd575d148e68";
export const AUTHORITY_URL = "https://login.microsoftonline.com/common";
export const API_URL = "http://localhost:8080/oauth/token";
export const AURHENTICATTOR_APP = "https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&gl=US";

