import React, { useState } from "react";
import { Button, Tooltip } from "antd";
import { v4 as uuidv4 } from "uuid";
const AddEditFormField = ({ fieldData, setFieldData }) => {
  const permissions  = localStorage.getItem("permissions")!=""?JSON.parse(localStorage.getItem("permissions")):"";
  const handleAddField = () => {
    const formList = (fieldData && fieldData.length) > 0 ? [...fieldData] : [];
    const _id = uuidv4();
    formList.push({
      id: _id,
      type: "object",
      title: `CONFIGURATION GROUP - ${fieldData.length}`,
      properties: {},
    });
    setFieldData([...formList]);
  };
  return (
    <div style={{ alignSelf: "center" }}>
      {" "}
      {permissions?.allpermissions || permissions[`update_app_settings`]?<Tooltip placement="bottom" title={"Click to add new group Form"}>
        {" "}
        <Button
          type="primary"
          style={{ width: "fit-content", marginTop: "20px" }}
          onClick={handleAddField}
          size="large"
        >
          {" "}
          Add new group{" "}
        </Button>{" "}
      </Tooltip>:null}
    </div>
  );
};
export default AddEditFormField;
