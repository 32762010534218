import { all, takeEvery, put, fork } from 'redux-saga/effects';

//import axios from 'axios';
import axios from './initialize'
import { Modal } from 'antd';
import {CheckCircleFilled,ExclamationCircleOutlined} from  '@ant-design/icons';

import { GET_ACCOUNT_USER_LIST,SEND_INVITATION_MAIL,ACCEPT_INVITATION,GET_NOTIFICATION,CLEAR_NOTIFICATION,READ_NOTIFICATION, GET_INVITE_ACCOUNT_USER_LIST, REMOVE_ACCOUNT_USER, RESTORE_USER_TO_ACCOUNT, DELETE_NOTIFICATIONS, DELETE_NOTIFICATION, RESEND_INVITATION, DELETE_USER_INVITATION, GET_ALL_USER_LIST} from "redux/constants/user";

import { accountUserList, showAccountUserMessage,notificatonList,inviteAccountUserList, allUserList } from "redux/actions/user";
import { showAuthMessage} from "redux/actions/Auth";

import { API, API_BASE_URL, APP_PREFIX_PATH, AUTH_PREFIX_PATH, MANAGE_PREPIX_PATH, USERS_PREPIX_PATH, VERSION } from 'configs/AppConfig';
import {
	AUTH_TOKEN,
} from '../constants/Auth';
import { t } from 'i18next';

import {GET_ACCOUNT_LIST } from  "redux/constants/account";

export function* getAccountUserList(){
    yield takeEvery (GET_ACCOUNT_USER_LIST, function*  ({requestData}){
	    const {query, sorter,pagination,type,userType} = requestData;
	   const accountId = localStorage.getItem("accountId");
	  const headers = {Authorization : `Bearer ${localStorage.getItem(AUTH_TOKEN)}`}
	//  var tokenHeader = new HttpHeaders({ 'Authorization': 'Bearer ' + localStorage.getItem(AUTH_TOKEN), 'Content-Type': 'application/json' });
const config = {
		headers: { "Content-Type": 'application/json',"Access-Control-Allow-Origin": "*",
		"Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",Authorization : `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
	    "accountId": accountId,
		accountUserId: localStorage.getItem("accountUserId")}
	  };
		try {
		const res = yield  axios.get(`${API_BASE_URL}${MANAGE_PREPIX_PATH}${APP_PREFIX_PATH}/account-user/account-users?accountId=${accountId}&userType=${userType}&type=${type}&query=${query}&sortfield=${sorter?.field}&sortorder=${sorter?.order}&page=${pagination?.current-1}&size=${pagination?.pageSize}`,
		config);
			if (res.status===200) {
				yield put(accountUserList(res.data));
			} 
		} catch (err) {
			console.log(err);
			if(err?.response?.status==401 || err?.response?.data?.status==401){
				yield put(showAccountUserMessage(err.response));
			}
			yield put(showAccountUserMessage(err.response));
		}
    })
}

export function* updateAccountUser(){
    yield takeEvery (RESTORE_USER_TO_ACCOUNT, function*  ({accountUser}){
		const {query, sorter,pagination,accountId,userId,status,userType} = accountUser;
const config = {
		headers: { "Content-Type": 'application/json',"Access-Control-Allow-Origin": "*",
		"Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",Authorization : `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
		accountUserId: localStorage.getItem("accountUserId") }
	  };
	  const data = {"status":status};
		try {
		const res = yield  axios.put(`${API_BASE_URL}${MANAGE_PREPIX_PATH}${APP_PREFIX_PATH}/account-user/account-users?accountId=${accountId}&userId=${userId}`,data,
		config);
			if (res.status===200) {
				Modal.success({
					title: t("impaqx.adminApp.userRestored"),
					icon: <CheckCircleFilled />,
					okText: "Close",
					onOk() {	
					}
				});
				const res = yield  axios.get(`${API_BASE_URL}${MANAGE_PREPIX_PATH}${APP_PREFIX_PATH}/account-user/account-users?accountId=${accountUser.accountId}&userType=${userType}&type=inActiveUser&query=${query}&sortfield=${sorter?.field}&sortorder=${sorter?.order}&page=${pagination?.current-1}&size=${pagination?.pageSize}`,
		 config);
			 if (res.status===200) {
				 yield put(accountUserList(res.data));
			 } 
			} 
		} catch (err) {
			console.log(err);
			if(err?.response?.status==401 || err?.response?.data?.status==401){
				yield put(showAccountUserMessage(err.response));
			}
			yield put(showAccountUserMessage(err.response));
		}
    })
}

export function* getInviteAccountUserList(){
    yield takeEvery (GET_INVITE_ACCOUNT_USER_LIST, function*  ({requestData}){
	    const {query, sorter,pagination,type,userType} = requestData;
	   const accountId = localStorage.getItem("accountId");
	  const headers = {Authorization : `Bearer ${localStorage.getItem(AUTH_TOKEN)}`}
	//  var tokenHeader = new HttpHeaders({ 'Authorization': 'Bearer ' + localStorage.getItem(AUTH_TOKEN), 'Content-Type': 'application/json' });
const config = {
		headers: { "Content-Type": 'application/json',"Access-Control-Allow-Origin": "*",
		"Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",Authorization : `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
		accountUserId: localStorage.getItem("accountUserId") }
	  };
		try {
		const res = yield  axios.get(`${API_BASE_URL}${MANAGE_PREPIX_PATH}${APP_PREFIX_PATH}/account-user/invite-users?accountId=${accountId}&type=${type}&userType=${userType}
		&query=${query}&sortfield=${sorter?.field}&sortorder=${sorter?.order}&page=${pagination?.current-1}&size=${pagination?.pageSize}`,
		config);
			if (res.status===200) {
				yield put(inviteAccountUserList(res.data));
			} 
		} catch (err) {
			console.log(err);
			if(err?.response?.status==401 || err?.response?.data?.status==401){
				yield put(showAccountUserMessage(err.response));
			}
			yield put(showAccountUserMessage(err.response));
		}
    })
}

export function* sendInvitationEmail() {
	yield takeEvery(SEND_INVITATION_MAIL, function* ({payload}) {
		const {query, sorter,pagination,role,values,userType} = payload;
		  try {
			const config = {
				headers: {
				  "Content-Type": "application/json",
				  "Access-Control-Allow-Origin": "*",
				  "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
				  Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
				  accountId: localStorage.getItem("accountId"),
				  accountUserId: localStorage.getItem("accountUserId")
				},
			  };
			var data={};
			data["toEmail"] = values.username;
			data["fromAccountId"] = localStorage.getItem("accountId");
			data["fromUserId"] = localStorage.getItem("userId");
			data["roleId"] = role;
			data["userType"]=userType;
			data["appRoles"]=JSON.stringify(payload?.appRoles);
		  const res = yield  axios.post(`${API_BASE_URL}${MANAGE_PREPIX_PATH}${API}${VERSION}${USERS_PREPIX_PATH}/invite-user`, data,config);
			  if (res.status===200) {		
				  const response = yield  axios.get(`${API_BASE_URL}${MANAGE_PREPIX_PATH}${APP_PREFIX_PATH}/account-user/invite-users?accountId=${localStorage.getItem("accountId")}&type=pendingUsers&userType=${userType}
				  &query=${query}&sortfield=${sorter?.field}&sortorder=${sorter?.order}&page=${pagination?.current-1}&size=${pagination?.pageSize}`,
		config);
			if (response.status===200) {
				yield put(inviteAccountUserList(response.data));
			} 
			yield put(showAccountUserMessage(res));
			  } else {
				  yield put(showAuthMessage(res));
			  }
		  } catch (err) {
			  console.log(err.response);
			  yield put(showAuthMessage(err.response));
		  }
	  });
  }

  export function* removeAccountUser() {
	yield takeEvery(REMOVE_ACCOUNT_USER, function* ({payload}) {
		  try {
			const {accountUser,query, sorter,pagination,type,userType} = payload;

			const accountId = localStorage.getItem("accountId");
			const id= accountUser?.accountUserId;
			const config = {
				headers: {
				  "Content-Type": "application/json",
				  "Access-Control-Allow-Origin": "*",
				  "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
				  Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
				  accountId: accountId,
				  accountUserId: localStorage.getItem("accountUserId")
				},
			  };
			payload["toEmail"] = payload.username;
			payload["fromAccountId"] = localStorage.getItem("accountId");
			payload["fromUserId"] = localStorage.getItem("userId");
		  const res = yield  axios.delete(`${API_BASE_URL}${MANAGE_PREPIX_PATH}${APP_PREFIX_PATH}/account-user/${id}`,config);
			  if (res.status===200) {	
				Modal.success({
					title: t("impaqx.adminApp.userRemoveMessage"),
					icon: <CheckCircleFilled />,
					okText: "Close",
					onOk() {	
					}
				});	
				const response = yield  axios.get(`${API_BASE_URL}${MANAGE_PREPIX_PATH}${APP_PREFIX_PATH}/account-user/account-users?accountId=${accountId}&userType=${userType}&type=${type}&query=${query}&sortfield=${sorter?.field}&sortorder=${sorter?.order}&page=${pagination?.current-1}&size=${pagination?.pageSize}`,
		        config);
			if (response.status===200) {
				yield put(accountUserList(response.data));
			} 
				 // yield put(showAccountUserMessage(res));
			  } else {
				  yield put(showAuthMessage(res));
			  }
		  } catch (err) {
			  console.log(err.response);
			  yield put(showAuthMessage(err.response));
		  }
	  });
  }

  export function* deleteUserInvitation() {
	yield takeEvery(DELETE_USER_INVITATION, function* ({payload}) {
		  try {
			const {userInvitation,query, sorter,pagination,type,userType} = payload;

			const accountId = localStorage.getItem("accountId");
			const id= userInvitation?.id;
			const config = {
				headers: {
				  "Content-Type": "application/json",
				  "Access-Control-Allow-Origin": "*",
				  "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
				  Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
				  accountId: accountId,
				  accountUserId: localStorage.getItem("accountUserId")
				},
			  };
		  const res = yield  axios.delete(`${API_BASE_URL}${MANAGE_PREPIX_PATH}${APP_PREFIX_PATH}/account-user/userinvitation/${id}`,config);
			  if (res.status===200) {	
				Modal.success({
					title: t("impaqx.adminApp.deleteUserInvitationSuccessMessage"),
					icon: <CheckCircleFilled />,
					okText: "Close",
					onOk() {	
					}
				});	
					const response = yield  axios.get(`${API_BASE_URL}${MANAGE_PREPIX_PATH}${APP_PREFIX_PATH}/account-user/invite-users?accountId=${accountId}&type=${type}&userType=${userType}
					&query=${query}&sortfield=${sorter?.field}&sortorder=${sorter?.order}&page=${pagination?.current-1}&size=${pagination?.pageSize}`,
					config);
						if (response.status===200) {
							yield put(inviteAccountUserList(response.data));
						} 
			  } else {
				  yield put(showAuthMessage(res));
			  }
		  } catch (err) {
			  console.log(err.response);
			  yield put(showAuthMessage(err.response));
		  }
	  });
  }


  export function* acceptInvitations(){
    yield takeEvery (ACCEPT_INVITATION, function*  ({payload}){
	    const { key,nId } = payload;
	  const headers = {Authorization : `Bearer ${localStorage.getItem(AUTH_TOKEN)}`}
const config = {
		headers: { "Content-Type": 'application/json',"Access-Control-Allow-Origin": "*",
		"Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",Authorization : `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
		accountUserId: localStorage.getItem("accountUserId") }
	  };
		try {
		const res = yield  axios.put(`${API_BASE_URL}${MANAGE_PREPIX_PATH}${AUTH_PREFIX_PATH}/accept-invitation?key=${key}`,
		config);
			if (res.status===200) {
				if(res.data.userPresent){
					sessionStorage.setItem("activationMessage",t("impaqx.adminApp.acceptInvitationMessage"));
					sessionStorage.setItem("userPresent",res.data.userPresent);
					sessionStorage.setItem("email",res.data.email);
					sessionStorage.setItem("accountName",res?.data?.accountName);
					if(localStorage.getItem("userId")==res.data.accountUser.userId){
							Modal.success({
								title: t("impaqx.adminApp.acceptInvitationMessage").replace("email123",res.data.email).replace("account1234",res?.data?.accountName),
								icon: <CheckCircleFilled />,
								okText: "Close",
								//cancelText: 'Cancel',
								onOk() {	
									window.location.replace("/admin/app/pages/account-list?mode=view-all");
								}
							});
					}else{
						window.location.replace("/admin/auth/login");
					}
							//deleteApp(id);
				//}   
						
				}else{
					sessionStorage.setItem("activationMessage",t("impaqx.adminApp.acceptInvitationRegMessage"));
					sessionStorage.setItem("userPresent",res?.data?.userPresent);
					sessionStorage.setItem("email",res?.data?.email);
					sessionStorage.setItem("accountName",res?.data?.accountName);
                    window.location.replace(`/admin/auth/register?key=${res.data.key}&nId=${nId}`);
				}
			} 
		} catch (err) {
			console.log(err);
			if(err?.response?.status==400){
				sessionStorage.setItem("activationerrorMessage",err?.response?.data?.message);
				sessionStorage.setItem("userPresent",err?.response?.data?.userPresent);
				sessionStorage.setItem("email",err?.response?.data?.email);
				if(localStorage.getItem("userId")){
				  if(err?.response?.data?.message.includes('expired')){
                    Modal.info({
						title: err?.response?.data?.message,
						icon: <ExclamationCircleOutlined />,
						okText: "Yes",
						cancelText: 'No',
						okCancel:true,
						onOk() {	
							axios.put(`${API_BASE_URL}${MANAGE_PREPIX_PATH}${AUTH_PREFIX_PATH}/resend-invitation-request?key=${key}`,
							config).then(resendInviteResponse=> {
									Modal.info({
										title: t("impaqx.adminApp.invitationRequestMessage"),
										icon: <ExclamationCircleOutlined />,
										okText: "Yes",
										onOk() {
											window.location.replace("/admin/app/pages/account-list?mode=view-all");
										}
									});
								}).catch(error => {
								})	
						},
						onCancel() {
							window.location.replace("/admin/app/pages/account-list?mode=view-all");
						}
					})
				  }else{
					Modal.info({
						title: t("impaqx.adminApp.activationerrorMessage"),
						icon: <ExclamationCircleOutlined />,
						okText: "Close",
						onOk() {	
						  window.location.replace("/admin/app/pages/account-list?mode=view-all");
						}
					})
				  }
				 }else{
					window.location.replace("/admin/auth/login");
				}
			}
			if(err?.response?.status==401 || err?.response?.data?.status==401){
				yield put(showAccountUserMessage(err.response));
			}
			//yield put(showAccountUserMessage(err.response));
		}
    })
}

export function* getNotificationList(){
    yield takeEvery (GET_NOTIFICATION, function*  ({requestData}){
	//   const accountId = localStorage.getItem("accountId");
	   const userId = localStorage.getItem("userId");
	  const headers = {Authorization : `Bearer ${localStorage.getItem(AUTH_TOKEN)}`}
	//  var tokenHeader = new HttpHeaders({ 'Authorization': 'Bearer ' + localStorage.getItem(AUTH_TOKEN), 'Content-Type': 'application/json' });
const config = {
		headers: { "Content-Type": 'application/json',"Access-Control-Allow-Origin": "*",
		"Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",Authorization : `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
		accountUserId: localStorage.getItem("accountUserId") }
	  };
		try {
		const res = yield  axios.get(`${API_BASE_URL}${MANAGE_PREPIX_PATH}${API}${VERSION}${USERS_PREPIX_PATH}/notifications?userId=${userId}`,config);
			if (res.status===200) {
				yield put(notificatonList(res.data));
			} 
		} catch (err) {
			console.log(err);
			if(err?.response?.status==401 || err?.response?.data?.status==401){
				yield put(showAccountUserMessage(err.response));
			}
			yield put(showAccountUserMessage(err.response));
		}
    })
}

export function* clearNotificationData() {
	yield takeEvery(CLEAR_NOTIFICATION, function* ({payload}) {
		const userId = localStorage.getItem("userId");
		const config = {
			headers: {
			  "Content-Type": "application/json",
			  "Access-Control-Allow-Origin": "*",
			  "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
			  Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
			  accountId: localStorage.getItem("accountId"),
			  accountUserId: localStorage.getItem("accountUserId")
			},
		  };
		  try {
		  const res = yield  axios.put(`${API_BASE_URL}${MANAGE_PREPIX_PATH}${API}${VERSION}${USERS_PREPIX_PATH}/clear-notification?userId=${userId}`,config);
			  if (res.status===200) {		
				const res = yield  axios.get(`${API_BASE_URL}${MANAGE_PREPIX_PATH}${API}${VERSION}${USERS_PREPIX_PATH}/notifications?userId=${userId}`,config);
					if (res.status===200) {
						yield put(notificatonList(res.data));
					} 
				 // yield put(showAccountUserMessage(res));
			  } else {
				  yield put(showAuthMessage(res));
			  }
		  } catch (err) {
			  console.log(err.response);
			  yield put(showAuthMessage(err.response));
		  }
	  });
  }

  export function* readNotificationData() {
	yield takeEvery(READ_NOTIFICATION, function* ({payload}) {
		const userId = localStorage.getItem("userId");
		const config = {
			headers: {
			  "Content-Type": "application/json",
			  "Access-Control-Allow-Origin": "*",
			  "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
			  Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
			  accountId: localStorage.getItem("accountId"),
			  accountUserId: localStorage.getItem("accountUserId")
			},
		  };
		  try {
		  const res = yield  axios.get(`${API_BASE_URL}${MANAGE_PREPIX_PATH}${AUTH_PREFIX_PATH}/read-notification?notificationId=${payload.id}`,config);
			  if (res.status===200) {		
				const res = yield  axios.get(`${API_BASE_URL}${MANAGE_PREPIX_PATH}${API}${VERSION}${USERS_PREPIX_PATH}/notifications?userId=${userId}`,config);
					if (res.status===200) {
						yield put(notificatonList(res.data));
					} 
				  yield put(showAccountUserMessage(res));
			  } else {
				  yield put(showAuthMessage(res));
			  }
		  } catch (err) {
			  console.log(err.response);
			  yield put(showAuthMessage(err.response));
		  }
	  });
  }

  export function* deleteNotification() {
	yield takeEvery(DELETE_NOTIFICATION, function* ({payload}) {
		const {id} = payload;
		const userId = localStorage.getItem("userId");
		const config = {
			headers: {
			  "Content-Type": "application/json",
			  "Access-Control-Allow-Origin": "*",
			  "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
			  Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
			  accountId: localStorage.getItem("accountId"),
			  accountUserId: localStorage.getItem("accountUserId")
			},
		  };
		  try {
		  const res = yield  axios.put(`${API_BASE_URL}${MANAGE_PREPIX_PATH}${API}${VERSION}${USERS_PREPIX_PATH}/delete-notification?notificationId=${id}`,config);
			  if (res.status===200) {	
				Modal.info({
					title: "Notification deleted successfully",
					icon: <CheckCircleFilled />,
					okText: "Close",
					//cancelText: 'Cancel',
					onOk() {	
					}
				})	
				const res = yield  axios.get(`${API_BASE_URL}${MANAGE_PREPIX_PATH}${API}${VERSION}${USERS_PREPIX_PATH}/notifications?userId=${userId}`,config);
					if (res.status===200) {
						yield put(notificatonList(res.data));
					} 
				 // yield put(showAccountUserMessage(res));
			  } else {
				  yield put(showAuthMessage(res));
			  }
		  } catch (err) {
			  console.log(err.response);
			  yield put(showAuthMessage(err.response));
		  }
	  });
  }

  export function* deleteNotifications() {
	yield takeEvery(DELETE_NOTIFICATIONS, function* ({payload}) {
		const userId = localStorage.getItem("userId");
		const config = {
			headers: {
			  "Content-Type": "application/json",
			  "Access-Control-Allow-Origin": "*",
			  "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
			  Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
			  accountId: localStorage.getItem("accountId"),
			  accountUserId: localStorage.getItem("accountUserId")
			},
		  };
		  try {
		  const res = yield  axios.put(`${API_BASE_URL}${MANAGE_PREPIX_PATH}${API}${VERSION}${USERS_PREPIX_PATH}/delete-notifications?userId=${userId}`,config);
			  if (res.status===200) {		
				Modal.info({
					title: "Notifications deleted successfully",
					icon: <CheckCircleFilled />,
					okText: "Close",
					//cancelText: 'Cancel',
					onOk() {	
					}
				})	
				const res = yield  axios.get(`${API_BASE_URL}${MANAGE_PREPIX_PATH}${API}${VERSION}${USERS_PREPIX_PATH}/notifications?userId=${userId}`,config);
					if (res.status===200) {
						yield put(notificatonList(res.data));
					} 
				 // yield put(showAccountUserMessage(res));
			  } else {
				  yield put(showAuthMessage(res));
			  }
		  } catch (err) {
			  console.log(err.response);
			  yield put(showAuthMessage(err.response));
		  }
	  });
  }

  export function* resendInvitation(){
    yield takeEvery (RESEND_INVITATION, function*  ({payload}){
	    const { key,nId } = payload;
const config = {
		headers: { "Content-Type": 'application/json',"Access-Control-Allow-Origin": "*",
		"Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",Authorization : `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
		accountUserId: localStorage.getItem("accountUserId") }
	  };
		try {
		const res = yield  axios.put(`${API_BASE_URL}${MANAGE_PREPIX_PATH}${AUTH_PREFIX_PATH}/resend-invitation?key=${key}`,
		config);
			if (res.status===200) {
							Modal.success({
								title: res.data,
								icon: <CheckCircleFilled />,
								okText: "Close",
								//cancelText: 'Cancel',
								onOk() {	
									if(localStorage.getItem("userId")!=null)
									window.location.replace("/admin/app/pages/account-list?mode=view-all");
								}
							});
			} 
		} catch (err) {
			console.log(err);
			yield put(showAccountUserMessage(err.response));
		}
    })
}

export function* getAllUserList(){
    yield takeEvery (GET_ALL_USER_LIST, function*  ({requestData}){
	    const {query, sorter,pagination,type,userType} = requestData;
	   const accountId = localStorage.getItem("accountId");
	  const headers = {Authorization : `Bearer ${localStorage.getItem(AUTH_TOKEN)}`}
	//  var tokenHeader = new HttpHeaders({ 'Authorization': 'Bearer ' + localStorage.getItem(AUTH_TOKEN), 'Content-Type': 'application/json' });
const config = {
		headers: { "Content-Type": 'application/json',"Access-Control-Allow-Origin": "*",
		"Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",Authorization : `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
	    "accountId": accountId,
		accountUserId: localStorage.getItem("accountUserId")}
	  };
		try {
		const res = yield  axios.get(`${API_BASE_URL}${MANAGE_PREPIX_PATH}${APP_PREFIX_PATH}/account-user/users?query=${query}&sortfield=${sorter?.field}&sortorder=${sorter?.order}&
		page=${pagination?.current-1}&size=${pagination?.pageSize}`,
		config);
			if (res.status===200) {
				yield put(allUserList(res.data));
			} 
		} catch (err) {
			console.log(err);
			if(err?.response?.status==401 || err?.response?.data?.status==401){
				yield put(showAccountUserMessage(err.response));
			}
			yield put(showAccountUserMessage(err.response));
		}
    })
}

export default function* rootSaga(){
    yield all([
		  fork(getAccountUserList),
		  fork(getInviteAccountUserList),
		  fork(sendInvitationEmail),
		  fork(acceptInvitations),
		  fork(getNotificationList),
		  fork(clearNotificationData),
		  fork(readNotificationData),
		  fork(removeAccountUser),
		  fork(deleteUserInvitation),
		  fork(updateAccountUser),
		  fork(deleteNotification),
		  fork(deleteNotifications),
		  fork(resendInvitation),
		  fork(getAllUserList)
    ])
    
}