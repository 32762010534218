export const GET_CONNECTORS_API_LIST="GET_CONNECTORS_API_LIST";
export const CONNECTOR_API_MESSAGE="CONNECTOR_API_MESSAGE";
export const CONNECTORS_API_LIST="CONNECTORS_API_LIST";
export const SHOW_LOADING="SHOW_LOADING";
export const HIDE_LOADING="HIDE_LOADING";
export const DELETE_CONNECTOR_API="DELETE_CONNECTOR_API";
export const UPDATE_CONNECTOR_API="UPDATE_CONNECTOR_API";
export const ADD_CONNECTOR_API="ADD_CONNECTOR_API";
export const SET_ADD_EDIT="SET_ADD_EDIT";
export const CONNECTOR_API_REGISTER_SUCCESS="CONNECTOR_API_REGISTER_SUCCESS";
export const TEST_CONNECTOR="TEST_CONNECTOR";
export const CONNECTOR_DATA="CONNECTOR_DATA";
export const TEST_CONNECTOR_DATA="TEST_CONNECTOR_DATA";
export const SHOW_HIDE_TEST_CONNECTOR="SHOW_HIDE_TEST_CONNECTOR";
export const GET_INSTANCE_CONNECTORS_API_LIST="GET_INSTANCE_CONNECTORS_API_LIST";
export const INSTANCE_CONNECTORS_API_LIST="INSTANCE_CONNECTORS_API_LIST";
export const UPDATE_INSTANCE_CONNECTOR_API="UPDATE_INSTANCE_CONNECTOR_API";