import {
  GET_APP_LIST,
  APP_LIST,
  SAVE_APP,
  APP_REGISTER_SUCCESS,
  APP_MESSAGE,
  DELETE_APP,
  APP_DATA,
  PUBLISHED_APP_LIST,
  SUBSCRIBED_APP_LIST,
  DEVELOPER_APP,
  GET_MFA_SETUP_HOME_SUCCESS,
  TOGGLE_2FA,
  APP_PERMISSION_LIST,
  APP_ROLE_LIST,
  APP_ROLE_PERMISSION_LIST,
  SET_ADD_EDIT,
  APP_SETTINGS_JSON,
  ADD_APP_SETTINGS_JSON,
  GET_APP_SETTINGS_DETAILS,
  APP_INSTANCE_DATA,
  SHOW_LOADING,
  HIDE_LOADING,
  UI_PROPERTIES_JSON,
  APP_UI_PROPERTIES,
  ADD_APP_UI_PROPERTIES,
  EDIT_APP_UI_PROPERTIES,
  DELETE_APP_UI_PROPERTIES,
  ACCOUNT_USER_APP_INSTANCE_ROLES,
} from "redux/constants/app";
import { APP_PREFIX_PATH } from "configs/AppConfig";

const initState = {
  loading: true,
  apps: [],
  publishedApps: [],
  subscribedApps: [],
  appPermissions: [],
  appRolePermissions: [],
  appRoles: [],
  uiPropertiesJsonData: [],
  accountUserRoles:[],
  developerApp: {},
  app: {},
  message: "",
  showMessage: false,
  using2fa: false,
  redirect: "",
  allowRedirect: false,
  status: 0,
  addEditValue: false,
  permissions : localStorage.getItem("permissions")!=""?JSON.parse(localStorage.getItem("permissions")):"",
};

const app = (state = initState, action) => {
  switch (action?.type) {
    case APP_LIST:
      return {
        ...state,
        apps: action.apps,
        allowRedirect: false,
        loading: false,
        message: "",
      };
    case PUBLISHED_APP_LIST:
      return {
        ...state,
        publishedApps: action.publishedApps,
        allowRedirect: false,
        loading: false,
        message: "",
      };
    case SUBSCRIBED_APP_LIST:
      return {
        ...state,
        subscribedApps: action.subscribedApps,
        loading: false,
        allowRedirect: false,
        message: "",
      };
    case DEVELOPER_APP:
      return {
        ...state,
        developerApp: action.developerApp,
        allowRedirect: false,
        loading: false,
        message: "",
      };
    case APP_DATA:
      return {
        ...state,
        app: action.app,
        loading: false,
        allowRedirect: false,
        message: "",
      };
    case APP_MESSAGE:
      return {
        ...state,
        message: messageRead(action),
        status: action?.response?.status,
        showMessage: true,
        loading: false,
      };
    case GET_MFA_SETUP_HOME_SUCCESS:
      return {
        ...state,
        loading: false,
        message: action?.data?.message,
        mfaCode: action?.data?.mfaCode,
        qrCode: action?.data?.qrCode,
        loading: false,
        showMessage: true,
      };
    case APP_REGISTER_SUCCESS:
      return {
        ...state,
        message: messageRead(action),
        status: action?.response?.status,
        showMessage: true,
        loading: false,
        allowRedirect: true,
        redirect: `${APP_PREFIX_PATH}/${localStorage.getItem(
          "accountId"
        )}/developer-app`,
      };
    case TOGGLE_2FA: {
      return {
        ...state,
        loading: false,
        using2fa: action.using2fa,
      };
    }
    case APP_PERMISSION_LIST:
      return {
        ...state,
        appPermissions: action.appPermissions,
        loading: false,
        allowRedirect: false,
        message: "",
      };
    case APP_ROLE_LIST:
      return {
        ...state,
        appRoles: action.appRoles,
        loading: false,
        allowRedirect: false,
        message: "",
      };
    case APP_ROLE_PERMISSION_LIST:
      return {
        ...state,
        appRolePermissions: action.appRolePermissions,
        loading: false,
        allowRedirect: false,
        message: "",
      };
    case ACCOUNT_USER_APP_INSTANCE_ROLES:
      return {
        ...state,
        accountUserRoles: action.accountUserRoles,
        loading: false,
        allowRedirect: false,
        message: "",
      }
    case SHOW_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }
    case HIDE_LOADING: {
      return {
        ...state,
        loading: false,
      };
    }
    case SET_ADD_EDIT:
      return {
        ...state,
        addEditValue: action.addEditValue,
        loading: false,
        appRolePermissions: [],
      };
    case APP_SETTINGS_JSON:
      return {
        ...state,
        appSettingsJson: action.appSettingsJson,
        loading: false,
        message: "",
      };
    case ADD_APP_SETTINGS_JSON:
      return {
        ...state,
        loading: false,
        resData: {},
      };

    case GET_APP_SETTINGS_DETAILS:
      return {
        ...state,
        loading: false,
        resData: {},
      };
    case APP_INSTANCE_DATA:
      return {
        ...state,
        loading: false,
        app: action.appInstanceData,
      };
    case APP_UI_PROPERTIES:
      return { ...state, loading: false, resData: {} };
    case ADD_APP_UI_PROPERTIES:
      return { ...state, loading: false, resData: {} };
    case EDIT_APP_UI_PROPERTIES:
      return { ...state, loading: false, resData: {} };
    case DELETE_APP_UI_PROPERTIES:
      return { ...state, loading: false, resData: {} };
      case UI_PROPERTIES_JSON:
      return {
        ...state,
        uiPropertiesJsonData: action.uiPropertiesJsonData,
        loading: false,
        message: "",
      };
    default:
      return state;
  }
};

const messageRead=(action)=>{
  if(action?.response?.status==401 || action?.response?.response?.status){
    return "Unauthorized"
  }
	if(action?.response && action?.response?.response && action?.response?.response?.data && action?.response?.response?.message){
	 return action?.response?.response?.data?.message;
	}else if(action?.response && action?.response?.data && action?.response?.data?.message){
		return action?.response?.data?.message;
	}else if(action?.response && action?.response?.data && action?.response?.data?.message){
		return action?.response?.data?.message;
	}else if(action?.data?.message){
		return action?.data?.message;
	}else if(action?.data){
		return action?.data;
	}else{
		return "something went wrong"
	}
}

export default app;
