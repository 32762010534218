import React, { lazy } from "react";
import { Route, Switch, Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import AppLayout from "layouts/app-layout";
import AuthLayout from "layouts/auth-layout";
import OrderLayout from "layouts/order-layout";
import AppLocale from "lang";
import { IntlProvider } from "react-intl";
import { ConfigProvider } from "antd";
import {
  APP_PREFIX_PATH,
  AUTH_PREFIX_PATH,
  CUSTOM_CART,
} from "configs/AppConfig";
import useBodyClass from "hooks/useBodyClass";
import i18n from "i18next";

function RouteInterceptor({ children, isAuthenticated, ...rest }) {
  return (
    <Route
      {...rest}
      render={
        ({ location }) =>
          isAuthenticated
            ? children
            : window.location.replace("/admin" + AUTH_PREFIX_PATH + "/login")
        // <Redirect
        //   to={{
        //     pathname: AUTH_PREFIX_PATH+"/login",
        //     state: { from: location }
        //   }}
        // />
      }
    />
  );
}

export const Views = (props) => {
  const { locale, token, location, direction } = props;
  const currentAppLocale = AppLocale[locale];
  i18n.changeLanguage(localStorage.getItem("langId"));
  useBodyClass(`dir-${direction}`);
  return (
    <IntlProvider
      locale={currentAppLocale.locale}
      messages={currentAppLocale.messages}
    >
      <ConfigProvider locale={currentAppLocale.antd} direction={direction}>
        <Switch>
          <Route path={CUSTOM_CART}>
            <OrderLayout direction={direction} />
          </Route>
          <Route exact path="/">
             
            {token ? (
              <Redirect to={`${APP_PREFIX_PATH}/pages/account-list`} />
            ) : (
              <Redirect to={`${AUTH_PREFIX_PATH}/login`} />
            )}
          </Route>
          <Route exact path={`/${APP_PREFIX_PATH}`}>
             
            {token ? (
              <Redirect to={`${APP_PREFIX_PATH}/pages/account-list`} />
            ) : (
              <Redirect to={`${AUTH_PREFIX_PATH}/login`} />
            )}
          </Route>

          <Route path={AUTH_PREFIX_PATH}>
            <AuthLayout direction={direction} />
          </Route>
          <RouteInterceptor path={APP_PREFIX_PATH} isAuthenticated={token}>
            <AppLayout direction={direction} location={location} />
          </RouteInterceptor>
          <Route path="/*" >
          <Redirect to={`${AUTH_PREFIX_PATH}/404`} />
            </Route>
        </Switch>
      </ConfigProvider>
<link rel="canonical" href={window.location.href}></link>
    </IntlProvider>
  );
};

const mapStateToProps = ({ theme, auth }) => {
  const { locale, direction } = theme;
  const { token } = auth;
  return { locale, direction, token };
};

export default withRouter(connect(mapStateToProps)(Views));
